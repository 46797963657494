
const LogoutMobileIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.10837 13C8.10837 12.4201 8.57847 11.95 9.15837 11.9499L20.9649 11.9499L18.1476 9.13265C17.7376 8.72259 17.7376 8.05777 18.1476 7.64772C18.5577 7.23767 19.2225 7.23767 19.6325 7.64772L24.2423 12.2575C24.4392 12.4544 24.5498 12.7215 24.5498 12.9999C24.5498 13.2784 24.4392 13.5455 24.2423 13.7424L19.6325 18.3521C19.2225 18.7622 18.5577 18.7622 18.1476 18.3521C17.7376 17.9421 17.7376 17.2773 18.1476 16.8672L20.9649 14.0499L9.15837 14.0499C8.57847 14.0499 8.10837 13.5799 8.10837 13Z" fill="#898A8C" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.54971 3.29385V22.706H13.7424V19.7999C13.7424 19.6343 13.8767 19.5 14.0424 19.5H15.5424C15.7081 19.5 15.8424 19.6343 15.8424 19.7999V22.7316C15.8424 23.8773 14.9137 24.806 13.768 24.806H3.5241C2.37844 24.806 1.44971 23.8773 1.44971 22.7316V3.26824C1.44971 2.12258 2.37844 1.19385 3.5241 1.19385H13.768C14.9137 1.19385 15.8424 2.12258 15.8424 3.26824V6.19995C15.8424 6.36564 15.7081 6.49995 15.5424 6.49995H14.0424C13.8767 6.49995 13.7424 6.36564 13.7424 6.19995V3.29385H3.54971Z" fill="#898A8C" />
        </svg>
    )
}

export default LogoutMobileIcon