import { EventInfoFormStateType } from './types'
import dayjs from 'dayjs'
import { ValidationErrors } from "@store/types";
import { OrganizerEventDataFullItem } from '@store/type-event/events/models';

export const initEventInfoFormState = (fetchedEvent: OrganizerEventDataFullItem | undefined): EventInfoFormStateType => {
    const initialDates = fetchedEvent?.schedule ? [dayjs(fetchedEvent.schedule.started_at ?? '', 'YYYY/MM/DD'), dayjs(fetchedEvent.schedule.finished_at ?? '', 'YYYY/MM/DD')] : ['', '']

    return {
        avatarUrl: fetchedEvent?.image_logo || '',
        category: { id: fetchedEvent?.category?.id || 0, slug: fetchedEvent?.category?.name || '' },
        dates: initialDates,
        eventName: fetchedEvent?.name || '',
        heroImageUrl: fetchedEvent?.image_bg || '',
        overview: fetchedEvent?.description || '',
        url: fetchedEvent?.url || '',
        location: { id: fetchedEvent?.location?.id || 0, slug: fetchedEvent?.location?.slug || '' },
        participants: fetchedEvent?.participants || 100,

    }

}
export const initEventInfoFormValues = (fetchedEvent: OrganizerEventDataFullItem | undefined) => {
    const initialDates = fetchedEvent?.schedule ? [dayjs(fetchedEvent.schedule.started_at ?? '', 'YYYY/MM/DD'), dayjs(fetchedEvent.schedule.finished_at ?? '', 'YYYY/MM/DD')] : ['', '']
    return {
        avatar: fetchedEvent?.image_logo || '',
        eventName: fetchedEvent?.name || '',
        dates: initialDates,
        heroImage: fetchedEvent?.image_bg || '',
        overview: fetchedEvent?.description || '',
        url: fetchedEvent?.url || '',
        location: fetchedEvent?.location || { id: 0, slug: '' },
        category: fetchedEvent?.category || { id: 0, slug: '' },
        participants: fetchedEvent?.participants || 100,
    }
}

export const initErrorState = (): ValidationErrors<EventInfoFormStateType> => {
    return [
        {
            field: 'avatarUrl',
            messages: []
        },
        {
            field: 'category',
            messages: []
        },
        {
            field: "dates",
            messages: []
        },
        {
            field: 'eventName',
            messages: []
        },
        {
            field: 'location',
            messages: []
        },
        {
            field: "heroImageUrl",
            messages: []
        },
        {
            field: "overview",
            messages: []
        },
        {
            field: "participants",
            messages: []
        },
        {
            field: "url",
            messages: []
        }
    ]
}
