import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { Badge, Button, Input, Spin } from "antd";
import FilterButtonIcon from "@assets/FilterButtonIcon.tsx";
import FilterButtonMobileIcon from "@assets/buttons/mobile/FilterButtonMobileIcon.tsx";
import ExitCrossIcon from "@assets/ExitCrossIcon.tsx";
import React, { useCallback, useState } from "react";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { useGetOrganizerPartnersByEventQuery } from "@store/type-event/partners/partners.api.ts";
import { useBreakpoints } from "@hooks/browser";
import {
    FiltersKeys,
    initialFilters
} from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/constants.ts";
import { Filters } from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/types.ts";
import { useParams } from "react-router-dom";
import { SearchIcon } from "@assets/SearchIcon.tsx";

type Props = {
    filters: Filters
    setFilters: (filters: (prev: Filters) => Filters) => void | Filters
    setInitialFilters: () => void
}

const CampaignsTabFilters = ({
    filters,
    setFilters,
    setInitialFilters,
}: Props) => {
    const { eventId } = useParams()

    const isDesktop = useBreakpoints().md

    const [partnersPage, setPartnersPage] = useState(INIT_PAGE);

    const { data: partnersRes, isLoading: isPartnersLoading, isFetching: isPartnersFetching } = useGetOrganizerPartnersByEventQuery({
        eventId: eventId || '', page: partnersPage
    }, { skip: !eventId })

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[] | boolean) => {
        setFilters((prev) => ({ ...prev, [field]: value }))
    }, [])


    return (
        <div className={`gap-8 mb-12 organizer-event-tab__campaigns__filters organizer-event-tab__campaigns__filters-drawer`}>

            <div className="organizer-event-tab__campaigns__filters-label">
                Sponsors
            </div>
            <InfiniteSelect
                id="organizer-billing-page__partners"
                style={{ width: '100%' }}
                // placeholder='select'
                maxTagCount="responsive"
                mode="multiple"
                filterOption={false}
                notFoundContent={isPartnersLoading || isPartnersFetching ? <Spin size="small" /> : <div>There is no sponsors for selected event</div>}
                isLoading={isPartnersLoading || isPartnersFetching}
                value={filters.partners || []}
                onChange={(value: string[]) => handleChangeFilter(FiltersKeys.partners, value)}
                optionsMapper={({ name, organization }) => ({ value: organization.id.toString(), label: name })}
                data={partnersRes}
                listHeight={130}
                page={partnersPage}
                setPage={setPartnersPage}
                allowClear
                classNameProp="analytics-page__infinite-select__sponsor"
            />
            {isDesktop &&
                <Button
                    size='small'
                    type="text"
                    onClick={setInitialFilters}
                    className="analytics-page__reset-button"
                >
                    <ExitCrossIcon fill="#898A8C" />
                    Reset
                </Button>
            }
        </div>
    )
}

export default CampaignsTabFilters
