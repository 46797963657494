const MarkIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.24674 3.17264C8.01395 2.93725 7.63465 2.93428 7.39822 3.16603L2.96322 7.5493L1.03528 5.55341C0.805336 5.31375 0.424656 5.30588 0.185022 5.53583C-0.0546382 5.76577 -0.0625045 6.14645 0.16744 6.38609C0.168482 6.38715 0.169498 6.38822 0.17054 6.38929L2.52125 8.82538C2.63375 8.94014 2.7875 9.00513 2.94821 9.00578H2.95483C3.11249 9.00529 3.26367 8.94288 3.37578 8.832L8.24132 4.02115C8.47639 3.78803 8.47879 3.40876 8.24674 3.17264Z" fill={fillColor || "#252628"} />
            <path d="M6.12954 8.82591C6.24175 8.94039 6.39502 9.00532 6.55531 9.00631H6.56193C6.71959 9.00582 6.87077 8.94341 6.98288 8.83252L11.8484 4.02168C12.069 3.77335 12.0465 3.39327 11.7981 3.17273C11.573 2.97279 11.2347 2.97016 11.0065 3.16655L6.57334 7.54983L6.41518 7.38565C6.18526 7.14599 5.80458 7.1381 5.56492 7.36805C5.32526 7.59796 5.31737 7.97864 5.54731 8.2183C5.54835 8.2194 5.54939 8.22047 5.55044 8.22153L6.12954 8.82591Z" fill="#1B1C1E" />
        </svg>
    )
}

export default MarkIcon
