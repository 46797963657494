
const PersonZoomCardIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.15165 12.8503C4.70291 10.4016 4.70291 6.43138 7.15165 3.98265C9.60038 1.53391 13.5706 1.53391 16.0193 3.98265C18.468 6.43138 18.468 10.4016 16.0193 12.8503C13.5706 15.299 9.60038 15.299 7.15165 12.8503ZM6.1617 13.8403C6.02391 13.7025 5.89246 13.5607 5.76735 13.4154C5.45305 13.0504 5.17875 12.6628 4.94445 12.2584C3.24441 9.32415 3.65015 5.50424 6.1617 2.9927C9.15717 -0.00277376 14.0138 -0.00277376 17.0093 2.9927C20.0047 5.98817 20.0047 10.8448 17.0093 13.8403C14.4973 16.3522 10.6765 16.7577 7.74201 15.0566C7.33768 14.8222 6.95017 14.5479 6.58524 14.2335C6.44038 14.1087 6.29908 13.9776 6.1617 13.8403Z" fill="#C0C1C3" />
            <path d="M2.31619 17.6846C2.54207 17.9105 2.90829 17.9105 3.13417 17.6846L6.58524 14.2335C6.95017 14.5479 7.33768 14.8222 7.74201 15.0566L4.12412 18.6745C3.3515 19.4471 2.09885 19.4471 1.32624 18.6745C0.553629 17.9019 0.553627 16.6493 1.32624 15.8767L4.94445 12.2584C5.17875 12.6628 5.45305 13.0504 5.76735 13.4154L2.31619 16.8666C2.09031 17.0925 2.09031 17.4587 2.31619 17.6846Z" fill="#C0C1C3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4518 7.27512C10.8783 7.27512 10.4111 6.80937 10.4111 6.23209C10.4111 5.65481 10.8783 5.18906 11.4518 5.18906C12.0254 5.18906 12.4926 5.65481 12.4926 6.23209C12.4926 6.80937 12.0254 7.27512 11.4518 7.27512ZM11.4518 8.67512C10.1038 8.67512 9.01106 7.58134 9.01106 6.23209C9.01106 4.88284 10.1038 3.78906 11.4518 3.78906C12.7998 3.78906 13.8926 4.88284 13.8926 6.23209C13.8926 7.58134 12.7998 8.67512 11.4518 8.67512Z" fill="#C0C1C3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4644 11.901H14.294H8.85307H7.68268C7.57222 11.901 7.48242 11.8113 7.48502 11.7009C7.51604 10.3859 7.85514 9.2524 8.51479 8.52367C9.21561 7.74944 10.228 7.30078 11.4523 7.30078C12.7509 7.30078 13.8274 7.73635 14.5797 8.50388C15.2919 9.23061 15.6309 10.3956 15.6621 11.7009C15.6647 11.8114 15.5749 11.901 15.4644 11.901ZM9.53031 9.44471C9.94883 8.98236 10.58 8.67251 11.4523 8.67251C12.4358 8.67251 13.1417 8.99545 13.6014 9.46451C13.8828 9.75157 14.0794 9.97563 14.1884 10.5519H8.96366C9.07672 9.96467 9.26997 9.73233 9.53031 9.44471Z" fill="#C0C1C3" />
        </svg>
    )
}

export default PersonZoomCardIcon