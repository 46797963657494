import React from "react";
import styles from "./LoadingView.module.css";
import LottieAnimation from "@shared/LottieAnimation";
import animationData from "@assets/lottie/LoadingViewLottie.json"

const LoadingView: React.FC = () => {
    return (
        <div className={styles.LoadingView__loadingSpin}>
            <LottieAnimation  animationData={animationData} height={112} width={112}/>
        </div>
    )
}

export default LoadingView;
