//https://boost.allnetru.dev/api/documentation#/User
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    InviteInfoResponse,
    PasswordResetRequest,
    UserProfileUpdate,
    UserRegistrationResponse, UserLoginResponse, ChangeUserPasswordRequest
} from '@store/models-to replace/auth';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const userauth = `user`;

export const userAuth = createApi({
    reducerPath: userauth,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        userauth
    ],
    endpoints: build => ({
        // AUTHENTIFICATION
        getUser: build.query<UserLoginResponse, void>({
            query: () => ({
                url: '/login/user',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: ['User'],
            skipCache: true,
        }),
        getInviteByEventHash: build.query<InviteInfoResponse, string>({
            query: (event_hash: string) => ({
                url: `register/invite/${event_hash}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            })
        }),

        createEditorUser: build.mutation<UserRegistrationResponse, { email: string, password: string }>({
            query: ({ email, password }) => ({
                body: {
                    email,
                    password
                },
                method: 'POST',
                url: '/register',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [userauth],
        }),
        createUserAccount: build.mutation<UserRegistrationResponse, { email: string, password: string }>({
            query: ({ email, password }) => ({
                body: {
                    email,
                    password,
                    remember: true
                },
                method: 'POST',
                url: '/register',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [userauth],
        }),
        //TODO
        // createUser: build.mutation<UserRegistrationResponse, UserCreate>({
        //     query: (body) => ({
        //         body: {
        //             email: body.email,
        //             password: body.password
        //         },
        //         method: 'POST',
        //         url: '/register',
        //         headers: {
        //             Accept: 'application/json', // Specify JSON response
        //         },
        //     }),
        //     invalidatesTags: [userauth],
        // }),
        logout: build.mutation<UserLoginResponse, void>({
            query: () => ({
                method: `POST`,
                url: `/logout`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
        }),
        passUpdate: build.mutation<any, { password: string }>({
            query: (body) => ({
                body,
                method: `PATCH`,
                url: `/register/password`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
        }),
        loginUser: build.mutation<UserLoginResponse, { email: string; password: string }>({
            query: (body) => ({
                body: { ...body, remember: true },
                method: 'POST',
                url: '/login',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },

            }),
            invalidatesTags: [userauth],
        }),
        // authFB: build.query<any, void>({
        //     query: () => ({
        //         url: `/login/auth/facebook`,
        //         headers: {
        //             Accept: 'application/json', // Specify JSON response
        //         },
        //     }),
        //     providesTags: [userauth]
        // }),
        // authGoogle: build.query<any, void>({
        //     query: () => ({
        //         url: `/login/auth/google`,
        //         headers: {
        //             Accept: 'application/json', // Specify JSON response
        //         },
        //     }),
        //     providesTags: [userauth]
        // }),
        // authUser: build.query<any, { social: string }>({
        //     query: ({social}) => ({
        //         url: `/login/auth/${social}`,
        //         headers: {
        //             Accept: 'application/json', // Specify JSON response
        //         },
        //     }),
        //     providesTags: [userauth]
        // }),
        //PROFILE
        patchUserProfile: build.mutation<UserLoginResponse, UserProfileUpdate>({
            query: (body) => ({
                url: '/user',
                method: 'PATCH',
                body,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }),
            invalidatesTags: [userauth],

        }),
        passwordRecover: build.mutation<void, { email: string }>({
            query: (body) => ({
                body: { ...body },
                method: 'POST',
                url: '/forgot-password',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },

            }),
            invalidatesTags: [userauth],

        }),
        passwordReset: build.mutation<void, PasswordResetRequest>({
            query: (body) => ({
                url: '/reset-password',
                method: 'POST',
                body,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [userauth],
        }),
        passwordChange: build.mutation<void, { password: string, password_confirmation: string }>({
            query: (body) => ({
                url: '/change-password',
                method: 'POST',
                body,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [userauth],
        }),
        passwordUpdate: build.mutation<void, ChangeUserPasswordRequest>({
            query: (body) => ({
                url: '/user/change-password',
                method: 'POST',
                body,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [userauth],
        }),
    }),
});

export const {
    useCreateEditorUserMutation,
    // useCreateUserMutation,
    useLogoutMutation,
    useLazyGetUserQuery,
    useLoginUserMutation,
    usePassUpdateMutation,
    // useGetUserQuery,
    useGetInviteByEventHashQuery,
    useLazyGetInviteByEventHashQuery,
    usePasswordChangeMutation,
    // useAuthFBQuery,
    // useAuthGoogleQuery,
    // useAuthUserQuery,
    // useUpdateUserMutation,
    // useGetUser2Query,
    usePatchUserProfileMutation,
    usePasswordRecoverMutation,
    usePasswordResetMutation,
    usePasswordUpdateMutation,
    useCreateUserAccountMutation
} = userAuth;