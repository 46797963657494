
const PartnersCardIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5553_2521)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.392265 8.56805C0.0979298 8.27371 0.0979863 7.79654 0.392265 7.50227L6.32913 1.5654C6.62075 1.27378 7.09319 1.27423 7.38436 1.5654L12.378 6.55899C13.0978 7.2788 13.0978 8.44584 12.378 9.16564C11.6581 9.88545 10.4911 9.88545 9.7713 9.16564L6.53949 5.93384C6.24842 5.64276 6.24842 5.17084 6.53949 4.87977C6.83057 4.58869 7.30249 4.58869 7.59356 4.87977L10.8254 8.11158C10.963 8.24924 11.1862 8.24924 11.3239 8.11158C11.4615 7.97392 11.4615 7.75072 11.3239 7.61306L6.85675 3.14592L1.96751 8.03516L3.09376 9.1614C3.15844 9.22608 3.15844 9.33095 3.09375 9.39564L2.27392 10.2155C2.20924 10.2802 2.10437 10.2802 2.03969 10.2155L0.392265 8.56805Z" fill="#C0C1C3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12.346 1.75945C12.6403 1.46517 13.1175 1.46512 13.4118 1.75945L19.6624 8.01003C19.9567 8.30432 19.9567 8.78149 19.6624 9.07581L10.3614 18.3768L9.45456 17.4196L18.0871 8.54292L12.8789 3.3347L10.489 5.72456L9.43498 4.67049L12.346 1.75945Z" fill="#C0C1C3" />
                <path d="M6.75 8.28809L3.56373 11.4744C3.12308 11.915 3.12308 12.6294 3.56373 13.0701V13.0701C4.00438 13.5108 4.71882 13.5108 5.15947 13.0701L8.34574 9.88383" stroke="#C0C1C3" strokeWidth="1.32505" strokeLinecap="round" />
                <path d="M8.81418 10.3532C9.07291 10.0945 9.07291 9.67502 8.81418 9.41629C8.55545 9.15756 8.13596 9.15756 7.87723 9.41629L8.81418 10.3532ZM9.47297 11.012L6.2867 14.1983L7.22365 15.1353L10.4099 11.949L9.47297 11.012ZM5.62791 13.5395L8.81418 10.3532L7.87723 9.41629L4.69096 12.6026L5.62791 13.5395ZM5.62791 14.1983C5.44599 14.0164 5.44599 13.7214 5.62791 13.5395L4.69096 12.6026C3.99157 13.3019 3.99157 14.4359 4.69096 15.1353L5.62791 14.1983ZM6.2867 14.1983C6.10478 14.3802 5.80983 14.3802 5.62791 14.1983L4.69096 15.1353C5.39034 15.8346 6.52426 15.8346 7.22365 15.1353L6.2867 14.1983Z" fill="#C0C1C3" />
                <path d="M9.94141 11.4795L6.75514 14.6658C6.31448 15.1064 6.31448 15.8209 6.75514 16.2615V16.2615C7.19579 16.7022 7.91022 16.7022 8.35088 16.2615L11.5371 13.0752" stroke="#C0C1C3" strokeWidth="1.32505" strokeLinecap="round" />
                <path d="M12.0056 13.5446C12.2643 13.2859 12.2643 12.8664 12.0056 12.6077C11.7469 12.349 11.3274 12.349 11.0686 12.6077L12.0056 13.5446ZM12.6644 14.2034L9.4781 17.3897L10.4151 18.3267L13.6013 15.1404L12.6644 14.2034ZM8.81931 16.7309L12.0056 13.5446L11.0686 12.6077L7.88236 15.794L8.81931 16.7309ZM8.81931 17.3897C8.63739 17.2078 8.63739 16.9128 8.81931 16.7309L7.88236 15.794C7.18298 16.4934 7.18298 17.6273 7.88236 18.3267L8.81931 17.3897ZM9.4781 17.3897C9.29619 17.5716 9.00123 17.5716 8.81931 17.3897L7.88236 18.3267C8.58175 19.026 9.71567 19.026 10.4151 18.3267L9.4781 17.3897Z" fill="#C0C1C3" />
            </g>
            <defs>
                <clipPath id="clip0_5553_2521">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PartnersCardIcon