import React from "react";
import LogoHeaderAuth from "@assets/LogoHeaderAuth";
import { Typography } from "antd";
import PartnerOnboardingCircleErrorIcon from "@assets/PartnerOnboardingCircleErrorIcon";
import '../../styles.scss'

type PartnerOnboardingErrorViewPropsType = {
    message: string;
    closeErrorModal: () => void
}
const PartnerOnboardingErrorView: React.FC<PartnerOnboardingErrorViewPropsType> = (props) => {
    const { message, closeErrorModal } = props;

    return (
        <>
            <header className='partner-onboarding-error-view__header'>
                <LogoHeaderAuth />
            </header>
            <div className='partner-onboarding-error-view__content-slot'>
                <section className='partner-onboarding-error-view__modal'>
                    <PartnerOnboardingCircleErrorIcon />
                    <Typography.Title level={4} className='partner-onboarding-error-view__title'>
                        Invalid invitation code
                    </Typography.Title>
                    <div>
                        <Typography.Text className='partner-onboarding-error-view__text'>
                            {message}
                        </Typography.Text>
                        <Typography.Text
                            className='partner-onboarding-error-view__text'
                            style={{ padding: "0 4px" }}
                        >
                            Please
                        </Typography.Text>
                        <Typography.Text
                            className='partner-onboarding-error-view__link'
                            onClick={closeErrorModal}
                        >
                            try another one
                        </Typography.Text>
                    </div>
                </section>
            </div>
        </>
    )
}

export default PartnerOnboardingErrorView;
