import React, { useContext, useEffect } from 'react'
import BillingStripeFormWrapper from '@components/BillingStripeForm';
import { StripeContext } from '@contexts/StripeContext';
import Loader from '@components/Loader';
import MobileDrawer from '@components/mobile/Drawer';
interface AddPaymentDrawerMobileProps {
    isOpen: boolean;
    onClose: () => void;
    isLoading?: boolean;
}

const AddPaymentDrawerMobile: React.FC<AddPaymentDrawerMobileProps> = ({
    isOpen,
    onClose,
    isLoading
}) => {

    const {
        onOpenStripeForm,
        loading,
        intent,
        setIntent,
        apiKey,
        onAddPaymentClick
    } = useContext(StripeContext)


    useEffect(() => {
        if (isOpen) {
            onOpenStripeForm()
        }
    }, [isOpen])

    const onModalClose = () => {
        onClose();
        setIntent(null)
    }

    const onSubmit = async () => {
        await onAddPaymentClick()
            .then(() => onClose())
    }
    return (

        <MobileDrawer
            title='Add new card'
            isOpen={isOpen}
            onClose={onModalClose}
            onCancel={onModalClose}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onOk={onSubmit}
            resetButtonText="Cancel"
            applyButtonText="Save"
            isLoading={loading || isLoading}
        >
            <>

                {loading && <Loader />}
                {intent
                    ?
                    <BillingStripeFormWrapper
                        intent={intent}
                        apiKey={apiKey}
                    />
                    : null
                }</>

        </MobileDrawer>
    )
}

export default AddPaymentDrawerMobile