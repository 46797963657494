import React from "react";

export const HeroImageDropDownIcon: React.FC = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.018 9.58442L19.0137 9.56724L16.6032 3.44204C16.4958 3.09614 16.1757 2.85767 15.8126 2.85767H6.04148C5.67624 2.85767 5.35183 3.10044 5.2487 3.45063L2.99499 9.51353L2.98855 9.52856L2.98425 9.54575C2.95632 9.65103 2.94773 9.75845 2.96277 9.86372C2.96062 9.8981 2.95847 9.93247 2.95847 9.96685V17.828C2.95904 18.1742 3.09684 18.5062 3.34169 18.751C3.58654 18.9959 3.91846 19.1337 4.26472 19.1342H17.7397C18.4594 19.1342 19.046 18.5477 19.0481 17.828V9.96685C19.0481 9.93892 19.0481 9.91099 19.046 9.88735C19.0546 9.78208 19.046 9.6811 19.018 9.58442ZM12.663 8.66059L12.6565 8.9979C12.6393 9.96255 11.9733 10.6114 11.0001 10.6114C10.5253 10.6114 10.1171 10.4588 9.82273 10.1688C9.52839 9.87876 9.36726 9.47485 9.35867 8.9979L9.35222 8.66059H4.93074L6.63874 4.50767H15.2153L16.9706 8.66059H12.663ZM4.60632 10.3106H7.98581C8.50788 11.5374 9.61863 12.2614 11.0022 12.2614C11.7262 12.2614 12.3987 12.0594 12.9423 11.677C13.4192 11.3418 13.7909 10.8735 14.0315 10.3106H17.3938V17.4842H4.60632V10.3106Z" fill="currentColor"/>
        </svg>

    )
}
