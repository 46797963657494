export enum EEventTypes {
  GTM_UI_EVENT = 'gtm-ui-event',
}

export enum GTMEventName {
  generalRegistration = 'general_registration',
  eventRegistration = 'event_registration',
  partnerRegistration = 'partner_registration',
  createCompanyStep = 'create_company_step',
  partnerOnboardingFirstStep = 'partner_onboarding_first_step',
  partnerOnboardingSecondStep = 'partner_onboarding_add_billing_info',
  partnerOnboardingThirdStep = 'partner_onboarding_enter_card_data',
  eventOnboardingFirstStep = 'event_onboarding_fill_company_details',
  eventOnboardingSecondStep = 'event_onboarding_add_first_event',
  login = 'login',
  menuItemClick = 'menu_item_click',
  eventCatalog = 'event_catalog',
  profile = 'profile',
  partnerEventAction = 'partner_event_action',
  partnerEventCatalogAction = 'partner_event_catalog_action',
  saveLanding = 'save_landing',
  deleteCampaign = 'delete_campaign',
  pageVisit = 'page_visit',
  saveCampaign = 'save_campaign',
  confirmEmail = 'confirm_email',
  changeProfile = 'change_profile',
  viewNotifications = 'view_notifications',
  cancelProSubscription = 'cancel_pro_subscription',
  enableProSubscription = 'enable_pro_subscription',
  enterCardData = 'enter_card_data',
  saveEvent = 'save_event',
  publishEvent = 'publish_event',
  addInvitations = 'add_invitations',
  inviteStatus = 'invite_status',
  landingStatus = 'landing_status',
  addBillingInfo = 'add_billing_info',
}
