import React, {useEffect} from 'react';
import '../PrivacyPolicyPage/styles.scss'

const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = 'https://cdn.iubenda.com/iubenda.js';
        if (tag.parentNode) {
            tag.parentNode.insertBefore(script, tag);
        }
    }, []);
    return (
        <div className="privacy-policy__page">
            <a href="https://www.iubenda.com/privacy-policy/16978737"
               className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
               title="Privacy Policy">Privacy Policy</a>
        </div>
    );
};

export default PrivacyPolicy;
