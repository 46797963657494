import React from "react";
import LogoHeaderAuth from "@assets/LogoHeaderAuth";
import { Steps } from "antd";
import { EVENT_INFO_STEP } from "@pages/Onboarding/EventOnboarding/constants";
import styles from "./HeaderPanel.module.css";
import { useBreakpoints } from '@hooks/browser';

type HeaderPanelPropsType = {
    currentStep: string
}
const HeaderPanel: React.FC<HeaderPanelPropsType> = (props) => {
    const { currentStep } = props;
    const isDesktop = useBreakpoints().md;

    return (
        <>
            <LogoHeaderAuth />
            {isDesktop
                ?
                <Steps
                    size={"small"}
                    className={styles.HeaderPanel__steps}
                    current={currentStep === EVENT_INFO_STEP ? 1 : 0}
                    items={[
                        {
                            title: 'Company details',
                        },
                        {
                            title: 'Add event',
                        },
                    ]}
                />
                :
                null}
        </>
    )
}

export default HeaderPanel;
