import '../styles.scss'
import { Input, Form } from 'antd';
import CustomButton from '@components/CustomButton';
import GoogleLogo from '@assets/GoogleLogo';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "@contexts/AuthContext";
import RegistrationLayout from '@layouts/RegistrationLayout';
import { useState } from 'react';
import { useLoginUserMutation } from '@store/main/-user/user.api';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import ErrorBanner from '@pages/CommonPages/components/banners/ErrorBanner';
import { userActions } from "@store/main/-user/user.slice";
import { useDispatch } from "react-redux";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

interface ErrorsValues {
    email: string;
    password: string;
}

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: 'Please enter a valid e-mail address',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */

const errorsInitial = {
    email: '',
    password: ''
}

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const LoginPage = () => {
    const [authError, setAuthError] = useState<ErrorsValues>(errorsInitial)
    const [isLoading, setLoading] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { reFetchUser } = useAuth();

    const [loginUser] = useLoginUserMutation()

    const onFinish = async (values: { email: string, password: string }) => {
        setLoading(true)
        try {
            const response = await loginUser(values)

            if ('error' in response) {
                setLoading(false)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                setAuthError(errors as unknown as ErrorsValues)
            }

            if ('data' in response) {
                dispatch(userActions.setUserState(response?.data?.data))
                dispatch(userActions.setUserError(''))
                const userData = response?.data?.data
                sendAnalytics(`${GTMEventName.login}_${response?.data?.data?.organization?.type}`)
                if (userData && !userData?.organization?.type) {
                    navigate(`/registration`)
                } else if (userData?.organization?.onboarding !== 'ready') {
                    if (userData?.organization?.type === 'event') {
                        navigate(`/onboarding/event`)
                    }
                    if (userData?.organization?.type === 'partner') {
                        navigate(`/onboarding/partner`)
                    }
                } else {
                    navigate(`/events`)
                }
                return setLoading(false)
            }
            console.log('Success:');
        } catch (error) {
            setLoading(false)
            console.log('SMTH WRONG');
        }
        setLoading(false)
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <RegistrationLayout>
            <div className='auth'>
                <main className='auth__form'>
                    <p className='text-title mb-24'>
                        Welcome back!
                    </p>
                    {
                        authError.email || authError.password
                            ? (
                                <ErrorBanner
                                    text={authError.email || authError.password}
                                />
                            )
                            :
                            null
                    }
                    <Form
                        name="login"
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onFieldsChange={() => setAuthError(errorsInitial)}
                        autoComplete="off"
                        layout="vertical"
                        validateMessages={validateMessages}
                    >

                        <Form.Item
                            name='email'
                            label="Email"
                            validateStatus={authError.email ? 'error' : ''}
                        >
                            <Input placeholder='Your email' />
                        </Form.Item>

                        <Form.Item<FieldType>
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <div>
                            <Link to="/pass_recover">
                                Forgot password
                            </Link>
                        </div>

                        <Form.Item className='mt-24'>
                            <CustomButton
                                title='Log In'
                                htmlType="submit"
                                classOptional='w-full'
                                type='primary'
                                size='large'
                                loading={isLoading}
                            />
                        </Form.Item>
                    </Form>

                </main>
                <footer className='auth__footer flex-col items-center'>
                    <span className='auth__footer-title mb-24'>
                        or
                    </span>

                    <div className='auth__footer-btn'>
                        <a href={`${apiBaseUrl}/auth/google`}>
                            <CustomButton
                                title='Continue with Google'
                                prefix={<GoogleLogo />}
                                size='large'
                                classOptional='w-full'
                            />
                        </a>
                    </div>
                </footer>

                <p className='auth__optional-footer'>
                    New to Boost.Express?
                    {' '}
                    <Link to="/registration">
                        Create an account
                    </Link>
                </p>

            </div>
        </RegistrationLayout>
    )
}

export default LoginPage
