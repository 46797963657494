import { useParams } from 'react-router-dom'

import React, { useCallback, useEffect, useState } from 'react'
import ZeroCampaigns from '@pages/PartnerRolePages/MyEvents/pages/MyEventEditPage/ZeroState/ZeroCampaigns';
import EventCampaignsTable from './Table';
import EventCatalogTableZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import { useGetOrganizerCampaignsByEventQuery } from '@store/type-event/campaigns/campaigns.api';
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import './styles.scss'
import { initialFilters } from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/constants.ts";
import { Filters } from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/types.ts";
import CampaignsTabFilters from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/Filters.tsx";
import { CloseOutlined } from "@ant-design/icons";
import { Badge, Button, Drawer, Input, TablePaginationConfig } from "antd";
import { useBreakpoints } from "@hooks/browser";
import { debounce } from "@shared/utils.ts";
import { INIT_PAGE_SIZE, INIT_TOTAL, INIT_CURRENT_PAGE } from "@shared/constants.ts";
import MobileDrawer from '@components/mobile/Drawer';
import { SearchIcon } from '@assets/SearchIcon';
import FilterButtonIcon from '@assets/FilterButtonIcon';
import ExitCrossIcon from '@assets/ExitCrossIcon';
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';

const CampaignsTab = () => {
    const isDesktop = useBreakpoints().md

    const [filters, setFilters] = useState<Filters>(initialFilters)
    const [drawerFilters, setDrawerFilters] = useState<Partial<Filters>>(filters)

    const [isOpenFilter, setOpenFilter] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [pagination, setPagination] = useState({
        pageSize: INIT_PAGE_SIZE,
        total: INIT_TOTAL
    });

    const { eventId } = useParams()
    const { data: campaignsRes, isLoading, refetch: refetchCampaignssData } = useGetOrganizerCampaignsByEventQuery({ eventId: eventId!, filters }, { skip: !eventId })

    const [campaigns, setCampaigns] = useState<CampaignDataItem[]>([])

    const handleTableChange = (paginationData: TablePaginationConfig) => {
        setPagination((prev) => ({ ...prev, current: paginationData.current || INIT_CURRENT_PAGE }))
    }

    useEffect(() => {
        void refetchCampaignssData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (campaignsRes?.data) {
            setCampaigns(campaignsRes.data)
        }
    }, [campaignsRes?.data])

    useEffect(() => {
        setPagination({
            total: campaignsRes?.meta?.total || INIT_TOTAL,
            pageSize: campaignsRes?.meta?.per_page || INIT_PAGE_SIZE,
        })
    }, [campaignsRes]);

    const setInitialFilters = useCallback(() => {
        setFilters(initialFilters)
        setSearchValue('')
    }, [])

    const debouncedOnSearchUpdate = useCallback(
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            setFilters((prev) => ({ ...prev, search: e.target.value }));
        }, 300),
        []
    );

    const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        debouncedOnSearchUpdate(e)
        setSearchValue(e.target.value)
    }, [])

    const countChangedFilters = () => {
        const { page, search, ...currentFilters } = filters;

        return Object.entries(currentFilters).reduce((count, [key, value]) => {
            if (Array.isArray(value)) {
                return count + (value.length ? 1 : 0);
            } else {
                const initialValue = initialFilters[key as keyof Filters];
                return count + (value !== initialValue ? 1 : 0);
            }
        }, 0);
    };


    //MOBILE DRAWER
    const onDrawerResetClick = () => {
        setFilters(initialFilters)
        setSearchValue('')
        setDrawerFilters(filters)
        setOpenFilter(false)
    }
    const onDrawerFilterApply = () => {
        // onFilterUpdate(drawerFilters)
        // setShowFilters(false)
    }
    const isFilterApplied =
        filters?.statuses?.length ||
        filters?.landings?.length ||
        filters?.partners?.length ||
        filters?.search


    if (isLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }

    return (
        <article className='px-24'>
            <div className='organizer-event-tab__campaigns flex justify-space-between'>
                <h3 className='m-0'>Campaigns</h3>
                <Button
                    disabled
                    className=""
                >
                    + Create campaign
                </Button>
            </div>
            <div className='flex my-16 items-center'>
                <Input
                    style={{ width: isDesktop ? '340px' : '100%' }}
                    value={searchValue}
                    placeholder="Search by keywords"
                    onChange={onSearchChange}
                    prefix={<SearchIcon />}
                />
                {isDesktop
                    ?
                    (
                        <div className='flex gap-8'>
                            <Badge color='#FF4D4F' count={countChangedFilters()}>
                                <Button
                                    className="mx-12 organizer-event-tab__campaigns__all-filter-button"
                                    icon={<FilterButtonIcon />}
                                    onClick={() => setOpenFilter(true)}
                                >
                                    All filters
                                </Button>
                            </Badge>
                            <Button
                                size='small'
                                type="text"
                                onClick={setInitialFilters}
                                className="analytics-page__reset-button"
                            >
                                <ExitCrossIcon fill="#898A8C" />
                                Reset
                            </Button>
                        </div>

                    )
                    :
                    <button
                        style={{
                            width: '32px',
                            height: '32px',
                            marginLeft: '8px'
                        }}
                        className='no-style'
                        onClick={() => setOpenFilter(true)}
                    >
                        <Badge
                            dot={!!isFilterApplied}
                            status="processing"
                        >
                            <FilterButtonMobileIcon
                                isActive={!!isFilterApplied}
                            />
                        </Badge>
                    </button>
                }
            </div>
            {/* {!isDrawer && (
                <button
                    className='no-style organizer-event-tab__campaigns__filter-button'
                    onClick={() => setOpenFilter(true)}
                >
                    <Badge
                        dot={!!isFilterApplied}
                        status="processing"
                    >
                        <FilterButtonMobileIcon
                            isActive={!!isFilterApplied}
                        />
                    </Badge>
                </button>
            )}


            {/* <CampaignsTabFilters
                filters={filters}
                setFilters={setFilters}
                setOpenFilter={setOpenFilter}
                setInitialFilters={setInitialFilters}
                searchValue={searchValue}
                onSearchChange={onSearchChange}
            /> */}

            {campaigns?.length ? (
                <EventCampaignsTable
                    campaigns={campaigns}
                    pagination={pagination}
                    handleTableChange={handleTableChange}
                />
            ) : (
                <ZeroCampaigns
                    eventId={eventId || ''}
                    readonly
                />
            )}

            {isDesktop
                ?
                <Drawer
                    title="Filters"
                    closeIcon={false}
                    placement='right'
                    open={isOpenFilter}
                    autoFocus={false}
                    mask={false}
                    onClose={() => setOpenFilter(false)}
                    maskStyle={{ 'opacity': 0, 'top': '62px' }}
                    rootStyle={{ 'top': '62px' }}
                    rootClassName="organizer-billing-page__drawer"
                    contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
                    extra={(
                        <CloseOutlined
                            onClick={() => setOpenFilter(false)}
                        />
                    )}
                >
                    <CampaignsTabFilters
                        filters={filters}
                        setFilters={setFilters}
                        setInitialFilters={setInitialFilters}
                    />
                </Drawer>
                :
                <MobileDrawer
                    title='Filters'
                    onClose={onDrawerResetClick}
                    onCancel={onDrawerResetClick}
                    isOpen={isOpenFilter}
                    onOk={onDrawerFilterApply}
                    applyButtonText="Apply"
                    resetButtonText="Reset"
                >

                    <CampaignsTabFilters
                        filters={filters}
                        setFilters={setFilters}
                        setInitialFilters={setInitialFilters}
                    />
                </MobileDrawer>
            }

        </article>
    )
}

export default CampaignsTab
