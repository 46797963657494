import SkeletonCustom from '@components/SkeletonCustom'
import { useBreakpoints } from '@hooks/browser'

const EventItemCardSkeleton = () => {
    const isDesktop = useBreakpoints().md

    if (!isDesktop) {
        <div className='my-event-card justify-space-between'>
            <header >
                <div className='flex'>
                    <SkeletonCustom type='avatar-sm' />
                    <div className='mx-6'>
                        <SkeletonCustom type='paragraph-lg' />
                    </div>
                </div>

                <div className='my-8'>
                    <SkeletonCustom type='button-l' />
                </div>
            </header>

            <footer className='my-event-card__footer flex justify-space-between'>
                <SkeletonCustom type='button-l' />
                <SkeletonCustom type='button' />
            </footer>
        </div>
    }

    return (
        <div className='my-event-card flex-col justify-space-between'>
            <header >
                <div className='flex'>
                    <SkeletonCustom type='avatar-sm' />
                    <div className='mx-6'>
                        <SkeletonCustom type='paragraph-lg' />
                    </div>
                </div>

                <div className='my-8'>
                    <SkeletonCustom type='button-l' />
                </div>
            </header>

            <footer className='my-event-card__footer flex justify-space-between'>
                <SkeletonCustom type='button-l' />
                <SkeletonCustom type='button' />
            </footer>
        </div>
    )
}

export default EventItemCardSkeleton