import { StripeContext } from '@contexts/StripeContext';
import StripeCertifiedPlug from '@pages/PartnerRolePages/Billing/components/StripeCertifiedPlug'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useContext, useEffect, useState } from 'react'
import SkeletonStripe from "@pages/Onboarding/PartnerOnboarding/steps/payment-method/SkeletonStripe";

import './styles.scss'

const BillingStripeForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { setStripe, setElements } = useContext(StripeContext)
    const [isStripeLoading, setIsStripeLoading] = useState(true)

    // can be mounted only inside the strime Elements wrapper
    useEffect(() => {
        if (stripe) setStripe(stripe)
        if (elements) setElements(elements)
    }, [stripe, elements, setStripe, setElements])

    const isLoading = !stripe || !elements || isStripeLoading

    return (
        <>
            {isLoading && <SkeletonStripe />}
            <div className={isLoading ? 'billing-stripe-form__paymentElement_hidden' : ''}>
                <PaymentElement onReady={() => {
                    setIsStripeLoading(false)
                }}
                />
                <div className='divider' />
                <StripeCertifiedPlug />
            </div>
        </>
    )
}

export default BillingStripeForm
