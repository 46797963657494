const BoostLogo = () => {
    return (
        <div style={{ display: 'inline-block', position: 'relative' }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="1">
                    <path fillRule="evenodd" clipRule="evenodd" d="M15.2286 8H30.2087C32.335 8 33.9929 8.45911 35.1824 9.37733C36.3937 10.2956 37 11.5926 37 13.2683C37 14.5768 36.6913 15.7475 36.0738 16.7805C35.4794 17.7905 34.6215 18.5825 33.5015 19.1564C34.5532 19.5237 35.3533 20.1205 35.9024 20.9469C36.4739 21.7504 36.7596 22.7604 36.7596 23.9771C36.7596 25.6528 36.3937 27.0875 35.6626 28.2812C34.9532 29.4749 33.947 30.3931 32.6437 31.0359C31.3405 31.6786 29.8081 32 28.0477 32H4.76745C3.58736 32 2.73896 30.8605 3.07361 29.7243C3.29513 28.9706 3.98446 28.4534 4.76745 28.4534H19.2833L20.2572 21.4978H10.2066C9.05362 21.4978 8.20346 20.4162 8.47211 19.2906C8.66418 18.485 9.38119 17.9168 10.2066 17.9168H20.7591L21.6511 11.5466H15.2286C14.0692 11.5466 13.2243 10.4446 13.5207 9.31964C13.7257 8.54175 14.4268 8 15.2286 8ZM28.0477 28.3501H23.2801L24.2405 21.3946H29.6254C30.7001 21.3946 31.5007 21.6356 32.0263 22.1177C32.5754 22.5768 32.8493 23.2425 32.8493 24.1148C32.8493 25.4462 32.4263 26.4907 31.5803 27.2482C30.7572 27.9828 29.5795 28.3501 28.0477 28.3501ZM29.1111 17.8135H24.7548L25.6121 11.6499H29.9C30.9057 11.6499 31.6722 11.868 32.1977 12.3042C32.7239 12.7174 32.9866 13.3142 32.9866 14.0947C32.9866 14.8063 32.8152 15.4491 32.4723 16.0229C32.1524 16.5739 31.6946 17.0101 31.1001 17.3314C30.5286 17.6528 29.8652 17.8135 29.1111 17.8135Z" fill="white" />
                </g>
            </svg>

        </div>
    );
};

export default BoostLogo;
