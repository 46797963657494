import React, { useContext, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};


interface ImageUploaderProps {
    action: string;
    image: string;
    type?: 'person' | 'presentation' | 'company',
    handleResponse: (url: string) => void,
    errorCb: () => void
}
const ImageUploader: React.FC<ImageUploaderProps> = ({
    action,
    image,
    type,
    handleResponse,
    errorCb
}) => {
    const [loading, setLoading] = useState(false);
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'error') {
            setLoading(false);
            void message.open({
                type: 'error',
                content: 'Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed.',
            });
            errorCb()
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);

            });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (info.file?.response?.data) {

                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                handleResponse(info.file?.response?.data as string)
            }
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const imageComponent = loading ? <LoadingOutlined /> : (
        <img
            src={image}
            alt=""
            style={{
                // minWidth: '100%',
                height: '100%',
                objectFit: 'contain',
                borderRadius: '8px'

            }}
        />
    )

    return (
        <Upload
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={action}
            method='POST'
            onChange={handleChange}
        >
            {image ? imageComponent : uploadButton}
        </Upload>
    );
};

export default ImageUploader;
