
import './styles.scss'
import { Link } from 'react-router-dom'
import { HELP_CENTER_LINK } from "@shared/constants.ts";

const HelpBoostPro = () => {
    return (
        <div
            className='banner-boost-pro-billing flex-col banner-boost-pro-help'
        >
            <div className='flex banner-boost-pro-billing__content'>
                <div className='banner-boost-pro-help__main'>
                    <p className='banner-section-title'>
                        Billing FAQ
                    </p>
                    <Link to='/'>
                        What payment methods are available?
                    </Link>
                    <Link to='/'>
                        What currencies are supported?                        </Link>
                    <Link to='/'>
                        When is a VAT # requred?
                    </Link>
                </div>
            </div>

            <footer className='flex'>
                <span className='mr-6'>
                    Learn more

                </span>
                <Link
                    className='link-style'
                    to={HELP_CENTER_LINK}
                    target="_blank"
                >
                    {'in our Help Center >'}
                </Link>
            </footer>
        </div>
    )
}

export default HelpBoostPro