import ZeroCampaignsIcon from '@assets/zerostate/ZeroCampaignsIcon'
import '../../styles.scss'
import CustomButton from '@components/CustomButton';

interface ZeroInvitationsProps {
    readonly?: boolean;
    onCtaClick: () => void
}

const ZeroInvitations = ({
    readonly,
    onCtaClick
}: ZeroInvitationsProps) => {
    return (
        <div className='campaign-editor__zero-island'>
            <ZeroCampaignsIcon />
            <p className='campaign-editor__zero-island-title'>
                The event does not have any invitations yet            </p>
            <p className='campaign-editor__zero-island-desc'>
                To invite a partner to the event, create invitations by clicking the button <strong>Add Invitation</strong> above            </p>
            {readonly
                ?
                null
                :
                <CustomButton
                    type='primary'
                    title='+ Add Invitations'
                    onClick={onCtaClick}
                />
            }

        </div>
    )
}

export default ZeroInvitations