import React, { FC, useContext, useState, RefObject } from 'react'
import './styles.scss'
import StatusDescription from '@components/StatusDescription'
import MegaphoneIcon from '@assets/MegaphoneIcon'
import LayoutIcon from '@assets/LayoutIcon'
import { Link } from 'react-router-dom'
import Avatar from '@components/Avatar'
import {ErrorDataResponse, formatDateRangeToCommonFormat} from '@shared/utils'
import {Dropdown, message, Button} from 'antd'
import Loader from '@components/Loader'
import { GetPartnerEventByIdDataItem, GetPartnerEventDataItem } from '@store/type-partner/events/models'
import { usePatchPartnerEventStatusByIdMutation } from '@store/type-partner/events/partner-event.api'
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import ThreeDotsIcon from "@assets/pro/ThreeDotsIcon.tsx";
import { TourContext } from "@contexts/TourContext.tsx";
import { EventsTourSteps } from "@components/TourComponent/constants.ts";

interface EventsCardProps {
    event: GetPartnerEventDataItem | GetPartnerEventByIdDataItem
    refetch: () => Promise<void>
    refProp?: RefObject<HTMLAnchorElement>;
}

const EventsCard: FC<EventsCardProps> = ({ event, refetch, refProp }) => {
    const {
        setTourStep,
    } = useContext(TourContext);

    const [isLoadingStatus, setIsLoadingStatus] = useState(false)
    const [patchEventStatus] = usePatchPartnerEventStatusByIdMutation()

    const [eventItem, setEventItem] = useState<GetPartnerEventDataItem | GetPartnerEventByIdDataItem>(event)

    const handlePatchEventStatus = async ({ event, status }: { event: number, status: number }) => {
        try {
            setIsLoadingStatus(true)
            const response = await patchEventStatus({ event, status });

            if ('error' in response) {
                setIsLoadingStatus(false)
                const err = response as unknown as ErrorDataResponse
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const content = err?.error?.data?.message || 'OOOPS, something is wrong'
                void message.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content
                });
                // Handle the error here
            } else {
                const event = response?.data as unknown as GetPartnerEventByIdDataItem
                event && setEventItem(event)
                setIsLoadingStatus(false)
                // eslint-disable-next-line no-void
                void await refetch()
                return void message.open({
                    type: 'success',
                    content: 'Status updated',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            console.log('SMTH WRONG');
            return void message.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
            // Handle the error here
        }
    }

    const onEventCardClick = () => {
        setTourStep(EventsTourSteps.landingPages)
    }

    return (
        <Link
            ref={refProp}
            to={`edit/${eventItem.id}`}
            className='no-style my-event-card-link'
            onClick={onEventCardClick}
        >
            {isLoadingStatus && <Loader />}
            <div
                className='my-event-card flex-col justify-space-between'
            >
                <div
                    className='my-event-card__header'
                >
                    <div
                        className='flex-col my-event-card__header-text'
                    >
                        <div>
                            <div className='flex items-center'>
                                <Avatar
                                    url={eventItem?.logo || ''}
                                    size="50"
                                />
                                <div>
                                    <h4>
                                        {eventItem.name}
                                    </h4>
                                    <p className='text-regular-14'>
                                        {formatDateRangeToCommonFormat(eventItem?.date?.started_at, eventItem?.date?.finished_at)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {eventItem?.status?.actions?.length
                        ?
                        <div className='my-event-card__header-cta-container'>
                            <Dropdown
                                placement="bottomRight"
                                menu={{ items: eventItem.status.actions.map(cta => {
                                        return {
                                            key: cta.id,
                                            label: (
                                                <Button
                                                    key={cta.id}
                                                    type="text"
                                                    size="small"
                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                        e.preventDefault()
                                                        sendAnalytics(`${GTMEventName.partnerEventAction}_${cta.slug}`)
                                                        void handlePatchEventStatus({ event: eventItem.id, status: cta.id })
                                                    }}
                                                >{cta.transit}</Button>
                                            )
                                        }
                                    }
                                )}}
                            >
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                    className='cursor-pointer'
                                >
                                    <ThreeDotsIcon />
                                </div>
                            </Dropdown>
                        </div>
                        :
                        null}
                </div>
                <footer className='my-event-card__footer flex justify-space-between'>
                    <StatusDescription
                        status={eventItem?.status?.name}
                    />

                    {event?.status?.name === 'active'
                        ?
                        <aside className='flex'>
                            <div className='my-event-card__statistics'>
                                <MegaphoneIcon fillColor="#C0C1C3" />
                                <span>
                                    {eventItem?.campaigns || 0}
                                </span>
                            </div>
                            <div className='my-event-card__statistics'>
                                <LayoutIcon fillColor="#C0C1C3" />
                                <span>
                                    {eventItem?.landings || 0}
                                </span>
                            </div>
                        </aside>
                        :
                        null}
                </footer>
            </div>
        </Link>
    )
}

export default EventsCard