import { useParams } from 'react-router-dom'
import EventsPageLayout from '@layouts/EventsPageLayout'
import ContentLayout from '@layouts/ContentLayout'
import EventInfo from './components/EventInfo'
import EventLandingPages from './components/EventLandingPages'
import EventCampaignsTableSection from './components/EventCampaignsTable'
import MyEventEditPageZeroState from './ZeroState'
import React, { useCallback, useEffect, useState, useMemo, useRef, useContext } from 'react'
import { events } from '@pages/routes';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import { useGetPartnerEventByIdQuery } from '@store/type-partner/events/partner-event.api'
import {
    useDeletePartnerLandingByIdMutation,
    useGetPartnerLandingsByEventIdQuery
} from '@store/type-partner/landings/landings.api'
import { useGetPartnerCampaignsQuery } from '@store/type-partner/campaigns/campaigns.api'
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import { useBreakpoints } from '@hooks/browser'
import { TourComponent, TourTitle } from "@components/TourComponent/TourComponent.tsx";
import { TourContext } from "@contexts/TourContext.tsx";
import {EventsTourSteps} from "@components/TourComponent/constants.ts";

const MyEventEditPage = () => {
    const { id } = useParams()
    const isDesktop = useBreakpoints().md
    const [isDeleting, setIsDeleting] = useState(false)

    const landingPagesRef = useRef(null);
    const myCampaignsRef = useRef(null);

    const {
        isTourOpen,
        setIsTourOpen,
        tourStep,
        setTourStep,
    } = useContext(TourContext);

    const [deleteLanding] = useDeletePartnerLandingByIdMutation()

    const { data: eventsRes, isLoading: eventsLoading } = useGetPartnerEventByIdQuery({ id: id! }, { skip: !id })
    const { data: landingsRes, isLoading: lpLoading, refetch: refetchLandingsData } = useGetPartnerLandingsByEventIdQuery({ id: +id!, page: INIT_PAGE }, { skip: !id })
    const { data: campaignsRes, isLoading: campaignsLoading, refetch: refetchCampaignssData } = useGetPartnerCampaignsQuery({ filter: { events: [id || ''] } }, { skip: !id })

    const eventInfo = eventsRes?.data
    const eventCampaigns = campaignsRes?.data || []

    const onDeleteLanding = useCallback(async (landingId: string) => {
        setIsDeleting(true)
        try {
            const res = await deleteLanding({ landing: landingId, event: id ?? '' });
            if (res) {
                refetchLandingsData()
                    .finally(() => {
                        setIsDeleting(false)
                    });
            }
            setIsDeleting(false)
        } catch (error) {
            setIsDeleting(false)
            console.error('Error deleting landing:', error);
        }
    }, [deleteLanding, id, refetchLandingsData])

    useEffect(() => {
        void refetchLandingsData()
        void refetchCampaignssData()
    }, [])

    const breadCrumbs = useMemo(() => {
        return [
            {
                url: events,
                label: 'My events',
                idx: 1,
                isZeroLevel: true
            },
            {
                label: eventInfo?.name || '',
                idx: 2,
            }
        ]
    }, [eventInfo])

    if (eventsLoading || lpLoading || campaignsLoading) {
        return (
            <EventsPageLayout>
                <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                />
                <ContentLayout>
                    <MyEventEditPageZeroState />
                </ContentLayout>
            </EventsPageLayout>
        )
    }

    // todo check mobile
    return (
        <EventsPageLayout>
            {isDesktop
                ?
                <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                />
                :
                null}
            {eventInfo && <EventInfo event={eventInfo} />}
            <EventLandingPages
                landingPagesRef={landingPagesRef}
                eventLandings={landingsRes?.data || []}
                eventId={id ?? ''}
                isActive={eventInfo?.status.name === 'active'}
                isDeleting={isDeleting}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onDeleteLanding={onDeleteLanding}
            />
            <EventCampaignsTableSection
                myCampaignsRef={myCampaignsRef}
                campaigns={eventCampaigns}
                eventId={id ?? ''}
                isActive={eventInfo?.status.name === 'active'}
            />
            <TourComponent
                totalCount={7}
                open={(tourStep === EventsTourSteps.landingPages || tourStep === EventsTourSteps.myCampaigns) && isTourOpen}
                onClose={() => {
                    setIsTourOpen(false)
                }}
                gap={{
                    offset: [0, -20]
                }}
                current={tourStep}
                onChange={(step) => {
                    setTourStep(step)
                }}
                disabledInteraction
                prefixCls="event-info-step-tour-component"
                steps={[
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div className="mr-28">
                                Here you can see and create landing
                                <br/>
                                pages for the event.
                            </div>
                        ),
                        target: () => landingPagesRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Here you can manage event’s campaigns,<br/>
                                track statistics and create new ones.
                            </div>
                        ),
                        target: () => myCampaignsRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                    },
                ]}
            />
        </EventsPageLayout>
    )
}

export default MyEventEditPage
