
const LayoutIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1054_29672)">
                <path d="M14.5707 0.859375H1.42787C1.1118 0.859375 0.856445 1.11473 0.856445 1.4308V14.5737C0.856445 14.8897 1.1118 15.1451 1.42787 15.1451H14.5707C14.8868 15.1451 15.1422 14.8897 15.1422 14.5737V1.4308C15.1422 1.11473 14.8868 0.859375 14.5707 0.859375ZM2.14216 2.14509H4.57073V13.8594H2.14216V2.14509ZM13.8564 13.8594H5.71359V5.71652H13.8564V13.8594ZM5.71359 4.57366V2.14509H13.8564V4.57366H5.71359Z" fill={fillColor || "#BFBFBF"} />
            </g>
            <defs>
                <clipPath id="clip0_1054_29672">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LayoutIcon