import BillingStripeFormWrapper from '@components/BillingStripeForm'
import SkeletonStripe from './SkeletonStripe';
import { Intent } from '@store/main/payment/models';

type Props = {
    apiKey: string
    intent: Intent | null
}

const PaymentMethodStep = ({ apiKey, intent }: Props) => {
    if (!intent || !apiKey) {
        return (
            <SkeletonStripe />
        )
    }

    return (
        <div className='mt-24'>
            <BillingStripeFormWrapper
                intent={intent}
                apiKey={apiKey}
            />
        </div>
    )
}

export default PaymentMethodStep