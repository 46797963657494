import Avatar from '@components/Avatar';
import CustomButton from '@components/CustomButton';
import StatusDescription from '@components/StatusDescription';
import { EventCatalogItemStatuses } from '@shared/constants';
import { formatDateRange } from '@shared/utils';
import { message } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScrollTable from '@components/InfiniteScrollTable/InfiniteScrollTable';
import { usePatchPartnerEventStatusByIdMutation } from '@store/type-partner/events/partner-event.api';
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import { GetEventCatalogDataItem } from "@store/type-partner/events-catalog/models";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

interface EventCatalogTableProps {
    events: GetEventCatalogDataItem[]
    isLastPage: boolean
    onNextPage: () => void
    isLoading: boolean
    setPage: (page: number) => void
    page: number
}
const EventCatalogTable: FC<EventCatalogTableProps> = ({
    events,
    isLastPage,
    onNextPage,
    isLoading,
    setPage,
    page
}) => {
    const [patchEventStatus] = usePatchPartnerEventStatusByIdMutation()
    const [eventsList, setEventsList] = useState(events);

    useEffect(() => {
        setEventsList(events)
    }, [events])

    const [isLoadingStatus, setIsLoadingStatus] = useState(false)

    const handlePatchEventStatus = async ({ event, status, slug }: { event: number, status: number, slug: string }) => {
        try {
            setIsLoadingStatus(true)
            const response = await patchEventStatus({ event, status });

            if ('error' in response) {
                setIsLoadingStatus(false)
                void message.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: response?.error?.data?.message || 'OOOPS, something is wrong',
                });
                // Handle the error here
            } else {
                sendAnalytics(`${GTMEventName.partnerEventCatalogAction}_${slug}`)
                setPage(INIT_PAGE)
                setIsLoadingStatus(false)
                return void message.open({
                    type: 'success',
                    content: 'Status updated',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            console.log('SMTH WRONG');
            return void message.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
            // Handle the error here
        }
    }

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '120px',
            render: (status: {
                id: number;
                name: EventCatalogItemStatuses,
                actions: {
                    id: number
                    slug: EventCatalogItemStatuses
                    transit: string
                }[]
            }, row: { id: number }) => {
                return (
                    <div>
                        <StatusDescription status={status.name} />
                        {status?.actions
                            ?
                            status?.actions.map(cta => {
                                return (
                                    <div className='my-8' key={cta.id}>
                                        <CustomButton
                                            title={cta.transit}
                                            size='small'
                                            block
                                            // loading={isLoadingStatus}
                                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                            onClick={() => handlePatchEventStatus({ event: row.id, status: cta.id, slug: cta.slug })}
                                        />
                                    </div>
                                )
                            })
                            :
                            null}
                    </div>
                )
            }
        },
        {
            title: 'Event title',
            dataIndex: 'event_info',
            key: 'event_info',
            width: '220px',
            render: (_: any, row: GetEventCatalogDataItem) => {
                return (
                    <Link className='no-style' to={`${row.id}`}>
                        <div>
                            <div className='flex'>
                                <Avatar
                                    size='medium'
                                    url={row?.logo}
                                />
                                <h4 className='mx-6'>{row?.name || '---'}</h4>
                            </div>

                        </div>
                        <span>
                            A forum bringing together industry leaders and experts…
                        </span>
                    </Link>
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '220px',
            render: (date: { started_at: string, finished_at: string }) => {
                return (
                    <div className='whitespace-nowrap'>
                        {formatDateRange(date) || '---'}
                    </div>
                )
            }

        },
        {
            title: 'Organizer',
            dataIndex: 'organization',
            key: 'organization',
            width: '120px',
            render: (organization: string) => {
                return (
                    <div className='whitespace-nowrap'>
                        {organization || '---'}
                    </div>
                )
            }
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            width: '120px',

            // render: (_: any, row: any) => {
            //     return (
            //         <div className='whitespace-nowrap'>
            //             {row?.location_city || ''}{row?.location_country ? `, ${row.location_country}` : ''}
            //         </div>
            //     )
            // }
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: '120px',

        },
        {
            title: 'Participants',
            dataIndex: 'participants',
            key: 'participants',
            width: '120px',

        }
    ];
    return (
        <div>
            <InfiniteScrollTable
                isLastPage={isLastPage}
                onNextPage={onNextPage}
                dataSource={eventsList}
                columns={columns}
                rowKey='id'
                loading={isLoading || isLoadingStatus}
                isFirstPage={page === INIT_PAGE}
            />
        </div>
    );
};

export default EventCatalogTable;
