import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import cn from 'classnames';

const DownloadButton = ({ link, name, className }: {link: string, name: string, className?: string}) => {
    const fileName = `${name || 'invoice'}.pdf`
    const handleDownloadClick = (e: React.MouseEvent, pdfUrl: string) => {
        e.preventDefault();

        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = fileName;

        anchor.click();
    }

    return (
        <a
            className={cn('cursor-pointer', className)}
            href={link}
            download={fileName}
            onClick={(e) => handleDownloadClick(e, link)}
        >
            <DownloadOutlined />
        </a>
    )
}

export default DownloadButton
