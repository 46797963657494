import { EventCatalogItemStatuses } from '@shared/constants';
import { CatalogEvent } from '@store/events/models';

export const mockCatalog: CatalogEvent[] = [
    {
        id: '0',
        status: 'not_available' as EventCatalogItemStatuses,
        image_logo: 'https://helder.design/wp-content/uploads/2023/09/Logo.svg',
        name: 'Blockchain Innovation Forum',
        description: 'A forum bringing together industry leaders and experts…',
        started_at: 'June 30 – July 6, 2024',
        organization_name: 'CryptoTech',
        location_country: 'Portugal',
        location_city: 'Lisbon',
        category: 'Technology & Finance',
        participants: 450,
    },
    {
        id: '1',
        status: 'awaiting_approval' as EventCatalogItemStatuses,
        image_logo: 'https://helder.design/wp-content/uploads/2021/08/DCSO-Wort-Bildmarke.svg',
        name: 'FinTech Innovation Forum',
        description: 'Forum dedicated to innovation in the economic sector…',
        started_at: 'Aug. 2 – 6, 2024',
        organization_name: 'FinTechCorp',
        location_country: 'Mexico',
        location_city: 'Leon',
        category: 'Health & Technology',
        participants: 210,
    },
    {
        id: '2',
        status: 'awaiting_approval' as EventCatalogItemStatuses,
        image_logo: 'https://helder.design/wp-content/uploads/2021/08/DCSO-Wort-Bildmarke.svg',
        name: 'FinTech Innovation Forum',
        description: 'Forum dedicated to innovation in the economic sector…',
        started_at: 'Aug. 2 – 6, 2024',
        organization_name: 'FinTechCorp',
        location_country: 'Mexico',
        location_city: 'Leon',
        category: 'Health & Technology',
        participants: 210,
    },

]