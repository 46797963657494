import React, { FC } from 'react';
import { Button, Modal, } from 'antd';
import { useBreakpoints } from "@hooks/browser";

type Props = {
    isOpen: boolean
    billing: { label: string; value: number } | null
    onClose: () => void
    onChange: () => void
    isLoading: boolean
}

const ChangeAdAccountsModal: FC<Props> = ({ isOpen, onClose, onChange, billing, isLoading }) => {
    const isDesktop = useBreakpoints().md;
    return (
        <Modal
            title='Add account'
            open={isOpen}
            onCancel={onClose}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            footer={(
                <div
                    className="flex justify-end"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                        className='mr-6'
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={isLoading}
                        size="large"
                        type="primary"
                        onClick={onChange}
                    >
                        Change
                    </Button>
                </div>
            )}
        >
            Are you sure you want to add the account
            {' '}
            <b>{billing?.label || ''}</b>
            ?
        </Modal>
    )
}

export default ChangeAdAccountsModal;
