import React from 'react';

interface FacebookIconProps {
    width?: number;
    height?: number;
    isActive?: boolean;
    isBlue?: boolean;
    isWhite?: boolean;
    fillColor?: string
    isRed?: boolean;
}

const FacebookIcon: React.FC<FacebookIconProps> = (
    {
        width = 18,
        height = 18,
        isActive = false,
        isBlue = false,
        isRed = false,
        isWhite = false,
        fillColor = ''
    }
) => {
    return (
        <svg width={width} height={height} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity={isActive ? 1 : .3}>
                <path fillRule="evenodd" clipRule="evenodd" d="M13.628 0C6.12788 0 0.0478516 6.04416 0.0478516 13.5C0.0478516 20.9558 6.12788 27 13.628 27C21.1281 27 27.2081 20.9558 27.2081 13.5C27.2081 6.04416 21.1281 0 13.628 0ZM15.0434 14.093V21.4377H11.9865V14.0933H10.4593V11.5623H11.9865V10.0426C11.9865 7.97782 12.8489 6.75 15.299 6.75H17.3388V9.28132H16.0638C15.11 9.28132 15.0469 9.63503 15.0469 10.2952L15.0434 11.562H17.3532L17.0829 14.093H15.0434Z" fill={isBlue && isActive ? '#1677FF' : isRed ? 'red' : isWhite ? 'white' : fillColor ? fillColor : `black`} />
            </g>
        </svg>
    );
};

export default FacebookIcon;
