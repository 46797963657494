import EventsPageLayout from '@layouts/EventsPageLayout'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { DatePicker, Button, Spin } from 'antd';
import dayjs, { Dayjs } from "dayjs";
import ContentLayout from "@layouts/ContentLayout.tsx";
import { useGetOrganizerStatisticsQuery, useGetOrganizerStatisticsMetricsQuery } from '@store/type-event/statistics/statistics.api';
import ExitCrossIcon from '@assets/ExitCrossIcon'
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants.ts";
import { useGetOrganizerEventsQuery } from "@store/type-event/events/events.api.ts";
import AnalyticsTable from "@components/Analytics/components/AnalyticsTable.tsx";
import "@components/Analytics/styles.scss";
import CustomResponsiveLine from "@components/Analytics/components/CustomResponsiveLine.tsx";
import { Filters, FiltersKeys, StatisticsMetrics } from "@shared/statisticTypes.ts";
import { initialFilters } from "@components/Analytics/constants.tsx";
import { getResponsiveLineData } from "@components/Analytics/helpers.ts";
import { useGetOrganizerPartnersByEventQuery } from "@store/type-event/partners/partners.api.ts";
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { useGetOrganizerLandingsQuery } from "@store/type-event/landings/landings.api.ts";
import { useGetOrganizerCampaignsByEventQuery } from "@store/type-event/campaigns/campaigns.api.ts";
import './styles.scss'
import { GAP_INIT } from "@shared/constants.ts";
import { getCurrencySymbol } from "@shared/utils.ts";

const { RangePicker } = DatePicker;

const AnalyticsPageDesktop = () => {
    const [statisticsLeftValue, setStatisticsLeftValue] = useState<StatisticsMetrics[]>([])
    const [statisticsRightValue, setStatisticsRightValue] = useState<StatisticsMetrics[]>([])
    const [filters, setFilters] = useState<Filters>(initialFilters)
    const [eventsSearchValue, setEventsSearchValue] = useState<string>()
    const [landingsPage, setLandingsPage] = useState(INIT_PAGE);
    const [eventsPage, setEventsPage] = useState(INIT_PAGE);
    const [partnersPage, setPartnersPage] = useState(INIT_PAGE);
    const [campaignsPage, setCampaignsPage] = useState(INIT_PAGE);
    const [currency, setCurrency] = useState<string>()

    const { data: statisticsMetrics } = useGetOrganizerStatisticsMetricsQuery()
    const { data: statistics, isLoading: isStatisticsLoading, isFetching: isStatisticsFetching } = useGetOrganizerStatisticsQuery(filters, { skip: !filters?.dateFrom })
    const { data: eventsRes, isLoading: isEventsLoading, isFetching: isEventsFetching } = useGetOrganizerEventsQuery({ filters: { search: eventsSearchValue || '' }, page: eventsPage })
    const { data: partnersRes, isLoading: isPartnersLoading, isFetching: isPartnersFetching } = useGetOrganizerPartnersByEventQuery({ eventId: filters?.events?.[0]?.toString() || '', page: partnersPage }, { skip: !filters?.events?.length })
    const { data: landingsRes, isLoading: isLandingsLoading, isFetching: isLandingsFetching } = useGetOrganizerLandingsQuery({ search: '', page: landingsPage, filters: { partners: filters.partners, events: filters.events } }, { skip: !filters?.events?.length })
    const { data: campaignsRes, isLoading: isCampaignsLoading, isFetching: isCampaignsFetching, } = useGetOrganizerCampaignsByEventQuery({ eventId: filters?.events?.[0]?.toString() || '' }, { skip: !filters?.events?.length })

    const currenciesOptions = useMemo(() => {
        return statistics?.data?.sums?.map(({ currency: currencyValue }) => {
            return ({ value: currencyValue || '', label: `${getCurrencySymbol(currencyValue)} ${currencyValue ? currencyValue.toUpperCase() : ''}` })
        }) || []
    }, [statistics])

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (currenciesOptions && currenciesOptions[0]?.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            setCurrency(currenciesOptions[0].value)
        }
    }, [currenciesOptions]);

    useEffect(() => {
        const initialValue = statisticsMetrics?.data?.[0] ? [{
            ...(statisticsMetrics?.data?.[0] || {}),
            value: statisticsMetrics?.data?.[0]?.metric
        }] : []
        setStatisticsLeftValue(initialValue)
    }, [statisticsMetrics?.data]);

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setFilters({
            ...initialFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    const onDateChange = (dateStrings: string[]) => {
        setFilters((prev) => ({
            ...prev,
            dateFrom: dateStrings[0],
            dateTo: dateStrings[1]
        }))
    }

    const statisticsDates = useMemo(() => {
        return statistics?.data?.dates || []
    }, [statistics])

    const { leftData, rightData } = useMemo(() => getResponsiveLineData({ statisticsLeftValue, statisticsRightValue, statisticsDates, currency }), [statisticsDates, statisticsLeftValue, statisticsRightValue, currency])

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[]) => {
        if (field === FiltersKeys.events) {
            setPartnersPage(INIT_PAGE)
            setLandingsPage(INIT_PAGE)
            setCampaignsPage(INIT_PAGE)
        }
        if (field === FiltersKeys.partners) {
            setLandingsPage(INIT_PAGE)
            setCampaignsPage(INIT_PAGE)
        }
        if (field === FiltersKeys.landings) {
            setCampaignsPage(INIT_PAGE)
        }
        if (field === FiltersKeys.events && !value.length) {
            setFilters((prev) => ({ ...prev, [field]: value, partners: [], landings: [], campaigns: [] }))
        } else if (field === FiltersKeys.partners && !value.length) {
            setFilters((prev) => ({ ...prev, partners: [], landings: [], campaigns: [] }))
        } else {
            setFilters((prev) => ({ ...prev, [field]: value }))
        }
    }, [])

    const maxDate = dayjs().endOf('day');

    const disabledDate = (current: Dayjs) => {
        return current && current > maxDate;
    };

    const handleGapChange = useCallback((value: string) => {
        setFilters((prev) => ({ ...prev, gap: value }))
    }, [])

    const tickRotation = useMemo(() => ([...leftData, ...rightData]?.[0]?.data?.length > 20 ? -45 : 0), [leftData, rightData])

    return (
        <EventsPageLayout>
            <ContentLayout>
                <div className='flex gap-8 mb-12'>
                    <InfiniteSelect
                        id="events-page__events"
                        placeholder='select'
                        maxTagCount={1}
                        style={{ width: '200px' }}
                        filterOption={false}
                        notFoundContent={isEventsLoading || isEventsFetching ? <Spin size="small" /> :
                            <div>There is no events</div>}
                        isLoading={isEventsLoading || isEventsFetching}
                        listHeight={160}
                        onChange={(value: string) => handleChangeFilter(FiltersKeys.events, value ? [value] : [])}
                        page={eventsPage}
                        setPage={setEventsPage}
                        data={eventsRes}
                        value={filters.events}
                        allowClear
                        showSearch
                        setSearchValue={(val) => {
                            setEventsSearchValue(val)
                        }}
                        optionsMapper={({ event, id }) => ({ value: id.toString(), label: event.name })}
                        classNameProp="analytics-page__infinite-select__event"
                    />
                    <InfiniteSelect
                        id="events-page__partners"
                        disabled={!filters.events.length}
                        placeholder='select'
                        style={{ width: '200px' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        filterOption={false}
                        notFoundContent={isPartnersLoading || isPartnersFetching ? <Spin size="small" /> : <div>There is no sponsors for selected event</div>}
                        isLoading={isPartnersLoading || isPartnersFetching}
                        value={filters.partners || []}
                        onChange={(value) => handleChangeFilter(FiltersKeys.partners, value)}
                        optionsMapper={({ name, organization }) => ({ value: organization.id.toString(), label: name })}
                        data={partnersRes}
                        listHeight={130}
                        page={partnersPage}
                        setPage={setPartnersPage}
                        allowClear
                        classNameProp="analytics-page__infinite-select__sponsor"
                    />
                    <InfiniteSelect
                        id="events-page__landings"
                        disabled={!filters.events.length}
                        placeholder='select'
                        style={{ width: '200px' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        allowClear
                        filterOption={false}
                        notFoundContent={isLandingsLoading || isLandingsFetching ? <Spin size="small" /> : <div>There is no landings for selected event</div>}
                        isLoading={isLandingsLoading || isLandingsFetching}
                        value={filters.landings || []}
                        onChange={(value) => handleChangeFilter(FiltersKeys.landings, value)}
                        optionsMapper={({ details, id, title }) => ({ value: id, label: details?.title || title || id })}
                        data={landingsRes}
                        listHeight={160}
                        page={landingsPage}
                        setPage={setLandingsPage}
                        classNameProp="analytics-page__infinite-select__landing"
                    />
                    <InfiniteSelect
                        id="events-page__campaigns"
                        disabled={!filters.events.length}
                        placeholder='select'
                        style={{ width: '200px' }}
                        maxTagCount="responsive"
                        mode="multiple"
                        allowClear
                        filterOption={false}
                        notFoundContent={isCampaignsLoading || isCampaignsFetching ? <Spin size="small" /> :
                            <div>There is no campaigns for selected event</div>}
                        isLoading={isCampaignsLoading || isCampaignsFetching}
                        value={filters.campaigns || []}
                        onChange={(value) => handleChangeFilter(FiltersKeys.campaigns, value)}
                        optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                        data={campaignsRes}
                        listHeight={160}
                        page={campaignsPage}
                        setPage={setCampaignsPage}
                        classNameProp="analytics-page__infinite-select__campaigns"
                    />
                    <RangePicker
                        disabledDate={disabledDate}
                        className="analytics-page__range-picker"
                        format="YYYY-MM-DD"
                        value={[filters?.dateFrom ? dayjs(filters.dateFrom) : null, filters?.dateTo ? dayjs(filters.dateTo) : null]}
                        onChange={(_, dateStrings) => onDateChange(dateStrings)}
                    />
                    <Button
                        size='large'
                        type="text"
                        onClick={setInitialFilters}
                        className="analytics-page__reset-button"
                    >
                        <ExitCrossIcon />
                        Reset
                    </Button>
                </div>
                <div className="mb-24">
                    <AnalyticsTable
                        data={statistics?.data?.sums}
                        isLoading={isStatisticsLoading || isStatisticsFetching}
                    />
                </div>
                <CustomResponsiveLine
                    leftData={leftData}
                    rightData={rightData}
                    tickRotation={tickRotation}
                    statisticsRightValue={statisticsRightValue}
                    statisticsLeftValue={statisticsLeftValue}
                    setStatisticsRightValue={setStatisticsRightValue}
                    setStatisticsLeftValue={setStatisticsLeftValue}
                    statisticsMetrics={statisticsMetrics}
                    gap={filters?.gap || GAP_INIT}
                    handleGapChange={handleGapChange}
                    currenciesOptions={currenciesOptions}
                    currency={currency}
                    setCurrency={setCurrency}
                />
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default AnalyticsPageDesktop
