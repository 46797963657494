import {Filters} from "@pages/EventRolePages/EventsPage/pages/info/campaigns-tab/types.ts";

export const initialFilters: Filters = {
    page: 1,
    statuses: [],
    landings: [],
    partners: [],
    search: '',
}

export enum FiltersKeys {
    page = 'page',
    statuses = 'statuses',
    landings = 'landings',
    partners = 'partners',
    search = 'search',
}