import React, { FC } from "react";
import { Button, Modal, } from "antd";

type Props = {
    isOpen: boolean
    onClose: () => void
    onCancelSubscription: () => void
    isLoading: boolean
    date: string
}

const CancelModal: FC<Props> = ({ isOpen, onClose, onCancelSubscription, isLoading, date = '' }) => {
    return (
        <Modal
            title="Cancel subscription"
            open={isOpen}
            onCancel={onClose}
            centered
            width={440}
            footer={(
                <div
                    className="flex justify-end"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                        className='mr-6'
                    >
                        Not now
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        danger
                        onClick={onCancelSubscription}
                        loading={isLoading}
                    >
                        Cancel subscription
                    </Button>
                </div>
            )}
        >
            <span>
                Subscription paid until
                {' '}
                {date}
                . If you would like to proceed with canceling your subscribtion, please select
                <b> Cancel subscription </b>
                below
            </span>
        </Modal>
    )
}

export default CancelModal;
