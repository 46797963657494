import { format } from "date-fns";

export const formatReportDate = (inputDate: string, gap?: string | number) => {
    if (!inputDate) {
        return ''
    }
    const gapNumber = gap ? Number(gap) : 1
    if (gapNumber === 1) {
        return format(new Date(inputDate), "MMM. d, yyyy");
    }
    if (gapNumber === 2) {
        return format(new Date(inputDate), "MMM., yyyy");
    }
    if (gapNumber === 3) {
        return format(new Date(inputDate), "yyyy");
    }
}
