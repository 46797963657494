
const TargetCardIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2773 10.2734L13.7047 18.5001L14.9215 14.9176L18.504 13.7008L10.2773 10.2734Z" stroke="#C0C1C3" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.93012 6.28049C7.91394 6.28049 6.27952 7.91492 6.27952 9.93109C6.27952 11.9028 7.35802 13.1594 9.12137 13.534C9.19479 13.5496 9.25504 13.6033 9.27642 13.6752L9.57781 14.6895C9.61796 14.8246 9.50925 14.9575 9.36951 14.939C6.73433 14.591 4.83984 12.6432 4.83984 9.93109C4.83984 7.11981 7.11884 4.84082 9.93012 4.84082C12.6441 4.84082 14.8358 6.90189 14.9842 9.39738C14.9922 9.53148 14.8624 9.62782 14.7339 9.58865L13.6653 9.2629C13.6 9.243 13.5494 9.19068 13.5301 9.12523C12.9891 7.2955 11.9085 6.28049 9.93012 6.28049Z" fill="#C0C1C3" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.93042 2.93967C6.06846 2.93967 2.93772 6.07041 2.93772 9.93237C2.93772 13.7943 6.06846 16.9251 9.93042 16.9251C10.0186 16.9251 10.0585 16.9234 10.0992 16.9202C10.1927 16.9128 10.2782 16.9707 10.3051 17.0606L10.6165 18.1039C10.6531 18.2265 10.5667 18.3506 10.4389 18.3552C10.2624 18.3615 10.0658 18.3647 9.93042 18.3647C5.27335 18.3647 1.49805 14.5894 1.49805 9.93237C1.49805 5.2753 5.27335 1.5 9.93042 1.5C14.5875 1.5 18.3628 5.2753 18.3628 9.93237C18.3628 10.0481 18.3503 10.2395 18.3384 10.4091C18.3294 10.537 18.204 10.6243 18.081 10.5883L17.0624 10.2899C16.9727 10.2636 16.9146 10.1787 16.9198 10.0854C16.922 10.046 16.9231 10.0052 16.9231 9.93237C16.9231 6.07041 13.7924 2.93967 9.93042 2.93967Z" fill="#C0C1C3" />
        </svg>
    )
}

export default TargetCardIcon