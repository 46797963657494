
const HelpMobileIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_15291_9143)">
                <path d="M13.0045 0.464844C6.08184 0.464844 0.46875 6.07793 0.46875 13.0006C0.46875 19.9232 6.08184 25.5363 13.0045 25.5363C19.9271 25.5363 25.5402 19.9232 25.5402 13.0006C25.5402 6.07793 19.9271 0.464844 13.0045 0.464844ZM13.0045 23.4097C7.25706 23.4097 2.59534 18.748 2.59534 13.0006C2.59534 7.25316 7.25706 2.59144 13.0045 2.59144C18.7519 2.59144 23.4136 7.25316 23.4136 13.0006C23.4136 18.748 18.7519 23.4097 13.0045 23.4097Z" fill="#898A8C" />
                <path d="M16.1268 7.5349C15.2873 6.79898 14.1792 6.39605 13.004 6.39605C11.8288 6.39605 10.7207 6.80178 9.88129 7.5349C9.00827 8.29879 8.52699 9.32571 8.52699 10.4254C8.52699 10.6665 8.72242 10.8619 8.9635 10.8619H9.44643H9.88129C10.1224 10.8619 10.3178 10.6665 10.3178 10.4254C10.3178 9.1914 11.5238 8.18686 13.004 8.18686C14.4842 8.18686 15.6903 9.1914 15.6903 10.4254C15.6903 11.2956 15.0747 12.0931 14.1205 12.4596C13.5273 12.6863 13.0236 13.0836 12.6627 13.6041C12.2961 14.1357 12.1058 14.7737 12.1058 15.4201V16.0217C12.1058 16.1448 12.2066 16.2455 12.3297 16.2455H13.6728C13.7959 16.2455 13.8966 16.1448 13.8966 16.0217V15.3865C13.8981 15.1149 13.9814 14.85 14.1356 14.6264C14.2898 14.4027 14.5079 14.2308 14.7613 14.1329C16.4122 13.4978 17.4783 12.0427 17.4783 10.4254C17.4811 9.32571 16.9998 8.29879 16.1268 7.5349ZM11.8848 19.1556C11.8848 19.4525 12.0027 19.7372 12.2126 19.9471C12.4225 20.157 12.7072 20.2749 13.004 20.2749C13.3009 20.2749 13.5856 20.157 13.7955 19.9471C14.0054 19.7372 14.1233 19.4525 14.1233 19.1556C14.1233 18.8588 14.0054 18.5741 13.7955 18.3642C13.5856 18.1543 13.3009 18.0364 13.004 18.0364C12.7072 18.0364 12.4225 18.1543 12.2126 18.3642C12.0027 18.5741 11.8848 18.8588 11.8848 19.1556Z" fill="#898A8C" />
            </g>
            <defs>
                <clipPath id="clip0_15291_9143">
                    <rect width="26" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HelpMobileIcon