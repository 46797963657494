const ChatIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.4 15C1.6268 15 1 14.3732 1 13.6L1 2.4C1 1.6268 1.6268 1 2.4 1L11.0872 1C11.8604 1 12.4872 1.6268 12.4872 2.4L12.4872 12.2L15 15L2.4 15Z"
                fill="white"/>
        </svg>
    )
}

export default ChatIcon