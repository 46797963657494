export const joditButtons = [
    'bold',
    'italic',
    'underline',
    'strikethrough',
    // 'ul',
    // 'ol',
    // 'outdent',
    // 'indent',
    'format',
    'left',
    'center',
    'right',
    'justify',
    'link',
    // 'image',
    // 'table',
    // 'hr',
    // 'eraser',
    'paragraph',
    'undo',
    'redo'
    // 'source'
]
export const joditControls = {
    paragraph: {
        list: {
            p: 'Normal',
            h1: 'Heading 1',
            h2: 'Heading 2',
            h3: 'Heading 3',
            h4: 'Heading 4',
            blockquote: 'Quote',
            pre: 'Code'
        }
    }
}