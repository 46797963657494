import React, { useEffect } from "react";
import '../PrivacyPolicyPage/styles.scss'

const TermsAndConditions = () => {
    useEffect(() => {
        const script = document.createElement('script');
        const tag = document.getElementsByTagName('script')[0];
        script.src = 'https://cdn.iubenda.com/iubenda.js';
        if (tag.parentNode) {
            tag.parentNode.insertBefore(script, tag);
        }
    }, []);
    return (
        <div className="privacy-policy__page">
            <a href="https://www.iubenda.com/terms-and-conditions/16978737"
               className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
               title="Terms and Conditions">Terms and Conditions</a>
        </div>
    );
}

export default TermsAndConditions;
