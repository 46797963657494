import StatusDescription from '@components/StatusDescription';
import { CampaignStatuses } from '@shared/constants';
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import { Table, TablePaginationConfig } from 'antd';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatByCurrency, formatKNumber } from "@shared/utils";
import { Pagination } from "@shared/types.ts";

interface EventCampaignsTableProps {
    campaigns: CampaignDataItem[]
    pagination: Pagination
    handleTableChange: (data: TablePaginationConfig) => void
}
const EventCampaignsTable: FC<EventCampaignsTableProps> = ({
    campaigns,
    pagination,
    handleTableChange
}) => {
    const { eventId } = useParams()

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: (status: { id: number, name: string }) => <StatusDescription status={status.name as CampaignStatuses} />
        },
        {
            title: 'Сampaign name',
            dataIndex: 'name',
            key: 'name',
            width: 240,
            render: (value: string, row: { id: string }) => {
                return (
                    <Link
                        className='no-style'
                        to={`/campaigns/edit/${eventId!}/campaign/${row.id}`}
                    >
                        {value}

                    </Link>
                )
            }
        },
        {
            title: 'Sponsor',
            dataIndex: 'partner',
            key: 'partner',
            width: 160,
        },
        {
            title: 'Landing page',
            dataIndex: 'landing',
            key: 'landing',
            width: 160,
            render: (value: CampaignDataItem['landing']) => {
                return (
                    <Link
                        className='no-style'
                        to={value.url}
                        target="_blank"
                    >
                        {value.title}

                    </Link>
                )
            }
        },
        {
            title: 'Spent',
            dataIndex: 'cost',
            key: 'cost',
            width: 120,
            render: (cost: number, row: CampaignDataItem) => formatByCurrency(row.statistics?.cost || 0, row.statistics?.currency)
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (_: number, row: CampaignDataItem) => formatKNumber(row.statistics?.impressions || 0)
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            render: (_: number, row: CampaignDataItem) => row?.statistics?.clicks || 0
        },
        {
            title: 'Average CTR',
            dataIndex: 'average_ctr',
            key: 'average_ctr',
            render: (_: number, row: CampaignDataItem) => `${(row.statistics?.average_ctr || 0).toFixed(2)}%`
        },
        {
            title: 'Average CPM',
            dataIndex: 'average_cpm',
            key: 'average_cpm',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpm || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Average CPC',
            dataIndex: 'average_cpc',
            key: 'average_cpc',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpc || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
            render: (_: number, row: CampaignDataItem) => (
                <span>
                    {(row?.statistics?.conversions || 0).toFixed(2)}
                    %
                </span>
            )
        },
        {
            title: 'Cost Per Conversion',
            dataIndex: 'cost_per_conversion',
            key: 'cost_per_conversion',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.conversions || 0, row.statistics?.currency)}</span>
        },
    ];

    return (
        <Table
            dataSource={campaigns}
            columns={columns}
            scroll={{ x: 1200 }}
            pagination={pagination}
            onChange={handleTableChange}
        />
    );
};

export default EventCampaignsTable;
