import React, { useState } from 'react';
import { Input, Drawer, Badge, Button, Select } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import '../styles.scss'
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';
import { LeadsFilters } from '@store/models-to replace/leads';
import { useGetPartnerCampaignsQuery } from '@store/type-partner/campaigns/campaigns.api';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import ExportToCsvButton from '../../../ExportToCsvButton';
import { useGetLeadsExported } from '@pages/PartnerRolePages/Leads/leads.api';
import useSwipeDown from '@hooks/useSwipeDown';

interface Props {
    filters: LeadsFilters;
    onFilterUpdate: (updatedFilters: Partial<LeadsFilters>) => void;
    onResetFilters: () => void;
    debouncedOnSearchUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterDrawer: React.FC<Props> = ({
    filters,
    onResetFilters,
    debouncedOnSearchUpdate,
    onFilterUpdate
}) => {

    const { data: optionsData, isLoading: filterIsLoading } = useGetFiltersByEnumQuery({ name: 'lead_type' })
    const { error: campaignError, data: campaignData } = useGetPartnerCampaignsQuery({ filter: undefined });
    const { isLoading: isExportLoading, run: runExport } = useGetLeadsExported();

    const [isShowFilters, setShowFilters] = useState(false)
    const [drawerFilters, setDrawerFilters] = useState<Partial<LeadsFilters>>(filters)

    const onCloseFilters = () => {
        setShowFilters(false)
    }

    const mapTypesToOptions = () => {
        return optionsData?.data.map((option) => {
            return { value: option.id, label: option.slug }
        })
    }

    const mapCampaignsToOptions = () => {
        if (campaignData?.data?.length) {
            return campaignData.data.map(campaign => ({ value: campaign.id, label: campaign.name }))
        } else {
            return []
        }
    }

    const mapFilterValues = (newFilters: LeadsFilters) => {
        const result: LeadsFilters = {
            search: '',
            types: [],
            campaigns: [],
        };
        if (newFilters.search && newFilters.search.length > 2) {
            result.search = newFilters.search
        }
        if (newFilters.types && newFilters.types.length > 0) {
            result.types = newFilters.types
        }
        if (newFilters.campaigns && newFilters.campaigns.length > 0) {
            result.campaigns = newFilters.campaigns
        }
        return result;
    }
    const { onTouchStart, onTouchEnd } = useSwipeDown(onCloseFilters);

    const onDrawerFilterChange = () => {
        onFilterUpdate(drawerFilters)
        setShowFilters(false)
    }

    const onDrawerResetClick = () => {
        onResetFilters();
        setDrawerFilters(filters)
        setShowFilters(false)
    }

    const isFilterApplied = filters.search.length > 3 || filters.types.length || filters.campaigns.length;
    return (
        <>
            <div className='flex items-center justify-space-between'>
                <h3>
                    My leads
                </h3>
                {/* <Button
                    type='primary'
                    className="ml-auto"
                    onClick={() => {
                        sendAnalytics(GTMEventName.eventCatalog)
                        navigate('catalog')
                    }}
                >
                    Export
                </Button> */}
                <ExportToCsvButton
                    runExportReq={() => runExport({ filters: mapFilterValues(filters) })}
                    isLoading={isExportLoading}
                >
                    Export CSV
                </ExportToCsvButton>
            </div>
            <div className='my-event-page-mobile__filter-container'>
                <Input
                    className='my-event-page-mobile__filter-text'
                    allowClear
                    placeholder="Search by keywords"
                    prefix={<SearchOutlined />}
                    onChange={debouncedOnSearchUpdate}
                />
                <button
                    className='no-style'
                    onClick={() => setShowFilters(true)}
                >
                    <Badge
                        dot={!!isFilterApplied}
                        status="processing"

                    >
                        <FilterButtonMobileIcon
                            isActive={!!isFilterApplied}
                        />
                    </Badge>
                </button>



                <Drawer
                    closeIcon={null}
                    placement='bottom'
                    title={
                        <div
                            onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            className='mobile-drawer__header'
                        >
                            <svg width="51" height="4" viewBox="0 0 51 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 2H49.5" stroke="#D9DADC" stroke-width="3" stroke-linecap="round" />
                            </svg>
                            <div className='mobile-drawer__header-title'>
                                <div></div>
                                <span className='mobile-drawer__header-title-text'>Filters</span>
                                <CloseOutlined
                                    onClick={onDrawerResetClick}
                                />
                            </div>
                        </div>}
                    onClose={onCloseFilters}
                    open={isShowFilters}
                    className='mobile-drawer'
                    footer={<footer className='mobile-drawer__footer flex'>
                        <Button
                            size='large'
                            block
                            style={{ marginRight: '6px' }}
                            onClick={onDrawerResetClick}
                        >
                            Reset
                        </Button>
                        <Button
                            block
                            type='primary'
                            size='large'
                            onClick={onDrawerFilterChange}
                        >
                            Apply
                        </Button>
                    </footer>}
                >
                    <div
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                        className='flex flex-col drawer-filter-content'
                    >
                        <p>
                            Lead type
                        </p>
                        <Select
                            mode="multiple"
                            options={mapTypesToOptions()}
                            disabled={!optionsData?.data || optionsData.data.length < 1}
                            placeholder={'Lead type'}
                            value={drawerFilters.types}
                            onChange={(types) => setDrawerFilters({ types })}
                            loading={filterIsLoading}
                            style={{
                                width: '100%',
                                marginBottom: '12px'
                            }}
                        />
                        <p>
                            Campaigns
                        </p>
                        <Select
                            mode="multiple"
                            options={campaignError ? [] : mapCampaignsToOptions()}
                            disabled={!campaignData?.data || campaignData.data.length < 1}
                            placeholder={'Campaigns'}
                            value={drawerFilters.campaigns}
                            onChange={(campaigns) => setDrawerFilters({ campaigns })}
                            style={{
                                width: '100%'
                            }}
                        />
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default FilterDrawer;
