import { Table, Tooltip } from "antd";
import type { ColumnsType } from 'antd/es/table';
import React, { useMemo } from "react";
import { formatByCurrency } from "@shared/utils.ts";
import { useGetRevShareCommissionsQuery } from '@store/type-event/billing/billing.api.ts';
import {
    TabNames,
} from "@pages/EventRolePages/Billing/constants.ts";
import TotalNumbers from './TotalNumbers.tsx';
import emptyTransactions from "@assets/png/emptyTransactions.png";
import { formatNumberWithThousandsSeparator } from "@components/Analytics/helpers.ts";
import InfoIcon from "@icons/header/InfoIcon.tsx";

const RevShareCommissionsTable = () => {
    const { data: revShareRes, isLoading: isRevShareLoading, isFetching: isRevShareFetching } = useGetRevShareCommissionsQuery()

    const revShareMapped = useMemo(() => {
        const currency = revShareRes?.currency
        return revShareRes?.data?.map((revShare, index, array) => {
            const costMax = array[index + 1]?.cost_min ? array[index + 1].cost_min : null
            const formattedMin = formatByCurrency(revShare?.cost_min || 0, currency)
            const spent = costMax ? `from ${formattedMin} to ${formatByCurrency(costMax || 0, currency)}` : `from ${formattedMin}`
            return {
                ...revShare,
                spent
            }
        })
    }, [revShareRes])

    const columns: ColumnsType = [
        {
            title: 'Spent',
            dataIndex: 'spent',
            key: 'spent',
        },
        {
            title: 'Revshare',
            dataIndex: 'revshare',
            key: 'revshare',
            align: 'right',
            render: (revshare: number) => {
                return (
                    <span className="organizer-billing-page__reports__number">
                        {formatNumberWithThousandsSeparator((revshare || 0) * 100)}
                        %
                    </span>
                )
            }
        },
    ];

    return (
        <>
            <TotalNumbers />
            <div className="flex gap-6 organizer-billing-page__rev-share-title">
                <div className="organizer-billing-page__title">{TabNames.RevShareCommissions}</div>
                <Tooltip
                    overlayClassName='tooltip-general ad-accounts-tooltip'
                    title={(
                        <div>
                            Rev share commissions can differ depending on the event. The table below displays the values for
                            the selected event.
                        </div>
                    )}
                    placement="bottom"
                >
                    <div className='cursor-pointer'>
                        <InfoIcon fillColor="#57585A" width={18} height={18} />
                    </div>
                </Tooltip>
            </div>
            <div className="mb-24">
                <Table
                    className={`organizer-billing-page__transactions__table ${revShareMapped?.length ? '' : 'organizer-billing-page__table-empty'}`}
                    dataSource={revShareMapped}
                    columns={columns}
                    rowKey="id"
                    loading={isRevShareLoading || isRevShareFetching}
                    pagination={false}
                    locale={{
                        emptyText: isRevShareLoading || isRevShareFetching ? ' ' : (
                            <div className='ad-accounts-page__empty-credentials'>
                                <img src={emptyTransactions} alt=' emptyTransactions' />
                                <span><b>You have no rev share commissions</b></span>
                                <span>It will be displayed here when you have your first transactions</span>
                            </div>
                        )
                    }}
                />
            </div>
        </>
    )
}

export default RevShareCommissionsTable
