import React, { FC } from 'react';
import { Button, Modal, } from 'antd';

type RejectionReasonModalPropsType = {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    comment: string
    reason: string
}

const RejectionReasonModal: FC<RejectionReasonModalPropsType> = ({
    isOpen, onClose, comment, reason = ''
}) => {
    return (
        <Modal
            title="Rejection reason"
            open={isOpen}
            onCancel={onClose}
            className="rejection-reason-modal"
            centered
            zIndex={1001}
            footer={(
                <Button
                    size="large"
                    type="primary"
                    onClick={onClose}
                >
                    Got it
                </Button>
            )}
        >
            <div className='mb-6'>
                <b>
                    Rejection reason:
                    &nbsp;
                    {reason}
                </b>
            </div>
            <span>{comment}</span>
        </Modal>
    )
}

export default RejectionReasonModal;
