//https://boost.allnetru.dev/api/documentation#/Cities
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetFiltersResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const cities = `cities`;

export const citiesApi = createApi({
    reducerPath: cities,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [cities],
    endpoints: build => ({
        getCitiesBySearch: build.query<GetFiltersResponse, { search: string, countries?: string, page: number }>({
            query: ({ search, countries, page }) => {
                const params = new URLSearchParams();

                if (search && !(search.length > 0 && search.length < 2)) {
                    params.append('search', search.toString());
                }
                if (page) {
                    params.append('page', page.toString());
                }
                const countriesQueryString = countries ? `countries[]=${countries}` : ''
                const queryString = [
                    params.toString(),
                    countriesQueryString,
                ].filter(Boolean).join('&');
                return {
                    url: `/cities/filter/${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
        })
    }),
});

export const {
    useGetCitiesBySearchQuery,
} = citiesApi;