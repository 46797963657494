import React, { createContext, useContext, PropsWithChildren, useEffect, useState } from 'react';
import { useLazyGetUserQuery, useLogoutMutation, useLoginUserMutation } from "@store/main/-user/user.api";
import { AxiosError, AxiosResponse } from "axios";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { UserLoginResponse, UserType } from "@store/models-to replace/auth";
import { userActions } from "@store/main/-user/user.slice";
import { useAppSelector } from "@hooks/redux";
import { useDispatch } from "react-redux";
import { ErrorDataResponse } from "@shared/utils";
import * as Sentry from '@sentry/react';

interface AuthContextType {
    user?: UserType;
    reFetchUser: () => Promise<AxiosResponse<UserLoginResponse>>;
    userLoadingError?: FetchBaseQueryError | SerializedError;
    isUserLoading: boolean;
    isAuthenticated: boolean;
    logout: (path?: string) => void;
    login: (values: { email: string, password: string }) => Promise<boolean>;
    emailConfirmedData: {
        isConfirmed: boolean;
        error: string;
    };
    setEmailConfirmedData: ({ isConfirmed, error }: {
        isConfirmed: boolean;
        error: string;
    }) => void;
    isHubspotChatOpen: boolean;
    setIsHubspotChatOpen: (isOpen: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
    const [isHubspotChatOpen, setIsHubspotChatOpen] = useState(false);

    const { user, isUserLoading, userError } = useAppSelector(state => state.userAuth)
    const dispatch = useDispatch()

    const [onGetUser] = useLazyGetUserQuery()

    const handleGetUser = async () => {
        dispatch(userActions.setIsUserLoading(true))
        try {
            const response = await onGetUser();
            if (response?.data) {
                dispatch(userActions.setUserState(response?.data?.data))
                dispatch(userActions.setUserError(''))
            }
            if ((response as ErrorDataResponse)?.error?.data?.message) {
                dispatch(userActions.setUserError((response as ErrorDataResponse).error.data.message || ''))
            }
        } catch (error: AxiosError) {
            if (error?.response?.status === 401) {
                dispatch(userActions.setUserState(undefined))
            } else {
                dispatch(userActions.setUserError(error?.message))
            }
        } finally {
            dispatch(userActions.setIsUserLoading(false))
        }
    };

    useEffect(() => {
        // eslint-disable-next-line no-void
        void handleGetUser()
    }, [])

    useEffect(() => {
        if (!user?.id) {
            Sentry.setUser(null);
        } else {
            Sentry.setUser({
                id: user.id,
                email: user.email,
            });
        }
        return () => {
            Sentry.setUser(null);
        };
    }, [user]);

    const [loginUser] = useLoginUserMutation()
    const [logoutUser] = useLogoutMutation()
    // todo change when back is ready
    const [emailConfirmedData, setEmailConfirmedData] = useState({
        isConfirmed: false,
        error: ''
    })

    const { children } = props;

    const logout = async (path?: string) => {
        try {
            await logoutUser().then(() => {
                const nextPath = typeof path === 'string' ? path : ''
                window.location.replace(nextPath || '/');
            })
        } catch (e) {
            console.error("Logout error:", e)
        }
    }

    const login = async (values: { email: string, password: string }) => {
        try {
            const response = await loginUser(values)
            if ('data' in response) {
                dispatch(userActions.setUserState(response.data?.data))
                dispatch(userActions.setUserError(''))
                await handleGetUser()
                return true
            }
        } catch (err) {
            console.error('Login error:', err)
            return false
        }
    }

    const isAuthenticated = !!user;

    return (
        <AuthContext.Provider value={{
            user,
            isAuthenticated,
            isUserLoading,
            userLoadingError: userError,
            login,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            logout,
            reFetchUser: handleGetUser,
            emailConfirmedData,
            setEmailConfirmedData,
            isHubspotChatOpen,
            setIsHubspotChatOpen
        }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}
