import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import InfoIcon from '@icons/header/InfoIcon'
import { HELP_CENTER_LINK } from "@shared/constants.ts";
import {useAuth} from "@contexts/AuthContext.tsx";
import {isHubSpotScriptLoaded, toggleHubspotChat} from "@components/HubSpotButton/helpers.ts";

const InfoDropdown = ({ fillColor }: { fillColor?: string }) => {
    const { isHubspotChatOpen, setIsHubspotChatOpen } = useAuth()

    const onOpenSupportChat = () => {
        if (isHubSpotScriptLoaded()) {
            const isOpenClass = document.querySelector('.hs-messages-widget-open')
            const isOpen = Boolean(isOpenClass || isHubspotChatOpen)
            toggleHubspotChat(isOpen)
            setIsHubspotChatOpen(!isOpen);
        } else {
            console.error('HubSpot Conversations not loaded');
        }
    }

    const onHelpCenterClick = () => {
        window.open(HELP_CENTER_LINK, '_blank')
    }

    const items: MenuProps['items'] = [
        {
            label: <span>Help center</span>,
            key: '0',
            onClick: onHelpCenterClick
        },
        {
            onClick: onOpenSupportChat,
            label: <span>Support chat</span>,
            key: '1',
        },
    ];

    return (
        <Dropdown
            overlayStyle={{ width: '119px' }}
            menu={{ items }}
            trigger={['click']}
        >
            <div className='header-item header-item-info'>
                <InfoIcon
                    fillColor={fillColor}
                />
            </div>
        </Dropdown>
    )
};

export default InfoDropdown;
