import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import './styles.scss'

interface AssetImagesDrawerProps {
    open: boolean;
    onClose: () => void;
    images: { id?: number | null, value: string }[];
    onDeleteClick?: (url: string) => void
}
const AssetImagesDrawer: React.FC<AssetImagesDrawerProps> = ({
    open,
    onClose,
    images,
    onDeleteClick
}) => {


    return (
        <>
            <Drawer
                title="Creative Images Gallery"
                placement="right"
                onClose={onClose}
                open={open}
            >
                <div
                    className='asset-image-drawer__img-container'
                >
                    {images.map((item, idx) => {
                        return (
                            <article
                                key={idx}
                                className='asset-image-drawer__img-item'
                            >
                                <img
                                    src={item.value}
                                />
                            </article>
                        )
                    })
                    }
                </div>
            </Drawer>
        </>
    );
};

export default AssetImagesDrawer;