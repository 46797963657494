import SkeletonCustom from '@components/SkeletonCustom'
import EventCatalogTableZeroState from './components/TableZeroState'

const MyEventsCatalogZeroState = () => {
    return (
        <div>
            <div className='my-event-page__filter-container flex'>
                <div className='mr-6'>
                    <SkeletonCustom type='title' width='232px' />
                </div>
                <div className='mr-6'>
                    <SkeletonCustom type='title' width='232px' />
                </div>
                <div className='mr-6'>
                    <SkeletonCustom type='title-m' width='112px' />
                </div>
                <div className='mx-6'>
                    <SkeletonCustom type='title-m' />
                </div>
            </div>

            <div className='flex'>
                <EventCatalogTableZeroState isInfinity />
            </div>

        </div>)
}

export default MyEventsCatalogZeroState