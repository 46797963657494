
const BoostProMobileIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.0918 3.83699C16.2179 4.31171 13.4142 6.76536 11.8064 9.4777C10.7585 11.2455 10.0878 13.1668 9.82742 14.5359L13.5499 17.1424C14.7474 16.4295 16.3235 15.1421 17.6262 13.5528C19.6249 11.1145 20.9708 7.63991 20.0918 3.83699ZM12.7792 19.0443L8.30387 15.9106L4.63125 14.8179C3.38303 14.4465 2.91307 12.9256 3.73415 11.9148L6.36764 8.6726C6.71079 8.25014 7.22442 8.00267 7.76865 7.99756L10.3862 7.97299C12.3095 4.99751 15.5767 2.29035 20.0943 1.8241C20.9651 1.73423 21.769 2.29712 21.9824 3.14613C23.0894 7.55063 21.6642 11.5475 19.5259 14.3727L20.3981 16.8408C20.5794 17.354 20.5226 17.9213 20.2429 18.3882L18.097 21.9718C17.4279 23.0891 15.8381 23.1676 15.0622 22.1217L12.7792 19.0443ZM18.0165 16.104C17.0025 17.1318 15.9232 17.9998 14.9567 18.6228L16.5142 20.7221L18.4853 17.4305L18.0165 16.104ZM9.27561 9.9835L7.86876 9.99671L5.4498 12.9748L7.95521 13.7202C8.21009 12.599 8.65654 11.2879 9.27561 9.9835Z" fill="#898A8C" />
            <path d="M17.7671 10.6435C17.1335 11.5483 15.8865 11.7682 14.9816 11.1347C14.0768 10.5011 13.8569 9.25403 14.4905 8.34922C15.124 7.44441 16.3711 7.22451 17.2759 7.85807C18.1808 8.49162 18.4007 9.73871 17.7671 10.6435Z" fill="#898A8C" />
            <path d="M10.3148 22.1608C9.41214 23.3347 5.87699 24.14 5.87699 24.14C5.87699 24.14 5.53164 20.5444 6.21901 19.2929C6.76637 18.2456 8.16257 17.6019 9.70083 18.679C11.2344 19.7528 11.0245 21.2378 10.3148 22.1608Z" fill="#898A8C" />
        </svg>
    )
}

export default BoostProMobileIcon