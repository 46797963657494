import './styles.scss'
import ProfileDropdown from '@components/Header/ProfileDropdown'
import { Breadcrumb } from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
    campaigns_ads,
    campaigns_create,
    campaigns_edit,
    campaigns_group
} from '@pages/routes'
import NotificationsHeader from '@components/NotificationsHeader'
import ProHeader from '@components/ProHeader/ProHeader';
import InfoDropdown from '@components/Header/InfoDropdown';
import { StripeProvider } from '@contexts/StripeContext';
import React from "react";

const menuItems = [
    {
        title: <Link to={'/campaigns'}>Campaigns</Link>,
    },
    {
        title: <Link to={'/campaigns'}>Campaigns Groups</Link>,
        // menu: { items: menuItems },
    },
    {
        title: 'Ads',
    },
];

const munuCampaignsCreate = [
    {
        title: <Link to={'/campaigns'}>Campaigns</Link>,
    },
    {
        title: <span className='isActive'>
            Create New Campaign
        </span>,
    },
];
const CampaignBreadcrumb = () => {
    const { pathname } = useLocation()
    const { id } = useParams()


    switch (pathname) {
        case campaigns_create:
            return <Breadcrumb
                items={munuCampaignsCreate}
            />
        case campaigns_edit + `/${id ?? ''}`:
            return <Breadcrumb
                items={menuItems}
            />
        case campaigns_ads + `/${id ?? ''}`:
            return <Breadcrumb
                items={menuItems}
            />
        case campaigns_group + `/${id ?? ''}`:
            return <Breadcrumb
                items={menuItems}
            />
        default:
            break;
    }
}

type Props = {
    children: React.ReactNode
}

export const SettingsHeader = ({ children }: Props) => {
    return (
        <header className='settings-header'>
            <div className='settings-header__main'>
                <CampaignBreadcrumb />
            </div>

            {children}

            <aside>
                <StripeProvider>
                    <ProHeader />
                </StripeProvider>
                <div
                    className='settings-header__item'
                >
                    <InfoDropdown fillColor="#57585A" />
                </div>
                <div
                    className='settings-header__item'
                >
                    <NotificationsHeader fillColor="#57585A" />
                </div>
                <div
                    className='settings-header__item'
                >
                    <ProfileDropdown />
                </div>
            </aside>

        </header>
    )
}

export default SettingsHeader

