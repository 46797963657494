import React, { useState } from 'react'
import { Button, Drawer, InputNumber, InputNumberProps, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import '../styles.scss'
import { useAuth } from '@contexts/AuthContext';
import { usePatchBillingAutodebitMutation } from '@store/main/billing/billing.api';

interface AutoPaymentDrawerProps {
    isOpen: boolean;
    onClose: () => void;
}

const AutoPaymentDrawer: React.FC<AutoPaymentDrawerProps> = ({
    isOpen,
    onClose
}) => {
    const { user, reFetchUser } = useAuth()
    const billing = user?.organization?.billing

    const [updateAutoDebit] = usePatchBillingAutodebitMutation()
    const [isLoading, setLoading] = useState(false)
    const [amount, setAmount] = useState<number | null>(billing?.autodebit_amount || null)
    const [error, setError] = useState('')

    const onChange: InputNumberProps['onChange'] = (value) => {
        setError('')
        setAmount(value ? +value : 0)
    };

    const onSubmit = async () => {
        if (!amount || +amount < 100) {
            void message.open({
                type: 'error',
                content: 'Amount should be more then $100',
            });
            return setError('Amount should be more then $100')
        }
        setLoading(true)
        try {
            const response = await updateAutoDebit({ amount, autodebit: true });
            if ('data' in response) {
                setLoading(false)
                onClose()
                void reFetchUser()
                return message.open({
                    type: 'success',
                    content: 'Automatic Payment Limit Updated',
                });
            }

        } catch (error) {
            console.error('rejected', error);
        }
    }

    return (
        <Drawer
            title='Automatic payment amount'
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            className='drawer-body'
            width={450}
            mask
            maskClosable={false}
            onClose={onClose}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={
                <CloseOutlined
                    onClick={onClose}
                />
            }
        >
            <div className='drawer-content'>
                <p className='text-subdued'>
                    The automatic payment will be debited when your account balance reaches the $100 limit.
                </p>

                <InputNumber<number>
                    style={{ width: '100%' }}
                    defaultValue={100}
                    // min={100}
                    value={amount}
                    status={error ? 'error' : ''}
                    formatter={(value) => `$ ${value || ''}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                    onChange={onChange}
                />
                <p className='text-subdued drawer-disclaimer-grey'>
                    We recommend depositing at least $100,00 to test the advertising capabilities of Boost.Express
                </p>
            </div>

            <footer className='drawer-footer'>
                <Button
                    size='large'
                    block
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <div style={{ minWidth: '14px' }} />
                <Button
                    size='large'
                    block
                    type='primary'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onSubmit}
                    loading={isLoading}
                >
                    Save
                </Button>
            </footer>

        </Drawer>
    )
}

export default AutoPaymentDrawer