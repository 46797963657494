import React from 'react'
import './styles.scss'
import { DeleteOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import StatusDescription from '@components/StatusDescription'
import { CampaignStatuses } from '@shared/constants'
import { useDispatch } from 'react-redux'
import { useDeleteCampaignByIdMutation } from '@store/type-partner/campaigns/campaigns.api'
interface FooterCampaignsProps {
    selectedCount: number;
    selectedIds: string[];
    resetSelected: () => void
}


const FooterCampaigns: React.FC<FooterCampaignsProps> = ({
    selectedCount,
    selectedIds,
    resetSelected
}) => {

    const [onDelete] = useDeleteCampaignByIdMutation()
    // const [updateStatusByIdList] = usePostCampaignStatusByIdListMutation()
    const dispatch = useDispatch()

    // const onCampaignsListStatusChange = async ({ status }: { status: "in_progress" | "paused" }) => {
    //     dispatch(campaignsActions.updateCampaignStatus({
    //         campaignIds: selectedIds,
    //         status
    //     }))
    //     resetSelected()

    //     try {
    //         const response = await updateStatusByIdList({
    //             campaign_ids: selectedIds,
    //             status
    //         });

    //         if ('data' in response) {
    //             void message.open({
    //                 type: 'success',
    //                 content: 'Status Updated',
    //             });
    //         }
    //         if ('error' in response) {
    //             console.log('SMTH WRONG');
    //             // Handle the error here
    //         } else {
    //             // login(values);
    //             console.log('SMTH WRONG: 2');
    //         }
    //     } catch (error) {
    //         console.log('SMTH WRONG');
    //         // Handle the error here
    //     }
    // }

    const onDeleteClick = async () => {
        // dispatch(campaignsActions.removeCampaignsById({ campaignIds: selectedIds }))
        try {
            const response = await onDelete({ id: selectedIds[0].toString() });

            if ('error' in response) {
                console.log('SMTH WRONG');
                // Handle the error here
            } else {
                // navigate('/campaigns');
                // login(values);
                console.log('Success:');
            }
        } catch (error) {
            console.log('SMTH WRONG');
            // Handle the error here
        }
    }

    const itemsStatus: MenuProps['items'] = [
        {
            key: '1',
            label: (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                <div
                // onClick={() => onCampaignsListStatusChange({ status: "in_progress" })}
                >
                    <StatusDescription
                        status={CampaignStatuses.in_progress}
                    />
                </div>

            ),
        },
        {
            key: '2',
            label: (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                <div
                // onClick={() => onCampaignsListStatusChange({ status: "paused" })}
                >
                    <StatusDescription
                        status={CampaignStatuses.paused}
                    />
                </div>

            )
        },
        {
            key: '3',
            label: (
                <StatusDescription
                    status={CampaignStatuses.remove}
                />

            )
        }
    ];

    return (
        <footer className='footer-campaigns'>
            <div className='footer-campaigns__main-controls'>
                <p className='footer-campaigns__counter'>
                    {selectedCount} items
                </p>
                {/* <p className='footer-campaigns__btn'>
                    Budget
                </p>
                <p className='footer-campaigns__btn'>
                    Bit strategy
                </p> */}
                <Dropdown
                    menu={{ items: itemsStatus }}
                    trigger={['click']}
                >
                    <p className='footer-campaigns__btn'>
                        Status
                    </p>
                </Dropdown>

            </div>

            <p
                className='footer-campaigns__cancel-cta'
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={onDeleteClick}
            >
                <DeleteOutlined style={{ color: '#FFF' }} />
                <span>Delete</span>
            </p>

        </footer>
    )
}

export default FooterCampaigns