import { Subscription } from "@store/models-to replace/auth";

export enum SubscriptionName {
    boost_pro = 'boost_pro'
}

export enum SubscriptionStatus {
    active = 'active',
    paused = 'paused'
}

export type SubscriptionData = {
    id: number
    slug: string
    translate: string
}

export type GetSubscriptionDataResponse = {
    data: SubscriptionData[]
}

export type PostSubscriptionResponse = {
    data: Subscription
}
