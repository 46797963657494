const MaxLeadsIcon = () => {
    return (
        <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 21.5V19.5C17 18.4391 16.5786 17.4217 15.8284 16.6716C15.0783 15.9214 14.0609 15.5 13 15.5H5C3.93913 15.5 2.92172 15.9214 2.17157 16.6716C1.42143 17.4217 1 18.4391 1 19.5V21.5" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M23 21.4989V19.4989C22.9993 18.6126 22.7044 17.7517 22.1614 17.0512C21.6184 16.3508 20.8581 15.8505 20 15.6289" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16 3.62891C16.8604 3.84921 17.623 4.34961 18.1676 5.05122C18.7122 5.75283 19.0078 6.61574 19.0078 7.50391C19.0078 8.39208 18.7122 9.25499 18.1676 9.9566C17.623 10.6582 16.8604 11.1586 16 11.3789" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29 21.4989V19.4989C28.9993 18.6126 28.7044 17.7517 28.1614 17.0512C27.6184 16.3508 26.8581 15.8505 26 15.6289" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22 3.62891C22.8604 3.84921 23.623 4.34961 24.1676 5.05122C24.7122 5.75283 25.0078 6.61574 25.0078 7.50391C25.0078 8.39208 24.7122 9.25499 24.1676 9.9566C23.623 10.6582 22.8604 11.1586 22 11.3789" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default MaxLeadsIcon