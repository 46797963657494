import React, { useContext, useEffect } from 'react'
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import BillingStripeFormWrapper from '@components/BillingStripeForm';
import { StripeContext } from '@contexts/StripeContext';
import Loader from '@components/Loader';
interface AddPaymentDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    isLoading?: boolean;
}

const AddPaymentDrawer: React.FC<AddPaymentDrawerProps> = ({
    isOpen,
    onClose,
    isLoading
}) => {

    const {
        onOpenStripeForm,
        loading,
        intent,
        setIntent,
        apiKey,
        onAddPaymentClick
    } = useContext(StripeContext)


    useEffect(() => {
        if (isOpen) {
            onOpenStripeForm()
        }
    }, [isOpen])

    const onModalClose = () => {
        onClose();
        setIntent(null)
    }

    const onSubmit = async () => {
        await onAddPaymentClick()
            .then(() => onClose())
    }
    return (
        <Drawer
            title='Add new card'
            closeIcon={false}
            placement="right"
            open={isOpen}
            width={450}
            autoFocus={false}
            mask
            maskClosable={false}
            onClose={onModalClose}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={
                <CloseOutlined
                    onClick={onModalClose}
                />
            }
        >
            <div className='drawer-content'>
                {loading && <Loader />}
                {intent
                    ?
                    <BillingStripeFormWrapper
                        intent={intent}
                        apiKey={apiKey}
                    />
                    : null
                }
            </div>


            <footer className='drawer-footer'>
                <Button
                    size='large'
                    block
                    onClick={onModalClose}
                >
                    Cancel
                </Button>
                <div style={{ minWidth: '14px' }} />
                <Button
                    size='large'
                    block
                    type='primary'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onSubmit}
                    loading={loading || isLoading}
                >
                    Save
                </Button>
            </footer>
        </Drawer >
    )
}

export default AddPaymentDrawer