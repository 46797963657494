// todo https://boost.allnetru.dev/api/documentation/partner#/Landings
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getQueryStringFromParams } from "@shared/utils.ts";
import {
    FiltersKeys,
    GetStatisticsMetricsResponse,
    GetStatisticsParams,
    GetStatisticsResponse
} from "@shared/statisticTypes.ts";

const baseUrl = import.meta.env.VITE_API_URL as string;
const organizer_statistics = `organizer_statistics`;

export const organizerStatistics = createApi({
    reducerPath: organizer_statistics,
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    tagTypes: [
        organizer_statistics
    ],
    endpoints: (build) => ({
        getOrganizerStatisticsMetrics: build.query<GetStatisticsMetricsResponse, void>({
            query: () => {
                return {
                    url: '/organizer/statistics/metrics',
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [organizer_statistics],
        }),
        getOrganizerStatistics: build.query<GetStatisticsResponse, GetStatisticsParams>({
            query: ({ events, landings, campaigns, partners, dateFrom, dateTo, gap }) => {
                const landingsQueryString = getQueryStringFromParams(landings, FiltersKeys.landings)
                const campaignsQueryString = getQueryStringFromParams(campaigns, FiltersKeys.campaigns)
                const eventsQueryString = getQueryStringFromParams(events, FiltersKeys.events)
                const partnersQueryString = getQueryStringFromParams(partners, FiltersKeys.partners)
                const params = new URLSearchParams();

                if (dateFrom) {
                    params.append('filters[date][from]', `${dateFrom}`);
                }

                if (dateTo) {
                    params.append('filters[date][to]', `${dateTo}`);
                }

                if (gap) {
                    params.append('filters[gap]', gap);
                }

                const paramsString = params.toString();

                const queryString = [
                    paramsString,
                    landingsQueryString,
                    campaignsQueryString,
                    eventsQueryString,
                    partnersQueryString,
                ].filter(Boolean).join('&');

                return {
                    url: `/organizer/statistics${queryString.length ? "?" + queryString : ''}`,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                };
            },
            providesTags: [organizer_statistics],
        }),
    }),
});

export const {
    useGetOrganizerStatisticsMetricsQuery,
    useGetOrganizerStatisticsQuery,
} = organizerStatistics;