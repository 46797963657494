import { SetStateAction, useEffect, useState } from 'react';

const TextTyper = ({ text }: { text: string }) => {
    const [typedText, setTypedText] = useState("");
    // declare the variable to hold the phrase
    // declare a variable with the interval of 100 milliseconds
    const interval = 50
    const typingRender = (text: string | any[], updater: { (value: SetStateAction<string>): void; (arg0: string): void; }, interval: number | undefined) => {
        let localTypingIndex = 0;
        let localTyping = "";
        if (text) {
            const printer = setInterval(() => {
                if (localTypingIndex < text.length) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
                    updater((localTyping += text[localTypingIndex]));
                    localTypingIndex += 1;
                } else {
                    localTypingIndex = 0;
                    localTyping = "";
                    clearInterval(printer);
                }
            }, interval);
        }
    };
    // run this effect on first render
    useEffect(() => {
        // call the function passing a phrase, setter method for state and interval var
        typingRender(text, setTypedText, interval);
    }, [text, interval]);
    return <span>{typedText}</span>
}
export default TextTyper