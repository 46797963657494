import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react'
import './styles.scss'
interface TagCustomProps {
    id: number;
    name: string;
    onClose: (id: number) => void
}
const TagCustom: React.FC<TagCustomProps> = ({
    id,
    name,
    onClose
}) => {
    return (
        <div
            className='tag-custom'
        >
            {name}
            <div
                className='tag-custom__close-icon'
                onClick={() => onClose(id)}
            >
                <CloseCircleOutlined />
            </div>
        </div>
    )
}

export default TagCustom