import { parseISO, format } from "date-fns";
import { formatDate } from '@shared/utils';

export const formatCommentDate = (date?: string) => {
    if (!date) {
        return ''
    }
    const parsedDate = parseISO(date);
    const formattedTime = format(parsedDate, 'HH:mm');
    const formattedDate = formatDate(date)

    return `${formattedDate} | ${formattedTime}`
}
