import '../styles.scss'
import NoEventsDataFound from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState/NoDataFound'
import { EventCatalogItemStatuses } from '@shared/constants'
import EventItemHeaderInfo from '../components/EventItemHeaderInfo'
import SkeletonEventDetailsState from './skeleton'
import { useEffect, useState } from 'react'
import { InlineWidget } from 'react-calendly'
import { Divider } from 'antd'
import { Typography } from 'antd';
import { GetEventCheckListByEventIdItem, OrganizerEventDataFullItem } from '@store/type-event/events/models';
import EventSetupProgress from '../components/EventSetupProgress'
import { EventChecklistName } from '../constants'
import EventItemHeaderInfoMobile from '../components/EventItemHeaderInfoMobile'
import { useBreakpoints } from '@hooks/browser'

const { Title } = Typography;

type Props = {
    event?: OrganizerEventDataFullItem
    isLoading: boolean
    isFetching: boolean
    isEventLoading: boolean
    refetch: () => void
}

const DetailsTab = ({ event, isLoading, isFetching, isEventLoading, refetch }: Props) => {
    const isDesktop = useBreakpoints().md
    const [isViewMore, setViewMore] = useState(false)

    const [tooltipList, setTooltipList] = useState<{ id: string, name: EventChecklistName }[]>([])
    const [bannerCheckList, setBannerCheckList] = useState<GetEventCheckListByEventIdItem[]>([])

    useEffect(() => {
        if (event?.checklist && event.checklist.length) {
            setBannerCheckList(event?.checklist)
            const mappedList = event?.checklist
                .filter(item => !item.completed)
                .map(item => item.item);

            setTooltipList(mappedList);
        }
    }, [event?.checklist]);

    if (isLoading || isFetching || isEventLoading) {
        return (
            <>
                <div className='organizer-event-item-details'>
                    <SkeletonEventDetailsState />
                </div>
            </>
        )
    }
    if (!event) {
        return <NoEventsDataFound />
    }

    return (
        <div>
            <header className='organizer-event-item-details__header'>
                <img src={event.image_bg} />
            </header>

            <article className='organizer-event-item-details__body'>
                {isDesktop
                    ?
                    <EventItemHeaderInfo
                        info={
                            {
                                id: event.id,
                                actions: event.status.actions,
                                logo: event.image_logo,
                                title: event.name,
                                status: event.status.name as EventCatalogItemStatuses,
                                date: {
                                    started_at: event.schedule?.started_at || '',
                                    finished_at: event.schedule?.finished_at || '',
                                },
                                location: event.location?.slug || '',
                                participants: (event.participants).toString(),
                                category: event.category?.name || '',
                                organization_url: event.url,
                            }
                        }
                        checkList={bannerCheckList || []}
                        tooltipList={tooltipList || []}
                        refetch={refetch}
                    />
                    :
                    <EventItemHeaderInfoMobile
                        info={
                            {
                                id: event.id,
                                actions: event.status.actions,
                                logo: event.image_logo,
                                title: event.name,
                                status: event.status.name as EventCatalogItemStatuses,
                                date: {
                                    started_at: event.schedule?.started_at || '',
                                    finished_at: event.schedule?.finished_at || '',
                                },
                                location: event.location?.slug || '',
                                participants: (event.participants).toString(),
                                category: event.category?.name || '',
                                organization_url: event.url,
                            }
                        }
                        checkList={bannerCheckList || []}
                        tooltipList={tooltipList || []}
                        refetch={refetch}
                    />
                }
                <div className='divider' />

                {tooltipList.length
                    ?
                    <EventSetupProgress
                        eventId={event.id}
                        bannerCheckList={bannerCheckList}
                    />
                    :
                    null}
                <p className={`organizer-event-item-details__body-description ${isViewMore ? '' : 'isShort'}`}>
                    {event?.description || ''}
                </p>

                {
                    isViewMore
                        ?
                        <button onClick={() => setViewMore(false)} className='more-btn' >
                            Show less
                        </button>
                        :
                        <button onClick={() => setViewMore(true)} className='more-btn'>
                            View more
                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.08882 0.837358C0.861017 1.06516 0.861017 1.43451 1.08882 1.66232L4.58882 5.16232C4.81663 5.39012 5.18598 5.39012 5.41378 5.16232L8.91378 1.66232C9.14159 1.43451 9.14159 1.06516 8.91378 0.837358C8.68598 0.609552 8.31663 0.609552 8.08882 0.837358L5.0013 3.92488L1.91378 0.837358C1.68598 0.609552 1.31663 0.609552 1.08882 0.837358Z" fill="#4E67EA" />
                            </svg>
                        </button>
                }
                <Divider />
                {/* <BannerSupport /> */}
                <article className='organizer-event-item-details__calendly'>
                    <header>
                        <Title level={4}>
                            Meet with your success manager
                        </Title>
                        <p className='organizer-event-item-details__calendly-desc'>
                            Meet with your success manager to complete your event setup
                        </p>
                    </header>
                    <InlineWidget url="https://calendly.com/boost-express/event-onboarding?hide_event_type_details=1&hide_gdpr_banner=1" />
                </article>
            </article>
        </div>
    )
}

export default DetailsTab