import React, { FC, useState } from "react";
import { Button, Form, Input, Modal, notification } from "antd";
import { ErrorDataResponse, errorsToFormAdapter } from "@shared/utils.ts";
import { ErrorsValues } from "@pages/CommonPages/Authentification/Register/models.ts";
import { usePostEventSetupTrackToEmailMutation } from '@store/type-event/events/events.api';
import styles from "@pages/CommonPages/UserProfile/UserProfile.module.scss";

type Props = {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    eventId: string
    snippet: string
}

const SendToEmailModal: FC<Props> = ({ isOpen, onClose, eventId, snippet }) => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({ email: '' })
    const [postToEmail] = usePostEventSetupTrackToEmailMutation()
    const handleClose = () => {
        onClose(false)
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            const response = await postToEmail({ eventId, email, snippet })

            if ('error' in response) {
                setIsLoading(false)
                const errorsRes = errorsToFormAdapter(response as ErrorDataResponse)
                setErrors(errorsRes as unknown as ErrorsValues)
            }

            if ('data' in response) {
                setIsLoading(false)
                notification.open({
                    message: (
                        <span>
                            The code has been sent successfully to
                            {' '}
                            <b>{email}</b>
                        </span>
                    ),
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success',
                });
                setEmail('')
                onClose(false)
            }
        } catch (error) {
            setIsLoading(false)
            notification.open({
                message: (
                    <span>
                        Code has not been sent
                    </span>
                ),
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'error',
            });
        }
    };

    return (
        <Modal
            title="Send the instructions"
            open={isOpen}
            onCancel={handleClose}
            className="send-instructions-modal"
            centered
            zIndex={1001}
            footer={null}
        >
            <Form
                layout="vertical"
            >
                <Form.Item
                    label="E-mail"
                    htmlFor="email"
                    className={styles.UserProfile__formItem}
                    help={<span className="color-danger">{errors?.email ? errors?.email : ''}</span>}
                    validateStatus={errors?.email ? 'error' : ''}
                >
                    <Input
                        id="email"
                        value={email}
                        placeholder=''
                        onChange={(e) => {
                            setEmail(e.target.value)
                            setErrors({ email: '' })
                        }}
                    />
                </Form.Item>
            </Form>
            <div
                className="send-instructions-modal__footer"
            >
                <Button
                    key="cancel"
                    size="large"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    key="submit"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={!email}
                    onClick={handleSubmit}
                >
                    Send
                </Button>
            </div>
        </Modal>
    )
}

export default SendToEmailModal;
