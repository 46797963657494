import { useContext } from 'react';
import './styles.scss';
import ChannelCard from './ChannelCard';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import { Channel } from '@store/main/organization/models';

const RadioChannel = () => {
    const {
        formValues: form,
        handleFormChange: onChange,
    } = useContext(CampaignEditorContext);
    const onRadioCardUpdate = (updatedChannel: Channel) => {
        const updatedChannels = form.channels.map(channel => {
            if (channel.type === updatedChannel.type) {
                return updatedChannel;
            }
            return channel;
        });
        const updatedForm = { ...form, channels: updatedChannels };
        onChange(updatedForm);
    }

    return (
        <div className='radio-channel'>
            {form.channels.map((channel: Channel, idx: number) => {
                if (idx > 0) {
                    return null;
                }
                return (
                    <ChannelCard
                        key={channel.type + idx}
                        channel={channel}
                        onChange={onRadioCardUpdate}
                    />
                );
            })}
        </div>

    );
}

export default RadioChannel;
