import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    EventBilling,
    BillingResponse,
    BillingsResponse,
    UserBillingOrganizerResponse, UserBillingOrganizerTransactionsResponse, UserBillingOrganizerWithdrawalResponse, RevShareResponse
} from './models';
import { getQueryStringFromParams } from "@shared/utils";
import { GetFiltersByEnumResponse } from "@store/main/enums/models.ts";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const billing = `organizer_billing`;
const post_billing = `organizer_post_billing`;
const update_billing_by_id = `organizer_update_billing_by_id`;

export const organizerBillingApi = createApi({
    reducerPath: billing,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        billing,
        post_billing
    ],
    endpoints: build => ({
        getUserOrganizerBilling: build.query<BillingsResponse, { page: number }>({
            query: ({ page }) => {
                return ({
                    url: `/organizer/billing?page=${page}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing, post_billing],
        }),
        postBilling: build.mutation<{ data: EventBilling }, EventBilling>({
            query: (body) => ({
                body,
                method: 'POST',
                url: `/organizer/billing`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [post_billing]
        }),
        patchBillingById: build.mutation<{ data: EventBilling }, { data: EventBilling, id?: string }>({
            query: ({ data, id }) => ({
                url: `/organizer/billing/${id ? id.toString() : ''}`,
                method: 'PATCH',
                body: data,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [post_billing, update_billing_by_id],
            }),
        }),
        getBillingById: build.query<{ data: EventBilling }, { id: string }>({
            query: ({ id }) => ({
                url: `/organizer/billing/${id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [billing, update_billing_by_id],
            }),
        }),
        getUserOrganizerBillingByEventId: build.query<BillingResponse, { eventId: string }>({
            query: ({ eventId }) => {
                return ({
                    url: `/organizer/billing/event/${eventId}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        getUserOrganizerBillingReports: build.query<UserBillingOrganizerResponse, {
            dateFrom?: string;
            dateTo?: string;
            events?: string[];
            partners?: string[];
            group_by_partner?: boolean;
            gap?: string | number;
            page?: number;
            billingId: string;
        }>({
            query: ({ dateFrom, dateTo, partners, events, gap, group_by_partner, page, billingId }) => {
                const params = new URLSearchParams();

                const partnersQueryString = getQueryStringFromParams(partners, 'partners')
                const eventsQueryString = getQueryStringFromParams(events, 'events')

                if (dateFrom) {
                    params.append('filters[date][from]', `${dateFrom}`);
                }

                if (dateTo) {
                    params.append('filters[date][to]', `${dateTo}`);
                }

                if (gap) {
                    params.append('filters[gap]', `${gap}`);
                }

                if (group_by_partner) {
                    params.append('filters[group_by_partner]', `${group_by_partner ? 1 : 0}`);
                }

                // todo add page
                if (page) {
                    // params.append('filters[page]', `${page}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    partnersQueryString,
                    eventsQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');

                return ({
                    url: `/organizer/billing/${billingId}/reports${queryString.length ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        getUserOrganizerBillingCurrent: build.query<UserBillingOrganizerResponse, { billingId: string }>({
            query: ({ billingId }) => {
                return ({
                    url: `/organizer/billing/${billingId}/reports/current`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        getUserOrganizerBillingWithdrawals: build.query<UserBillingOrganizerWithdrawalResponse, {
            dateFrom?: string;
            dateTo?: string;
            status?: string[];
            page?: number;
            billingId: string;
        }>({
            query: ({ dateFrom, dateTo, status, page, billingId }) => {
                const params = new URLSearchParams();

                const statusQueryString = getQueryStringFromParams(status, 'status')

                if (dateFrom) {
                    params.append('filters[date][from]', `${dateFrom}`);
                }

                if (dateTo) {
                    params.append('filters[date][to]', `${dateTo}`);
                }

                if (page) {
                    params.append('filters[page]', `${page}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    statusQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');

                return ({
                    url: `/organizer/billing/${billingId}/withdrawals${queryString.length ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        postWithdrawal: build.mutation<void, { amount: number, billingId: string }>({
            query: ({ amount, billingId }) => ({
                url: `/organizer/billing/${billingId}/withdrawals`,
                method: 'POST',
                body: {
                    amount
                },
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [billing],
        }),
        getUserOrganizerBillingTransactions: build.query<UserBillingOrganizerTransactionsResponse, {
            dateFrom?: string;
            dateTo?: string;
            types?: string[];
            events?: string[];
            page?: number;
            billingId: string;
        }>({
            query: ({ dateFrom, dateTo, types, events, page, billingId }) => {
                const params = new URLSearchParams();

                const typesQueryString = getQueryStringFromParams(types, 'types')
                const eventsQueryString = getQueryStringFromParams(events, 'events')

                if (dateFrom) {
                    params.append('filters[date][from]', `${dateFrom}`);
                }

                if (dateTo) {
                    params.append('filters[date][to]', `${dateTo}`);
                }

                if (page) {
                    params.append('filters[page]', `${page}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    typesQueryString,
                    eventsQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');

                return ({
                    url: `/organizer/billing/${billingId}/transactions${queryString.length ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        getCurrenciesByISO: build.query<GetFiltersByEnumResponse, {
            iso: string
        }>({
            query: ({ iso }) => {
                return ({
                    url: `/organizer/billing/currencies/${iso}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        getRevShareCommissions: build.query<RevShareResponse, void>({
            query: () => {
                return ({
                    url: `/organizer/billing/reports/commissions`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        postChangeBilling: build.mutation<BillingResponse, { eventId: string; billingId: number }>({
            query: ({ eventId, billingId }) => {
                return ({
                    method: 'POST',
                    url: `/organizer/events/${eventId}/setup/billing/${billingId}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            invalidatesTags: [billing],
        }),
    }),
});

export const {
    useGetUserOrganizerBillingQuery,
    useGetUserOrganizerBillingByEventIdQuery,
    useGetUserOrganizerBillingReportsQuery,
    useGetUserOrganizerBillingCurrentQuery,
    useGetUserOrganizerBillingWithdrawalsQuery,
    usePostWithdrawalMutation,
    useGetUserOrganizerBillingTransactionsQuery,
    useGetCurrenciesByISOQuery,
    usePostChangeBillingMutation,
    usePostBillingMutation,
    usePatchBillingByIdMutation,
    useGetBillingByIdQuery,
    useGetRevShareCommissionsQuery,
} = organizerBillingApi;
