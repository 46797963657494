import React from 'react';
import { Form, Input } from "antd";
import { BillingErrorsValues } from './models';
import SuggestSelectCities from '@components/SuggestSelectCities';
import SuggestSelectCountries from '@components/SuggestSelectCountries';
import { PatchBillingInfoUser } from '@store/main/billing/models';

interface BillingInfoProps {
    form: PatchBillingInfoUser;
    validationErrors: BillingErrorsValues
    onFormChange: (field: Partial<PatchBillingInfoUser>) => void;
}

interface CityFieled {
    city: {
        country: {
            id: number;
            slug: string
        }

        id: number;
        slug: string
    }
}

const BillingForm: React.FC<BillingInfoProps> = ({
    form,
    onFormChange,
    validationErrors
}) => {

    const formValuesHadler = (field: Partial<PatchBillingInfoUser>) => {
        if ('city' in field) {
            const values = field as CityFieled
            onFormChange({ country: { ...values?.city?.country || {} } })
            onFormChange({ city: values.city })
            return
        }
        onFormChange(field)
    }


    return (
        <Form
            name="billingInfo"
            initialValues={form}
            autoComplete="off"
            layout="vertical"
            onValuesChange={(field: Partial<PatchBillingInfoUser>) => formValuesHadler(field)}
        >
            <Form.Item
                help={validationErrors?.company_name && validationErrors?.company_name}
                validateStatus={validationErrors?.company_name ? 'error' : ''}
                name='company_name'
                label="Company name">
                <Input placeholder='Your company name' />
            </Form.Item>
            <Form.Item
                help={validationErrors?.country || validationErrors?.['country.id']}
                validateStatus={validationErrors?.country || validationErrors?.['country.id']
                    ? 'error' : ''}
                // name='country'
                label="Country"
            >
                <SuggestSelectCountries
                    value={form.country}
                    placeholder={form?.country?.slug || 'Сhoose country'}
                    onChange={(country) => {
                        onFormChange({ country })
                        onFormChange({ city: null })
                    }}
                />
            </Form.Item>
            <Form.Item
                help={validationErrors?.['city.id'] && validationErrors?.['city.id']}
                validateStatus={validationErrors?.['city.id'] ? 'error' : ''}
                name="city"
                label="City">
                <SuggestSelectCities
                    value={form?.city || null}
                    countryValue={form.country}
                    placeholder={form?.city?.slug || 'Сhoose city'}
                    onChange={(city) => onFormChange({ city })}
                />
            </Form.Item>

            <Form.Item
                help={validationErrors?.postal_code && validationErrors?.postal_code}
                validateStatus={validationErrors?.postal_code ? 'error' : ''}
                name="postal_code"
                label="Postal code">
                <Input placeholder='Your code' />
            </Form.Item>

            <Form.Item
                help={validationErrors?.email && validationErrors?.email}
                validateStatus={validationErrors?.email ? 'error' : ''}
                name='email'
                label="Email" rules={[{ type: 'email' }]}>
                <Input placeholder='Your email' />
            </Form.Item>

            <Form.Item
                help={validationErrors?.address && validationErrors?.address}
                validateStatus={validationErrors?.address ? 'error' : ''}
                name="address"
                label="Organisation address">
                <Input placeholder='Your address' />
            </Form.Item>

            <Form.Item
                help={validationErrors?.tax_code && validationErrors?.tax_code}
                validateStatus={validationErrors?.tax_code ? 'error' : ''}
                name="tax_code"
                label="VAT number">
                <Input placeholder='VAT' type='numeric' />
            </Form.Item>
        </Form>
    );
};

export default BillingForm;
