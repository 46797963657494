import { CampaignStatuses, EventCatalogItemStatuses, statusesFormatted } from '@shared/constants'
import './styles.scss'

// dp?
// const statuses = {
//     active: 'Active',
//     available: 'Available',
//     archive: 'Archived',
//     archived: 'Archived',

//     cancelled: 'Cancelled',
//     enabled: 'Enabled',
//     in_progress: 'In progress',
//     invitation_pending: 'Invitation pending',
//     needs_attention: 'Needs attention',
//     paused: 'Paused',
//     pending: 'Pending',
//     remove: 'Remove',
//     removed: 'Removed',
//     reject: 'Rejected',
//     rejected: 'Rejected',
//     review: 'On Review',
//     scheduled: 'Scheduled',
// }

const StatusTag = ({ status }: { status: CampaignStatuses | EventCatalogItemStatuses }) => {
    return (
        <span className={`flex items-center  status-tag status-tag__${status}`}>
            {statusesFormatted[status]}
        </span>
    )
}

export default StatusTag
