import React from "react";
import LeadsZeroState from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager/components/LeadsZeroState";
import { LeadsTableItem } from "@store/models-to replace/leads";
import LeadsTable from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager/components/LeadsTable";
import LoadingView from "@components/common/LoadingView";
import LeadsErrorView from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager/components/LeadsErrorView";
import { PaginationConfig } from "@pages/PartnerRolePages/Leads/components/LeadsBlock";

type LeadsTableViewManagerPropsType = {
    data: LeadsTableItem[] | undefined;
    error: object | null;
    loading: boolean;
    paginationConfig: PaginationConfig;
}

const LeadsTableViewManager: React.FC<LeadsTableViewManagerPropsType> = (props) => {
    const { data, error, loading, paginationConfig } = props;

    if (loading) {
        return <LoadingView />
    }

    if (error) {
        return <LeadsErrorView />
    }

    if (data) {
        return <LeadsTable data={data} paginationConfig={paginationConfig} />
    }

    if (!data && !error && !loading) {
        return <LeadsZeroState />
    }

    return null;
}

export default LeadsTableViewManager;
