import IslandLayout from '@layouts/IslandLayout';
import { DatePicker, Form, Input, Select, message, Checkbox } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import './styles.scss'
import RadioGoal from '../RadioGoal';
import AssertForm from './AssetForm';
import RadioChannel from '../RadioChannel';
import dayjs from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import TagInput from '../TagInput';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomButton from '@components/CustomButton';
import BoostProBanner from '@components/BoostProBanner/BoostProBanner';
import { useAuth } from '@contexts/AuthContext';
import EnableBoostProDrawer from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawer';
import SuccessModal from '@components/Subscriptions/BoostPro/SuccessModal';
import { StripeContext } from '@contexts/StripeContext';
import { useGetPartnerLandingsByEventIdQuery } from '@store/type-partner/landings/landings.api';
import { usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import { usePostSubscriptionMutation } from '@store/main/subscription/subscription.api';
import { Campaign } from '@store/main/organization/models';
import { useDispatch } from 'react-redux';
import { partnerCampaignsActions } from '@store/type-partner/campaigns/campaigns.slice';
import { LANDINGS_PLACEHOLDER } from '@pages/PartnerRolePages/Campaigns/constants';
import LandingFAQ from '@pages/PartnerRolePages/Campaigns/components/LandingFAQ/LandingFAQ';
import { useGetParnerAudiencesQuery } from '@store/type-partner/events/partner-event.api';
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import EnableBoostProDrawerMobile from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawerMobile';
import { useBreakpoints } from '@hooks/browser';
import { TourComponent, TourTitle } from "@components/TourComponent/TourComponent.tsx";
import { TourContext } from "@contexts/TourContext.tsx";
import { CampaignsTourSteps } from "@components/TourComponent/constants.ts";

const CampaignForm = ({ eventId }: { eventId: string }) => {
    const { user, reFetchUser } = useAuth()
    const isDesktop = useBreakpoints().md
    const [onSubscribe] = usePostSubscriptionMutation()
    const { data: audiencesRes, isLoading: isAudiencesLoading, refetch: getAudiences } = useGetParnerAudiencesQuery({ eventId }, { skip: !eventId })
    const { data: fetchedLandings, isLoading: landingsLoading } = useGetPartnerLandingsByEventIdQuery({ id: +eventId, page: INIT_PAGE }, { skip: !eventId })
    const [postDefault] = usePostDefaultBillingMethodIdMutation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { campaignId } = useParams()
    const isPro = user && user?.organization?.subscriptions && user.organization.subscriptions.some(item => item.status?.id === 1 && item.status.name === 'active') || false

    const [isEnableProModalOpen, setIsEnableProModalOpen] = useState(false)
    const [landingOptions, setLandingOptions] = useState<{ value: string, label: string }[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [successModalDate, setSuccessModalDate] = useState<string | boolean>('')
    const [isTimerPassed, setIsTimerPassed] = useState(false)

    const campaignGoalRef = useRef(null);
    const audienceRef = useRef(null);
    const scheduleRef = useRef(null);
    const adPlatformRef = useRef(null);
    const adCreativesRef = useRef(null);
    const landingPageRef = useRef(null);

    const {
        formValues,
        formErrors,
        handleFormChange,
    } = useContext(CampaignEditorContext);
    const { selectedCardId } = useContext(StripeContext);

    const {
        isCampaignTourOpen,
        campaignTourStep,
        setCampaignTourStep,
        setIsCampaignTourOpen
    } = useContext(TourContext);

    useEffect(() => {
        if (isCampaignTourOpen) {
           setTimeout(() => {
               setIsTimerPassed(true)
           }, 1000)
        }
    }, [isCampaignTourOpen]);

    const audiencesList = audiencesRes?.data || []

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        eventId && void getAudiences()
    }, [])

    //TOFIX: ts error
    const landingPlaceholder = formValues.ads[0]?.landing
        ? landingOptions.some(item => +item.value === formValues.ads[0]?.landing)
            ? landingOptions.find(item => +item.value === formValues.ads[0]?.landing)?.label || LANDINGS_PLACEHOLDER
            : LANDINGS_PLACEHOLDER
        : LANDINGS_PLACEHOLDER;

    useEffect(() => {
        if (fetchedLandings?.data) {
            const options = fetchedLandings?.data.map(landing => {
                return {
                    value: (landing.id).toString(),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    label: landing?.title || ''
                }
            })
            const urlValue = `${pathname}/landing`
            const newOptions = options?.length ? [...options, { value: urlValue, label: '+ Add landing', type: 'link' }] : options
            setLandingOptions(newOptions)
        }
    }, [fetchedLandings, eventId, pathname])

    const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current < dayjs(formValues.schedule.started_at);
    };

    const onLandingUpdate = (id: string, option?: { value: string, label: string, type: string }) => {
        if (option?.type === 'link') {
            dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId, campaignId }))
            navigate(option.value)
            return
        }
        const next = {
            ...formValues,
            ads: [
                {
                    ...formValues.ads[0],
                    landing: +id
                }
            ]
        };
        handleFormChange(next);
    }


    const onCloseEnableModal = () => {
        setIsEnableProModalOpen(false)
    }

    const onEnablePro = async () => {
        setIsLoading(true)
        await postDefault({ id: selectedCardId })
        try {
            const response = await onSubscribe()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.enableProSubscription)
                setSuccessModalDate(response?.data?.data?.date || true)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }

    const onAddLandings = () => {
        dispatch(partnerCampaignsActions.setCampaignsEditorData({ campaignEditor: formValues, selectedEvent: eventId, campaignId }))
        const urlValue = `${pathname}/landing`
        navigate(urlValue)
    }

    const onAddSubscription = (id: string | number) => {
        switch (id) {
            case 1:
                return setIsEnableProModalOpen(true)

            default:
                break;
        }
    }

    return (
        <>
            <Form
                onValuesChange={(_, fields: Campaign) => {
                    handleFormChange(fields)
                }}
                initialValues={formValues}
            >
                <div className='max-w-l'>
                    <p className={`text-regular-14 label text-neutral-8 ${formErrors['ads.0.assets.title.0.value'] ? 'error' : ''}`}>
                        Campaign name
                    </p>
                    <Input
                        count={{
                            show: true,
                            max: 50,
                        }}
                        onChange={(event) => handleFormChange({ ...formValues, title: event?.target?.value || '' })}
                        value={formValues.title || ''}
                        placeholder='Default campaign name'
                        status={formErrors['ads.0.assets.title.0.value'] ? 'error' : ''}
                    />
                    {!isPro && (
                        <BoostProBanner
                            onEnablePro={() => setIsEnableProModalOpen(true)}
                        />
                    )}

                    <div className='campaign-create__settings'>
                        <p className='text-title-30 campaign-create__title'>
                            Campaign Settings
                        </p>

                        <IslandLayout refProp={campaignGoalRef}>
                            <p className='island-title'>
                                Campaign Goals
                            </p>
                            <article className='create-campaign-checkboxex'>
                                <RadioGoal
                                    goalValue={formValues.goal!}
                                    onClick={(goal) => handleFormChange({ ...formValues, goal })}
                                    onAddSubscription={onAddSubscription}
                                />
                            </article>
                        </IslandLayout>

                        <IslandLayout refProp={audienceRef}>
                            {!isAudiencesLoading && !audiencesList.length
                                ?

                                <div className='campaign-create__audience'>
                                    <div>
                                        <p className='island-title'>
                                            Audience
                                        </p>
                                        <article className='flex items-center'>
                                            <p className='text-regular-14 label text-subdued'>
                                                This event does not have any audience yet                                    </p>
                                        </article>
                                    </div>
                                </div>
                                :
                                <div className='campaign-create__audience'>
                                    <div>
                                        <p className='island-title'>
                                            Audience
                                        </p>
                                        <article className='flex items-center'>
                                            <p className='text-regular-14 label text-subdued'>
                                                Select from an existing audience to determine the types of people that can see your ads.
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            }
                            {audiencesList.length
                                ?
                                <div className='campaign-create__audience-container'>
                                    <p className='text-regular-14 label'>
                                        Segment Attributes
                                    </p>
                                    <TagInput
                                        options={audiencesList as { id: number, name: string }[]}
                                        onChange={(ids) => handleFormChange({ ...formValues, audiences: ids as unknown as number[] })}
                                        formIdsValues={formValues?.audiences || []}
                                    />
                                    <div className='divider' />
                                    <Checkbox
                                        className="mb-12 campaign-create__audience__checkbox"
                                        checked={formValues?.audience_extend}
                                        onChange={(e) => handleFormChange({ ...formValues, audience_extend: e.target.checked })}
                                        id="audience_extend"
                                    >
                                        Enable Audience Expansion
                                    </Checkbox>
                                    <p className='text-small text-subdued'>
                                        Reach people who are similar to your target audience, based on attributes like job titles, companies, or skills. Learn more
                                    </p>

                                </div>
                                :
                                null}
                        </IslandLayout>

                        <IslandLayout refProp={scheduleRef}>
                            <p className='island-title'>
                                Schedule
                            </p>
                            <p className='text-regular-14 text-neutral-8 campaign-create__disclaimer'>
                                Your ads will only run during the specified period.
                            </p>
                            <p className='text-regular-14 text-neutral-8'>
                                Campaign schedule
                            </p>
                            <div className='campaign-create__budget-form'>
                                <div
                                    className='campaign-create__budget-form-date'
                                >
                                    <DatePicker
                                        value={formValues.schedule.started_at ? dayjs(formValues.schedule.started_at) : ''}
                                        className='campaign-create__budget-form-date'
                                        onChange={(_, i) => handleFormChange({ ...formValues, schedule: { ...formValues.schedule, started_at: i as string } })}
                                        placeholder='Start Date'
                                        allowClear
                                        disabledDate={disabledStartDate}
                                        status={formErrors['schedule.started_at'] ? 'error' : ''}
                                    />
                                    {formErrors['schedule.started_at'] && (
                                        <p className={`text-regular-14 label ${formErrors['schedule.started_at'] ? 'error' : ''}`}>
                                            Invalid date.
                                        </p>
                                    )}
                                </div>
                                <div
                                    className='campaign-create__budget-form-date'
                                >
                                    <DatePicker
                                        value={formValues.schedule.finished_at ? dayjs(formValues.schedule.finished_at) : ''}
                                        className='campaign-create__budget-form-date'
                                        onChange={(_, i) => handleFormChange({ ...formValues, schedule: { ...formValues.schedule, finished_at: i as string } })}
                                        placeholder='Finish Date'
                                        allowClear
                                        disabledDate={disabledEndDate}
                                        status={formErrors['schedule.finished_at'] ? 'error' : ''}
                                    />
                                    {formErrors['schedule.finished_at'] && (
                                        <p className={`text-regular-14 label ${formErrors['schedule.finished_at'] ? 'error' : ''}`}>
                                            Invalid date.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </IslandLayout>
                        <IslandLayout refProp={adPlatformRef}>
                            <p className='island-title'>
                                Ad Platform
                            </p>
                            <p className='text-regular-14 label text-neutral-8'>
                                Select Ad Platform that suits your needs.
                            </p>
                            <RadioChannel />
                        </IslandLayout>

                    </div>

                    <p className='text-title-30'>
                        Creative Assets
                    </p>
                    <div>
                        <AssertForm adCreativesRef={adCreativesRef} />
                    </div>

                    <p className='text-title-30'>
                        Landing page
                    </p>

                    <IslandLayout refProp={landingPageRef}>
                        <p className='campaign-create__landing-title text-regular-14 label'>
                            Landing page
                        </p>
                        <article className='campaign-create__landing-select'>
                            <Select
                                loading={landingsLoading}
                                style={{ width: '100%' }}
                                placeholder={<span className='text-regular-14'>{landingPlaceholder || LANDINGS_PLACEHOLDER}</span>}
                                options={landingOptions}
                                onChange={(id: string, option) => onLandingUpdate(id, option)}
                                error={formErrors['ads.0.landing']}
                                popupClassName='campaign-create__landing-select__popup'
                                notFoundContent={(
                                    <div className='campaign-create__landing-select__empty-content'>
                                        <b>You haven't added any landing page</b>
                                        <span>Click on the button to create your first landing page</span>
                                        <CustomButton
                                            type='primary'
                                            title='+ Add landing'
                                            onClick={onAddLandings}
                                        />
                                    </div>
                                )}
                            />
                            {formErrors['ads.0.landing'] && (
                                <p className='text-regular-14 label error'>
                                    Select an existing landing page or add a new one
                                </p>
                            )}
                            <CustomButton
                                onClick={onAddLandings}
                                title='+ Add landing'
                                classOptional='mt-16'
                            />
                            <LandingFAQ />
                        </article>
                    </IslandLayout>
                </div>
            </Form>
            {isEnableProModalOpen && isDesktop
                ?
                <EnableBoostProDrawer
                    isOpen={isEnableProModalOpen}
                    onClose={onCloseEnableModal}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onConfirm={onEnablePro}
                    isLoading={isLoading}
                />
                :
                null}
            {isEnableProModalOpen && !isDesktop
                ?
                <EnableBoostProDrawerMobile
                    isOpen={isEnableProModalOpen}
                    onClose={onCloseEnableModal}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onConfirm={onEnablePro}
                    isLoading={isLoading}
                />
                :
                null}
            <SuccessModal
                isOpen={Boolean(successModalDate)}
                onClose={() => {
                    setSuccessModalDate('')
                    void reFetchUser()
                }}
                date={successModalDate}
            />
            <TourComponent
                totalCount={6}
                open={isTimerPassed && isCampaignTourOpen && campaignTourStep !== CampaignsTourSteps.adCreatives}
                onClose={() => {
                    setIsCampaignTourOpen(false)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                current={campaignTourStep}
                onChange={(step) => {
                    setCampaignTourStep(step)
                }}
                disabledInteraction
                prefixCls="campaign-tour-component"
                gap={{
                    offset: [10, 8]
                }}
                steps={[
                    {
                        placement: 'top',
                        title: <TourTitle />,
                        description: (
                            <div>
                                Pick a goal to guide your campaign's strategy.
                            </div>
                        ),
                        target: () => campaignGoalRef.current,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Define who should see your ads.
                            </div>
                        ),
                        target: () => audienceRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Choose when your ads will be live.
                            </div>
                        ),
                        target: () => scheduleRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Select the platform aligned with your goals.
                            </div>
                        ),
                        target: () => adPlatformRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Enter details to generate your ad creatives.
                            </div>
                        ),
                        target: () => adCreativesRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Select the page where your campaign traffic will be directed.
                            </div>
                        ),
                        target: () => landingPageRef.current,
                        placement: 'top',
                        nextButtonProps: {
                            children: 'Got it'
                        }
                    },
                ]}
            />
            <TourComponent
                totalCount={6}
                open={isTimerPassed && isCampaignTourOpen && campaignTourStep === CampaignsTourSteps.adCreatives}
                onClose={() => {
                    setIsCampaignTourOpen(false)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                current={campaignTourStep}
                onChange={(step) => {
                    setCampaignTourStep(step)
                }}
                disabledInteraction
                prefixCls="ad-creatives-tour-component"
                gap={{
                    offset: [34, 32]
                }}
                steps={[
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Enter details to generate your ad creatives.
                            </div>
                        ),
                        target: () => adCreativesRef.current,
                        placement: 'top',
                    },
                    {
                        title: <TourTitle />,
                    },
                ]}
            />
        </>
    )
}

export default CampaignForm
