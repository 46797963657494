import { useEffect } from 'react';
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";

const usePageAnalytics = (pageName: string) => {
    useEffect(() => {
        if (window?.dataLayer) {
            const prevEvent = window.dataLayer[window.dataLayer.length - 1]
            if (prevEvent.event_name !== `${pageName}_${GTMEventName.pageVisit}`) {
                sendAnalytics(`${pageName}_${GTMEventName.pageVisit}`)
            }
        }
    }, [pageName]);
};

export default usePageAnalytics
