import { useApiReq } from "@hooks/useApiRequest.ts";
import { UserLoginResponse } from "@store/models-to replace/auth";

export type UpdateCompanyDataBodyType = {
    name: string
    description: string
}

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

export const useUpdateCompanyData = () => {
    return useApiReq<UserLoginResponse, UpdateCompanyDataBodyType>(
        {
            reqConfig: {
                method: 'post',
                url: `${apiBaseUrl}/organizer/onboarding`
            }
        }
    )
}