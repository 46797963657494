import React from "react";
import {Typography} from "antd";
import styles from "./AvatarRulesTip.module.css";

const AvatarRulesTip: React.FC = () => {

    return (
        <>
            <div className={styles.AvatarRulesTip}>
                <Typography.Title className={styles.AvatarRulesTip__title} level={4}>
                    Upload event logo
                </Typography.Title>
                <Typography.Text className={styles.AvatarRulesTip__text}>
                    Supports: .jpg, .png. Max size: 10MB. Ratio: 1:1
                </Typography.Text>
            </div>
        </>
    )
}

export default AvatarRulesTip;
