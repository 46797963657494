import SkeletonCustom from "@components/SkeletonCustom";
import { ModerationDataIndexes } from "@pages/EventRolePages/ModerationPage/types";
import React from "react";
import { Checkbox } from "antd";
import { Filters } from '@store/type-event/models';

export const initialFilters: Filters = {
    search: '',
    partners: [],
    statuses: [],
    events: []
}

export const tableZeroColumnsData = [
    {
        key: ModerationDataIndexes.status,
        width: 144
    },
    {
        key: ModerationDataIndexes.landing_page,
        width: 336
    },
    {
        key: ModerationDataIndexes.landing_page_title,
        width: 336
    },
    {
        key: ModerationDataIndexes.partner_name,
        width: 336
    },
    {
        key: ModerationDataIndexes.event_name,
        width: 336
    }
]

export const tableZeroColumns = tableZeroColumnsData.map(({ key, width }) => {
    return ({
        key,
        width,
        title: <SkeletonCustom type='button' />,
        dataIndex: key,
        render: () => {
            return (
                <div>
                    <SkeletonCustom type='button-l' />
                </div>
            )
        }
    })
})

export const statusMenuItemSelectedIcon = ({ isSelected }: { isSelected: boolean }) => (
    <>
        <Checkbox checked={Boolean(isSelected)} />
        <span className="status-plug" />
    </>
)
