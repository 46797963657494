
const MegaphoneIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1054_29668)">
                <path d="M9.4839 9.32718V3.56803M9.4839 9.32718L14.0103 11.8375C14.0779 11.875 14.1546 11.8947 14.2325 11.8945C14.3104 11.8943 14.3869 11.8743 14.4544 11.8363C14.5218 11.7984 14.5778 11.744 14.6168 11.6785C14.6558 11.613 14.6763 11.5387 14.6764 11.4631V1.43211C14.6764 1.35641 14.656 1.28204 14.6171 1.21644C14.5782 1.15084 14.5222 1.09631 14.4547 1.05833C14.3872 1.02034 14.3107 1.00023 14.2327 1C14.1547 0.999776 14.078 1.01945 14.0103 1.05704L9.4839 3.56803M9.4839 9.32718H4.29137M9.4839 3.56803H4.29137C3.50444 3.56803 2.74973 3.87141 2.19328 4.41144C1.63683 4.95147 1.32422 5.6839 1.32422 6.44761C1.32422 7.21132 1.63683 7.94375 2.19328 8.48378C2.74973 9.0238 3.50444 9.32718 4.29137 9.32718M4.29137 9.32718L4.85291 13.1426C4.90321 13.4613 5.08619 14.602 5.13693 14.9182C5.14457 14.9658 5.18552 15 5.23374 15H6.55352H7.94905C8.00746 15 8.05289 14.9494 8.04632 14.8913C8.01195 14.5879 7.90679 13.6914 7.76072 12.7395L7.25853 9.32718H4.29137Z" stroke={fillColor || "#BFBFBF"} strokeWidth="1.25668" />
            </g>
            <defs>
                <clipPath id="clip0_1054_29668">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default MegaphoneIcon