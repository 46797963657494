import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import ActivityTab from './ActivityTab';

const onChange = (key: string) => {
    console.log(key);
};

const BillingInfoTabs = () => {

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Billing activity',
            children: <ActivityTab />,
        }
    ];
    return (
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />)
}

export default BillingInfoTabs