//https://boost.allnetru.dev/api/documentation#/Payment
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetOpenStripeResponse, GetUserBillingPaymentsResponse, PostBillingMethodIdResponse, PostDefaultBillingMethodIdResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const payment = `payment`;

export const paymentApi = createApi({
    reducerPath: payment,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [payment],
    endpoints: build => ({

        getUserBillingPayments: build.query<GetUserBillingPaymentsResponse, void>({
            query: () => ({
                url: '/billing/payment',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [payment],
        }),

        // STRIPE
        getOpenStripe: build.query<GetOpenStripeResponse, void>({
            query: () => ({
                url: '/billing/payment/setupIntent',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [payment],
        }),
        postBillingMethodId: build.mutation<PostBillingMethodIdResponse, { id: string }>({
            query: ({ id }) => ({

                method: 'POST',
                url: `/billing/payment/${id}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [payment],
        }),
        deleteBillingMethodId: build.mutation<void, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/billing/payment/${id}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [payment],
        }),
        postDefaultBillingMethodId: build.mutation<PostDefaultBillingMethodIdResponse, { id: string }>({
            query: ({ id }) => ({
                method: 'POST',
                url: `/billing/payment/${id}/default`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [payment],
        }),
    }),
});

export const {
    useGetUserBillingPaymentsQuery,
    useLazyGetOpenStripeQuery,
    usePostBillingMethodIdMutation,
    useDeleteBillingMethodIdMutation,
    usePostDefaultBillingMethodIdMutation
} = paymentApi;