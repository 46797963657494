import { Select, SelectProps as AntdSelectProps } from "antd";
import React, { UIEvent, useCallback, useEffect, useState } from "react";
import { debounce, getSearchValue } from "@shared/utils";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import { Meta } from "@shared/types";
import './styles.scss'

interface Option {
    label: string;
    value: string;
}

interface DataWrapper<T> {
    meta: Meta;
    data: T[];
}

type Props<T> = Omit<AntdSelectProps, 'value'> & {
    id: string;
    setSearchValue?: (value?: string) => void;
    page: number;
    setPage: (page: number | ((prevPage: number) => number)) => void;
    isLoading: boolean;
    data?: DataWrapper<T>
    value: { value: number; label: string }[] | string[] | string | number | null;
    optionsMapper?: (value: T) => Option
    classNameProp?: string
};

const InfiniteSelect = <T, >({
    id,
    showSearch,
    setSearchValue,
    page,
    setPage,
    data,
    isLoading,
    optionsMapper,
    classNameProp,
    ...restProps
}: Props<T>) => {
    const [options, setOptions] = useState<Option[]>([]);
    const debouncedEventsUpdate = useCallback(
        debounce((search: string) => {
            const searchValue = getSearchValue(search);
            if (setSearchValue) {
                setSearchValue(searchValue || "");
            }
            setPage(INIT_PAGE);
        }, 200),
        [setSearchValue, setPage]
    );

    const handleScroll = debounce((event: UIEvent<HTMLDivElement>) => {
        const target = event.target as HTMLDivElement;
        const scrolled = target.scrollTop + target.clientHeight >= target.scrollHeight;
        if (page < (data?.meta?.last_page || 0) && !isLoading && scrolled) {
            setPage((prevPage: number) => prevPage + 1);
        }
    }, 200);

    useEffect(() => {
        setOptions((prev: Option[]) => {
            let mappedOptions
            if (optionsMapper) {
                mappedOptions = data?.data?.map(optionsMapper) || []
            } else {
                mappedOptions = data?.data || []
            }
            const from = data?.meta?.from;
            if (typeof from === 'number' && prev.length < from) {
                return ([...prev, ...mappedOptions]) as Option[]
            }
            return mappedOptions as Option[];
        });
    }, [data]);

    const handleClear = useCallback(() => {
        const inputElement = document.querySelector<HTMLInputElement>(`#infinite-select-${id}`);
        if (inputElement) {
            const parentElement = inputElement.closest('.ant-select-selector');
            if (parentElement) {
                parentElement.classList.remove('filled');
            }
        }
    }, [id])

    const onDropdownVisibleChange = useCallback((isOpen: boolean) => {
        if (!isOpen && setSearchValue) {
            setSearchValue(undefined);
        }
        if (!isOpen) {
            handleClear()
        }
    }, [handleClear, setSearchValue]);

    useEffect(() => {
        const inputElement = document.querySelector<HTMLInputElement>(`#infinite-select-${id}`);

        const handleInputEvent = (event: Event) => {
            const target = event.target as HTMLInputElement;
            const parentElement = target.closest('.ant-select-selector');
            if (parentElement) {
                if (target.value) {
                    parentElement.classList.add('filled');
                } else {
                    parentElement.classList.remove('filled');
                }
            }
        };

        inputElement?.addEventListener('input', handleInputEvent);
        inputElement?.addEventListener('blur', handleClear);


        return () => {
            inputElement?.removeEventListener('input', handleInputEvent);
            inputElement?.removeEventListener('blur', handleClear);
        };
    }, [handleClear, id]);

    return (
        <Select
            id={`infinite-select-${id}`}
            listHeight={160}
            showSearch={showSearch}
            onSearch={showSearch ? debouncedEventsUpdate : undefined}
            onDropdownVisibleChange={onDropdownVisibleChange}
            options={options}
            onPopupScroll={handleScroll}
            loading={isLoading}
            className={`${classNameProp || ''} infinite-select`}
            onClear={handleClear}
            onBlur={handleClear}
            onFocus={handleClear}
            {...restProps}
        />
    );
};

export default InfiniteSelect;
