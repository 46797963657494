import ExitCrossIcon from '@assets/ExitCrossIcon';
import ZeroTableIcon from '@assets/zerostate/ZeroTableIcon';
import CustomButton from '@components/CustomButton';
import ContentLayout from '@layouts/ContentLayout';
import EventsPageLayout from '@layouts/EventsPageLayout';
import { DatePicker, Input, Select } from 'antd'

import '../../styles.scss'
import { Link } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';

const { RangePicker } = DatePicker;

const NoEventsDataFound = () => {
    const { user } = useAuth()

    return (
        <EventsPageLayout>
            <ContentLayout>
                {/* <div className='my-event-page__filter-container flex'>
                    <div className='my-event-page__filter-container-text'>
                        <Input
                            value='Search by keywords'
                            disabled
                        />
                    </div>
                    <div className='my-event-page__filter-container-range'>
                        <RangePicker
                            disabled
                        />
                    </div>

                    <div className='my-event-page__filter-container-status '>
                        <Select
                            style={{ width: '100%' }}
                            disabled
                            value={'Status: All'}
                        />
                    </div>

                    <div className='my-event-page__filter-container-filters-close-btn events-disabled opacity-5'>
                        <CustomButton
                            type='text'
                            size='large'
                            // block
                            title='Reset'
                            prefix={<ExitCrossIcon />}
                        // disabled
                        />
                    </div>
                </div> */}

                <div
                    className='my-events-cards-page__no-data'
                >
                    <ZeroTableIcon />
                    {user?.name && <p className='my-events-cards-page__no-data-greet'>Hi, {user.name}!</p>}
                    <p className='my-events-cards-page__no-data-desc'>
                        There are no events on your desk yet. Please explore the event catalog to find something interesting
                    </p>

                    <Link to='catalog' className='no-style'>
                        <CustomButton
                            type='primary'
                            size='large'
                            // block
                            title='Explore events'
                        />
                    </Link>

                </div>
            </ContentLayout>
        </EventsPageLayout>)
}

export default NoEventsDataFound