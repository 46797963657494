import './styles.scss'
interface SkeletonCustomProps {
    shape?: 'round' | 'square' | 'default';
    type:
    'paragraph' |
    'paragraph-m' |
    'paragraph-lg' |
    'paragraph-max' |
    'avatar' |
    'avatar-sm' |
    'button' |
    'button-m' |
    'button-l' |
    'checkbox' |
    'radio' |
    'switch' |
    'image' |
    'image-rounded' |
    'title' |
    'title-m'

    width?: string;
    height?: string;
}
const SkeletonCustom = ({
    type,
    width,
    height
}: SkeletonCustomProps) => {

    return (
        <div
            className={`custom-skeleton-container custom-skeleton__type-${type}`}
            style={{
                width,
                height
            }}
        >
            <div className="skeleton-pulse"></div>
        </div>
    )
}

export default SkeletonCustom