import React, { FC } from 'react';
import { Button, Modal, } from 'antd';
import { useBreakpoints } from "@hooks/browser";

type Props = {
    isOpen: boolean
    userName?: string
    onClose: () => void
    onDelete: () => void
}

const ConfirmModal: FC<Props> = ({ isOpen, onClose, onDelete, userName }) => {
    const isDesktop = useBreakpoints().md;
    return (
        <Modal
            title='User deletion'
            open={isOpen}
            onCancel={onClose}
            centered
            width={isDesktop ? 440 : 'calc(100vw - 40px)'}
            maskClosable={false}
            footer={(
                <div
                    className="flex justify-end"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                        className='mr-6'
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        onClick={onDelete}
                        danger
                    >
                        Delete User
                    </Button>
                </div>
            )}
        >
            Are you sure you want to delete the user
            {' '}
            {userName || ''}
            ?
        </Modal>
    )
}

export default ConfirmModal;
