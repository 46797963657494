export interface Filters {
    dateFrom?: string;
    dateTo?: string;
    events: string[];
    partners?: string[];
    group_by_partner?: boolean;
    gap?: string | number;
    page?: number;
}

export enum FiltersKeys {
    events = 'events',
    partners = 'partners',
    group_by_partner = 'group_by_partner',
    gap = 'gap',
    types = 'types',
    status = 'status'
}

export interface TransactionsFiltersType {
    dateFrom?: string;
    dateTo?: string;
    events: string[];
    types?: string[];
    page?: number;
}

export interface WithdrawalFiltersType {
    dateFrom?: string;
    dateTo?: string;
    status?: string[];
    page?: number;
}

export interface EventBillingFormErrors {
    id?: string
    name: string;
    company_name: string;
    email: string;
    address: string;
    postal_code: string;
    tax_code: string;
    currency?: string;
    'city.id': string;
    'city': string;
    'country.id': string;
    'country': string;
    bank_details: string;
    'bank_details.bank_name': string;
    'bank_details.bank_code': string;
    'bank_details.account_number': string;
    'bank_details.iban': string;
}

export interface CityField {
    city: {
        country: {
            id: number;
            slug: string
        }
        id: number;
        slug: string
    }
}