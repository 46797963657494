import { FC, useState } from "react";
import { Button, Form, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Options } from "@shared/types";
import { PatchLandingStatusType } from "@pages/EventRolePages/ModerationPage/components/ModerationTable";
import { ModerationStatusAction, OrganizerLanding } from '@store/type-event/models';

type FormStateType = {
    reason: number | null
    comment: string
}

type ChangeStatusModalPropsType = {
    isOpen: boolean
    onClose: (isOpen: boolean) => void
    reasonsData: Options;
    data: {
        row: OrganizerLanding,
        status: ModerationStatusAction
    }
    handlePatchLandingStatus: ({ landing, status, reason, comment, statusSlug }: PatchLandingStatusType) => void
}

const RejectionModal: FC<ChangeStatusModalPropsType> = ({ isOpen, onClose, reasonsData, data, handlePatchLandingStatus }) => {
    const isLoadingChangeUserPassword = false

    const [formState, setFormState] = useState<FormStateType>({
        reason: null,
        comment: "",
    });

    const isDisabledRejectButton = !formState.reason || !formState.comment

    const onChangeFieldValue = (fieldName: string, value: string) => {
        setFormState(prevState => ({ ...prevState, [fieldName]: value }))
    }

    const handleCancel = (): void => {
        onClose(false)
        setFormState({
            reason: null,
            comment: "",
        })
    }

    const handleReject = () => {
        const { comment, reason } = formState
        const { row, status } = data
        const rejectionData = {
            comment,
            partner: row.partner,
            reason: reason || 0,
            landing: row.id,
            status: status.id,
            statusSlug: status.slug
        }
        handlePatchLandingStatus(rejectionData)
        setFormState({
            reason: null,
            comment: "",
        })
        onClose(false)
    }

    return (
        <Modal
            zIndex={1001}
            title={
                <span
                    className="rejection-modal__title"
                >
                    Rejection reason
                </span>
            }
            mask
            open={isOpen}
            onCancel={handleCancel}
            confirmLoading={isLoadingChangeUserPassword}
            className="rejection-modal"
            footer={
                <>
                    <div
                        className="rejection-modal__footer"
                    >
                        <Button
                            size={'large'}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            size={'large'}
                            type={'primary'}
                            danger
                            onClick={handleReject}
                            disabled={isDisabledRejectButton}
                        >
                            Reject
                        </Button>
                    </div>
                </>
            }
        >
            <Form layout={'vertical'}>
                <Form.Item
                    htmlFor={'reason'}
                >
                    <Select
                        id={'reason'}
                        placeholder='Select a rejection reason'
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={false}
                        value={formState.reason}
                        onChange={(value) => onChangeFieldValue('reason', value)}
                        fieldNames={{ label: 'slug', value: 'id' }}
                        options={reasonsData}
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            Please describe the reason for rejecting the <b>{data?.row?.partner}</b> landing page in more details
                        </span>
                    }
                    htmlFor={'comment'}
                >
                    <TextArea
                        id={'comment'}
                        placeholder='Describe the reason in more detail'
                        autoSize={{ minRows: 5, maxRows: 8 }}
                        value={formState.comment}
                        onChange={(e) => onChangeFieldValue('comment', e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default RejectionModal;
