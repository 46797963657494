import React from "react";

const PartnerOnboardingCircleErrorIcon: React.FC = () => {
    return (
        <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="104" height="104" rx="52" fill="#FC6F42" fill-opacity="0.08" />
            <path fillRule="evenodd" clipRule="evenodd" d="M52 80C67.464 80 80 67.464 80 52C80 36.536 67.464 24 52 24C36.536 24 24 36.536 24 52C24 67.464 36.536 80 52 80ZM52 84C69.6731 84 84 69.6731 84 52C84 34.3269 69.6731 20 52 20C34.3269 20 20 34.3269 20 52C20 69.6731 34.3269 84 52 84ZM50 36.5C50 36.2239 50.2239 36 50.5 36H53.5C53.7761 36 54 36.2239 54 36.5V56.5C54 56.7761 53.7761 57 53.5 57H50.5C50.2239 57 50 56.7761 50 56.5V36.5ZM52 68C53.933 68 55.5 66.433 55.5 64.5C55.5 62.567 53.933 61 52 61C50.067 61 48.5 62.567 48.5 64.5C48.5 66.433 50.067 68 52 68Z" fill="#FC6F42" />
        </svg>
    )
}

export default PartnerOnboardingCircleErrorIcon;
