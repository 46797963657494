import React from 'react'

const ContentLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className='content-layout'>
            {children}
        </div>
    )
}

export default ContentLayout