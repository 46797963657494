import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GetUserBillingResponse,
    PatchBillingAutodebitResponse,
    PatchBillingInfoBodyRequest,
    PatchBillingInfoResponse
} from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const billing = `billing`;

export const billingApi = createApi({
    reducerPath: billing,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        billing
    ],
    endpoints: build => ({
        // BILLING

        getUserBilling: build.query<GetUserBillingResponse, {
            last?: string;
            dateFrom?: string;
            dateTo?: string
        }>({
            query: ({ last, dateFrom, dateTo }) => {
                const params = new URLSearchParams();

                const lastQueryString = last ? `last=${last}` : ''

                if (dateFrom) {
                    params.append('filters[date][from]', `${dateFrom}`);
                }

                if (dateTo) {
                    params.append('filters[date][to]', `${dateTo}`);
                }

                const filterQueryString = params.toString();

                const queryString = [
                    lastQueryString,
                    filterQueryString,
                ].filter(Boolean).join('&');
                return ({
                    url: `/billing${queryString.length ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [billing],
        }),
        patchBillingInfo: build.mutation<PatchBillingInfoResponse, PatchBillingInfoBodyRequest>({
            query: (body) => ({
                body,
                method: 'PATCH',
                url: '/billing',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },

            }),
            invalidatesTags: [billing],
        }),
        patchBillingAutodebit: build.mutation<PatchBillingAutodebitResponse, {
            amount: number,
            autodebit: boolean
        }>({
            query: (body) => ({
                body,
                method: 'PATCH',
                url: '/billing/autodebit',
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },

            }),
            invalidatesTags: [billing],
        }),
    }),
});

export const {
    useGetUserBillingQuery,
    usePatchBillingAutodebitMutation,
    usePatchBillingInfoMutation
} = billingApi;