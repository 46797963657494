import StatusDescription from '@components/StatusDescription';
import { CampaignStatuses } from '@shared/constants';
import { message, Switch, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatByCurrency, formatKNumber } from "@shared/utils";
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import ErrorStatus from "@pages/PartnerRolePages/Campaigns/components/ErrorStatus";
import DropDownSettings from "@pages/PartnerRolePages/Campaigns/components/DropDownSettings";
import TableCellSettingsIcon from "@assets/TableCellSettingsIcon";
import ErrorStatusModal from "@pages/PartnerRolePages/Campaigns/components/ErrorStatusModal";
import { useBreakpoints } from '@hooks/browser';
import { usePatchPartnerCampaignsStatusByIdMutation } from "@store/type-partner/campaigns/campaigns.api.ts";

interface EventCampaignsTableProps {
    campaigns: CampaignDataItem[]
}
const EventCampaignsTable: FC<EventCampaignsTableProps> = ({
    campaigns
}) => {
    const isDesktop = useBreakpoints().md
    const { id: eventId } = useParams()
    const [campaignsList, setCampaignsList] = useState<CampaignDataItem[]>(campaigns)
    const [errorStatusModalData, setErrorStatusModalData] = useState<{ logs: CampaignDataItem['logs'], campaignTitle: string } | null>(null)

    const [patchPartnerCampaignsStatusById] = usePatchPartnerCampaignsStatusByIdMutation()

    useEffect(() => {
        setCampaignsList(campaigns)
    }, [campaigns])

    const onErrorStatusModalOpen = ({ campaignTitle, logs }: { logs: CampaignDataItem['logs'], campaignTitle: string }) => {
        if (!logs) {
            return
        }
        const errorLogs = logs.filter(log => log.type.name !== 'success');
        setErrorStatusModalData({ campaignTitle, logs: errorLogs });
    }

    const onErrorStatusModalClose = () => {
        setErrorStatusModalData(null)
    }

    const onCampaignStatusChange = async (status: number, campaignId: string) => {
        if (!status) {
            return message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        }
        try {
            const response = await patchPartnerCampaignsStatusById({
                campaignIds: [campaignId],
                status
            });

            if ('data' in response) {
                return message.open({
                    type: 'success',
                    content: 'Status Updated',
                });
            }
            if ('error' in response) {
                return message.open({
                    type: 'error',
                    content: 'Status Update Error',
                });
            }
            void message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        } catch (error) {
            void message.open({
                type: 'error',
                content: 'Status Update Error',
            });
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            width: 56,
            render: (_: number, row: CampaignDataItem) => {
                return (
                    <DropDownSettings
                        row={row}
                        campaignId={row.id}
                        eventId={row.event?.id || ''}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onCampaignStatusChange={onCampaignStatusChange}
                    >
                        <div className='cursor-pointer'>
                            <TableCellSettingsIcon />
                        </div>
                    </DropDownSettings>
                )
            }
        },
        {
            title: 'Campaign name',
            dataIndex: 'name',
            key: 'name',
            width: 250,
            fixed: isDesktop ? 'left' : '',
            render: (name: string, row: CampaignDataItem) => {
                return (
                    <div className='flex items-center justify-space-between'>
                        {row.event?.id ? (
                            <div>
                                <Link
                                    className='no-style'
                                    to={`/events/edit/${eventId!}/campaign/${row.id}`}
                                >
                                    <span>{name}</span>
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <span>{name}</span>
                            </div>
                        )}
                    </div>
                )
            }

        },
        {
            title: 'On/off',
            dataIndex: 'status',
            key: 'status',
            width: 77,
            render: (_: number, row: CampaignDataItem) => {
                const enabledId = row.status.actions.find(({ slug }) => slug === CampaignStatuses.enabled)?.id
                const pausedId = row.status.actions.find(({ slug }) => slug === CampaignStatuses.paused)?.id
                const newStatus = row.status.name === CampaignStatuses.enabled ? pausedId : enabledId
                return (
                    <Switch
                        checked={row.status.name === CampaignStatuses.enabled}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onChange={() => onCampaignStatusChange(newStatus || 0, row.id)}
                    />
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status: { id: string, name: string }, row: CampaignDataItem) => {
                const filteredWarnings = row?.warnings?.filter(({ translate }) => translate) || []
                const errorLogs = row?.logs?.filter(log => log.type.name !== 'success') || [];
                const statusName = errorLogs.length ? CampaignStatuses.error : status.name
                return (
                    <div className="flex">
                        <StatusDescription
                            status={statusName as CampaignStatuses}
                            warningTooltip={filteredWarnings.length && !errorLogs.length ? (
                                <div>{filteredWarnings.map(({ id, translate }) => <div key={id}>{translate}</div>)}</div>
                            ) : null}
                        />
                        {Boolean(errorLogs.length) && (
                            <ErrorStatus
                                onClick={() => onErrorStatusModalOpen({ logs: row.logs, campaignTitle: row.name })}
                            />
                        )}
                    </div>
                )
            }
        },
        {
            title: 'Spent',
            dataIndex: 'cost',
            key: 'cost',
            width: 100,
            render: (_: number, row: CampaignDataItem) => formatByCurrency(row.statistics?.cost || 0, row.statistics?.currency)
        },
        {
            title: 'Impressions',
            dataIndex: 'impressions',
            key: 'impressions',
            render: (_: number, row: CampaignDataItem) => formatKNumber(row.statistics?.impressions || 0)
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            render: (_: number, row: CampaignDataItem) => row?.statistics?.clicks || 0
        },
        {
            title: 'Average CPM',
            dataIndex: 'average_cpm',
            key: 'average_cpm',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpm || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Average CPC',
            dataIndex: 'average_cpc',
            key: 'average_cpc',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.average_cpc || 0, row.statistics?.currency)}</span>
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
            render: (_: number, row: CampaignDataItem) => <span>{(row?.statistics?.conversions || 0).toFixed(2)}%</span>
        },
        {
            title: 'Cost Per Conversion',
            dataIndex: 'cost_per_conversion',
            key: 'cost_per_conversion',
            render: (_: number, row: CampaignDataItem) => <span>{formatByCurrency(row?.statistics?.conversions || 0, row.statistics?.currency)}</span>
        },
    ];

    return (
        <>
            <Table
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                dataSource={campaignsList}
                columns={columns}
                scroll={{ x: 1200 }}
            />
            <ErrorStatusModal
                isOpen={Boolean(errorStatusModalData)}
                onClose={onErrorStatusModalClose}
                campaignTitle={errorStatusModalData?.campaignTitle || ''}
                logs={errorStatusModalData?.logs}
            />
        </>
    );
};

export default EventCampaignsTable;
