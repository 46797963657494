//#BFBFBF

const UserTypeEvent = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 21V56.5C3 58.7091 4.79086 60.5 7 60.5H42.5M3 21V13C3 10.7909 4.79086 9 7 9H56C58.2091 9 60 10.7909 60 13V21M3 21H60M60 21V37.5" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" strokeLinecap="round" />
            <path d="M19 5V13" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" strokeLinecap="round" />
            <path d="M44 5V13" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" strokeLinecap="round" />
            <path d="M54.1774 66.0032C62.3655 66.0032 69.0032 59.3655 69.0032 51.1774C69.0032 42.9893 62.3655 36.3516 54.1774 36.3516C45.9893 36.3516 39.3516 42.9893 39.3516 51.1774C39.3516 59.3655 45.9893 66.0032 54.1774 66.0032Z" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M54.1895 42.0283V52.0011L58.9995 56.0003" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" strokeLinecap="round" />
            <rect x="10" y="28" width="11.3333" height="6" rx="1" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" />
            <rect x="26.334" y="28" width="11.3333" height="6" rx="1" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" />
            <rect x="10" y="39" width="11.33" height="6" rx="1" stroke={isActive ? "#4E67EA" : "#BFBFBF"} strokeWidth="2" />
        </svg>
    )
}

export default UserTypeEvent