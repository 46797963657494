// https://github.com/allnetru/boost/pull/68
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    GetUsersAndRolesParams,
    GetUsersAndRolesResponse,
    PostUser,
    PostUsersAndRolesResponse
} from "@store/main/users-roles/models.ts";

const baseUrl = import.meta.env.VITE_API_URL as string;
const users_roles = `users_roles`;

export const usersRolesApi = createApi({
    reducerPath: users_roles,
    baseQuery: fetchBaseQuery({
        baseUrl,
    }),
    tagTypes: [
        users_roles
    ],
    endpoints: (build) => ({
        getUsers: build.query<GetUsersAndRolesResponse, GetUsersAndRolesParams & { isEvent?: boolean }>({
            query: ({ isEvent, page }) => {
                const params = new URLSearchParams();

                if (page) {
                    params.append('page', page.toString());
                }

                const queryString = params.toString();
                // todo добавить сортировку
                return {
                    url: `/${isEvent ? 'organizer' : 'partner'}/users${queryString ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [users_roles],
        }),
        postUser: build.mutation<PostUsersAndRolesResponse, PostUser & { isEvent?: boolean }>({
            query: (body) => {
                const { isEvent, ...rest } = body
                return ({
                    body: rest,
                    method: 'POST',
                    url: `/${isEvent ? 'organizer' : 'partner'}/users`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            invalidatesTags: [users_roles],
        }),
        patchUser: build.mutation<PostUsersAndRolesResponse, PostUser & { isEvent?: boolean }>({
            query: (body) => {
                const { id, isEvent, ...rest } = body
                return ({
                    url: `/${isEvent ? 'organizer' : 'partner'}/users/${id ? id.toString() : ''}`,
                    method: 'PATCH',
                    body: rest,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
            },
            invalidatesTags: [users_roles],
        }),
        deleteUserById: build.mutation<void, { id: number } & { isEvent?: boolean }>({
            query: ({ id, isEvent }) => ({
                method: 'DELETE',
                url: `/${isEvent ? 'organizer' : 'partner'}/users/${id}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [users_roles],
        }),
    }),
});

export const {
    useGetUsersQuery,
    usePostUserMutation,
    usePatchUserMutation,
    useDeleteUserByIdMutation,
} = usersRolesApi;