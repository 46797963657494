import React, { FC } from 'react';
import { Modal, List } from 'antd';
import { CampaignDataItem } from '@store/type-partner/campaigns/models';
import ErrorCrossIcon from '@assets/ErrorCrossIcon';

type Props = {
    isOpen: boolean
    onClose: () => void
    campaignTitle: string
    logs: CampaignDataItem['logs']
}

const ErrorStatusModal: FC<Props> = ({ isOpen, onClose, logs = [], campaignTitle = '' }) => {
    return (
        <Modal
            title={(
                <div className='flex'>
                    <div>Errors</div>
                    <div className='vertical-divider' />
                    <div>{campaignTitle}</div>
                </div>
            )}
            open={isOpen}
            onCancel={onClose}
            centered
            width={650}
            footer={null}
            className='error-status-modal'
        >
            <List
                itemLayout="horizontal"
                dataSource={logs}
                renderItem={(item) => (
                    <List.Item>
                        <ErrorCrossIcon />
                        <span className='text-neutral-8'>{item.message || item.type.translate}</span>
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default ErrorStatusModal;
