import React from "react";
import { Table } from "antd";
import { LeadsTableItem } from "@store/models-to replace/leads";
import { PaginationConfig } from "@pages/PartnerRolePages/Leads/components/LeadsBlock";
import { formatDate } from "date-fns";
import './styles.scss'
import { useBreakpoints } from '@hooks/browser';
type LeadsTablePropsType = {
    data: LeadsTableItem[];
    paginationConfig: PaginationConfig;
};
const LeadsTable: React.FC<LeadsTablePropsType> = (props) => {
    const { data, paginationConfig } = props;
    const isDesktop = useBreakpoints().md

    // Date - дата лида
    // Event - название эвента
    // Landing page - название лендинга
    // Name
    // Company
    // Email
    // Action type - Application/PDF Download/ Watching video
    // Source Campaign - название РК
    // Source Platform - Google Ads

    const columns = [
        {
            title: <span style={{ whiteSpace: 'pre' }}>Date</span>,
            dataIndex: 'date',
            key: 'date',
            width: '220px',
            render: (_: number, row: LeadsTableItem) => {
                return (
                    <span style={{ whiteSpace: 'pre' }}>
                        {formatDate(row?.date?.created_at, 'yyyy-MM-dd HH:mm')}
                    </span>
                );
            },
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Event</span>,
            dataIndex: 'event',
            key: 'event',
            width: '260px',
            render: (_: number, row: LeadsTableItem) => {
                return (
                    <span
                        style={{
                            minWidth: '220px',
                            maxWidth: '260px',
                            whiteSpace: 'pre',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {row?.event?.name}
                    </span>
                );
            },
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Landing page</span>,
            dataIndex: 'landing',
            key: 'landing',
            width: '120px',
            render: (_: number, row: LeadsTableItem) => {
                return (
                    <span
                        style={{
                            minWidth: '220px',
                            maxWidth: '260px',
                            whiteSpace: 'pre',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {row?.landing.name}
                    </span>
                );
            },
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Name</span>,
            dataIndex: 'contact_name',
            key: 'contact_name',
            width: '120px',
            render: (_: number, row: LeadsTableItem) => row?.lead?.contact_name,
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Company</span>,
            dataIndex: 'company_name',
            key: 'company_name',
            width: '120px',
            render: (_: number, row: LeadsTableItem) => row?.lead?.company_name,
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Email</span>,
            dataIndex: 'email',
            key: 'email',
            width: '120px',
            render: (_: number, row: LeadsTableItem) => row?.lead?.email,
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Action type</span>,
            dataIndex: 'type',
            key: 'type',
            width: '120px',
            render: (_: number, row: LeadsTableItem) => row?.type?.name,
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Source Campaign</span>,
            dataIndex: 'campaign',
            key: 'campaign',
            width: '120px',
            render: (_: number, row: LeadsTableItem) => {
                return (
                    <span
                        style={{
                            minWidth: '220px !important',
                            maxWidth: '260px',
                            whiteSpace: 'pre',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {row?.campaign?.name || "-"}
                    </span>
                );
            },
        },
        {
            title: <span style={{ whiteSpace: 'pre' }}>Source Platform</span>,
            dataIndex: 'contact_title',
            key: 'contact_title',
            width: '120px',
            render: () => "-", //(_: number, row: LeadsTableItem) => row?.lead?.contact_title
        },
    ];



    return (
        <div
            className='leads-table'
        >
            <Table
                dataSource={data}
                columns={columns}
                scroll={{ x: true, y: isDesktop ? 600 : 400 }}
                sticky
                pagination={paginationConfig}
            />

        </div>
    )
}

export default LeadsTable;
