import React from 'react';
import '../../styles.scss';
import { Typography } from "antd";
import { PatchBillingInfoUser } from '@store/models/billing';
import BillingForm from '@components/BillingForm';
import { BillingErrorsValues } from '@components/BillingForm/models';

interface BillingInfoProps {
    form: PatchBillingInfoUser;
    validationErrors: BillingErrorsValues
    onFormChange: (field: Partial<PatchBillingInfoUser>) => void;
    // onError?: (err: any) => void;
}

const BillingInfo: React.FC<BillingInfoProps> = ({
    form,
    onFormChange,
    validationErrors
}) => {

    return (
        <div className='partner-invitation'>
            <section className='partner-invitation-form'>
                <div>
                    <Typography.Paragraph className='partner-invitation-form__header'>
                        <Typography.Title level={4} className='partner-invitation-form__header-title'>
                            Billing contact
                        </Typography.Title>
                    </Typography.Paragraph>

                    <BillingForm
                        form={form}
                        validationErrors={validationErrors}
                        onFormChange={onFormChange}
                    />
                </div>
            </section>
        </div>
    );
};

export default BillingInfo;
