import BadgeCustom from '@components/BadgeCustom';
import NotificationIcon from '@icons/header/NotificationIcon';
import { Dropdown, Menu } from 'antd';
import NotificationCard from './NotificationCard';
import React, { useEffect, useState } from 'react';
import './styles.scss'
import { useGetNotificationsQuery, usePostNotificationsAreReadMutation } from '@store/main/notifications/notifications.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import EmptyIcon from "@assets/notifications/EmptyIcon";
import { Link } from 'react-router-dom';
import { Notification } from '@store/main/notifications/models';
import { useBreakpoints } from '@hooks/browser';

const NotificationsHeader = ({ fillColor }: { fillColor?: string }) => {
    const isDesktop = useBreakpoints().md
    const [page, setPage] = useState(1)

    const { data, refetch } = useGetNotificationsQuery({ page }, { pollingInterval: 60000 });
    const [onReadAllNotifications] = usePostNotificationsAreReadMutation()
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const [notifications, setNotifications] = useState<Notification[]>([])
    const filteredNotifications = notifications.filter(item => !item.is_viewed)
    const newNotificationsCount = filteredNotifications.length > 9 ? '9+' : filteredNotifications.length

    const menuItems = notifications.map(item => (
        <Menu.Item key={item.id}>
            <NotificationCard
                notification={item}
            />
        </Menu.Item>
    ));

    const onCloseDropdown = async () => {
        try {
            const response = await onReadAllNotifications();
            if (response) {
                void refetch(); // Refetch data
            }
        } catch (error) {
            // Handle error if needed
        }
    };

    useEffect(() => {
        if (data?.data) {
            setNotifications(data?.data || [])
        }
    }, [data?.data])

    useEffect(() => {
        void refetch()
    }, [])

    const onShowMoreClick = () => {
        setIsDropdownOpen(false)
    }

    return (
        <Dropdown
            overlayClassName='notification-card__overlay'
            // TO FIX: v5^ wants 'menu' prop...
            overlay={
                notifications?.length ? (
                    <>
                        <div className='p-16'>
                            <Menu>
                                <div className='notification-card__title'>
                                    <p>
                                        Notifications
                                    </p>
                                    <div className='notification-card__title-total-count'>
                                        {notifications?.length || ''}
                                    </div>
                                </div>
                                {menuItems}
                            </Menu>
                        </div>
                        <Link to="/notifications" onClick={onShowMoreClick}>
                            <div className='notification-card__show-more'>Show more</div>
                        </Link>
                    </>
                )
                    : (
                        <div className='p-16'>
                            <Menu>
                                <div className='notification-card__title'>
                                    <p>
                                        Notifications
                                    </p>
                                    <div className='notification-card__title-total-count'>
                                        {notifications?.length || ''}
                                    </div>
                                </div>
                                <div className='notification-card__wrapper'>
                                    <EmptyIcon />
                                    <div className='notification-card__empty-title'>Your notifications list is empty</div>
                                    <div className='notification-card__empty-subtitle'>Stay tuned for updates to ensure you don’t miss out on anything!</div>
                                </div>
                            </Menu>
                        </div>
                    )
            }
            overlayStyle={{
                maxHeight: '576px',
                width: isDesktop ? '506px' : '80%',
                overflow: 'auto',
            }}

            open={isDropdownOpen}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onOpenChange={(open) => {
                if (open) {
                    sendAnalytics(GTMEventName.viewNotifications)
                }
                setIsDropdownOpen(open)
                return !open && onCloseDropdown()
            }}
            trigger={['hover']}
        >
            <div className='header-item header-item-notification'>
                <NotificationIcon
                    fillColor={fillColor}
                />

                {Boolean(newNotificationsCount) && (
                    <div>
                        <BadgeCustom count={newNotificationsCount.toString()} />
                    </div>
                )}
            </div>
        </Dropdown>
    );
}

export default NotificationsHeader;
