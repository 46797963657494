import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import { Table, Tooltip, Button, notification, Row, Col, Select, Spin } from 'antd';
import StatusDescription from '@components/StatusDescription';
import { CampaignStatuses, InvitationStatuses } from '@shared/constants';
import ZeroInvitations from './ZeroState';
import { formatDate } from '@shared/utils';
import { format, parseISO } from "date-fns";
import '../../styles.scss'
import { useGetInvitationsExported, useGetOrganizerUserInvitesByEventIdQuery, usePatchOrganizerEventInvitationTagMutation } from '@store/type-event/events/events.api';
import { GetOrganizerUserInvitesByEventIdDataItem } from "@store/type-event/events/models";
import ArrowIcon from "@assets/ArrowIcon";
import InfoIcon from "@icons/header/InfoIcon";
import { InvitationKeys } from "@pages/EventRolePages/EventsPage/pages/info/constants";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";
import { FilterByEnumItem } from "@store/main/enums/models";
import { INIT_PAGE } from "@components/InfiniteScrollTable/constants";
import { useBreakpoints } from '@hooks/browser';
import TagDropdown from './TagDropdown';
import InvitationCode from './InvitationCode';
import TagModal from './TagModal';
import ExportToCsvButton from '@pages/PartnerRolePages/Leads/components/ExportToCsvButton';
import DownloadIcon from '@assets/DownloadIcon';
import CustomButton from '@components/CustomButton';
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";

interface InvitationsTableProps {
    onInviteClick: () => void
    isPublished?: boolean
}
const InvitationsTable = ({
    onInviteClick,
    isPublished
}: InvitationsTableProps) => {
    const { eventId: id } = useParams()
    const isDesktop = useBreakpoints().md

    const [pagination, setPagination] = useState({
        current: 0,
        total: 0,
        pageSize: 0
    });
    const [page, setPage] = useState(0)
    const [filters, setFilters] = useState<{ statuses: number[], tags: number[] }>({})
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([] as number[])
    const [tagModalData, setTagModalData] = useState<FilterByEnumItem | null>(null)
    const [isPatchInvitationTagLoading, setIsPatchInvitationTagLoading] = useState(false)

    const { data: invitations, isLoading, isFetching, isSuccess, refetch } = useGetOrganizerUserInvitesByEventIdQuery({ eventId: id?.toString() || '', page, filters }, { skip: !id })
    const [patchInvitationTag] = usePatchOrganizerEventInvitationTagMutation()
    const { data: inviteStatuses, isLoading: isInviteStatusesLoading } = useGetFiltersByEnumQuery({ name: 'user_invite_status' })
    const { data: inviteTags, isLoading: isInviteTagsLoading } = useGetFiltersByEnumQuery({ name: 'user_invite_tag' })
    const { isLoading: isExportLoading, run: runExport } = useGetInvitationsExported(id?.toString() || '');

    const [invitationsList, setInvitationsList] = useState(invitations?.data || []);

    useEffect(() => {
        void refetch()
    }, [])

    useEffect(() => {
        if (invitations?.data) {
            setInvitationsList(invitations.data)
            setPagination({
                current: invitations.meta.current_page,
                total: invitations.meta.total,
                pageSize: invitations.meta.per_page,
            });
        }
    }, [invitations?.data])

    const onSentClick = useCallback(async ({ tagId, slug, statusId }: { tagId: string | string[], statusId: string, slug: string }) => {
        try {
            setIsPatchInvitationTagLoading(true)
            const response = await patchInvitationTag({
                tag: statusId,
                ids: Array.isArray(tagId) ? tagId : [tagId],
                eventId: id!
            });
            if ('error' in response) {
                setIsPatchInvitationTagLoading(false)
                return notification.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: response?.error?.data?.message || 'OOOPS, something is wrong',
                    placement: 'bottomLeft',
                    closeIcon: false
                });
            }
            setIsPatchInvitationTagLoading(false)
            notification.open({
                message: (
                    <span>
                        The invitation codes have been marked as
                        {' '}
                        <b>{slug === InvitationKeys.Sent ? 'Sent' : 'Not sent'}</b>
                    </span>
                ),
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'success',
            });
        } catch (error) {
            setIsPatchInvitationTagLoading(false)
            return notification.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
                placement: 'bottomLeft',
                closeIcon: false
            });
        }
    }, [id, patchInvitationTag])

    const onOpenTagModal = (data: FilterByEnumItem) => {
        setTagModalData(data)
    }

    const onHandleSelectedSentClick = async () => {
        setSelectedRowKeys([])
        setTagModalData(null)
        await onSentClick({ statusId: (tagModalData?.id || '').toString(), tagId: selectedRowKeys.toString(), slug: tagModalData?.slug || '' })
    }

    const onTagModalClose = () => {
        setTagModalData(null)
    }

    const handleChangeFilter = useCallback((field: string, value: number[]) => {
        setFilters((prev) => ({ ...prev, [field]: value }))
        setPage(INIT_PAGE)
    }, [])

    const mapFilterValues = (newFilters: { statuses: number[], tags: number[] }): Partial<{ statuses: number[], tags: number[] }> => {
        const result: Partial<{ statuses: number[], tags: number[] }> = {};

        if (newFilters.statuses.length) {
            result.statuses = newFilters.statuses;
        }

        if (newFilters.tags && newFilters.tags.length > 0) {
            result.tags = newFilters.tags;
        }

        return result;
    }

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            render: (status: { id: number, name: string }) => <StatusDescription status={status.name as CampaignStatuses} formattedStatusData={InvitationStatuses} />
        },
        {
            title: (
                <div className='flex invitations-tab__tag__header'>
                    <span>Tag</span>
                    <Tooltip
                        overlayClassName='tooltip-general'
                        placement='bottom'
                        title="Tags are a simple and effective way to keep track of the status of your invite codes. By adding tags to the active codes, you can easily identify which ones have been sent to partners."
                    >
                        <div className='cursor-pointer'>
                            <InfoIcon fillColor="#252628" width={14} height={14} />
                        </div>
                    </Tooltip>
                </div>
            ),
            dataIndex: 'tag',
            key: 'tag',
            width: '220px',
            render: (tag: GetOrganizerUserInvitesByEventIdDataItem['tag'], row: GetOrganizerUserInvitesByEventIdDataItem) => {
                return (
                    <TagDropdown
                        tagId={row.id}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onSentClick={onSentClick}
                        inviteTags={inviteTags?.data || []}
                    >
                        <span className='invitations-tab__tag__wrapper'>
                            <span className={`invitations-tab__tag ${tag.name === InvitationKeys.Sent ? 'invitations-tab__tag-sent' : ''}`}>{tag.name === 'sent' ? 'Sent' : 'Not Sent'}</span>
                            <ArrowIcon fillColor='#C0C1C3' />
                        </span>
                    </TagDropdown>
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '28%',
            render: (date: string) => {
                let formattedTime
                if (date) {
                    const parsedDate = parseISO(date);
                    formattedTime = format(parsedDate, 'HH:mm')
                }
                return (
                    <span className='whitespace-nowrap'>
                        <span>{formatDate(date || '')}</span>
                        <span className='invitations-tab__divider'> | </span>
                        <span>{formattedTime || ''}</span>
                    </span>
                )
            }
        },
        {
            title: 'Invitation code',
            dataIndex: 'code',
            key: 'code',
            width: '28%',
            render: (link: string) => (
                <InvitationCode link={link} />
            )
        },
        {
            title: 'Invitation link',
            dataIndex: 'link',
            key: 'link',
            width: '36%',
        },

    ];

    return (
        <>
            <div className='moderation-page__filter my-16'>
                <div className='flex items-center justify-space-between w-full'>
                    <div className='flex w-full'>

                        <div className='moderation-page__filter-select'>
                            <Select
                                placeholder='Status: All'
                                mode="multiple"
                                maxTagCount={1}
                                style={{ width: '100%' }}
                                allowClear
                                filterOption={false}
                                notFoundContent={isInviteStatusesLoading ? <Spin size="small" /> : null}
                                loading={isInviteStatusesLoading}
                                value={filters.statuses}
                                onChange={(value) => handleChangeFilter('statuses', value)}
                                fieldNames={{ label: 'translate', value: 'id' }}
                                options={inviteStatuses?.data || []}
                                popupClassName='moderation-table__popup-select'
                                menuItemSelectedIcon={menuItemSelectedIcon}
                            />
                        </div>
                        {isDesktop && (
                            <div className='moderation-page__filter-select'>
                                <Select
                                    placeholder='Tag: All'
                                    style={{ width: '100%' }}
                                    maxTagCount={1}
                                    mode="multiple"
                                    allowClear
                                    filterOption={false}
                                    notFoundContent={isInviteTagsLoading ? <Spin size="small" /> : null}
                                    loading={isInviteTagsLoading}
                                    value={filters.tags}
                                    onChange={(value) => handleChangeFilter('tags', value)}
                                    fieldNames={{ label: 'translate', value: 'id' }}
                                    options={inviteTags?.data || []}
                                    popupClassName='moderation-table__popup-select'
                                    menuItemSelectedIcon={menuItemSelectedIcon}
                                />
                            </div>
                        )}
                    </div>

                    {isDesktop && (
                        <div className='flex'>
                            <ExportToCsvButton
                                className='mr-6 export-button'
                                // runExportReq={() => runExport({ filters: mapFilterValues(filters) })}
                                runExportReq={runExport}
                                isLoading={isExportLoading}
                                fileName='Invitations.csv'
                                type='default'
                            >
                                <DownloadIcon />
                                Export CSV
                            </ExportToCsvButton>
                            <CustomButton
                                title=' +Add invitation'
                                type='primary'
                                disabled={!isPublished}
                                onClick={onInviteClick}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Table
                loading={isPatchInvitationTagLoading || isLoading || isFetching}
                dataSource={invitationsList}
                columns={columns}
                rowKey='id'
                scroll={{ x: '50vh' }}
                locale={{
                    emptyText: (
                        isPatchInvitationTagLoading || isLoading || isFetching ? ' ' : (
                            <ZeroInvitations
                                readonly
                                onCtaClick={onInviteClick}
                            />
                        )
                    )
                }}
                pagination={
                    invitationsList &&
                    invitationsList.length > 0 &&
                    pagination?.total > 30 && {
                        ...pagination,
                        showSizeChanger: false,
                        onChange: setPage,
                        //   position: position ? position : ['bottomLeft'],
                    }
                }
                onChange={(newPagination) => setPagination(prev => ({ ...prev, pagination: newPagination }))}
                rowSelection={{
                    onChange: (rowKeys: React.Key[]) => {
                        setSelectedRowKeys(rowKeys)
                    },
                    selectedRowKeys
                }}
            />
            {Boolean(selectedRowKeys.length) && (
                <div className='footer-campaigns footer-invitations'>
                    {inviteTags?.data?.map((item) => {
                        return (
                            <Button
                                size='large'
                                type={item.slug === InvitationKeys.Sent ? 'primary' : 'default'}
                                onClick={() => onOpenTagModal(item)}
                            >
                                Apply “
                                {item.translate}
                                ” tag
                            </Button>
                        )
                    })}
                </div>
            )}
            <TagModal
                isOpen={Boolean(tagModalData)}
                tagModalData={tagModalData}
                onClose={onTagModalClose}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onApply={onHandleSelectedSentClick}
            />
        </>
    )
}

export default InvitationsTable
