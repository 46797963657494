import React, { useMemo, useEffect, useContext, useState, useCallback } from 'react';
import './styles.scss'
import EventsPageLayout from '@layouts/EventsPageLayout';
import { useParams, useLocation } from 'react-router-dom';
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb';
import { useGetEventByIdFromCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api';
import NonEditableSection
    from "@pages/PartnerRolePages/LandingPageConstructor/components/NonEditableSection /NonEditableSection";
// import { footerHtml, headerHtml } from "@pages/PartnerRolePages/LandingPageConstructor/mocks";
import EmptySectionsBlock
    from "@pages/PartnerRolePages/LandingPageConstructor/components/EmptySectionsBlock/EmptySectionsBlock";
import { getCrumbs } from "@pages/PartnerRolePages/LandingPageConstructor/helpers";
import { JoditOptionName } from './components/LandingPageWYSIWYGconstructor/assets/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import SectionSettingsDrawer from './components/drawers/settings/SectionSettingsDrawer';
import AddSectionModal from './components/modals/AddSectionModal';
import CustomButton from '@components/CustomButton';
import { OrganizerLanding } from "@store/type-event/models";
import StatusDescription from "@components/StatusDescription";
import { ModerationStatusesFormatted } from "@shared/constants";
import InfoIcon from "@icons/header/InfoIcon";
import { Button, Input, Skeleton, Typography } from "antd";
import RejectionReasonModal
    from "@pages/PartnerRolePages/MyEvents/pages/MyEventEditPage/components/EventLandingPages/components/RejectionReasonModal";
import LoaderAi from './components/loaders/LoaderAi';
import { LandingAiGenerationSteps } from './models';
import { footerHtmlDemo, headerHtmlDemo } from "@pages/PartnerRolePages/LandingPageConstructor/mocksDemo";
import { useLazyGetUserQuery } from '@store/main/-user/user.api';
import { useGetPartnerEventByIdQuery } from "@store/type-partner/events/partner-event.api";
import InfoTooltipIcon from "@icons/InfoTooltipIcon";
import { useGetPartnerLandingByIdQuery } from '@store/type-partner/landings/landings.api';
import usePageAnalytics from '@hooks/sendAnalytics/usePageAnalytics';
import PublishedModal from './components/modals/PublishedModal';
import SectionComponent
    from "@pages/PartnerRolePages/LandingPageConstructor/components/SectionComponent/SectionComponent.tsx";
import Loader from '@components/Loader';
import PreviewBtnIcon from "@icons/header/PreviewBtnIcon.tsx";
import ContentLayout from "@layouts/ContentLayout.tsx";

const { Paragraph } = Typography;

const LandingPageConstructor = () => {
    usePageAnalytics('lp_editor');

    const {
        clickedSectionId,
        setClickedSectionId,
        editorSections,
        setEditorSections,
        layoutSettingsData,
        onDeleteSectionFromList,
        setLandingErrors,
        landingErrors,
        landingTitle,
        setLandingTitle,
        landingUrl,
        setLandingUrl,
        updateLandingHandler,
        createLandingHandler,
        isLoading,
        // isLandingLoading,
        isPublishedModalOpen,
        onClosePublishedModal,
        onPublishClick,
        isPublishLoading,
        isAiLoading,
        setAiLoading,
        onPreviewClick
    } = useContext(LandingEditorContext);
    const { eventId = '', campaignId = '', landingId = '', type = '' } = useParams()

    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false)
    const [loadedHeader, setLoadedHeader] = useState('')
    const [loadedFooter, setLoadedFooter] = useState('')
    const [isLandingDataLoading, setIsLandingDataLoading] = useState(Boolean(landingId))

    const { data: eventData } = useGetEventByIdFromCatalogQuery(
        { id: eventId?.toString() || '' },
        { skip: !eventId }
    );
    const { data: eventsRes, isLoading: isEventsResLoading } = useGetPartnerEventByIdQuery({ id: eventId || '' }, { skip: !eventId })
    const { data: fetchedLandingData, isLoading: isLandingLoading, isFetching: isLandingsFetching, refetch: refetchLandingData } = useGetPartnerLandingByIdQuery({ landingId, eventId }, { skip: !landingId })

    const [dumbGetUser] = useLazyGetUserQuery()

    const { pathname } = useLocation()


    const breadCrumbs = useMemo(() => getCrumbs(pathname, eventData?.data, campaignId), [pathname, eventData])

    // WYSIWYG editor options
    const onOptionClick = useCallback(({ option, id }: { option: JoditOptionName, id: string }) => {
        switch (option) {
            case JoditOptionName.Delete:
                onDeleteSectionFromList(id)
                break;
            case JoditOptionName.Up:
                // eslint-disable-next-line no-case-declarations
                const indexUp = editorSections.findIndex(item => item.id === id);
                if (indexUp > 0) {
                    const newList = [...editorSections];
                    const temp = newList[indexUp - 1];
                    newList[indexUp - 1] = newList[indexUp];
                    newList[indexUp] = temp;
                    setEditorSections(newList);
                }
                break;
            case JoditOptionName.Down:
                // eslint-disable-next-line no-case-declarations
                const indexDown = editorSections.findIndex(item => item.id === id);
                if (indexDown < editorSections.length - 1) {
                    const newList = [...editorSections];
                    const temp = newList[indexDown + 1];
                    newList[indexDown + 1] = newList[indexDown];
                    newList[indexDown] = temp;
                    setEditorSections(newList);
                }
                break;

            default:
                break;
        }
    }, [editorSections, onDeleteSectionFromList, setEditorSections])

    const [aiLoaderStep, setAiLoaderStep] = useState<LandingAiGenerationSteps>('title')
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setClickedSectionId('')
            }
        };
        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (fetchedLandingData?.data) {
            setIsLandingDataLoading(true)
            const data = fetchedLandingData.data
            const content = fetchedLandingData.data.content || []
            const sections = content.map((item, idx) => ({ id: item.name + idx.toString(), layout: item }))
            setEditorSections(sections)
            setLandingTitle(data.title)
            setLandingUrl(data.url.path)
            setTimeout(() => {
                setIsLandingDataLoading(false)
            }, 700)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedLandingData])




    //    cb hell for testing
    useEffect(() => {
        if (type === 'ai') {
            const timer = 500
            const steps: LandingAiGenerationSteps[] = ['title', 'description', 'images', 'finish'];
            setAiLoading(true);
            void dumbGetUser().then(() => {
                setAiLoaderStep(steps[0])
                setTimeout(() => {
                    void dumbGetUser().then(() => {
                        setAiLoaderStep(steps[1])
                        setTimeout(() => {
                            void dumbGetUser().then(() => {
                                setAiLoaderStep(steps[3])
                                setTimeout(() => {
                                    setLoadedHeader(headerHtmlDemo)
                                    // setEditorSections(mockedAiSections)
                                    setLoadedFooter(footerHtmlDemo)
                                    setAiLoading(false);
                                }, timer);
                            });
                        }, timer);
                    });
                }, timer);
            })
        } else {
            // setLoadedHeader(headerHtml)
            // setLoadedFooter(footerHtml)
            setAiLoading(false);
            setAiLoaderStep('title');
        }
    }, [type]);



    const onInfoIconClick = () => {
        setIsRejectionModalOpen(true)
    }

    const onCloseRejectionModal = () => {
        setIsRejectionModalOpen(false)
    }

    const onSaveClick = () => {
        landingId ? updateLandingHandler() : createLandingHandler()
    }

    const renderedEditorSections = useMemo(
        () => {
            return editorSections.map((section, idx) => {
                const tooltipVisible = section.id === clickedSectionId
                return (
                    <SectionComponent
                        key={section.id}
                        section={section}
                        idx={idx}
                        tooltipVisible={tooltipVisible}
                        onOptionClick={onOptionClick}
                        isLoading={isLandingDataLoading}
                    />
                )
            })
        },
        [editorSections, clickedSectionId, onOptionClick, isLandingDataLoading]
    );

    return (
        <EventsPageLayout className='landing-editor-page'>
            <ContentLayout>
                <HeaderBreadcrumb crumbs={breadCrumbs} />
                <div className='flex'>
                    <div className='mr-auto'>
                        <div className='landing-constructor__project-title'>
                            <Paragraph editable={{ onChange: setLandingTitle, onStart: () => setLandingErrors({}) }} >{landingTitle}</Paragraph>
                        </div>
                        {landingErrors?.title && (
                            <div className='text-regular-14 label ml-6 error'>{landingErrors.title}</div>
                        )}
                    </div>
                    <>
                        {landingId ?
                            <aside className='mx-8'>
                                {fetchedLandingData?.data && (fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.actions?.map(({ id, transit }) => {
                                    return <CustomButton
                                        key={id}
                                        loading={isPublishLoading || isLandingLoading}
                                        type='primary'
                                        shape="round"
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={() => onPublishClick(id, transit)}
                                        title={transit}
                                    />
                                })}
                            </aside>
                            :
                            null}
                        <aside
                            className={`flex-center  ${layoutSettingsData && clickedSectionId ? 'isDrawerOpen my-events-landing-pages-card__margin-right' : ''}`}>
                            {landingId && (
                                <div className='my-events-landing-pages-card__status whitespace-nowrap mx-8'>
                                    <StatusDescription
                                        status={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.name}
                                        formattedStatusData={ModerationStatusesFormatted}
                                        size="small"
                                    />
                                    {(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.comment && (
                                        <div onClick={onInfoIconClick}>
                                            <InfoIcon fillColor="#252628" width={16} height={16} />
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="mx-8">
                                {landingId ?
                                    <CustomButton
                                        type="primary"
                                        shape="round"
                                        prefix={<PreviewBtnIcon />}
                                        title='Preview'
                                        onClick={onPreviewClick}
                                    />
                                    :
                                    null}
                            </div>
                            <Button
                                loading={isLoading}
                                type='primary'
                                disabled={!editorSections.length}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={onSaveClick}
                            >
                                Save
                            </Button>
                        </aside>
                    </>
                </div>
                <div className='flex mb-12'>
                    <p className='mr-6'>
                        Page URL
                    </p>
                    <InfoTooltipIcon />
                </div>
                <article className='mb-24'>
                    {isLandingLoading || isEventsResLoading ? (
                        <Skeleton active paragraph={false} title={{ width: '300px' }} />
                    ) : (
                        <>
                            <Input
                                style={{ width: '600px' }}
                                addonBefore={`${eventsRes?.data?.subdomain || ''}/`}
                                allowClear
                                value={landingUrl}
                                status={landingErrors?.['url.path'] ? 'error' : ''}
                                onChange={e => {
                                    setLandingErrors({})
                                    setLandingUrl(e.target.value)
                                }}
                            />
                            {landingErrors?.['url.path'] && (
                                <div className='text-regular-14 label my-8 error'>{landingErrors['url.path']}</div>
                            )}
                        </>
                    )}
                </article>
                {(isLandingLoading || isLandingsFetching || isEventsResLoading || isLandingDataLoading) && <Loader />}
                <div className="main-page landing-constructor__page">
                    {/* TOFIX: get header from BE  */}
                    <NonEditableSection html={loadedHeader} />

                    {renderedEditorSections}

                    {!isLandingLoading && !isEventsResLoading && !isLandingDataLoading && !editorSections.length && (
                        <EmptySectionsBlock />
                    )}

                    <NonEditableSection html={loadedFooter} />
                </div>

                {/* MODAL SECTION  */}
                <AddSectionModal />
                {layoutSettingsData && clickedSectionId
                    ?
                    <SectionSettingsDrawer
                        clickedSectionId={clickedSectionId}
                        // clickedSectionId is needed to close the drawer if the section is unclicked
                        isOpen={!!layoutSettingsData}
                        layoutData={layoutSettingsData}
                    />
                    :
                    null
                }

                <RejectionReasonModal
                    isOpen={Boolean(isRejectionModalOpen)}
                    onClose={onCloseRejectionModal}
                    comment={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.comment || ''}
                    reason={(fetchedLandingData?.data as unknown as OrganizerLanding)?.status?.reason || ''}
                />
                <PublishedModal
                    isOpen={Boolean(isPublishedModalOpen)}
                    onClose={onClosePublishedModal}
                />
                <LoaderAi
                    isOpen={isAiLoading}
                    step={aiLoaderStep}
                />
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default LandingPageConstructor
