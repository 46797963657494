import React, { useContext, useState } from 'react';
import styles from './EventInfoForm.module.css';
import { DatePicker, Divider, Form, Input } from "antd";
import AvatarUpload from "./components/AvatarUpload";
import HeroImage from "./components/HeroImage";
import InfoTooltipIcon from "@icons/InfoTooltipIcon.tsx";
import Locations from "./components/Locations";
import Categories from "./components/Categories";
import {
    EventInfoFormStateType
} from "@components/EventInfoForm/types.ts";

import { initEventInfoFormState, initEventInfoFormValues } from './utils';
import dayjs from 'dayjs';
import { EventEditorContext } from '@contexts/forms/event/EventEditorContext';
import { CreateOrganizerEventRequestBody, OrganizerEventDataFullItem, OrganizerEventDataItem } from '@store/type-event/events/models';
import { EventErrorFields } from "@contexts/forms/event/constants.ts";

export type EventInfoFormFooterPropsType = {
    onSubmit: () => void
    isSubmitDisabled: boolean
    isLoading?: boolean
}

type EventInfoFormPropsType = {
    Header?: React.ReactNode
    Footer?: React.FC<EventInfoFormFooterPropsType>
    // onSubmitEvent: (eventData: CreateOrganizerEventRequestBody) => void
    fetchedData?: OrganizerEventDataFullItem;
    onSubmit: (eventData: CreateOrganizerEventRequestBody, isEventInfoStep?: boolean) => void
    isLoading?: boolean;
    isEventInfoStep?: boolean;
}


const EventInfoForm: React.FC<EventInfoFormPropsType> = ({
    Header,
    Footer,
    fetchedData,
    onSubmit,
    isLoading,
    isEventInfoStep
}) => {
    const { errors, resetErrorField } = useContext(EventEditorContext)

    // uses to init the form state
    const initialFormState = initEventInfoFormState(fetchedData)
    const [formState, setFormState] = useState<EventInfoFormStateType>(initialFormState);

    // uses to init the form items initial values. helps to evoid the 'value' implementation to each item
    const initialFormValues = initEventInfoFormValues(fetchedData);

    const onFieldChange = (fieldName: string, value: string | { id: number, slug: string } | string[]) => {
        /* clearing error messages by field name */
        resetErrorField(EventErrorFields[fieldName] || {})

        setFormState(prev => ({ ...prev, [fieldName]: value }));
    };

    const onSubmitClick = () => {
        const payload: CreateOrganizerEventRequestBody = {
            name: formState.eventName,
            schedule: {
                started_at: dayjs(formState.dates[0]).format('YYYY-MM-DD') as unknown as string,
                finished_at: dayjs(formState.dates[1]).format('YYYY-MM-DD') as unknown as string,
            },
            logo: formState.avatarUrl, // url to logo file
            image: formState.heroImageUrl, // url to hero image file
            description: formState.overview,
            url: formState.url,
            location: formState.location.id,
            category: formState.category.id,
            participants: formState.participants,
        }
        onSubmit(payload, isEventInfoStep);
    };

    return (
        <div className={styles.EventInfoForm}>
            {Header || null}
            <Form
                layout={'vertical'}
                initialValues={initialFormValues}>
                <Form.Item
                    name={"avatar"}
                    help={errors.logo}
                    validateStatus={errors.logo ? "error" : ""}
                >
                    <AvatarUpload
                        onChangeFormState={onFieldChange}
                        url={formState.avatarUrl}
                    />
                </Form.Item>
                <Divider />
                <Form.Item
                    name={'eventName'}
                    htmlFor={'EventNameField'}
                    label={<span className={styles.EventInfoForm__inputName}>Event name</span>}
                    help={errors.name}
                    validateStatus={errors.name ? "error" : ""}
                >
                    <Input
                        id={"EventNameField"}
                        size={'large'}
                        onChange={(e) => onFieldChange("eventName", e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name={'dates'}
                    htmlFor={'DatesField'}
                    label={<span className={styles.EventInfoForm__inputName}>Dates</span>}
                    help={errors['schedule.finished_at'] || errors['schedule.started_at'] ? 'The date must be valid.' : ''}
                    validateStatus={errors['schedule.finished_at'] || errors['schedule.started_at'] ? "error" : ""}

                >
                    <DatePicker.RangePicker
                        id={"DatesField"}
                        size={'large'}
                        style={{ width: '100%' }}
                        onChange={(_, info) => onFieldChange("dates", info)}
                    />
                </Form.Item>
                <Form.Item
                    name={"heroImage"}
                    label={
                        <div className={styles.EventInfoForm__heroImageBlock}>
                            <span className={styles.EventInfoForm__inputName}>Hero image</span>
                            <InfoTooltipIcon />
                        </div>
                    }
                    help={errors.image}
                    validateStatus={errors.image ? "error" : ""}
                >
                    <HeroImage
                        onChangeFormState={onFieldChange}
                        url={formState.heroImageUrl}
                    />
                </Form.Item>
                <Form.Item
                    name={'overview'}
                    label={<span className={styles.EventInfoForm__inputName}>Overview</span>}
                    htmlFor={'OverviewField'}
                    help={errors.description}
                    validateStatus={errors.description ? "error" : ""}
                >
                    <Input.TextArea
                        id={"OverviewField"}
                        rows={4}
                        placeholder={'Enter your overview...'}
                        className={styles.EventInfoForm__overview}
                        onChange={(e) => onFieldChange("overview", e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name={'url'}
                    htmlFor={'UrlField'}
                    label={<span className={styles.EventInfoForm__inputName}>URL</span>}
                    help={errors.url}
                    validateStatus={errors.url ? "error" : ""}
                >
                    <Input
                        id={"UrlField"}
                        size={'large'}
                        type={'url'}
                        onChange={(e) => onFieldChange("url", e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name={'location'}
                    htmlFor={'LocationField'}
                    label={<span className={styles.EventInfoForm__inputName}>Location</span>}
                    help={errors.location}
                    validateStatus={errors.location ? "error" : ""}
                >
                    <Locations
                        className={styles.EventInfoForm__Location}
                        initialPlaceholder={formState.location?.slug}
                        onLocationChange={onFieldChange}
                    />
                </Form.Item>
                <Form.Item
                    label={<span className={styles.EventInfoForm__inputName}>Category</span>}
                    help={errors.category}
                    validateStatus={errors.category ? "error" : ""}

                >
                    <Categories
                        onCategoryChange={onFieldChange}
                        initialPlaceholder={formState.category?.slug}
                    />
                </Form.Item>
                <Form.Item
                    name={'participants'}
                    htmlFor={'ParticipantsField'}
                    label={<span className={styles.EventInfoForm__inputName}>Number of participants</span>}
                    help={errors.participants}
                    validateStatus={errors.participants ? "error" : ""}

                >
                    <Input
                        id={"ParticipantsField"}
                        size={'large'}
                        type={'number'}
                        onChange={(e) => onFieldChange("participants", e.target.value)}
                    />
                </Form.Item>
            </Form>
            {Footer ? (
                <Footer
                    isSubmitDisabled={false}
                    onSubmit={onSubmitClick}
                    isLoading={isLoading}
                />
            ) : null}
        </div>
    )
};

export default EventInfoForm;
