import { CampaignEditorBody } from '@store/type-partner/campaigns/models';
import dayjs from 'dayjs';

export const campaignInitialAds = [
    {
        landing: '',
        assets: {
            business_name: [
                {
                    value: ''
                }
            ],
            title: [
                {
                    value: ''
                }
            ],
            short_title: [
                {
                    value: ''
                }
            ],
            description: [
                {
                    value: ''
                }
            ],
            image_original: [
            ],
            logo_original: [
            ]
        }
    }
]

export const campaignInitialForm: CampaignEditorBody = {
    title: "",
    goal: 0,
    schedule: {
        started_at: dayjs().format('YYYY-MM-DD'),
        finished_at: ''
    },
    channels: [
        {
            title: '',
            id: null,
            type: 1,
            bid: 1.00,
            budget: 100,
            status: 1
        }
    ],
    ads: campaignInitialAds
};

export const BUSINESS_NAME_MAX_COUNT = 25
export const TITLE_MAX_COUNT = 90
export const SHORT_TITLE_MAX_COUNT = 30
export const DESCRIPTION_MAX_COUNT = 90
