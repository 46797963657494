import ContentLayout from '@layouts/ContentLayout'
import EventsPageLayout from '@layouts/EventsPageLayout'

const PartnersPage = () => {
    return (
        <EventsPageLayout>
            <ContentLayout>
                <div>PartnersPage</div>
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default PartnersPage