export enum EventsTourSteps {
    eventCard,
    landingPages,
    myCampaigns,
    menuItemCampaigns,
    menuItemLeads,
    menuItemAnalytics,
    menuItemBoostPro,
}

export enum CampaignsTourSteps {
    campaignGoal,
    audience,
    schedule,
    adPlatform,
    adCreatives,
    landingPage,
}