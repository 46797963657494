import React, {useCallback, useContext, useMemo} from 'react';
import HtmlEditor
    from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/Editor.tsx";
import { PlusOutlined } from "@ant-design/icons";
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";
import {
    JoditOptionName
} from "@pages/PartnerRolePages/LandingPageConstructor/components/LandingPageWYSIWYGconstructor/assets/models.tsx";
import { LandingEditorContext } from "@contexts/LandingEditorContext.tsx";
import cn from 'classnames';

type Props = {
    section: { id?: string | undefined, layout: PartnerLandingSectionLayout }
    idx: number
    onOptionClick: ({ option, id }: {option: JoditOptionName, id: string}) => void
    tooltipVisible: boolean
    isLoading: boolean
}

const SectionComponent: React.FC<Props> = React.memo(({
    section,
    idx,
    tooltipVisible,
    onOptionClick,
    isLoading
}) => {
    const {
        setClickedSectionId,
        setSelectedSectionIndex,
        setIsAddSectionModalOpen,
    } = useContext(LandingEditorContext);

    const layout = useMemo(() => section.layout, [section.layout])

    const handleSectionClick = useCallback(() => {
        setClickedSectionId(section.id)
    }, [section?.id, setClickedSectionId])

    return (
        <section
            className={cn('html-editor cursor-pointer', isLoading && 'html-editor-loading')}
            onClick={handleSectionClick}
        >
            <HtmlEditor
                id={section.id!}
                tooltipVisible={tooltipVisible}
                layout={layout}
                onCustomOptionClick={onOptionClick}
            />
            <button
                className='landing-editor-page__add-section-cta'
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation()
                    setClickedSectionId('')
                    setIsAddSectionModalOpen(true)
                    setSelectedSectionIndex(idx + 1)
                }}
            >
                <PlusOutlined />
                <span className="landing-editor-page__add-section-text">Add Section</span>
            </button>
        </section>
    )
})

export default SectionComponent
