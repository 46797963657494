import { RcFile } from 'antd/lib/upload';
import { ImageTypes } from '@shared/types';

export const getImageType = (fileUrl: RcFile, isFile = true): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.width / img.height;
            let type;
            if (aspectRatio === 1) {
                type = ImageTypes.square;
            } else if (aspectRatio > 1) {
                type = ImageTypes.horizontal;
            } else {
                type = ImageTypes.vertical;
            }
            resolve(type);
        };
        img.onerror = reject;
        img.src = isFile ? URL.createObjectURL(fileUrl) : fileUrl;
    });
};

export const getImageTypes = async (images: { value: string }[]) => {
    const mappedImages = images.map(({ value }) => value)
    const promises = mappedImages.map(image => getImageType(image, false));
    try {
        const types = await Promise.all(promises);
        const newImageTypeMap: { [url: string]: string } = {};

        images.forEach((image, index) => {
            newImageTypeMap[image.value] = types[index];
        });

        return newImageTypeMap
    } catch (error) {
        return []
    }
};

export const getCheckTypeColor = ({ imagesTypes, type }: { imagesTypes: Record<string, string>, type: ImageTypes }) => {
    return Object.values(imagesTypes).some((item) => item === type) ? '#57585A' : '#D9DADC'
}
