import React, { useState, useMemo } from 'react'

import { Spin } from 'antd';

import { useGetCountriesBySearchQuery } from '@store/main/countries/countries.api';
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";

interface SuggestSelectCountriesProps {
    value: {
        id: number;
        slug: string;
    } | null;
    onChange?: (value: {
        id: number;
        slug: string;
    } | null) => void;
    placeholder: string;
    disabled?: boolean
}

const SuggestSelectCountries: React.FC<SuggestSelectCountriesProps> = ({
    value,
    onChange,
    placeholder,
    disabled
}) => {
    const [countrySearchValue, setCountrySearchValue] = useState<string>()
    const [page, setPage] = useState(INIT_CURRENT_PAGE)

    const { data: countries, isLoading: countriesLoading, isFetching: countriesFetching } = useGetCountriesBySearchQuery({ search: countrySearchValue || '', page })

    const handleChange = (newOption: {
        value: number;
        label: string;
    }) => {
        const newValue = newOption?.value ? {
            id: newOption.value,
            slug: newOption.label,
        } : null
        onChange && onChange(newValue)
        setCountrySearchValue(undefined)
    }

    const currentValue = useMemo(() => {
        if (!value) {
            return null
        }

        const { slug, id } = value || {}
        return { value: id, label: slug }
    }, [value])

    return (
        <InfiniteSelect
            id="suggest-select-countries"
            placeholder={placeholder}
            style={{ width: '100%' }}
            filterOption={false}
            notFoundContent={countriesLoading || countriesFetching ? <Spin size="small"/> : <div>There is no countries</div>}
            isLoading={countriesLoading || countriesFetching}
            listHeight={160}
            onChange={(_, newOption) => {
                handleChange(newOption as {
                    value: number;
                    label: string;
                })
            }}
            page={page}
            setPage={setPage}
            data={countries}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={currentValue}
            allowClear
            showSearch
            setSearchValue={(val) => {
                setCountrySearchValue(val)
            }}
            optionsMapper={({ slug, id, ...rest }) => ({ value: id.toString(), label: slug, slug, id, ...rest })}
            disabled={disabled}
        />
    )
}

export default SuggestSelectCountries