import ExitCrossIcon from "@assets/ExitCrossIcon.tsx";
import ChatIcon from "@assets/ChatIcon.tsx";
import React, { useEffect, useState } from "react";
import {
    isHubSpotScriptLoaded, isMobileDevice,
    toggleHubspotChat,
} from "@components/HubSpotButton/helpers.ts";
import { useAuth } from "@contexts/AuthContext.tsx";

const HubSpotButton = () => {
    const [isHubspotLoaded, setIsHubspotLoaded] = useState(false);
    const { isHubspotChatOpen, setIsHubspotChatOpen } = useAuth()
    const isMobile = isMobileDevice()

    const toggleChat = () => {
        if (isHubSpotScriptLoaded()) {
            const isOpenClass = document.querySelector('.hs-messages-widget-open')
            const isOpen = Boolean(isOpenClass || isHubspotChatOpen)
            toggleHubspotChat(isOpen)
            setIsHubspotChatOpen(!isOpen);
        }
    };

    const addHubspotCloseButton = () => {
        const hubspotContainer = document.querySelector('#hubspot-messages-iframe-container')
        const hubspotClose = document.querySelector('.hubspot-close-button')
        if (hubspotContainer && !hubspotClose) {
            const newElement = document.createElement('div');
            newElement.addEventListener('click', function() {
                toggleChat()
            });
            newElement.className = 'hubspot-close-button';
            if (hubspotContainer) {
                hubspotContainer.appendChild(newElement);
            }
        }
    }

    useEffect(() => {
        if (isHubSpotScriptLoaded()) {
            toggleHubspotChat(true)
            setIsHubspotChatOpen(false);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isHubSpotScriptLoaded()) {
                clearInterval(interval);
                addHubspotCloseButton()
                setIsHubspotLoaded(true)
            }
        }, 100);
    }, []);

    return isHubspotLoaded ? (
        <button className={`hubspot-chat-button ${isMobile ? 'hubspot-chat-button__mobile' : ''}`} onClick={toggleChat}>
            <span className="hubspot-chat-text">Help</span>
            {isHubspotChatOpen ? <ExitCrossIcon/> : <ChatIcon/>}
        </button>
    ) : null
}

export default HubSpotButton
