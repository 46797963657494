
import SkeletonCustom from '@components/SkeletonCustom'
import '../styles.scss'
const LPCard = () => {
    return (
        <div className='my-events-edit-zero-state__lp-card'>
            <div className='my-events-edit-zero-state__lp-card-img'>
                <SkeletonCustom
                    type='image'
                />
            </div>
            <aside>
                <SkeletonCustom
                    type='paragraph'
                />
                <SkeletonCustom
                    type='paragraph-m'
                />
                <div className='flex justify-space-between'>
                    <SkeletonCustom
                        type='button'
                    />
                    <SkeletonCustom
                        type='button-l'
                    />
                </div>
            </aside>
        </div>
    )
}

export default LPCard