// https://boost.allnetru.dev/api/documentation/partner#/Events
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    CreateOrganizerEventRequestBody,
    GetEventCheckListByEventIdResponse,
    GetEventSetupTrackResponse,
    GetOrganizerEventByIdResponse,
    GetOrganizerEventsFilterResponse,
    GetOrganizerEventsResponse,
    GetOrganizerUserInvitesByEventIdResponse,
    PatchOrganizerEventByIdResponse,
    PatchOrganizerEventStatusByIdResponse,
    PatchOrganizerEventSubdomainResponse,
    PostCreateOrganizerEventResponse,
    Subdomain
} from './models';
import { useApiReq } from "@hooks/useApiRequest.ts";
import { LeadsRequest } from "@store/models-to replace/leads";
import { getQueryStringFromParams } from "@shared/utils";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organizer_events = `organizer_events`;

export const organizerEvents = createApi({
    reducerPath: organizer_events,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organizer_events
    ],
    endpoints: build => ({

        getOrganizerEvents: build.query<GetOrganizerEventsResponse, {
            filters?: {
                search: string;
                date?: {
                    from: string;
                    to: string;
                };
                statuses?: string[];
            } | null;
            page?: number;
        }>({
            query: ({ filters, page }) => {
                const params = new URLSearchParams();

                if (Array.isArray(filters?.statuses)) {
                    filters.statuses.forEach(id => {
                        params.append('filters[statuses][]', id.toString());
                    });
                }

                if (filters?.search) {
                    params.append('filters[search]', filters.search);
                }

                if (page) {
                    params.append('page', page.toString());
                }

                if (filters?.date?.from) {
                    params.append('filters[date][from]', `${filters.date.from}`);
                }

                if (filters?.date?.to) {
                    params.append('filters[date][to]', `${filters.date.to}`);
                }

                const queryString = params.toString();

                return {
                    url: `organizer/events${queryString ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                };
            },
            providesTags: [organizer_events],
        }),

        postCreateOrganizerEvent: build.mutation<PostCreateOrganizerEventResponse, CreateOrganizerEventRequestBody>({
            query: (body) => {
                return {
                    body,
                    method: 'POST',
                    url: '/organizer/events',
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            invalidatesTags: [organizer_events],
        }),
        getOrganizerEventById: build.query<GetOrganizerEventByIdResponse, { eventId: string }>({
            query: ({ eventId }) => ({
                url: `/organizer/events/${eventId}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [organizer_events],
        }),
        getEventCheckListByEventId: build.query<GetEventCheckListByEventIdResponse, { eventId: string }>({
            query: ({ eventId }) => ({
                url: `/organizer/events/${eventId}/setup/checklist`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [organizer_events],
        }),
        getEventSetupTrack: build.query<GetEventSetupTrackResponse, { eventId: string }>({
            query: ({ eventId }) => ({
                url: `/organizer/events/${eventId}/setup/track`,
                headers: {
                    Accept: 'application/json',
                },
            }),
            providesTags: [organizer_events],
        }),

        postEventSetupTrackToEmail: build.mutation<GetEventSetupTrackResponse, { eventId: string, email: string, snippet: string }>({
            query: ({ eventId, email, snippet, }) => ({
                url: `/organizer/events/${eventId}/setup/track/send-manual`,
                headers: {
                    Accept: 'application/json',
                },
                method: 'POST',
                body: {
                    email,
                    snippet
                },
                providesTags: [organizer_events],
            }),
        }),
        patchOrganizerEventById: build.mutation<PatchOrganizerEventByIdResponse, { eventId: string, event: CreateOrganizerEventRequestBody }>({
            query: ({ event, eventId }) => ({
                url: `/organizer/events/${eventId}`,
                method: 'PATCH',
                body: event,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [organizer_events],
            }),
        }),
        patchOrganizerEventStatusById: build.mutation<PatchOrganizerEventStatusByIdResponse, { eventId: string, status: string }>({
            query: ({ eventId, status }) => ({
                url: `/organizer/events/${eventId}/status/${Number(status)}`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                providesTags: [organizer_events],
            }),
        }),

        // INVITATIONS
        getOrganizerUserInvitesByEventId: build.query<GetOrganizerUserInvitesByEventIdResponse, { eventId: string, page?: number, search?: string, filters: { tags: string[], statuses: string[] } }>({
            query: ({ eventId, page, filters }) => {
                const partnersQueryString = getQueryStringFromParams(filters?.statuses, 'statuses')
                const eventsQueryString = getQueryStringFromParams(filters?.tags, 'tags')
                const pageQueryString = page ? `page=${page}` : ''

                const queryString = [
                    partnersQueryString,
                    eventsQueryString,
                    pageQueryString
                ].filter(Boolean).join('&');

                return {
                    url: `organizer/events/${eventId}/users-invites${queryString.length ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            providesTags: [organizer_events],
        }),
        postCreateOrganizerEventInvitations: build.mutation<void, { count: number, eventId: string }>({
            query: ({ count, eventId }) => {
                return {
                    body: { count },
                    method: 'POST',
                    url: `/organizer/events/${eventId}/users-invites`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            invalidatesTags: [organizer_events],
        }),
        patchOrganizerEventInvitationTag: build.mutation<void, { tag: string, eventId: string, ids: string[] }>({
            query: ({ tag, ids, eventId }) => {
                return {
                    body: { tag, ids },
                    method: 'PATCH',
                    url: `/organizer/events/${eventId}/users-invites/tag`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            invalidatesTags: [organizer_events],
        }),

        //SUBDOMAIN
        getOrganizerEventSubdomainById: build.query<PatchOrganizerEventSubdomainResponse, { eventId: string }>({
            query: ({ eventId }) => ({
                url: `/organizer/events/${eventId}/setup/subdomain`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [organizer_events],
        }),
        patchOrganizerEventSubdomain: build.mutation<Subdomain, { subdomain: string, eventId: string }>({
            query: ({ subdomain, eventId }) => ({
                url: `/organizer/events/${eventId}/setup/subdomain`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: {
                    subdomain
                },
                providesTags: [organizer_events],
            }),
        }),

        //FILTERS
        getOrganizerEventsFilterOptions: build.query<GetOrganizerEventsFilterResponse, { search?: string, page?: number }>({
            query: ({ search, page }) => {
                const params = new URLSearchParams();
                if (search && search.length) {
                    params.append('search', search);
                }

                if (page) {
                    params.append('page', page.toString());
                }
                const queryString = params.toString();
                return {
                    url: `/organizer/events/filter${queryString ? `?${queryString}` : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    }
                }
            },
            providesTags: [organizer_events],
        }),
    }),
});

export const useGetInvitationsExported = (eventId: string) => {
    return useApiReq<never, LeadsRequest>(
        {
            reqConfig: {
                method: 'GET',
                url: `${apiBaseUrl}/organizer/events/${eventId}/users-invites/export`,
                headers: {
                    'Content-Type': 'text/csv'
                }
            }
        }
    )
}

export const {
    useGetOrganizerEventsQuery,
    usePostCreateOrganizerEventMutation,
    useGetOrganizerEventByIdQuery,
    usePatchOrganizerEventByIdMutation,
    useGetOrganizerEventsFilterOptionsQuery,
    usePatchOrganizerEventStatusByIdMutation,
    usePatchOrganizerEventInvitationTagMutation,
    useGetEventCheckListByEventIdQuery,
    useGetEventSetupTrackQuery,
    usePostEventSetupTrackToEmailMutation,

    useGetOrganizerUserInvitesByEventIdQuery,
    usePostCreateOrganizerEventInvitationsMutation,

    useGetOrganizerEventSubdomainByIdQuery,
    usePatchOrganizerEventSubdomainMutation
} = organizerEvents;
