import React from 'react';
import styles from './EventInfoFormFooter.module.css';
import {EventInfoFormFooterPropsType} from "@components/EventInfoForm";
import {Button} from "antd";

const EventInfoFormFooter: React.FC<EventInfoFormFooterPropsType> = (props) => {
    const {onSubmit, isSubmitDisabled, isLoading} = props;

    return (
        <div className={styles.EventInfoFormFooter}>
            <div className={styles.EventInfoFormFooter__content}>
                <Button
                    disabled={isSubmitDisabled}
                    onClick={onSubmit}
                    type={'primary'}
                    size={'large'}
                    className={styles.EventInfoFormFooter__submitButton}
                    loading={isLoading}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
}

export default EventInfoFormFooter;