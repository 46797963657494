import './styles.scss';
import ContentLayout from '@layouts/ContentLayout'
import CustomButton from '@components/CustomButton'
import { DatePicker } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import OrganizerEventsCard from './components/EventCard';
import { Link } from 'react-router-dom';
import MyEventsSkeletonZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState';
import { useGetOrganizerEventsQuery } from '@store/type-event/events/events.api';
import ZeroTableIcon from "@assets/zerostate/ZeroTableIcon";
import { useAuth } from "@contexts/AuthContext";
import EventsCardsFilter from './components/EventsCardsFilter';
import { useBreakpoints } from '@hooks/browser';
import EventsCardsFilterMobile from './components/mobile/EventsCardsFilterMobile';
import EventsPageLayout from '@layouts/EventsPageLayout';
import { OrganizerEventDataItem } from '@store/type-event/events/models';
import MyEventsSkeletonMobileZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCardsPage/ZeroState/SkeletonMobile';
import EventsTable from './components/EventsTable';
import RadioTable from './components/RadioTable';

const { RangePicker } = DatePicker;

// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (fn: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            fn(...args);
        }, delay);
    };
};
export interface MyEventsFilters {
    search: string;
    date: {
        from: string,
        to: string
    };
    statuses: string[]

}
const initialFilters: MyEventsFilters = {
    search: '',
    date: {
        from: '',
        to: ''
    },
    statuses: []
}

const MyEventsCardsPage = () => {
    const isDesktop = useBreakpoints().md

    const [filters, setFilters] = useState<MyEventsFilters>(initialFilters)
    const [searchValue, setSearchValue] = useState('')
    const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
    const [eventsList, setEventsList] = useState<OrganizerEventDataItem[]>([])
    const [isLoading, setIsloading] = useState(true)
    const [isTableView, setTableView] = useState(false)

    const { data, refetch: refetchEvents } = useGetOrganizerEventsQuery({ filters })
    const { user } = useAuth()

    const onResetFilters = () => {
        setFilters(initialFilters)
        onFilterUpdate(initialFilters)
        setSearchValue('')
    }

    const onFilterUpdate = (value: Partial<MyEventsFilters>) => {
        setFilters((prev) => ({ ...prev, ...value }));
    };

    const debouncedOnSearchUpdate = useCallback(
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            onFilterUpdate({ search: e.target.value });
        }, 500),
        [onFilterUpdate]
    );

    useEffect(() => {
        // Check if filters are not equal to initialFilters
        const filtersAreEqual =
            filters.search === initialFilters.search &&
            filters.date.from === initialFilters.date.from &&
            filters.date.to === initialFilters.date.to &&
            JSON.stringify(filters.statuses) === JSON.stringify(initialFilters.statuses);

        setFiltersChanged(!filtersAreEqual);
    }, [filters]);

    useEffect(() => {
        if (data?.data) {
            setEventsList(data.data)
        }
    }, [data])

    useEffect(() => {
        setIsloading(true)
        void refetchEvents()
            .then(res => {
                setEventsList(res?.data?.data || [])
            })
            .finally(() => setIsloading(false))
    }, [])

    if (isLoading) {
        return (
            <EventsPageLayout>
                <ContentLayout>
                    {isDesktop
                        ?
                        <MyEventsSkeletonZeroState />
                        :
                        <MyEventsSkeletonMobileZeroState />
                    }
                </ContentLayout>
            </EventsPageLayout>
        )
    }

    if (!isLoading && !eventsList.length && !filtersChanged) {
        return (
            <EventsPageLayout>
                <ContentLayout>
                    <div
                        className='my-events-cards-page__no-data'
                    >
                        <ZeroTableIcon />
                        {user?.name && (
                            <p className='my-events-cards-page__no-data-greet'>
                                Hi,
                                {user.name}
                                !
                            </p>
                        )}
                        <p className='my-events-cards-page__no-data-desc'>
                            There are no events on your desk yet. Please explore the event catalog to find something interesting
                        </p>

                        <Link to='setup' className='no-style'>
                            <CustomButton
                                type='primary'
                                size='large'
                                title='Explore events'
                            />
                        </Link>
                    </div>
                </ContentLayout>
            </EventsPageLayout>
        )
    }

    return (
        <EventsPageLayout>
            <ContentLayout>
                {isDesktop
                    ?
                    <div className='flex-center justify-space-between  mb-24'>
                        {/* EventsCardsFilter */}
                        <EventsCardsFilter
                            filters={filters}
                            statuses={[]}
                            searchValue={searchValue}
                            onFilterUpdate={onFilterUpdate}
                            onResetFilters={onResetFilters}
                            debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                        />
                        {isDesktop
                            ?
                            <div className='flex items-center'>
                                <div className='mx-8'>
                                    <RadioTable
                                        isTableView={isTableView}
                                        setTableView={setTableView}
                                    />
                                </div>
                                <Link to='setup' className='no-style'>
                                    <CustomButton
                                        title='+Add event'
                                        type='primary'
                                    />
                                </Link>
                            </div>
                            :
                            null}
                    </div>
                    :
                    <EventsCardsFilterMobile
                        filters={filters}
                        // statuses={statuses?.data}
                        setTableView={setTableView}
                        isTableView={isTableView}
                        searchValue={searchValue}
                        onFilterUpdate={onFilterUpdate}
                        onResetFilters={onResetFilters}
                        debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                    />
                }

                {isTableView
                    ?
                    <div className='my-events-cards-page__table'>
                        <EventsTable
                            events={eventsList}
                        />
                    </div>
                    :
                    <div
                        className='my-events-cards-page flex'
                    >
                        {
                            eventsList && Array.isArray(eventsList) && eventsList.map((event, idx: number) => {
                                return (
                                    <OrganizerEventsCard
                                        key={idx}
                                        event={event}
                                    />
                                );
                            })
                        }
                    </div>
                }
            </ContentLayout>
        </EventsPageLayout>

    )
}

export default MyEventsCardsPage
