import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LandingFaqState {
    activeFaqKeys: string[];
}

const initialState: LandingFaqState = {
    activeFaqKeys: ['1']
};

export const partnerLandingsSlice = createSlice({
    name: 'partner_landings',
    initialState,
    reducers: {
        setActiveFaqKeys: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.activeFaqKeys = action.payload;
        },
    },
});

export const partnerLandingsActions = partnerLandingsSlice.actions;
export const partnerLandingsReducer = partnerLandingsSlice.reducer;

export default partnerLandingsSlice.reducer;