import { createContext, useState, ReactNode, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { BillingErrorsValues } from '@components/BillingForm/models';
import { initialBillingErrorsValues, initialBillingValues } from '@components/BillingForm/constants';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import { usePatchBillingInfoMutation } from '@store/main/billing/billing.api';
import { PatchBillingInfoBodyRequest } from '@store/main/billing/models';
import { IntentResponse } from '@store/main/payment/models';
import { userActions } from "@store/main/-user/user.slice";
import { useDispatch } from "react-redux";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

type SectionType = "auto_payments" | "add_payment_method" | "payment_method" | "billing_info" | "enable_pro" | null;

interface UserBillingPageContextType {
    paymentIdToEdit: string;
    setPaymentIdToEdit: (id: string) => void;
    sectionType: SectionType;
    setSectionType: (type: SectionType) => void;
    close: () => void;
    validationErrors: BillingErrorsValues;
    setValidationErrors: (errors: BillingErrorsValues) => void;
    isLoading: boolean;
    setLoading: (isLoading: boolean) => void;
    form: PatchBillingInfoBodyRequest,
    setForm: (values: PatchBillingInfoBodyRequest) => void;
    onFormChange: (values: Partial<PatchBillingInfoBodyRequest>) => void;
    onBillingInfoUpdate: () => void;

    stripeData: IntentResponse | null;
    setStripeData: (stripeData: IntentResponse | null) => void;

}

export const UserBillingPageContext = createContext<UserBillingPageContextType>({
    paymentIdToEdit: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setPaymentIdToEdit: () => { },
    sectionType: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSectionType: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    close: () => { },

    validationErrors: initialBillingErrorsValues,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setValidationErrors: () => { },
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading: () => { },
    form: initialBillingValues,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setForm: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onFormChange: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBillingInfoUpdate: () => { },

    stripeData: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStripeData: () => { },


});

interface UserBillingPageProviderProps {
    children: ReactNode;
}

export const UserBillingPageProvider: React.FC<UserBillingPageProviderProps> = ({ children }) => {
    const { user } = useAuth()
    const [patchBillingInfo] = usePatchBillingInfoMutation()
    const dispatch = useDispatch()

    //DRAWER OPEN STATE
    const [sectionType, setSectionType] = useState<SectionType>(null);
    const [paymentIdToEdit, setPaymentIdToEdit] = useState('');
    //

    //FORM STATE
    const [form, setForm] = useState<PatchBillingInfoBodyRequest>(initialBillingValues)
    const [validationErrors, setValidationErrors] = useState<BillingErrorsValues>(initialBillingErrorsValues as BillingErrorsValues)
    const [isLoading, setLoading] = useState(false)
    const [stripeData, setStripeData] = useState<IntentResponse | null>(null)

    const onFormChange = (value: Partial<PatchBillingInfoBodyRequest>) => {
        setValidationErrors(initialBillingErrorsValues)
        setForm(prev => ({ ...prev, ...value }))
    }

    const close = () => {
        setSectionType(null)
        setPaymentIdToEdit('')
    }

    const onBillingInfoUpdate = async () => {
        setLoading(true)
        try {
            const response = await patchBillingInfo(form)
            if ('data' in response) {
                sendAnalytics(GTMEventName.addBillingInfo)
                setLoading(false)
                const newUserData = {
                    ...user,
                    name: form.name,
                    email: form.email,
                    organization: {
                        ...user?.organization,
                        billing: {
                            ...user?.organization?.billing,
                            email: form.email,
                            company_name: form.company_name,
                            address: form.address,
                            tax_code: form.tax_code,
                            postal_code: form.postal_code,
                            city: form.city,
                            country: form.country,
                        }
                    }
                }
                dispatch(userActions.patchUserState(newUserData))
                return close()
            }
            if ('error' in response) {
                const errors = errorsToFormAdapter(response as unknown as ErrorDataResponse)
                setValidationErrors(errors as unknown as BillingErrorsValues)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (user) {
            setForm((prev) => ({
                ...prev,
                name: user?.name || '',
                email: user?.organization?.billing?.email || user?.email || '',
                company_name: user?.organization?.billing?.company_name || '',
                address: user?.organization?.billing?.address || '',
                tax_code: user?.organization?.billing?.tax_code || '',
                postal_code: user?.organization?.billing?.postal_code || '',
                city: user?.organization?.billing?.city || null,
                country: user?.organization?.billing?.country || null
            }))
        }
    }, [user])



    return (
        <UserBillingPageContext.Provider value={{
            paymentIdToEdit,
            setPaymentIdToEdit,
            sectionType,
            setSectionType,
            close,
            isLoading,
            setLoading,
            validationErrors,
            setValidationErrors,
            form,
            setForm,
            onFormChange,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onBillingInfoUpdate,
            stripeData,
            setStripeData
        }}>
            {children}
        </UserBillingPageContext.Provider>
    );
};
