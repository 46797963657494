import React, { FC, useEffect, useState, useRef } from 'react';
import Layout700Center from '@layouts/CardLayout';
import { Link, useParams } from 'react-router-dom';
import { Button, Typography, Steps, notification, Spin } from 'antd';
import './styles.scss'
import CopyIcon from '@assets/CopyIcon';
import { useGetEventSetupTrackQuery } from '@store/type-event/events/events.api';
import { STATUSES } from "@pages/EventRolePages/EventsPage/pages/info/constants.ts";
import { CheckCircleSuccessIcon } from "@icons/CheckCircleSuccessIcon.tsx";
import MessageErrorIcon from "@assets/MessageErrorIcon.tsx";
import { HELP_CENTER_LINK } from "@shared/constants.ts";
import SendToEmailModal from "@pages/EventRolePages/EventsPage/pages/info/track-tab/SendToEmailModal.tsx";
import Loader from "@components/Loader";
import { useBreakpoints } from '@hooks/browser';

const CodeSnippet = ({ snippet }: { snippet: string }) => {
    const codeRef = useRef<HTMLPreElement>(null);
    const numbersRef = useRef<HTMLPreElement>(null);

    const addLineNumbers = () => {
        if (codeRef.current && numbersRef.current) {
            const codeElement = codeRef.current;
            const numbersElement = numbersRef.current;

            const computedStyle = window.getComputedStyle(codeElement);
            const lineHeightValue = parseFloat(computedStyle.lineHeight);

            if (lineHeightValue === 0) return;

            const totalHeight = codeElement.clientHeight - 34;
            const numberOfLines = Math.ceil(totalHeight / lineHeightValue);

            if (numberOfLines <= 0) return;

            const content = Array.from({ length: numberOfLines }, (_, index) => index + 1).join('\n');
            numbersElement.innerHTML = `<span>${content}</span>`
        }
    };

    useEffect(() => {
        addLineNumbers();
        window.addEventListener('resize', addLineNumbers);
        return () => window.removeEventListener('resize', addLineNumbers);
    }, [snippet]);

    return (
        <div className="code-container">
            <pre className="line-numbers" ref={numbersRef} />
            <pre className="code-snippet" ref={codeRef}>
                <code>{snippet}</code>
            </pre>
        </div>
    );
};

const TrackTab: FC = () => {
    // ASAP: check TS errors
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isVerifying, setIsVerifying] = useState(false)

    const { eventId } = useParams()
    const isDesktop = useBreakpoints().md

    const { data, isLoading, isFetching, refetch } = useGetEventSetupTrackQuery({ eventId: eventId! || '' }, { skip: !eventId })

    const copyToClipboard = async () => {
        if (data?.data[0]?.snippet) {
            try {
                if (navigator.clipboard) {
                    await navigator.clipboard.writeText(data?.data[0]?.snippet);
                } else {
                    const textarea = document.createElement('textarea');
                    textarea.value = data?.data[0]?.snippet;
                    document.body.appendChild(textarea);
                    textarea.select();
                    document.execCommand('copy');
                    document.body.removeChild(textarea);
                }
            } catch (err) {
                notification.open({
                    message: (
                        <span>
                            Code has not been copied
                        </span>
                    ),
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'error',
                });
            } finally {
                notification.open({
                    message: (
                        <span>
                            The code has been copied to your clipboard
                        </span>
                    ),
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success',
                });
            }
        }
    };

    const onVerify = async () => {
        setIsVerifying(true)
        await refetch()
        setTimeout(() => {
            setIsVerifying(false)
        }, 700)
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }

    if (!data || !data?.data || !data?.data[0].snippet) {
        return (
            <Typography.Title level={4}>
                No Tracking Code Detected
            </Typography.Title>
        )
    }

    return (
        <div className='event-track-tab'>
            <Layout700Center>
                <Typography.Title level={4}>
                    Tracking code installation
                </Typography.Title>

                <Steps
                    direction="vertical"
                    className="mt-16"
                    size="small"
                    current={0}
                    items={[
                        {
                            title: <div className='event-track-tab__first-step'>
                                <p>
                                    Copy this code.
                                </p>

                                <aside className='flex'>
                                    <Button
                                        onClick={() => {
                                            setIsModalOpen(true)
                                        }}
                                    >
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_15723_312707)">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.46067 5.59985C1.74314 5.85577 1.5848 6.80044 2.17997 7.27651L5.26505 9.74467L8.96369 6.04602C9.23706 5.77266 9.68028 5.77266 9.95364 6.04602C10.227 6.31939 10.227 6.76261 9.95364 7.03597L6.255 10.7346L8.7237 13.8202C9.19933 14.4149 10.1439 14.2572 10.4005 13.5393L14.1191 3.12422C14.3954 2.35105 13.6488 1.60418 12.8756 1.88048L2.46067 5.59985ZM4.81897 11.1807L1.30547 8.36978C-0.145222 7.20934 0.239439 4.90593 1.9901 4.28131L12.4045 0.562133C14.2902 -0.111762 16.1113 1.70925 15.4376 3.59497L11.719 14.0101C11.0939 15.7593 8.79144 16.1461 7.63052 14.6948L4.81897 11.1807Z" fill="#898A8C" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_15723_312707">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>

                                        {isDesktop && 'Send code to e-mail'}
                                    </Button>
                                    <Button
                                        className='event-track-tab__first-step__copy-button'
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={copyToClipboard}
                                    >
                                        <CopyIcon />
                                        {isDesktop && 'Copy code'}
                                    </Button>
                                </aside>

                            </div>,
                            description: <CodeSnippet
                                snippet={data?.data[0].snippet}
                            />

                        },
                        {
                            title: <div>
                                Paste the code before the closing
                                {' '}
                                <span className="event-track-tab__body-span">{'</body>'}</span>
                                {' '}
                                tag of each website page where you want to track user behaviour.
                            </div>,
                            description: '',
                        },
                        {
                            title: 'To make sure everything is ready, verify that your code was installed.',
                            description: '',
                        },
                    ]}
                />

                <Button
                    className='ml-32'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onVerify}
                    type="primary"
                    loading={isLoading || isFetching || isVerifying}
                >
                    Verify installation
                </Button>

                {data?.data?.[0]?.track?.hosts.length ? (
                    <div className="event-track-tab__results">
                        <div className="event-track-tab__results__title">Verification results:</div>
                        {isLoading || isFetching || isVerifying ? (
                            <Spin size="small" />
                        ) :
                            (
                                <div className="event-track-tab__results__items">
                                    {data.data[0].track.hosts.map(({ host, status }) => {
                                        return (
                                            <div>
                                                {status.name === STATUSES.Installed ? (
                                                    <CheckCircleSuccessIcon />
                                                ) : (
                                                    <MessageErrorIcon />
                                                )}
                                                <span>{host}</span>
                                                <span className="event-track-tab__results__divider"> | </span>
                                                <div className="event-track-tab__results__status">{status.name === STATUSES.Installed ? status.name : STATUSES.NotFound}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                    </div>
                ) : (
                    <div className="event-track-tab__results mt-12">
                        <div className="event-track-tab__results__title">Verification results:</div>
                        {isLoading || isFetching || isVerifying ? (
                            <Spin size="small" />
                        ) :
                            (
                                <div className="event-track-tab__results__items">
                                    <div>
                                        <MessageErrorIcon />
                                        <div className="event-track-tab__results__status">Tracking code not found</div>
                                    </div>
                                </div>
                            )}
                    </div>
                )}

                <div className="event-track-tab__footer">
                    Need help installing the code?
                    {' '}
                    <Link to={HELP_CENTER_LINK} target="_blank">
                        <span className='text-link'>Get help</span>
                    </Link>
                </div>

                <SendToEmailModal
                    isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false)
                    }}
                    eventId={eventId || ''}
                    snippet={data?.data[0].snippet || ''}
                />
            </Layout700Center>
        </div>
    )
}

export default TrackTab
