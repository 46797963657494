import '../styles.scss';
import { Input, Form, Popover, message } from 'antd';
import CustomButton from '@components/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginUserMutation, usePasswordResetMutation } from '@store/main/-user/user.api';
import { useState } from 'react';
import RegistrationLayout from '@layouts/RegistrationLayout';
import PasswordPopover from '../Register/components/PasswordPopover';
import { useDispatch } from 'react-redux';
import { userActions } from '@store/main/-user/user.slice';

type FieldType = {
    password_confirm?: string;
    password?: string;
    remember?: string;
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: 'Enter a valid email!',
        number: 'Enter a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const PasswordChangeFromRedirectPage = () => {

    const { search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [resetPass] = usePasswordResetMutation();
    const [loginUser] = useLoginUserMutation();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [isSuccessStatus, setIsSuccessStatus] = useState(false);
    const isSuccessStatusHandler = (statusState: boolean) => {
        setIsSuccessStatus(statusState);
    };

    const onFinish = async () => {
        setIsLoading(true);
        const searchParams = new URLSearchParams(search);

        const token = searchParams.get('token') || '';
        let email = searchParams.get('email') || '';
        if (email) {
            email = email.replace(/\s/g, '+'); // Replace spaces with plus signs
            email = email.replace(/_/g, '_');  // Ensure underscores remain as underscores
        }
        try {
            const response = await resetPass({
                token, email, password, password_confirmation: passwordConfirm
            });

            if ('error' in response) {
                void message.open({
                    type: 'error',
                    content: 'Password update error',
                });
                setIsLoading(false);
                return;
            } else {
                const loginResponse = await loginUser({ email, password });
                if ('data' in loginResponse) {
                    dispatch(userActions.setUserState(loginResponse.data.data));
                    navigate('/');
                } else {
                    void message.open({
                        type: 'error',
                        content: 'Login error',
                    });
                }
            }
        } catch (error) {
            void message.open({
                type: 'error',
                content: 'Password update error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <RegistrationLayout>
            <div className='auth'>
                <main className='auth__form'>
                    <p className='text-title'>
                        Create a new password
                    </p>
                    <Form
                        name="basic"
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        validateMessages={validateMessages}
                    >
                        <Popover
                            content={() => (
                                <PasswordPopover
                                    password={password}
                                    isSuccessStatusHandler={isSuccessStatusHandler}
                                />
                            )}
                            placement="bottomLeft"
                            trigger={'focus'}
                        >
                            <Form.Item<FieldType>
                                label="Password"
                                name="password"
                            >
                                <Input.Password
                                    onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
                                />
                            </Form.Item>
                        </Popover>
                        <Form.Item<FieldType>
                            label="Confirm password"
                            name="password_confirm"
                        >
                            <Input.Password
                                onChange={(e) => setPasswordConfirm((e.target as HTMLInputElement).value)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <CustomButton
                                title='Recover password'
                                htmlType="submit"
                                classOptional='w-full'
                                type='primary'
                                size='large'
                                loading={isLoading}
                                disabled={password.length < 8 || password !== passwordConfirm}
                            />
                        </Form.Item>
                    </Form>
                </main>
            </div>
        </RegistrationLayout>
    );
}

export default PasswordChangeFromRedirectPage;
