import React, { ReactNode } from 'react';
import { Drawer, Button, DrawerProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useSwipeDown from '@hooks/useSwipeDown';
import './styles.scss';

interface MobileDrawerProps {
    title: string;
    isOpen: boolean;
    onClose: () => void;
    onOk: () => void;
    onCancel: () => void;
    applyButtonText?: string;
    resetButtonText?: string;
    children: ReactNode;
    isLoading?: boolean
    propClassNames?: DrawerProps['classNames']
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({
    title,
    isOpen,
    onClose,
    onOk,
    onCancel,
    applyButtonText = 'Ok',
    resetButtonText = 'Cancel',
    children,
    isLoading,
    propClassNames
}) => {
    const { onTouchStart, onTouchEnd } = useSwipeDown(onClose);

    return (
        <Drawer
            closeIcon={null}
            placement='bottom'
            title={(
                <div
                    onTouchStart={onTouchStart}
                    onTouchEnd={onTouchEnd}
                    className='mobile-drawer__header'
                >
                    <svg width="51" height="4" viewBox="0 0 51 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 2H49.5" stroke="#D9DADC" strokeWidth="3" strokeLinecap="round" />
                    </svg>
                    <div className='mobile-drawer__header-title'>
                        <div />
                        <span className='mobile-drawer__header-title-text'>{title}</span>
                        <CloseOutlined onClick={onClose} />
                    </div>
                </div>
            )}
            onClose={onClose}
            open={isOpen}
            className='mobile-drawer'
            footer={(
                <footer className='mobile-drawer__footer'>
                    <Button
                        size='large'
                        block
                        style={{ marginRight: '6px' }}
                        onClick={onCancel}
                    >
                        {resetButtonText}
                    </Button>
                    <Button
                        block
                        type='primary'
                        size='large'
                        onClick={onOk}
                        loading={isLoading}
                    >
                        {applyButtonText}
                    </Button>
                </footer>
            )}
            classNames={propClassNames}
        >
            <div
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                className='flex flex-col drawer-filter-content'
            >
                {children}
            </div>
        </Drawer>
    );
};

export default MobileDrawer;
