
const AnalyticsMobileIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.7708 21.0208H4.0625V3.14579C4.0625 3.01975 3.95938 2.91663 3.83333 2.91663H2.22917C2.10312 2.91663 2 3.01975 2 3.14579V22.8541C2 22.9802 2.10312 23.0833 2.22917 23.0833H23.7708C23.8969 23.0833 24 22.9802 24 22.8541V21.25C24 21.1239 23.8969 21.0208 23.7708 21.0208ZM6.58333 18.7291H8.1875C8.31354 18.7291 8.41667 18.626 8.41667 18.5V14.375C8.41667 14.2489 8.31354 14.1458 8.1875 14.1458H6.58333C6.45729 14.1458 6.35417 14.2489 6.35417 14.375V18.5C6.35417 18.626 6.45729 18.7291 6.58333 18.7291ZM10.9375 18.7291H12.5417C12.6677 18.7291 12.7708 18.626 12.7708 18.5V9.33329C12.7708 9.20725 12.6677 9.10413 12.5417 9.10413H10.9375C10.8115 9.10413 10.7083 9.20725 10.7083 9.33329V18.5C10.7083 18.626 10.8115 18.7291 10.9375 18.7291ZM15.2917 18.7291H16.8958C17.0219 18.7291 17.125 18.626 17.125 18.5V11.5677C17.125 11.4416 17.0219 11.3385 16.8958 11.3385H15.2917C15.1656 11.3385 15.0625 11.4416 15.0625 11.5677V18.5C15.0625 18.626 15.1656 18.7291 15.2917 18.7291ZM19.6458 18.7291H21.25C21.376 18.7291 21.4792 18.626 21.4792 18.5V7.04163C21.4792 6.91558 21.376 6.81246 21.25 6.81246H19.6458C19.5198 6.81246 19.4167 6.91558 19.4167 7.04163V18.5C19.4167 18.626 19.5198 18.7291 19.6458 18.7291Z" fill="#898A8C" />
        </svg>

    )
}

export default AnalyticsMobileIcon