import { Table, Drawer, TablePaginationConfig, message, Button } from "antd";
import type { ColumnsType } from 'antd/es/table';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useGetUserOrganizerBillingWithdrawalsQuery, usePostWithdrawalMutation } from '@store/type-event/billing/billing.api.ts';
import { WithdrawalFiltersType } from "@pages/EventRolePages/Billing/types.ts";
import { initialWithdrawalFilters, ModalSteps, TabNames } from "@pages/EventRolePages/Billing/constants.ts";
import {
    WithdrawalItem
} from "@store/type-event/billing/models.ts";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useBreakpoints } from "@hooks/browser";
import WithdrawalFilters from "@pages/EventRolePages/Billing/components/WithdrawalFilters.tsx";
import { INIT_CURRENT_PAGE, INIT_PAGE_SIZE, INIT_TOTAL } from "@shared/constants.ts";
import { formatDateToCommonFormat, formatByCurrency, ErrorDataResponse, errorsToFormAdapter } from "@shared/utils.ts";
import StatusDescription from "@components/StatusDescription";
import WithdrawalModal from "@pages/EventRolePages/Billing/components/WithdrawalModal.tsx";
import { useParams } from "react-router-dom";
import TotalNumbers from "@pages/EventRolePages/Billing/components/TotalNumbers.tsx";
import emptyWithdrawal from "@assets/png/emptyWithdrawal.png";
import MobileDrawer from "@components/mobile/Drawer";

const WithdrawalTable = () => {
    const isMd = useBreakpoints().md
    const isLg = useBreakpoints().lg

    const { billingId = '' } = useParams()

    const [filters, setFilters] = useState<WithdrawalFiltersType>(initialWithdrawalFilters)
    const [isOpenFilter, setOpenFilter] = useState(false)
    const [pagination, setPagination] = useState({
        pageSize: INIT_PAGE_SIZE,
        total: INIT_TOTAL
    });
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isWithdrawalSending, setIsWithdrawalSending] = useState(false)
    const [withdrawalStep, setWithdrawalStep] = useState(ModalSteps.first)
    const [error, setError] = useState('')

    const { data: withdrawalRes, isLoading: isWithdrawalLoading, isFetching: isWithdrawalFetching, refetch } = useGetUserOrganizerBillingWithdrawalsQuery({ ...filters, billingId }, { skip: !filters?.dateFrom })
    const [postWithdrawal] = usePostWithdrawalMutation()

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setFilters({
            ...initialWithdrawalFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    const columns: ColumnsType = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 300,
            render: (date: WithdrawalItem['date']) => {
                return (
                    <span
                        className="organizer-billing-page__reports__date"
                    >
                        {formatDateToCommonFormat(date?.created_at)}
                    </span>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 300,
            align: 'right',
            render: (amount: WithdrawalItem['amount'], row: WithdrawalItem) => {
                return (
                    <span className="organizer-billing-page__reports__number">
                        {formatByCurrency(amount || 0, row.currency)}
                    </span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 300,
            render: (status: WithdrawalItem['status']) => (
                <StatusDescription status={status?.name} />
            )
        },
    ];

    const handleTableChange = (paginationData: TablePaginationConfig) => {
        setPagination((prev) => ({ ...prev, current: paginationData.current || INIT_CURRENT_PAGE }))
    }

    useEffect(() => {
        setPagination({
            total: withdrawalRes?.meta?.total || INIT_TOTAL,
            pageSize: withdrawalRes?.meta?.per_page || INIT_PAGE_SIZE,
        })
    }, [withdrawalRes]);

    const handleModalSubmit = async (amount: number) => {
        setIsWithdrawalSending(true)
        try {
            const response = await postWithdrawal({
                amount,
                billingId
            });
            if ('error' in response) {
                setIsWithdrawalSending(false)
                const errorsData = errorsToFormAdapter(response as unknown as ErrorDataResponse)
                if (errorsData?.amount) {
                    return setError(errorsData?.amount)
                }
                return message.error((response as ErrorDataResponse)?.error?.data?.message || 'Withdrawal is not sent');
            }
            setWithdrawalStep(ModalSteps.second)
            await refetch()
            return setIsWithdrawalSending(false)
        } catch (errorMsg) {
            setIsWithdrawalSending(false)
            return message.error('Withdrawal is not sent');
        }
    }

    return (
        <>
            <TotalNumbers />
            {isLg ? (
                <div className="organizer-billing-page__title">{TabNames.WithdrawalRequests}</div>
            ) : (
                <div className="organizer-billing-page__revenue-reports-title">
                    <div className="organizer-billing-page__title">{TabNames.WithdrawalRequests}</div>
                    <Button
                        className="organizer-billing-page__create-request-btn"
                        type="primary"
                        onClick={() => setIsModalOpen(true)}
                    >
                        <PlusOutlined />
                        Create request
                    </Button>
                </div>
            )}
            <WithdrawalFilters
                filters={filters}
                setInitialFilters={setInitialFilters}
                setFilters={setFilters}
                setOpenFilter={setOpenFilter}
                onModalOpen={() => setIsModalOpen(true)}
            />
            <div className="mb-24">
                <Table
                    className={`${withdrawalRes?.data?.length ? '' : 'organizer-billing-page__table-empty'}`}
                    dataSource={withdrawalRes?.data}
                    columns={columns}
                    rowKey="id"
                    loading={isWithdrawalLoading || isWithdrawalFetching}
                    pagination={pagination}
                    onChange={handleTableChange}
                    locale={{
                        emptyText: isWithdrawalLoading || isWithdrawalFetching ? ' ' : (
                            <div className='ad-accounts-page__empty-credentials'>
                                <img src={emptyWithdrawal} alt=' emptyWithdrawal' />
                                <span><b>You have no withdrawal requests</b></span>
                                <span>It will be displayed here when you have your first withdrawal requests</span>
                            </div>
                        )
                    }}
                    scroll={{ x: true }}
                />
            </div>
            {isMd ? (
                <Drawer
                    title="Filters"
                    closeIcon={false}
                    placement={isMd ? "right" : 'bottom'}
                    open={isOpenFilter}
                    autoFocus={false}
                    mask={false}
                    onClose={() => setOpenFilter(false)}
                    maskStyle={{ 'opacity': 0, 'top': '62px' }}
                    rootStyle={{ 'top': '62px' }}
                    rootClassName="organizer-billing-page__drawer"
                    contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
                    extra={(
                        <CloseOutlined
                            onClick={() => setOpenFilter(false)}
                        />
                    )}
                >
                    <WithdrawalFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        setOpenFilter={setOpenFilter}
                        onModalOpen={() => setIsModalOpen(true)}
                    />
                </Drawer>
            ) : (
                <MobileDrawer
                    title='Filters'
                    onClose={() => setOpenFilter(false)}
                    onCancel={setInitialFilters}
                    isOpen={isOpenFilter}
                    onOk={() => setOpenFilter(false)}
                    applyButtonText="Apply"
                    resetButtonText="Reset"
                    propClassNames={{
                        footer: 'organizer-billing-page__filter-drawer-footer'
                    }}
                >
                    <WithdrawalFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        setOpenFilter={setOpenFilter}
                        onModalOpen={() => setIsModalOpen(true)}
                    />
                </MobileDrawer>
            )}
            <WithdrawalModal
                error={error}
                setError={setError}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={handleModalSubmit}
                isLoading={isWithdrawalSending}
                withdrawalStep={withdrawalStep}
                setWithdrawalStep={setWithdrawalStep}
            />
        </>
    )
}

export default WithdrawalTable
