import { CampaignEditorContext } from '@contexts/CampaignEditorContext';
import { Channel } from '@store/main/organization/models';
import { InputNumber, Tooltip } from 'antd';
import { useContext } from 'react';
import InfoIcon from "@icons/header/InfoIcon";
import GoogleIcon from "@assets/campaign-settings/GoogleIcon.tsx";
import LinkedinIcon from "@assets/campaign-settings/LinkedinIcon.tsx";

interface ChannelCardProps {
    channel: Channel;
    onChange: (channel: Channel) => void;
}

const cardTitles = {
    1: 'Google Ads',
    2: 'LinkedIn'
}

const cardIcons = {
    1: <GoogleIcon />,
    2: <LinkedinIcon />
}

const ChannelCard = ({
    channel,
    onChange
}: ChannelCardProps) => {
    const {
        formErrors,
    } = useContext(CampaignEditorContext);
    return (
        <div
            key={channel.type}
            className='radio-channel__item'
        >
            <div className="flex gap-8">
                {cardIcons[channel.type]}
                <div>
                    {cardTitles[channel.type]}
                </div>
            </div>
            <div className='radio-channel__item-budget mt-14'>
                <div className='flex'>
                    <span className="radio-channel__item-budget__label">Maximum CPC, €</span>
                    <Tooltip
                        title={`The highest amount that you're willing to pay for a click on your ad`}
                        overlayClassName='tooltip-general tooltip-small'
                        placement='top'
                    >
                        <div>
                            <InfoIcon fillColor='#57585A' width={14} height={14} />
                        </div>
                    </Tooltip>
                </div>
                <InputNumber
                    status={formErrors['channels.0.bid'] ? 'error' : ''}
                    prefix="€"
                    style={{ width: '100%' }}
                    value={channel.bid}
                    parser={(value) => value?.replace(',', '.') as unknown as number}
                    step={'0.05'}
                    max={100}
                    controls
                    onChange={value => onChange({ ...channel, bid: value as number })}
                />
                <div className='flex mt-12'>
                    <span className="radio-channel__item-budget__label">Daily spending limit, €</span>
                    <Tooltip
                        title={`The daily spending limit is the maximum amount you can be billed for a campaign per day`}
                        overlayClassName='tooltip-general tooltip-small'
                        placement='right'
                    >
                        <div>
                            <InfoIcon fillColor='#57585A' width={14} height={14} />
                        </div>
                    </Tooltip>
                </div>
                <InputNumber
                    status={formErrors['channels.0.budget'] ? 'error' : ''}
                    prefix="€"
                    style={{ width: '100%' }}
                    value={channel.budget}
                    step={10}
                    parser={(value) => value?.replace(',', '.') as unknown as number}
                    controls
                    onChange={value => onChange({ ...channel, budget: value as number })}
                />
                <div className='mt-12' />
            </div>
        </div>)
}

export default ChannelCard