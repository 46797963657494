
const AnalyticsIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.7708 21.0209H4.0625V3.14591C4.0625 3.01987 3.95938 2.91675 3.83333 2.91675H2.22917C2.10312 2.91675 2 3.01987 2 3.14591V22.8542C2 22.9803 2.10312 23.0834 2.22917 23.0834H23.7708C23.8969 23.0834 24 22.9803 24 22.8542V21.2501C24 21.124 23.8969 21.0209 23.7708 21.0209ZM6.58333 18.7292H8.1875C8.31354 18.7292 8.41667 18.6261 8.41667 18.5001V14.3751C8.41667 14.249 8.31354 14.1459 8.1875 14.1459H6.58333C6.45729 14.1459 6.35417 14.249 6.35417 14.3751V18.5001C6.35417 18.6261 6.45729 18.7292 6.58333 18.7292ZM10.9375 18.7292H12.5417C12.6677 18.7292 12.7708 18.6261 12.7708 18.5001V9.33341C12.7708 9.20737 12.6677 9.10425 12.5417 9.10425H10.9375C10.8115 9.10425 10.7083 9.20737 10.7083 9.33341V18.5001C10.7083 18.6261 10.8115 18.7292 10.9375 18.7292ZM15.2917 18.7292H16.8958C17.0219 18.7292 17.125 18.6261 17.125 18.5001V11.5678C17.125 11.4417 17.0219 11.3386 16.8958 11.3386H15.2917C15.1656 11.3386 15.0625 11.4417 15.0625 11.5678V18.5001C15.0625 18.6261 15.1656 18.7292 15.2917 18.7292ZM19.6458 18.7292H21.25C21.376 18.7292 21.4792 18.6261 21.4792 18.5001V7.04175C21.4792 6.91571 21.376 6.81258 21.25 6.81258H19.6458C19.5198 6.81258 19.4167 6.91571 19.4167 7.04175V18.5001C19.4167 18.6261 19.5198 18.7292 19.6458 18.7292Z"
                fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
        </svg>
    );
};

export default AnalyticsIcon;
