import AuthMonoLayout from '@layouts/AuthMonoLayout'
import '../styles.scss'
const PasswordRecoverConfirmedPage = ({ email }: { email: string }) => {
    return (
        <AuthMonoLayout>
            <div className='confimed-content'>
                <div className='confimed-content__card'>
                    <div>
                        <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="104" height="104" rx="52" fill="#4E67EA" fillOpacity="0.06" />
                            <path d="M32.6666 32.6667H71.3333C73.9916 32.6667 76.1666 34.8417 76.1666 37.5V66.5C76.1666 69.1583 73.9916 71.3333 71.3333 71.3333H32.6666C30.0083 71.3333 27.8333 69.1583 27.8333 66.5V37.5C27.8333 34.8417 30.0083 32.6667 32.6666 32.6667Z" stroke="#4E67EA" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M76.1666 37.5L51.9999 54.4167L27.8333 37.5" stroke="#4E67EA" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <h1 className='confimed-content__card-title'>
                        Password recovery sent
                    </h1>
                    <p className='confimed-content__card-description'>
                        If {email} matches with an account in our system, you’ll receive an email with password recovery instructions. Check your inbox and click the link to proceed.
                    </p>
                </div>
            </div>
        </AuthMonoLayout>
    )
}

export default PasswordRecoverConfirmedPage