import ZeroLandingsIcon from '@assets/zerostate/ZeroLandingsIcon'
import './styles.scss'
import CustomButton from '@components/CustomButton'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

interface ZeroLandingsProps {
    eventId: string;
    isActive?: boolean;
}
const ZeroLandings = ({
    eventId,
    isActive
}: ZeroLandingsProps) => {
    return (
        <div className='campaign-editor__zero-island'>
            <ZeroLandingsIcon />
            <p className='campaign-editor__zero-island-title'>
                You have no landing pages
            </p>
            <p className='campaign-editor__zero-island-desc'>
                To add one of them, click on the button below
            </p>

            {eventId && isActive
                ?
                <Link to={`/events/${eventId}/landing`} className='no-style'>
                    <CustomButton
                        title='+ Create landing'
                        type='primary'
                    />
                </Link>
                :
                <Tooltip
                    title={'only for status "Active"'}
                >
                    <CustomButton
                        disabled
                        type="primary"
                        title='+ Create landing'
                    />
                </Tooltip>
            }
        </div>
    )
}

export default ZeroLandings