
const DashboardIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.71596 0.142578H0.573103C0.337388 0.142578 0.144531 0.335435 0.144531 0.57115V8.71401C0.144531 8.94972 0.337388 9.14258 0.573103 9.14258H8.71596C8.95168 9.14258 9.14453 8.94972 9.14453 8.71401V0.57115C9.14453 0.335435 8.95168 0.142578 8.71596 0.142578ZM7.3231 7.32115H1.96596V1.96401H7.3231V7.32115ZM19.4302 0.142578H11.2874C11.0517 0.142578 10.8588 0.335435 10.8588 0.57115V8.71401C10.8588 8.94972 11.0517 9.14258 11.2874 9.14258H19.4302C19.666 9.14258 19.8588 8.94972 19.8588 8.71401V0.57115C19.8588 0.335435 19.666 0.142578 19.4302 0.142578ZM18.0374 7.32115H12.6802V1.96401H18.0374V7.32115ZM8.71596 10.8569H0.573103C0.337388 10.8569 0.144531 11.0497 0.144531 11.2854V19.4283C0.144531 19.664 0.337388 19.8569 0.573103 19.8569H8.71596C8.95168 19.8569 9.14453 19.664 9.14453 19.4283V11.2854C9.14453 11.0497 8.95168 10.8569 8.71596 10.8569ZM7.3231 18.0354H1.96596V12.6783H7.3231V18.0354ZM19.4302 10.8569H11.2874C11.0517 10.8569 10.8588 11.0497 10.8588 11.2854V19.4283C10.8588 19.664 11.0517 19.8569 11.2874 19.8569H19.4302C19.666 19.8569 19.8588 19.664 19.8588 19.4283V11.2854C19.8588 11.0497 19.666 10.8569 19.4302 10.8569ZM18.0374 18.0354H12.6802V12.6783H18.0374V18.0354Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
        </svg>

    )
}

export default DashboardIcon