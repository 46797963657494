import React, { useState } from 'react';
import { Input, Select, DatePicker, Drawer, Badge, Button } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { MyEventsFilters } from '@pages/EventRolePages/EventsPage';
import { FilterByEnumItem } from '@store/main/enums/models';
import { statusesFormatted } from '@shared/constants';
import '../styles.scss';
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';
import sendAnalytics from '@hooks/sendAnalytics/sendAnalytics';
import { GTMEventName } from '@hooks/sendAnalytics/constants';
import { useNavigate } from 'react-router-dom';
import useSwipeDown from '@hooks/useSwipeDown';

const { RangePicker } = DatePicker;

interface Props {
    filters: MyEventsFilters;
    statuses: FilterByEnumItem[] | undefined;
    searchValue: string;
    onFilterUpdate: (updatedFilters: Partial<MyEventsFilters>) => void;
    onResetFilters: () => void;
    debouncedOnSearchUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MyEventPageFilterMobile: React.FC<Props> = ({
    filters,
    statuses,
    searchValue,
    onFilterUpdate,
    onResetFilters,
    debouncedOnSearchUpdate,
}) => {
    const navigate = useNavigate();
    const [isShowFilters, setShowFilters] = useState(false);
    const [drawerFilters, setDrawerFilters] = useState<Partial<MyEventsFilters>>(filters)

    const onCloseFilters = () => {
        setShowFilters(false);
    };

    const { onTouchStart, onTouchEnd } = useSwipeDown(onCloseFilters);

    const statusesFormatter = (statuses: FilterByEnumItem[]) => {
        return statuses.map(status => ({
            value: status.id,
            label: statusesFormatted[status.slug as keyof typeof statusesFormatted] || status.slug,
        }));
    };

    const isFilterApplied = filters.statuses.length > 0 || searchValue.length > 3 || filters.date.from || filters.date.to;

    const onDrawerFilterChange = () => {
        onFilterUpdate(drawerFilters)
        setShowFilters(false)
    }

    const onDrawerResetClick = () => {
        onResetFilters();
        setDrawerFilters(filters)
        setShowFilters(false)
    }

    return (
        <>
            <div className='flex items-center justify-space-between'>
                <h3>
                    My events
                </h3>
                <Button
                    type='primary'
                    className="ml-auto"
                    onClick={() => {
                        sendAnalytics(GTMEventName.eventCatalog);
                        navigate('catalog');
                    }}
                >
                    Events catalog
                </Button>
            </div>
            <div className='my-event-page-mobile__filter-container'>
                <Input
                    className='my-event-page-mobile__filter-text'
                    allowClear
                    placeholder="Search by keywords"
                    prefix={<SearchOutlined />}
                    onChange={debouncedOnSearchUpdate}
                />
                <button
                    className='no-style'
                    onClick={() => setShowFilters(true)}
                >
                    <Badge
                        dot={!!isFilterApplied}
                        status="processing"
                    >
                        <FilterButtonMobileIcon
                            isActive={!!isFilterApplied}
                        />
                    </Badge>
                </button>

                <Drawer
                    closeIcon={null}
                    placement='bottom'
                    title={
                        <div
                            className='mobile-drawer__header'
                            onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                        >
                            <svg width="51" height="4" viewBox="0 0 51 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 2H49.5" stroke="#D9DADC" strokeWidth="3" strokeLinecap="round" />
                            </svg>
                            <div className='mobile-drawer__header-title'>
                                <div></div>
                                <span className='mobile-drawer__header-title-text'>Filters</span>
                                <CloseOutlined onClick={onDrawerResetClick} />
                            </div>
                        </div>}
                    onClose={onDrawerResetClick}
                    open={isShowFilters}
                    className='mobile-drawer'
                    footer={
                        <footer className='mobile-drawer__footer flex'>
                            <Button
                                size='large'
                                block
                                style={{ marginRight: '6px' }}
                                onClick={onDrawerResetClick}
                            >
                                Reset
                            </Button>
                            <Button
                                block
                                type='primary'
                                size='large'
                                onClick={onDrawerFilterChange}
                            >
                                Apply
                            </Button>
                        </footer>}
                >
                    <div
                        className="drawer-content"
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                    >
                        <div className='my-event-page-mobile-drawer__filter-range'>
                            <p>Date</p>
                            <div className='flex'>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    value={drawerFilters?.date?.from ? dayjs(drawerFilters.date.from) : null}
                                    className='campaign-create__budget-form-date'
                                    onChange={(_, i) => setDrawerFilters({ date: { from: i as unknown as string, to: drawerFilters.date?.to || '' } })}
                                    placeholder='Finish Date'
                                    allowClear
                                />
                                <DatePicker
                                    format="YYYY-MM-DD"
                                    value={drawerFilters?.date?.to ? dayjs(drawerFilters.date.to) : null}
                                    className='campaign-create__budget-form-date'
                                    onChange={(_, i) => setDrawerFilters({ date: { to: i as unknown as string, from: drawerFilters.date?.from || '' } })}
                                    placeholder='Start Date'
                                    allowClear
                                />
                            </div>
                        </div>
                        {statuses && (
                            <div className='my-event-page-mobile-drawer__filter-status'>
                                <p>Status</p>
                                <Select
                                    style={{ width: '100%' }}
                                    mode="multiple"
                                    allowClear
                                    placeholder="All"
                                    value={drawerFilters.statuses}
                                    onChange={(statuses) => setDrawerFilters({ statuses })}
                                    maxTagCount={1}
                                    options={statusesFormatter(statuses || [])}
                                />
                            </div>
                        )}
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default MyEventPageFilterMobile;
