
const ZeroLandingsIcon = () => {
    return (
        <svg width="210" height="160" viewBox="0 0 210 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4736_64340)">
                <rect width="210" height="160" rx="12" fill="white" />
                <mask id="path-3-inside-1_4736_64340" fill="white">
                    <path d="M10 144H70V170C70 172.209 68.2091 174 66 174H14C11.7909 174 10 172.209 10 170V144Z" />
                </mask>
                <path d="M10 144H70V170C70 172.209 68.2091 174 66 174H14C11.7909 174 10 172.209 10 170V144Z" fill="white" />
                <path d="M10 144H70H10ZM71 170C71 172.761 68.7614 175 66 175H14C11.2386 175 9 172.761 9 170H11C11 171.657 12.3431 173 14 173H66C67.6569 173 69 171.657 69 170H71ZM14 175C11.2386 175 9 172.761 9 170V144H11V170C11 171.657 12.3431 173 14 173V175ZM71 144V170C71 172.761 68.7614 175 66 175V173C67.6569 173 69 171.657 69 170V144H71Z" fill="#EFF0F4" mask="url(#path-3-inside-1_4736_64340)" />
                <rect x="16" y="150" width="20" height="6" rx="3" fill="#EBEDF0" />
                <path d="M10 108C10 105.791 11.7909 104 14 104H66C68.2091 104 70 105.791 70 108V144H10V108Z" fill="#EFF0F4" />
                <mask id="path-7-inside-2_4736_64340" fill="white">
                    <path d="M75 144H135V170C135 172.209 133.209 174 131 174H79C76.7909 174 75 172.209 75 170V144Z" />
                </mask>
                <path d="M75 144H135V170C135 172.209 133.209 174 131 174H79C76.7909 174 75 172.209 75 170V144Z" fill="white" />
                <path d="M75 144H135H75ZM136 170C136 172.761 133.761 175 131 175H79C76.2386 175 74 172.761 74 170H76C76 171.657 77.3431 173 79 173H131C132.657 173 134 171.657 134 170H136ZM79 175C76.2386 175 74 172.761 74 170V144H76V170C76 171.657 77.3431 173 79 173V175ZM136 144V170C136 172.761 133.761 175 131 175V173C132.657 173 134 171.657 134 170V144H136Z" fill="#EFF0F4" mask="url(#path-7-inside-2_4736_64340)" />
                <rect x="81" y="150" width="20" height="6" rx="3" fill="#EBEDF0" />
                <path d="M75 108C75 105.791 76.7909 104 79 104H131C133.209 104 135 105.791 135 108V144H75V108Z" fill="#EFF0F4" />
                <mask id="path-11-inside-3_4736_64340" fill="white">
                    <path d="M140 144H200V170C200 172.209 198.209 174 196 174H144C141.791 174 140 172.209 140 170V144Z" />
                </mask>
                <path d="M140 144H200V170C200 172.209 198.209 174 196 174H144C141.791 174 140 172.209 140 170V144Z" fill="white" />
                <path d="M140 144H200H140ZM201 170C201 172.761 198.761 175 196 175H144C141.239 175 139 172.761 139 170H141C141 171.657 142.343 173 144 173H196C197.657 173 199 171.657 199 170H201ZM144 175C141.239 175 139 172.761 139 170V144H141V170C141 171.657 142.343 173 144 173V175ZM201 144V170C201 172.761 198.761 175 196 175V173C197.657 173 199 171.657 199 170V144H201Z" fill="#EFF0F4" mask="url(#path-11-inside-3_4736_64340)" />
                <rect x="146" y="150" width="20" height="6" rx="3" fill="#EBEDF0" />
                <path d="M140 108C140 105.791 141.791 104 144 104H196C198.209 104 200 105.791 200 108V144H140V108Z" fill="#EFF0F4" />
                <mask id="path-15-inside-4_4736_64340" fill="white">
                    <path d="M10 69.5H70V95.5C70 97.7091 68.2091 99.5 66 99.5H14C11.7909 99.5 10 97.7091 10 95.5V69.5Z" />
                </mask>
                <path d="M10 69.5H70V95.5C70 97.7091 68.2091 99.5 66 99.5H14C11.7909 99.5 10 97.7091 10 95.5V69.5Z" fill="white" />
                <path d="M10 69.5H70H10ZM71 95.5C71 98.2614 68.7614 100.5 66 100.5H14C11.2386 100.5 9 98.2614 9 95.5H11C11 97.1569 12.3431 98.5 14 98.5H66C67.6569 98.5 69 97.1569 69 95.5H71ZM14 100.5C11.2386 100.5 9 98.2614 9 95.5V69.5H11V95.5C11 97.1569 12.3431 98.5 14 98.5V100.5ZM71 69.5V95.5C71 98.2614 68.7614 100.5 66 100.5V98.5C67.6569 98.5 69 97.1569 69 95.5V69.5H71Z" fill="#EFF0F4" mask="url(#path-15-inside-4_4736_64340)" />
                <rect x="16" y="75.5" width="20" height="6" rx="3" fill="#EBEDF0" />
                <rect x="16" y="86.5" width="28.7395" height="6" rx="3" fill="#EBEDF0" />
                <rect x="50" y="86.5" width="14" height="6" rx="3" fill="#EBEDF0" />
                <path d="M10 33.5C10 31.2909 11.7909 29.5 14 29.5H66C68.2091 29.5 70 31.2909 70 33.5V69.5H10V33.5Z" fill="#EFF0F4" />
                <mask id="path-21-inside-5_4736_64340" fill="white">
                    <path d="M75 69.5H135V95.5C135 97.7091 133.209 99.5 131 99.5H79C76.7909 99.5 75 97.7091 75 95.5V69.5Z" />
                </mask>
                <path d="M75 69.5H135V95.5C135 97.7091 133.209 99.5 131 99.5H79C76.7909 99.5 75 97.7091 75 95.5V69.5Z" fill="white" />
                <path d="M75 69.5H135H75ZM136 95.5C136 98.2614 133.761 100.5 131 100.5H79C76.2386 100.5 74 98.2614 74 95.5H76C76 97.1569 77.3431 98.5 79 98.5H131C132.657 98.5 134 97.1569 134 95.5H136ZM79 100.5C76.2386 100.5 74 98.2614 74 95.5V69.5H76V95.5C76 97.1569 77.3431 98.5 79 98.5V100.5ZM136 69.5V95.5C136 98.2614 133.761 100.5 131 100.5V98.5C132.657 98.5 134 97.1569 134 95.5V69.5H136Z" fill="#EFF0F4" mask="url(#path-21-inside-5_4736_64340)" />
                <rect x="81" y="75.5" width="20" height="6" rx="3" fill="#EBEDF0" />
                <rect x="81" y="86.5" width="28.7395" height="6" rx="3" fill="#EBEDF0" />
                <rect x="115" y="86.5" width="14" height="6" rx="3" fill="#EBEDF0" />
                <path d="M75 33.5C75 31.2909 76.7909 29.5 79 29.5H131C133.209 29.5 135 31.2909 135 33.5V69.5H75V33.5Z" fill="#EFF0F4" />
                <mask id="path-27-inside-6_4736_64340" fill="white">
                    <path d="M140 69.5H200V95.5C200 97.7091 198.209 99.5 196 99.5H144C141.791 99.5 140 97.7091 140 95.5V69.5Z" />
                </mask>
                <path d="M140 69.5H200V95.5C200 97.7091 198.209 99.5 196 99.5H144C141.791 99.5 140 97.7091 140 95.5V69.5Z" fill="white" />
                <path d="M140 69.5H200H140ZM201 95.5C201 98.2614 198.761 100.5 196 100.5H144C141.239 100.5 139 98.2614 139 95.5H141C141 97.1569 142.343 98.5 144 98.5H196C197.657 98.5 199 97.1569 199 95.5H201ZM144 100.5C141.239 100.5 139 98.2614 139 95.5V69.5H141V95.5C141 97.1569 142.343 98.5 144 98.5V100.5ZM201 69.5V95.5C201 98.2614 198.761 100.5 196 100.5V98.5C197.657 98.5 199 97.1569 199 95.5V69.5H201Z" fill="#EFF0F4" mask="url(#path-27-inside-6_4736_64340)" />
                <rect x="146" y="75.5" width="20" height="6" rx="3" fill="#EBEDF0" />
                <rect x="146" y="86.5" width="28.7395" height="6" rx="3" fill="#EBEDF0" />
                <rect x="180" y="86.5" width="14" height="6" rx="3" fill="#EBEDF0" />
                <path d="M140 33.5C140 31.2909 141.791 29.5 144 29.5H196C198.209 29.5 200 31.2909 200 33.5V69.5H140V33.5Z" fill="#EFF0F4" />
                <rect y="18" width="210" height="1" fill="#EBEDF0" />
                <ellipse cx="14" cy="9.5" rx="4" ry="4" fill="#EBEDF0" />
                <ellipse cx="26" cy="9.5" rx="4" ry="4" fill="#EBEDF0" />
                <ellipse cx="38" cy="9.5" rx="4" ry="4" fill="#EBEDF0" />
            </g>
            <rect x="0.5" y="0.5" width="209" height="159" rx="11.5" stroke="#EBEDF0" />
            <defs>
                <clipPath id="clip0_4736_64340">
                    <rect width="210" height="160" rx="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ZeroLandingsIcon