import React from "react";

const ScreenErrorViewIcon: React.FC = () => {
    return (
        <svg width="240" height="182" viewBox="0 0 240 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.500122" width="239" height="181" rx="11.5" fill="#FAFBFD" stroke="#E4E4E8"/>
            <rect y="22" width="240" height="1" fill="#E4E4E8"/>
            <ellipse cx="16.5" cy="11.5" rx="4.5" ry="4.5" fill="#D5D6DB" fill-opacity="0.5"/>
            <ellipse cx="29.5" cy="11.5" rx="4.5" ry="4.5" fill="#D5D6DB" fill-opacity="0.5"/>
            <ellipse cx="42.5" cy="11.5" rx="4.5" ry="4.5" fill="#D5D6DB" fill-opacity="0.5"/>
            <circle cx="98" cy="83" r="6" fill="#D5D6DB" fill-opacity="0.6"/>
            <circle cx="142" cy="83" r="6" fill="#D5D6DB" fill-opacity="0.6"/>
            <path d="M152 126.312C152 136.226 135.111 131.915 120 131.915C104.889 131.915 88 136.657 88 126.312C88 115.966 102.327 97 120 97C137.673 97 152 116.397 152 126.312Z" fill="#D5D6DB" fill-opacity="0.6"/>
        </svg>

    )
}

export default ScreenErrorViewIcon;
