
const MenuPointsHIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3332 10.5C19.1616 10.5 19.8332 11.1716 19.8332 12C19.8332 12.8284 19.1616 13.5 18.3332 13.5C17.5047 13.5 16.8332 12.8284 16.8332 12C16.8332 11.1716 17.5047 10.5 18.3332 10.5Z" fill="#898A8C" />
            <path d="M11.9998 10.5C12.8283 10.5 13.4998 11.1716 13.4998 12C13.4998 12.8284 12.8283 13.5 11.9998 13.5C11.1714 13.5 10.4998 12.8284 10.4998 12C10.4998 11.1716 11.1714 10.5 11.9998 10.5Z" fill="#898A8C" />
            <path d="M5.6665 10.5C6.49493 10.5 7.1665 11.1716 7.1665 12C7.1665 12.8284 6.49493 13.5 5.6665 13.5C4.83808 13.5 4.1665 12.8284 4.1665 12C4.1665 11.1716 4.83808 10.5 5.6665 10.5Z" fill="#898A8C" />
        </svg>
    )
}

export default MenuPointsHIcon