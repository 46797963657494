import { Button, Modal } from 'antd';
import React from 'react';
import './styles.scss'

const PublishedModal = ({ isOpen, onClose }: {isOpen: boolean, onClose: () => void }) => {
    return (
        <Modal
            title='Landing page submitted'
            className='published-modal'
            open={isOpen}
            onCancel={onClose}
            centered
            width={500}
            footer={(
                <div
                    className="flex justify-end"
                >
                    <Button
                        size="large"
                        type="primary"
                        onClick={onClose}
                    >
                        Got it
                    </Button>
                </div>
            )}
        >
            <span>
                Your changes have been published and submitted to the event for moderation. Once the moderation process is complete, you will receive a notification with the results
            </span>
        </Modal>
    )
}

export default PublishedModal
