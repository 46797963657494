import { useState } from 'react';
import { Button, Col, InputNumber, message, Modal, Row, Select, Slider } from 'antd';
import CustomButton from '@components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import EventCatalogTableZeroState
    from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import './styles.scss'
import PartnersTable from './table';
import { EventStatuses } from '@shared/constants';
import {
    useGetOrganizerEventByIdQuery,
    usePostCreateOrganizerEventInvitationsMutation
} from '@store/type-event/events/events.api';
import { TabKeys } from '@pages/EventRolePages/EventsPage/pages/info/constants';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

type Props = {
    onChangeTab: (value: TabKeys) => void
}

const PartnersTab = ({ onChangeTab }: Props) => {
    const { eventId: id } = useParams()

    const navigate = useNavigate()

    const { data: res } = useGetOrganizerEventByIdQuery({ eventId: id?.toString() || '' }, { skip: !id })
    const isPublished = res?.data?.status?.name === EventStatuses.published
    // const { isLoading: isExportLoading, run: runExport } = useGetInvitationsExported(id?.toString() || '');

    const [count, setCount] = useState(5)
    const [isLoading, setLoading] = useState(false)
    // const [patchPartnerStatus] = usePatchPartnerStatusPartnerMutation()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [onInviteClick] = usePostCreateOrganizerEventInvitationsMutation()

    const handleOk = async () => {
        setLoading(true)
        try {
            const response = await onInviteClick({ eventId: id!, count })
            if ('data' in response) {
                sendAnalytics(GTMEventName.addInvitations)
                setLoading(false)
                setIsModalOpen(false);
                navigate(`/events/info/${id || ''}/${TabKeys.invitations}`)
                return message.open({
                    type: 'success',
                    content: 'Invitations added',
                });
            }
            if ('error' in response) {
                setLoading(false)
                setIsModalOpen(false);
                return message.open({
                    type: 'error',
                    content: 'Request error',
                });
            }
        } catch (error) {
            setLoading(false)
            setIsModalOpen(false);
            return message.open({
                type: 'error',
                content: 'Request error',
            });

        }
        setIsModalOpen(false);

    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCount(5)
    };
    if (isLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }

    // const onEventDetails = () => {
    //     onChangeTab(TabKeys.details)
    // }

    return (
        <div className='px-24 organizer-event-tab__partners'>
            <div className='organizer-event-tab__partners-header flex items-center justify-space-between w-full'>
                <h3>Partner</h3>
            </div>
            <div className='my-16 flex items-center justify-space-between w-full'>
                <Select
                    placeholder='All statuses'
                    maxTagCount={1}
                    mode="multiple"
                    showSearch={false}
                    style={{ width: '340px', marginRight: '8px' }}
                    allowClear
                    filterOption={false}
                // notFoundContent={isStatusesLoading ? <Spin size="small" /> : null}
                // loading={isStatusesLoading}
                // value={filters.statuses}
                // onChange={(statuses) => handleChangeFilter({ statuses })}
                // fieldNames={{ label: 'slug', value: 'id' }}
                // options={mappedStatusesData}
                // popupClassName='moderation-table__popup-select moderation-table__popup-select__status'
                // menuItemSelectedIcon={statusMenuItemSelectedIcon}
                />

                <div className='flex'>
                    <CustomButton
                        disabled={!isPublished}
                        title=' +Add invitation'
                        type='primary'
                        onClick={() => setIsModalOpen(true)}
                    />
                </div>
            </div>

            <PartnersTable
                onInviteClick={() => setIsModalOpen(true)}
                isPublished={isPublished}
                onChangeTab={onChangeTab}
            />

            <Modal
                title=""
                className='modal-footer-flex'
                open={isModalOpen}
                onCancel={handleCancel}
                width={430}
                footer={[
                    <Button
                        type="primary"
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleOk}
                    >
                        Add Invitations
                    </Button>
                ]}
            >
                <div className='organizer-event-tab__partners-invitation-modal-body'>
                    <Row>
                        <Col span={12}>
                            <Slider
                                min={1}
                                max={100}
                                onChange={setCount}
                                value={typeof count === 'number' ? count : 0}
                            />
                        </Col>
                        <Col span={4}>
                            <InputNumber
                                min={1}
                                max={100}
                                style={{ margin: '0 16px' }}
                                value={count}
                                onChange={(value: unknown) => setCount(value as number)}
                            />
                        </Col>
                    </Row>
                    <p>
                        Нow many invitations do you want to add?
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default PartnersTab