import {Link, useLocation} from 'react-router-dom';
import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useAuth } from "@contexts/AuthContext.tsx";

const LogoHeaderAuth = ({ isCompleteRegistration }: { isCompleteRegistration?: boolean }) => {
    const { logout } = useAuth()

    const { pathname } = useLocation()

    const [isModalOpen, setIsModalOpen] = useState(false)

    const onOk = () => {
        logout('/init')
    }

    const onIconClick = () => {
        setIsModalOpen(true)
    }

    const onClose = () => {
        setIsModalOpen(false)
    }

    return isCompleteRegistration ? (
        <>
            <svg
                onClick={onIconClick}
                width="142"
                height="48"
                viewBox="0 0 142 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
            >
                <g clipPath="url(#clip0_2711_6153)">
                    <path
                        d="M137.015 27.2895C136.265 27.2895 135.579 27.1609 134.958 26.9038C134.336 26.6466 133.843 26.2393 133.479 25.6821C133.114 25.1034 132.932 24.3639 132.932 23.4638C132.932 23.2923 132.932 23.1101 132.932 22.9172C132.954 22.7243 132.986 22.4886 133.029 22.2099L135.118 7.29258L139.009 5.29932L136.726 21.6634C136.704 21.8349 136.683 22.0278 136.662 22.2421C136.64 22.435 136.629 22.6279 136.629 22.8208C136.629 23.3352 136.737 23.721 136.951 23.9782C137.165 24.2353 137.53 24.3639 138.044 24.3639C138.323 24.3639 138.612 24.3425 138.912 24.2996C139.234 24.2353 139.566 24.1389 139.909 24.0103L139.491 26.9038C139.062 27.0323 138.633 27.1288 138.205 27.1931C137.798 27.2574 137.401 27.2895 137.015 27.2895ZM131.164 13.9154L131.582 11.1505H141.709L141.355 13.9154H131.164Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M122.914 27.2892C121.735 27.2892 120.717 27.1177 119.86 26.7748C119.003 26.4319 118.285 25.9925 117.706 25.4567C117.149 24.9209 116.72 24.3958 116.42 23.8814L119.024 21.9524C119.388 22.724 119.913 23.3348 120.599 23.7849C121.285 24.235 122.089 24.4601 123.011 24.4601C123.632 24.4601 124.179 24.3743 124.65 24.2029C125.122 24.01 125.486 23.7421 125.743 23.3991C126.022 23.0562 126.161 22.6811 126.161 22.2739C126.161 21.8881 126.033 21.5773 125.775 21.3416C125.54 21.0844 125.197 20.87 124.747 20.6986C124.318 20.5271 123.793 20.3556 123.171 20.1842C122.657 20.0342 122.11 19.8627 121.532 19.6698C120.974 19.4769 120.449 19.2304 119.956 18.9304C119.463 18.6089 119.067 18.2016 118.767 17.7087C118.488 17.2157 118.349 16.6156 118.349 15.9083C118.349 14.901 118.617 14.0222 119.153 13.272C119.688 12.5219 120.428 11.9325 121.371 11.5038C122.314 11.0752 123.396 10.8608 124.618 10.8608C125.925 10.8608 127.061 11.1288 128.026 11.6646C129.012 12.179 129.73 12.9291 130.18 13.915L127.801 15.4582C127.479 14.8152 127.029 14.3544 126.451 14.0758C125.872 13.7972 125.229 13.6578 124.522 13.6578C123.793 13.6578 123.139 13.8186 122.56 14.1401C122.003 14.4616 121.725 14.9117 121.725 15.4904C121.725 15.8976 121.917 16.2191 122.303 16.4548C122.71 16.6906 123.386 16.9264 124.329 17.1621C125.165 17.355 126 17.6122 126.836 17.9337C127.672 18.2338 128.358 18.6839 128.894 19.284C129.43 19.8627 129.698 20.6664 129.698 21.6952C129.698 22.8097 129.408 23.7849 128.83 24.6208C128.251 25.4567 127.447 26.1104 126.418 26.5819C125.411 27.0535 124.243 27.2892 122.914 27.2892Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M107.186 27.2892C105.815 27.2892 104.604 26.9892 103.554 26.389C102.503 25.7889 101.689 24.9637 101.11 23.9135C100.532 22.8419 100.242 21.6095 100.242 20.2163C100.242 18.9518 100.457 17.7623 100.885 16.6477C101.335 15.5332 101.957 14.5473 102.75 13.69C103.543 12.8112 104.486 12.1254 105.579 11.6324C106.672 11.118 107.872 10.8608 109.18 10.8608C110.594 10.8608 111.816 11.1609 112.845 11.761C113.874 12.3397 114.677 13.1542 115.256 14.2044C115.835 15.2546 116.124 16.4763 116.124 17.8694C116.124 19.134 115.899 20.3342 115.449 21.4702C114.999 22.6061 114.367 23.6135 113.552 24.4922C112.759 25.371 111.816 26.0568 110.723 26.5498C109.651 27.0427 108.472 27.2892 107.186 27.2892ZM107.412 24.1386C108.247 24.1386 108.976 23.9671 109.598 23.6242C110.241 23.2598 110.787 22.7883 111.237 22.2096C111.687 21.6095 112.02 20.9451 112.234 20.2163C112.47 19.4876 112.588 18.7375 112.588 17.9659C112.588 16.8085 112.277 15.8547 111.655 15.1046C111.055 14.3544 110.166 13.9793 108.987 13.9793C108.13 13.9793 107.369 14.1615 106.704 14.5259C106.061 14.8688 105.515 15.351 105.065 15.9726C104.636 16.5727 104.304 17.2371 104.068 17.9659C103.854 18.6732 103.746 19.3912 103.746 20.1199C103.746 21.2987 104.057 22.2632 104.679 23.0133C105.322 23.7635 106.233 24.1386 107.412 24.1386Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M90.0419 27.2892C88.6702 27.2892 87.4593 26.9892 86.4091 26.389C85.3588 25.7889 84.5444 24.9637 83.9657 23.9135C83.387 22.8419 83.0977 21.6095 83.0977 20.2163C83.0977 18.9518 83.312 17.7623 83.7406 16.6477C84.1907 15.5332 84.8123 14.5473 85.6053 13.69C86.3983 12.8112 87.3414 12.1254 88.4345 11.6324C89.5275 11.118 90.7278 10.8608 92.0352 10.8608C93.4498 10.8608 94.6715 11.1609 95.7002 11.761C96.729 12.3397 97.5328 13.1542 98.1115 14.2044C98.6901 15.2546 98.9795 16.4763 98.9795 17.8694C98.9795 19.134 98.7544 20.3342 98.3044 21.4702C97.8543 22.6061 97.222 23.6135 96.4075 24.4922C95.6145 25.371 94.6715 26.0568 93.5784 26.5498C92.5067 27.0427 91.3279 27.2892 90.0419 27.2892ZM90.267 24.1386C91.1029 24.1386 91.8316 23.9671 92.4531 23.6242C93.0961 23.2598 93.6427 22.7883 94.0928 22.2096C94.5429 21.6095 94.8751 20.9451 95.0894 20.2163C95.3252 19.4876 95.443 18.7375 95.443 17.9659C95.443 16.8085 95.1323 15.8547 94.5107 15.1046C93.9106 14.3544 93.0211 13.9793 91.8423 13.9793C90.985 13.9793 90.2241 14.1615 89.5597 14.5259C88.9167 14.8688 88.3702 15.351 87.9201 15.9726C87.4914 16.5727 87.1592 17.2371 86.9234 17.9659C86.7091 18.6732 86.6019 19.3912 86.6019 20.1199C86.6019 21.2987 86.9127 22.2632 87.5343 23.0133C88.1773 23.7635 89.0882 24.1386 90.267 24.1386Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M73.8685 27.2893C72.9897 27.2893 72.1753 27.1286 71.4251 26.8071C70.6964 26.4856 70.0963 25.9712 69.6247 25.2639C69.1532 24.5566 68.8532 23.635 68.7246 22.499L69.5926 22.5955L68.8532 27H65.4453L68.596 4.5918H72.1324L70.6214 15.5869L69.8176 15.3297C70.1606 14.3224 70.6321 13.4972 71.2322 12.8542C71.8323 12.1898 72.5182 11.6968 73.2898 11.3753C74.0828 11.0324 74.9187 10.8609 75.7974 10.8609C76.9548 10.8609 77.9836 11.1289 78.8838 11.6647C79.8054 12.2005 80.5234 12.9721 81.0378 13.9794C81.5736 14.9868 81.8415 16.1978 81.8415 17.6123C81.8415 18.7911 81.6594 19.9592 81.295 21.1166C80.9306 22.2526 80.4055 23.2921 79.7197 24.2351C79.0338 25.1567 78.1979 25.8962 77.212 26.4534C76.2261 27.0107 75.1116 27.2893 73.8685 27.2893ZM73.1933 24.0101C73.9649 24.0101 74.6508 23.86 75.2509 23.56C75.8725 23.2385 76.4083 22.7991 76.8584 22.2418C77.3085 21.6846 77.6514 21.0523 77.8872 20.345C78.1229 19.6163 78.2408 18.8447 78.2408 18.0303C78.2408 17.2373 78.1015 16.5514 77.8229 15.9727C77.5442 15.3726 77.137 14.9118 76.6012 14.5903C76.0868 14.2688 75.4652 14.108 74.7365 14.108C73.9864 14.108 73.3005 14.2688 72.6789 14.5903C72.0574 14.9118 71.5216 15.3511 71.0715 15.9084C70.6428 16.4657 70.3106 17.1087 70.0748 17.8374C69.8391 18.5447 69.7212 19.2948 69.7212 20.0878C69.7212 20.8809 69.8498 21.5774 70.107 22.1776C70.3856 22.7562 70.7928 23.2063 71.3287 23.5278C71.8645 23.8493 72.486 24.0101 73.1933 24.0101Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M133.839 44.9633C133.109 44.9633 132.471 44.8581 131.924 44.6476C131.391 44.4231 130.942 44.1355 130.577 43.7848C130.226 43.42 129.96 43.0412 129.777 42.6483L131.124 41.7013C131.321 42.2345 131.65 42.6624 132.113 42.9851C132.576 43.3078 133.159 43.4691 133.86 43.4691C134.323 43.4691 134.744 43.3989 135.123 43.2586C135.502 43.1043 135.803 42.8869 136.028 42.6063C136.266 42.3257 136.385 42.003 136.385 41.6382C136.385 41.3436 136.294 41.1051 136.112 40.9227C135.943 40.7263 135.691 40.5649 135.354 40.4386C135.018 40.2983 134.597 40.1651 134.092 40.0388C133.727 39.9406 133.362 39.8283 132.997 39.7021C132.646 39.5758 132.324 39.4215 132.029 39.2391C131.735 39.0427 131.496 38.8042 131.314 38.5236C131.145 38.2289 131.061 37.8712 131.061 37.4503C131.061 36.8189 131.229 36.2648 131.566 35.7877C131.903 35.3107 132.366 34.9389 132.955 34.6724C133.558 34.3918 134.246 34.2515 135.018 34.2515C135.817 34.2515 136.526 34.4268 137.143 34.7776C137.76 35.1143 138.209 35.6124 138.49 36.2718L137.29 37.0294C137.08 36.5383 136.771 36.1946 136.364 35.9982C135.958 35.7877 135.488 35.6825 134.954 35.6825C134.351 35.6825 133.825 35.8228 133.376 36.1034C132.941 36.37 132.724 36.7418 132.724 37.2188C132.724 37.5555 132.878 37.8151 133.187 37.9974C133.495 38.1798 133.993 38.3552 134.681 38.5236C135.228 38.6498 135.768 38.8112 136.301 39.0076C136.834 39.19 137.276 39.4636 137.627 39.8283C137.978 40.1791 138.153 40.6842 138.153 41.3436C138.153 42.0731 137.964 42.7115 137.585 43.2586C137.22 43.7918 136.715 44.2127 136.07 44.5213C135.424 44.816 134.681 44.9633 133.839 44.9633Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M123.22 44.9633C122.49 44.9633 121.852 44.8581 121.305 44.6476C120.772 44.4231 120.323 44.1355 119.958 43.7848C119.607 43.42 119.341 43.0412 119.158 42.6483L120.505 41.7013C120.701 42.2345 121.031 42.6624 121.494 42.9851C121.957 43.3078 122.539 43.4691 123.241 43.4691C123.704 43.4691 124.125 43.3989 124.504 43.2586C124.882 43.1043 125.184 42.8869 125.409 42.6063C125.647 42.3257 125.766 42.003 125.766 41.6382C125.766 41.3436 125.675 41.1051 125.493 40.9227C125.324 40.7263 125.072 40.5649 124.735 40.4386C124.398 40.2983 123.977 40.1651 123.472 40.0388C123.108 39.9406 122.743 39.8283 122.378 39.7021C122.027 39.5758 121.705 39.4215 121.41 39.2391C121.115 39.0427 120.877 38.8042 120.694 38.5236C120.526 38.2289 120.442 37.8712 120.442 37.4503C120.442 36.8189 120.61 36.2648 120.947 35.7877C121.284 35.3107 121.747 34.9389 122.336 34.6724C122.939 34.3918 123.627 34.2515 124.398 34.2515C125.198 34.2515 125.907 34.4268 126.524 34.7776C127.141 35.1143 127.59 35.6124 127.871 36.2718L126.671 37.0294C126.461 36.5383 126.152 36.1946 125.745 35.9982C125.338 35.7877 124.868 35.6825 124.335 35.6825C123.732 35.6825 123.206 35.8228 122.757 36.1034C122.322 36.37 122.104 36.7418 122.104 37.2188C122.104 37.5555 122.259 37.8151 122.567 37.9974C122.876 38.1798 123.374 38.3552 124.062 38.5236C124.609 38.6498 125.149 38.8112 125.682 39.0076C126.215 39.19 126.657 39.4636 127.008 39.8283C127.359 40.1791 127.534 40.6842 127.534 41.3436C127.534 42.0731 127.345 42.7115 126.966 43.2586C126.601 43.7918 126.096 44.2127 125.451 44.5213C124.805 44.816 124.062 44.9633 123.22 44.9633Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M112.067 44.9633C111.099 44.9633 110.292 44.7739 109.646 44.3951C109.015 44.0022 108.545 43.4761 108.236 42.8167C107.928 42.1573 107.773 41.4207 107.773 40.607C107.773 39.8213 107.893 39.0567 108.131 38.3131C108.37 37.5555 108.72 36.8751 109.183 36.2718C109.66 35.6545 110.236 35.1634 110.909 34.7986C111.583 34.4339 112.347 34.2515 113.203 34.2515C114.157 34.2515 114.929 34.4409 115.518 34.8197C116.107 35.1985 116.542 35.7036 116.823 36.3349C117.103 36.9522 117.244 37.6327 117.244 38.3763C117.244 38.6428 117.223 38.9515 117.18 39.3022C117.138 39.653 117.082 39.9546 117.012 40.2071H109.541C109.527 40.3054 109.52 40.3966 109.52 40.4807C109.52 40.5649 109.52 40.6491 109.52 40.7333C109.52 41.561 109.745 42.2064 110.194 42.6694C110.643 43.1183 111.281 43.3428 112.109 43.3428C112.74 43.3428 113.294 43.2376 113.771 43.0272C114.262 42.8167 114.732 42.494 115.181 42.0591L116.339 43.2376C115.679 43.8409 115.006 44.2828 114.318 44.5634C113.645 44.83 112.894 44.9633 112.067 44.9633ZM115.56 39.197C115.588 38.9585 115.609 38.7691 115.623 38.6288C115.637 38.4885 115.644 38.3061 115.644 38.0816C115.644 37.6888 115.56 37.317 115.392 36.9662C115.223 36.6015 114.964 36.3068 114.613 36.0824C114.262 35.8579 113.799 35.7456 113.224 35.7456C112.607 35.7456 112.053 35.893 111.562 36.1876C111.084 36.4682 110.685 36.847 110.362 37.324C110.053 37.787 109.836 38.2991 109.71 38.8603H116.107L115.56 39.197Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M98.9785 44.7949L100.452 34.4198H102.24L101.841 37.1977L101.314 37.1557C101.497 36.5383 101.806 36.0122 102.24 35.5773C102.689 35.1424 103.18 34.8127 103.714 34.5882C104.261 34.3637 104.773 34.2515 105.25 34.2515C105.559 34.2515 105.853 34.2936 106.134 34.3777C106.414 34.4479 106.702 34.5742 106.997 34.7565L105.965 36.2928C105.811 36.2086 105.622 36.1455 105.397 36.1034C105.187 36.0473 104.983 36.0192 104.787 36.0192C104.057 36.0192 103.461 36.2016 102.998 36.5664C102.549 36.9171 102.205 37.3942 101.967 37.9974C101.728 38.6007 101.553 39.2671 101.441 39.9967L100.767 44.7949H98.9785Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M85.4258 49.0881L87.4882 34.4198H89.2559L88.835 37.5134L88.2879 37.0715C88.5544 36.4682 88.8912 35.9561 89.298 35.5352C89.7189 35.1143 90.1959 34.7986 90.7291 34.5882C91.2622 34.3637 91.8515 34.2515 92.4968 34.2515C93.3527 34.2515 94.0822 34.4479 94.6855 34.8407C95.3028 35.2195 95.7728 35.7456 96.0955 36.4191C96.4322 37.0925 96.6006 37.8642 96.6006 38.734C96.6006 39.5477 96.4673 40.3264 96.2007 41.07C95.9482 41.8136 95.5764 42.48 95.0854 43.0692C94.6083 43.6585 94.0401 44.1215 93.3807 44.4582C92.7213 44.7949 91.9918 44.9633 91.1921 44.9633C90.5186 44.9633 89.9294 44.844 89.4243 44.6055C88.9333 44.353 88.5334 44.0163 88.2247 43.5954C87.9301 43.1604 87.7407 42.6764 87.6565 42.1433L88.2037 41.7013L87.1935 49.0881H85.4258ZM90.9185 43.3428C91.5077 43.3428 92.0409 43.2166 92.5179 42.964C92.9949 42.7115 93.4088 42.3748 93.7595 41.9539C94.1103 41.5189 94.3768 41.0349 94.5592 40.5018C94.7416 39.9546 94.8328 39.4004 94.8328 38.8392C94.8328 38.25 94.7206 37.7379 94.4961 37.303C94.2857 36.854 93.977 36.5103 93.5701 36.2718C93.1773 36.0192 92.7073 35.893 92.1601 35.893C91.5709 35.893 91.0377 36.0262 90.5607 36.2928C90.0837 36.5454 89.6768 36.8891 89.3401 37.324C89.0034 37.7589 88.7438 38.243 88.5615 38.7761C88.3931 39.3092 88.3089 39.8564 88.3089 40.4176C88.3089 40.9928 88.4141 41.5049 88.6246 41.9539C88.835 42.3888 89.1367 42.7325 89.5295 42.9851C89.9224 43.2236 90.3853 43.3428 90.9185 43.3428Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M73.4824 44.7945L77.965 39.5754L74.9976 34.4194H77.1021L79.2697 38.3548L82.5948 34.4194H84.7835L80.3009 39.5754L83.2893 44.7945H81.1848L78.9962 40.8171L75.6711 44.7945H73.4824Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M67.1428 44.9633C66.1747 44.9633 65.368 44.7739 64.7226 44.3951C64.0913 44.0022 63.6213 43.4761 63.3126 42.8167C63.0039 42.1573 62.8496 41.4207 62.8496 40.607C62.8496 39.8213 62.9689 39.0567 63.2074 38.3131C63.4459 37.5555 63.7966 36.8751 64.2596 36.2718C64.7366 35.6545 65.3119 35.1634 65.9853 34.7986C66.6587 34.4339 67.4234 34.2515 68.2792 34.2515C69.2332 34.2515 70.0048 34.4409 70.5941 34.8197C71.1834 35.1985 71.6183 35.7036 71.8989 36.3349C72.1795 36.9522 72.3198 37.6327 72.3198 38.3763C72.3198 38.6428 72.2987 38.9515 72.2566 39.3022C72.2146 39.653 72.1584 39.9546 72.0883 40.2071H64.6174C64.6033 40.3054 64.5963 40.3966 64.5963 40.4807C64.5963 40.5649 64.5963 40.6491 64.5963 40.7333C64.5963 41.561 64.8208 42.2064 65.2698 42.6694C65.7187 43.1183 66.3571 43.3428 67.1848 43.3428C67.8162 43.3428 68.3704 43.2376 68.8474 43.0272C69.3384 42.8167 69.8084 42.494 70.2574 42.0591L71.4149 43.2376C70.7554 43.8409 70.082 44.2828 69.3945 44.5634C68.7211 44.83 67.9705 44.9633 67.1428 44.9633ZM70.6362 39.197C70.6643 38.9585 70.6853 38.7691 70.6993 38.6288C70.7134 38.4885 70.7204 38.3061 70.7204 38.0816C70.7204 37.6888 70.6362 37.317 70.4678 36.9662C70.2995 36.6015 70.0399 36.3068 69.6892 36.0824C69.3384 35.8579 68.8754 35.7456 68.3002 35.7456C67.6829 35.7456 67.1287 35.893 66.6377 36.1876C66.1607 36.4682 65.7608 36.847 65.4381 37.324C65.1295 37.787 64.912 38.2991 64.7857 38.8603H71.1834L70.6362 39.197Z"
                        fill="#4E67EA"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.1216 5.16943H32.5191H38.6394H45.7941C49.2965 5.16943 52.0268 5.92263 53.9851 7.42901C55.9811 8.9354 56.979 11.0632 56.979 13.8123C56.979 15.9589 56.4706 17.8796 55.4538 19.5743C54.4747 21.2313 53.0624 22.5305 51.2171 23.472C52.9495 24.0746 54.2676 25.0537 55.1714 26.4095C56.1129 27.7276 56.5836 29.3846 56.5836 31.3806C56.5836 34.1297 55.9811 36.4834 54.776 38.4417C53.6085 40.4 51.9515 41.9064 49.8049 42.9609C47.6583 44.0154 45.1351 44.5426 42.2353 44.5426H33.2591H26.9831H3.8935C1.9498 44.5426 0.552774 42.6731 1.10351 40.8091C1.4688 39.5727 2.60427 38.7242 3.8935 38.7242H27.8012L29.4056 27.3133H12.8511C10.9523 27.3133 9.55239 25.5389 9.99431 23.6922C10.3106 22.3706 11.4921 21.4384 12.8511 21.4384H30.2316L31.701 10.9879H21.1216C19.2124 10.9879 17.8208 9.17999 18.3092 7.33437C18.6469 6.05823 19.8015 5.16943 21.1216 5.16943ZM42.2353 38.5547H34.3833L35.965 27.1438H44.8338C46.6038 27.1438 47.9219 27.5393 48.7881 28.3301C49.6919 29.0833 50.1438 30.1755 50.1438 31.6065C50.1438 33.7908 49.4471 35.5043 48.0537 36.7471C46.698 37.9522 44.7585 38.5547 42.2353 38.5547ZM43.9865 21.2689H36.8123L38.2245 11.1573H45.2857C46.9427 11.1573 48.2043 11.5151 49.0705 12.2306C49.9367 12.9085 50.3698 13.8876 50.3698 15.1681C50.3698 16.3355 50.0873 17.39 49.5224 18.3315C48.9952 19.2353 48.242 19.9509 47.2629 20.4781C46.3214 21.0053 45.2292 21.2689 43.9865 21.2689Z"
                        fill="#4E67EA"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2711_6153">
                        <rect width="141.617" height="48" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            <Modal
                className="logout-dropdown__modal"
                title="Exit registration"
                open={isModalOpen}
                onOk={onOk}
                width={440}
                centered
                onCancel={onClose}
                footer={[
                    <footer className='flex items-center justify-end mt-24'>
                        <Button
                            key="back"
                            onClick={onClose}
                            size='large'
                        >
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type='primary'
                            size='large'
                            onClick={onOk}
                        >
                            Confirm
                        </Button>
                    </footer>
                ]}
            >
                Are you sure you want to leave the registration page? Your information will be saved
            </Modal>
        </>
    ) : (
        <Link to={pathname === '/login' || pathname === '/registration' ? "/init" : '/'}>
            <svg
                width="142"
                height="48"
                viewBox="0 0 142 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: 'pointer' }}
            >
                <g clipPath="url(#clip0_2711_6153)">
                    <path
                        d="M137.015 27.2895C136.265 27.2895 135.579 27.1609 134.958 26.9038C134.336 26.6466 133.843 26.2393 133.479 25.6821C133.114 25.1034 132.932 24.3639 132.932 23.4638C132.932 23.2923 132.932 23.1101 132.932 22.9172C132.954 22.7243 132.986 22.4886 133.029 22.2099L135.118 7.29258L139.009 5.29932L136.726 21.6634C136.704 21.8349 136.683 22.0278 136.662 22.2421C136.64 22.435 136.629 22.6279 136.629 22.8208C136.629 23.3352 136.737 23.721 136.951 23.9782C137.165 24.2353 137.53 24.3639 138.044 24.3639C138.323 24.3639 138.612 24.3425 138.912 24.2996C139.234 24.2353 139.566 24.1389 139.909 24.0103L139.491 26.9038C139.062 27.0323 138.633 27.1288 138.205 27.1931C137.798 27.2574 137.401 27.2895 137.015 27.2895ZM131.164 13.9154L131.582 11.1505H141.709L141.355 13.9154H131.164Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M122.914 27.2892C121.735 27.2892 120.717 27.1177 119.86 26.7748C119.003 26.4319 118.285 25.9925 117.706 25.4567C117.149 24.9209 116.72 24.3958 116.42 23.8814L119.024 21.9524C119.388 22.724 119.913 23.3348 120.599 23.7849C121.285 24.235 122.089 24.4601 123.011 24.4601C123.632 24.4601 124.179 24.3743 124.65 24.2029C125.122 24.01 125.486 23.7421 125.743 23.3991C126.022 23.0562 126.161 22.6811 126.161 22.2739C126.161 21.8881 126.033 21.5773 125.775 21.3416C125.54 21.0844 125.197 20.87 124.747 20.6986C124.318 20.5271 123.793 20.3556 123.171 20.1842C122.657 20.0342 122.11 19.8627 121.532 19.6698C120.974 19.4769 120.449 19.2304 119.956 18.9304C119.463 18.6089 119.067 18.2016 118.767 17.7087C118.488 17.2157 118.349 16.6156 118.349 15.9083C118.349 14.901 118.617 14.0222 119.153 13.272C119.688 12.5219 120.428 11.9325 121.371 11.5038C122.314 11.0752 123.396 10.8608 124.618 10.8608C125.925 10.8608 127.061 11.1288 128.026 11.6646C129.012 12.179 129.73 12.9291 130.18 13.915L127.801 15.4582C127.479 14.8152 127.029 14.3544 126.451 14.0758C125.872 13.7972 125.229 13.6578 124.522 13.6578C123.793 13.6578 123.139 13.8186 122.56 14.1401C122.003 14.4616 121.725 14.9117 121.725 15.4904C121.725 15.8976 121.917 16.2191 122.303 16.4548C122.71 16.6906 123.386 16.9264 124.329 17.1621C125.165 17.355 126 17.6122 126.836 17.9337C127.672 18.2338 128.358 18.6839 128.894 19.284C129.43 19.8627 129.698 20.6664 129.698 21.6952C129.698 22.8097 129.408 23.7849 128.83 24.6208C128.251 25.4567 127.447 26.1104 126.418 26.5819C125.411 27.0535 124.243 27.2892 122.914 27.2892Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M107.186 27.2892C105.815 27.2892 104.604 26.9892 103.554 26.389C102.503 25.7889 101.689 24.9637 101.11 23.9135C100.532 22.8419 100.242 21.6095 100.242 20.2163C100.242 18.9518 100.457 17.7623 100.885 16.6477C101.335 15.5332 101.957 14.5473 102.75 13.69C103.543 12.8112 104.486 12.1254 105.579 11.6324C106.672 11.118 107.872 10.8608 109.18 10.8608C110.594 10.8608 111.816 11.1609 112.845 11.761C113.874 12.3397 114.677 13.1542 115.256 14.2044C115.835 15.2546 116.124 16.4763 116.124 17.8694C116.124 19.134 115.899 20.3342 115.449 21.4702C114.999 22.6061 114.367 23.6135 113.552 24.4922C112.759 25.371 111.816 26.0568 110.723 26.5498C109.651 27.0427 108.472 27.2892 107.186 27.2892ZM107.412 24.1386C108.247 24.1386 108.976 23.9671 109.598 23.6242C110.241 23.2598 110.787 22.7883 111.237 22.2096C111.687 21.6095 112.02 20.9451 112.234 20.2163C112.47 19.4876 112.588 18.7375 112.588 17.9659C112.588 16.8085 112.277 15.8547 111.655 15.1046C111.055 14.3544 110.166 13.9793 108.987 13.9793C108.13 13.9793 107.369 14.1615 106.704 14.5259C106.061 14.8688 105.515 15.351 105.065 15.9726C104.636 16.5727 104.304 17.2371 104.068 17.9659C103.854 18.6732 103.746 19.3912 103.746 20.1199C103.746 21.2987 104.057 22.2632 104.679 23.0133C105.322 23.7635 106.233 24.1386 107.412 24.1386Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M90.0419 27.2892C88.6702 27.2892 87.4593 26.9892 86.4091 26.389C85.3588 25.7889 84.5444 24.9637 83.9657 23.9135C83.387 22.8419 83.0977 21.6095 83.0977 20.2163C83.0977 18.9518 83.312 17.7623 83.7406 16.6477C84.1907 15.5332 84.8123 14.5473 85.6053 13.69C86.3983 12.8112 87.3414 12.1254 88.4345 11.6324C89.5275 11.118 90.7278 10.8608 92.0352 10.8608C93.4498 10.8608 94.6715 11.1609 95.7002 11.761C96.729 12.3397 97.5328 13.1542 98.1115 14.2044C98.6901 15.2546 98.9795 16.4763 98.9795 17.8694C98.9795 19.134 98.7544 20.3342 98.3044 21.4702C97.8543 22.6061 97.222 23.6135 96.4075 24.4922C95.6145 25.371 94.6715 26.0568 93.5784 26.5498C92.5067 27.0427 91.3279 27.2892 90.0419 27.2892ZM90.267 24.1386C91.1029 24.1386 91.8316 23.9671 92.4531 23.6242C93.0961 23.2598 93.6427 22.7883 94.0928 22.2096C94.5429 21.6095 94.8751 20.9451 95.0894 20.2163C95.3252 19.4876 95.443 18.7375 95.443 17.9659C95.443 16.8085 95.1323 15.8547 94.5107 15.1046C93.9106 14.3544 93.0211 13.9793 91.8423 13.9793C90.985 13.9793 90.2241 14.1615 89.5597 14.5259C88.9167 14.8688 88.3702 15.351 87.9201 15.9726C87.4914 16.5727 87.1592 17.2371 86.9234 17.9659C86.7091 18.6732 86.6019 19.3912 86.6019 20.1199C86.6019 21.2987 86.9127 22.2632 87.5343 23.0133C88.1773 23.7635 89.0882 24.1386 90.267 24.1386Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M73.8685 27.2893C72.9897 27.2893 72.1753 27.1286 71.4251 26.8071C70.6964 26.4856 70.0963 25.9712 69.6247 25.2639C69.1532 24.5566 68.8532 23.635 68.7246 22.499L69.5926 22.5955L68.8532 27H65.4453L68.596 4.5918H72.1324L70.6214 15.5869L69.8176 15.3297C70.1606 14.3224 70.6321 13.4972 71.2322 12.8542C71.8323 12.1898 72.5182 11.6968 73.2898 11.3753C74.0828 11.0324 74.9187 10.8609 75.7974 10.8609C76.9548 10.8609 77.9836 11.1289 78.8838 11.6647C79.8054 12.2005 80.5234 12.9721 81.0378 13.9794C81.5736 14.9868 81.8415 16.1978 81.8415 17.6123C81.8415 18.7911 81.6594 19.9592 81.295 21.1166C80.9306 22.2526 80.4055 23.2921 79.7197 24.2351C79.0338 25.1567 78.1979 25.8962 77.212 26.4534C76.2261 27.0107 75.1116 27.2893 73.8685 27.2893ZM73.1933 24.0101C73.9649 24.0101 74.6508 23.86 75.2509 23.56C75.8725 23.2385 76.4083 22.7991 76.8584 22.2418C77.3085 21.6846 77.6514 21.0523 77.8872 20.345C78.1229 19.6163 78.2408 18.8447 78.2408 18.0303C78.2408 17.2373 78.1015 16.5514 77.8229 15.9727C77.5442 15.3726 77.137 14.9118 76.6012 14.5903C76.0868 14.2688 75.4652 14.108 74.7365 14.108C73.9864 14.108 73.3005 14.2688 72.6789 14.5903C72.0574 14.9118 71.5216 15.3511 71.0715 15.9084C70.6428 16.4657 70.3106 17.1087 70.0748 17.8374C69.8391 18.5447 69.7212 19.2948 69.7212 20.0878C69.7212 20.8809 69.8498 21.5774 70.107 22.1776C70.3856 22.7562 70.7928 23.2063 71.3287 23.5278C71.8645 23.8493 72.486 24.0101 73.1933 24.0101Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M133.839 44.9633C133.109 44.9633 132.471 44.8581 131.924 44.6476C131.391 44.4231 130.942 44.1355 130.577 43.7848C130.226 43.42 129.96 43.0412 129.777 42.6483L131.124 41.7013C131.321 42.2345 131.65 42.6624 132.113 42.9851C132.576 43.3078 133.159 43.4691 133.86 43.4691C134.323 43.4691 134.744 43.3989 135.123 43.2586C135.502 43.1043 135.803 42.8869 136.028 42.6063C136.266 42.3257 136.385 42.003 136.385 41.6382C136.385 41.3436 136.294 41.1051 136.112 40.9227C135.943 40.7263 135.691 40.5649 135.354 40.4386C135.018 40.2983 134.597 40.1651 134.092 40.0388C133.727 39.9406 133.362 39.8283 132.997 39.7021C132.646 39.5758 132.324 39.4215 132.029 39.2391C131.735 39.0427 131.496 38.8042 131.314 38.5236C131.145 38.2289 131.061 37.8712 131.061 37.4503C131.061 36.8189 131.229 36.2648 131.566 35.7877C131.903 35.3107 132.366 34.9389 132.955 34.6724C133.558 34.3918 134.246 34.2515 135.018 34.2515C135.817 34.2515 136.526 34.4268 137.143 34.7776C137.76 35.1143 138.209 35.6124 138.49 36.2718L137.29 37.0294C137.08 36.5383 136.771 36.1946 136.364 35.9982C135.958 35.7877 135.488 35.6825 134.954 35.6825C134.351 35.6825 133.825 35.8228 133.376 36.1034C132.941 36.37 132.724 36.7418 132.724 37.2188C132.724 37.5555 132.878 37.8151 133.187 37.9974C133.495 38.1798 133.993 38.3552 134.681 38.5236C135.228 38.6498 135.768 38.8112 136.301 39.0076C136.834 39.19 137.276 39.4636 137.627 39.8283C137.978 40.1791 138.153 40.6842 138.153 41.3436C138.153 42.0731 137.964 42.7115 137.585 43.2586C137.22 43.7918 136.715 44.2127 136.07 44.5213C135.424 44.816 134.681 44.9633 133.839 44.9633Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M123.22 44.9633C122.49 44.9633 121.852 44.8581 121.305 44.6476C120.772 44.4231 120.323 44.1355 119.958 43.7848C119.607 43.42 119.341 43.0412 119.158 42.6483L120.505 41.7013C120.701 42.2345 121.031 42.6624 121.494 42.9851C121.957 43.3078 122.539 43.4691 123.241 43.4691C123.704 43.4691 124.125 43.3989 124.504 43.2586C124.882 43.1043 125.184 42.8869 125.409 42.6063C125.647 42.3257 125.766 42.003 125.766 41.6382C125.766 41.3436 125.675 41.1051 125.493 40.9227C125.324 40.7263 125.072 40.5649 124.735 40.4386C124.398 40.2983 123.977 40.1651 123.472 40.0388C123.108 39.9406 122.743 39.8283 122.378 39.7021C122.027 39.5758 121.705 39.4215 121.41 39.2391C121.115 39.0427 120.877 38.8042 120.694 38.5236C120.526 38.2289 120.442 37.8712 120.442 37.4503C120.442 36.8189 120.61 36.2648 120.947 35.7877C121.284 35.3107 121.747 34.9389 122.336 34.6724C122.939 34.3918 123.627 34.2515 124.398 34.2515C125.198 34.2515 125.907 34.4268 126.524 34.7776C127.141 35.1143 127.59 35.6124 127.871 36.2718L126.671 37.0294C126.461 36.5383 126.152 36.1946 125.745 35.9982C125.338 35.7877 124.868 35.6825 124.335 35.6825C123.732 35.6825 123.206 35.8228 122.757 36.1034C122.322 36.37 122.104 36.7418 122.104 37.2188C122.104 37.5555 122.259 37.8151 122.567 37.9974C122.876 38.1798 123.374 38.3552 124.062 38.5236C124.609 38.6498 125.149 38.8112 125.682 39.0076C126.215 39.19 126.657 39.4636 127.008 39.8283C127.359 40.1791 127.534 40.6842 127.534 41.3436C127.534 42.0731 127.345 42.7115 126.966 43.2586C126.601 43.7918 126.096 44.2127 125.451 44.5213C124.805 44.816 124.062 44.9633 123.22 44.9633Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M112.067 44.9633C111.099 44.9633 110.292 44.7739 109.646 44.3951C109.015 44.0022 108.545 43.4761 108.236 42.8167C107.928 42.1573 107.773 41.4207 107.773 40.607C107.773 39.8213 107.893 39.0567 108.131 38.3131C108.37 37.5555 108.72 36.8751 109.183 36.2718C109.66 35.6545 110.236 35.1634 110.909 34.7986C111.583 34.4339 112.347 34.2515 113.203 34.2515C114.157 34.2515 114.929 34.4409 115.518 34.8197C116.107 35.1985 116.542 35.7036 116.823 36.3349C117.103 36.9522 117.244 37.6327 117.244 38.3763C117.244 38.6428 117.223 38.9515 117.18 39.3022C117.138 39.653 117.082 39.9546 117.012 40.2071H109.541C109.527 40.3054 109.52 40.3966 109.52 40.4807C109.52 40.5649 109.52 40.6491 109.52 40.7333C109.52 41.561 109.745 42.2064 110.194 42.6694C110.643 43.1183 111.281 43.3428 112.109 43.3428C112.74 43.3428 113.294 43.2376 113.771 43.0272C114.262 42.8167 114.732 42.494 115.181 42.0591L116.339 43.2376C115.679 43.8409 115.006 44.2828 114.318 44.5634C113.645 44.83 112.894 44.9633 112.067 44.9633ZM115.56 39.197C115.588 38.9585 115.609 38.7691 115.623 38.6288C115.637 38.4885 115.644 38.3061 115.644 38.0816C115.644 37.6888 115.56 37.317 115.392 36.9662C115.223 36.6015 114.964 36.3068 114.613 36.0824C114.262 35.8579 113.799 35.7456 113.224 35.7456C112.607 35.7456 112.053 35.893 111.562 36.1876C111.084 36.4682 110.685 36.847 110.362 37.324C110.053 37.787 109.836 38.2991 109.71 38.8603H116.107L115.56 39.197Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M98.9785 44.7949L100.452 34.4198H102.24L101.841 37.1977L101.314 37.1557C101.497 36.5383 101.806 36.0122 102.24 35.5773C102.689 35.1424 103.18 34.8127 103.714 34.5882C104.261 34.3637 104.773 34.2515 105.25 34.2515C105.559 34.2515 105.853 34.2936 106.134 34.3777C106.414 34.4479 106.702 34.5742 106.997 34.7565L105.965 36.2928C105.811 36.2086 105.622 36.1455 105.397 36.1034C105.187 36.0473 104.983 36.0192 104.787 36.0192C104.057 36.0192 103.461 36.2016 102.998 36.5664C102.549 36.9171 102.205 37.3942 101.967 37.9974C101.728 38.6007 101.553 39.2671 101.441 39.9967L100.767 44.7949H98.9785Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M85.4258 49.0881L87.4882 34.4198H89.2559L88.835 37.5134L88.2879 37.0715C88.5544 36.4682 88.8912 35.9561 89.298 35.5352C89.7189 35.1143 90.1959 34.7986 90.7291 34.5882C91.2622 34.3637 91.8515 34.2515 92.4968 34.2515C93.3527 34.2515 94.0822 34.4479 94.6855 34.8407C95.3028 35.2195 95.7728 35.7456 96.0955 36.4191C96.4322 37.0925 96.6006 37.8642 96.6006 38.734C96.6006 39.5477 96.4673 40.3264 96.2007 41.07C95.9482 41.8136 95.5764 42.48 95.0854 43.0692C94.6083 43.6585 94.0401 44.1215 93.3807 44.4582C92.7213 44.7949 91.9918 44.9633 91.1921 44.9633C90.5186 44.9633 89.9294 44.844 89.4243 44.6055C88.9333 44.353 88.5334 44.0163 88.2247 43.5954C87.9301 43.1604 87.7407 42.6764 87.6565 42.1433L88.2037 41.7013L87.1935 49.0881H85.4258ZM90.9185 43.3428C91.5077 43.3428 92.0409 43.2166 92.5179 42.964C92.9949 42.7115 93.4088 42.3748 93.7595 41.9539C94.1103 41.5189 94.3768 41.0349 94.5592 40.5018C94.7416 39.9546 94.8328 39.4004 94.8328 38.8392C94.8328 38.25 94.7206 37.7379 94.4961 37.303C94.2857 36.854 93.977 36.5103 93.5701 36.2718C93.1773 36.0192 92.7073 35.893 92.1601 35.893C91.5709 35.893 91.0377 36.0262 90.5607 36.2928C90.0837 36.5454 89.6768 36.8891 89.3401 37.324C89.0034 37.7589 88.7438 38.243 88.5615 38.7761C88.3931 39.3092 88.3089 39.8564 88.3089 40.4176C88.3089 40.9928 88.4141 41.5049 88.6246 41.9539C88.835 42.3888 89.1367 42.7325 89.5295 42.9851C89.9224 43.2236 90.3853 43.3428 90.9185 43.3428Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M73.4824 44.7945L77.965 39.5754L74.9976 34.4194H77.1021L79.2697 38.3548L82.5948 34.4194H84.7835L80.3009 39.5754L83.2893 44.7945H81.1848L78.9962 40.8171L75.6711 44.7945H73.4824Z"
                        fill="#4E67EA"
                    />
                    <path
                        d="M67.1428 44.9633C66.1747 44.9633 65.368 44.7739 64.7226 44.3951C64.0913 44.0022 63.6213 43.4761 63.3126 42.8167C63.0039 42.1573 62.8496 41.4207 62.8496 40.607C62.8496 39.8213 62.9689 39.0567 63.2074 38.3131C63.4459 37.5555 63.7966 36.8751 64.2596 36.2718C64.7366 35.6545 65.3119 35.1634 65.9853 34.7986C66.6587 34.4339 67.4234 34.2515 68.2792 34.2515C69.2332 34.2515 70.0048 34.4409 70.5941 34.8197C71.1834 35.1985 71.6183 35.7036 71.8989 36.3349C72.1795 36.9522 72.3198 37.6327 72.3198 38.3763C72.3198 38.6428 72.2987 38.9515 72.2566 39.3022C72.2146 39.653 72.1584 39.9546 72.0883 40.2071H64.6174C64.6033 40.3054 64.5963 40.3966 64.5963 40.4807C64.5963 40.5649 64.5963 40.6491 64.5963 40.7333C64.5963 41.561 64.8208 42.2064 65.2698 42.6694C65.7187 43.1183 66.3571 43.3428 67.1848 43.3428C67.8162 43.3428 68.3704 43.2376 68.8474 43.0272C69.3384 42.8167 69.8084 42.494 70.2574 42.0591L71.4149 43.2376C70.7554 43.8409 70.082 44.2828 69.3945 44.5634C68.7211 44.83 67.9705 44.9633 67.1428 44.9633ZM70.6362 39.197C70.6643 38.9585 70.6853 38.7691 70.6993 38.6288C70.7134 38.4885 70.7204 38.3061 70.7204 38.0816C70.7204 37.6888 70.6362 37.317 70.4678 36.9662C70.2995 36.6015 70.0399 36.3068 69.6892 36.0824C69.3384 35.8579 68.8754 35.7456 68.3002 35.7456C67.6829 35.7456 67.1287 35.893 66.6377 36.1876C66.1607 36.4682 65.7608 36.847 65.4381 37.324C65.1295 37.787 64.912 38.2991 64.7857 38.8603H71.1834L70.6362 39.197Z"
                        fill="#4E67EA"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.1216 5.16943H32.5191H38.6394H45.7941C49.2965 5.16943 52.0268 5.92263 53.9851 7.42901C55.9811 8.9354 56.979 11.0632 56.979 13.8123C56.979 15.9589 56.4706 17.8796 55.4538 19.5743C54.4747 21.2313 53.0624 22.5305 51.2171 23.472C52.9495 24.0746 54.2676 25.0537 55.1714 26.4095C56.1129 27.7276 56.5836 29.3846 56.5836 31.3806C56.5836 34.1297 55.9811 36.4834 54.776 38.4417C53.6085 40.4 51.9515 41.9064 49.8049 42.9609C47.6583 44.0154 45.1351 44.5426 42.2353 44.5426H33.2591H26.9831H3.8935C1.9498 44.5426 0.552774 42.6731 1.10351 40.8091C1.4688 39.5727 2.60427 38.7242 3.8935 38.7242H27.8012L29.4056 27.3133H12.8511C10.9523 27.3133 9.55239 25.5389 9.99431 23.6922C10.3106 22.3706 11.4921 21.4384 12.8511 21.4384H30.2316L31.701 10.9879H21.1216C19.2124 10.9879 17.8208 9.17999 18.3092 7.33437C18.6469 6.05823 19.8015 5.16943 21.1216 5.16943ZM42.2353 38.5547H34.3833L35.965 27.1438H44.8338C46.6038 27.1438 47.9219 27.5393 48.7881 28.3301C49.6919 29.0833 50.1438 30.1755 50.1438 31.6065C50.1438 33.7908 49.4471 35.5043 48.0537 36.7471C46.698 37.9522 44.7585 38.5547 42.2353 38.5547ZM43.9865 21.2689H36.8123L38.2245 11.1573H45.2857C46.9427 11.1573 48.2043 11.5151 49.0705 12.2306C49.9367 12.9085 50.3698 13.8876 50.3698 15.1681C50.3698 16.3355 50.0873 17.39 49.5224 18.3315C48.9952 19.2353 48.242 19.9509 47.2629 20.4781C46.3214 21.0053 45.2292 21.2689 43.9865 21.2689Z"
                        fill="#4E67EA"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_2711_6153">
                        <rect width="141.617" height="48" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </Link>
    )
}

export default LogoHeaderAuth
