import { parseISO, format } from "date-fns";
import { formatDate } from "@shared/utils";

const isSameDay = (date1: Date, date2: Date) => {
    return date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();
};

const isYesterday = (date1: Date, date2: Date) => {
    const yesterday = new Date(date2);
    yesterday.setDate(date2.getDate() - 1);
    return isSameDay(date1, yesterday);
};

export const formatNotificationDate = (inputDate?: string) => {
    if (!inputDate) {
        return '';
    }

    const date = new Date(inputDate);
    const today = new Date();

    const diffMs = today.getTime() - date.getTime();
    const diffMins = diffMs / (1000 * 60);

    if (diffMs < 0) {
        return ''
    }

    const parsedDate = parseISO(inputDate);
    const formattedTime = format(parsedDate, 'HH:mm');

    if (diffMins < 1) {
        const diffSecs = Math.round(diffMs / 1000);
        return `${diffSecs} seconds ago`;
    } else if (diffMins < 60) {
        const diffMinsRounded = Math.round(diffMins);
        return `${diffMinsRounded === 1 ? '1 minute' : `${diffMinsRounded} minutes`} ago`;
    } else if (isSameDay(date, today)) {
        return `today | ${formattedTime}`;
    } else if (isYesterday(date, today)) {
        return `yesterday | ${formattedTime}`;
    } else {
        const formattedDate = formatDate(inputDate)
        return `${formattedDate} | ${formattedTime}`
    }
};
