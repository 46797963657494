import '../styles.scss'
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import RegistrationLayout from '@layouts/RegistrationLayout';
import React from 'react';
import { login, registration } from "@pages/routes.ts";
import initPageCard from '@assets/png/initPage/initPageCard.png'
import initPageCard1 from '@assets/png/initPage/initPageCard1.png'
import initPageCard2 from '@assets/png/initPage/initPageCard2.png'
import initPageArrow1 from '@assets/png/initPage/initPageArrow1.png'
import initPageArrow2 from '@assets/png/initPage/initPageArrow2.png'

const InitPage = () => {
    const navigate = useNavigate()

    const onLoginClick = () => {
        navigate(login)
    }

    const onRegistrationClick = () => {
        navigate(registration)
    }

    return (
        <RegistrationLayout
            className="init-page"
            // registration-layout__content-aside
            sidebar={(
                <div className="init-page__sidebar">
                    <Button
                        onClick={onLoginClick}
                        size="large"
                    >
                        Login
                    </Button>

                    <Button
                        onClick={onRegistrationClick}
                        type="primary"
                        size="large"
                    >
                        Sign up
                    </Button>
                </div>
            )}
        >
            <div className="init-page__left">
                <div className="init-page__title">
                    Collaborative Ads for the business events industry
                </div>
                <div className="init-page__subtitle">
                    Open a new monetization channel and drive free traffic while providing more value to your partners
                </div>
                <Button
                    className="init-page__get-started-btn"
                    onClick={onLoginClick}
                    type="primary"
                    size="large"
                >
                    Get started
                </Button>
            </div>
            <div className="init-page__right">
                <img className="init-page__card" src={initPageCard} alt='initPageCard' />
                <img className="init-page__card1" src={initPageCard1} alt='initPageCard1' />
                <img className="init-page__card2" src={initPageCard2} alt='initPageCard2' />
                <img className="init-page__arrow1" src={initPageArrow1} alt='initPageArrow1' />
                <img className="init-page__arrow2" src={initPageArrow2} alt='initPageArrow2' />
            </div>
        </RegistrationLayout>
    )
}

export default InitPage
