import React from "react";
import { Form, Input, Spin, Typography } from "antd";
import '../../styles.scss'
import { LoadingOutlined } from '@ant-design/icons';
import InputSuccessIcon from '@assets/InputSuccessIcon';

interface FormData {
    event_hash: string | undefined;
    company_name: string;
    country: {
        id: number;
        slug: string;
    } | null;
    city: {
        id: number;
        slug: string;
    } | null;
    postal_code: string;
    email: string;
    address: string;
    tax_code: string
}
type PartnerInvitationCodeFormPropsType = {
    form: FormData
    onFormChange: (field: Partial<FormData>) => void;
    isLoading: boolean;
    onResetHashError: () => void;
    codeIsValid: boolean
}

const InputSpinner = () => {
    return (
        <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
    )
}

const PartnerInvitationCodeForm: React.FC<PartnerInvitationCodeFormPropsType> = ({
    onFormChange,
    form,
    isLoading,
    codeIsValid
}) => {

    return (
        <div className='partner-invitation'>
            <section className='partner-invitation-form'>
                <div>
                    <Typography.Paragraph className='partner-invitation-form__header'>
                        <Typography.Title level={4} className='partner-invitation-form__header-title'>
                            Event invitation code
                        </Typography.Title>
                        <Typography.Text className='partner-invitation-form__header-subtitle'>
                            Provide an invitation code to join an event
                        </Typography.Text>
                    </Typography.Paragraph>

                    <Form
                        name="billingInfo"
                        initialValues={form}
                        // onFinishFailed={onError}
                        autoComplete="off"
                        layout="vertical"
                        onValuesChange={(field: Partial<FormData>) => onFormChange(field)}
                    >
                        <Form.Item
                            name='event_hash'
                            label="Invitation code">
                            <Input
                                suffix={
                                    isLoading
                                        ?
                                        <InputSpinner />
                                        :
                                        codeIsValid
                                            ?
                                            <InputSuccessIcon />
                                            :
                                            null
                                }
                                id="event_hash"
                                size='large'
                                disabled={codeIsValid}
                                value={form.event_hash}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </section>
        </div>
    )
}
export default PartnerInvitationCodeForm;
