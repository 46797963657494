import React from "react";
import styles from "./FooterPanel.module.css";

type FooterPanelPropsType = {
    children: React.ReactNode
}

const FooterPanel: React.FC<FooterPanelPropsType> = (props) => {
    const {children} = props;

    return (
        <div className={styles.FooterPanel}>
            <div className={styles.FooterPanel__content}>
                {children}
            </div>
        </div>
    )
}

export default FooterPanel;
