import React, { FC } from "react";
import { Avatar, List } from 'antd';
import StatusDescription from "@components/StatusDescription";
import { ModerationStatusesFormatted } from "@shared/constants";
import { formatCommentDate } from "@pages/EventRolePages/ModerationPage/helpers";
import { OrganizerHistory } from "@store/type-event/models";

type CommentListPropsType = {
    data: OrganizerHistory[]
}

const CommentList: FC<CommentListPropsType> = ({ data }) => {
    return (
        <List
            split={false}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
                <List.Item className='moderation-drawer__list-item'>
                    <List.Item.Meta
                        avatar={<Avatar size={28}>{item.user.name[0].toUpperCase()}</Avatar>}
                        title={
                            <div>
                                <span className='moderation-drawer__list-item__title'>{item.user.name}</span>
                                <span className='moderation-drawer__list-item__date'>{formatCommentDate(item.date)}</span>
                            </div>
                        }
                        description={
                            <span>
                                <div className="flex moderation-drawer__list-item__status">
                                    <span>
                                        Status has been updated to
                                    </span>
                                    <StatusDescription status={item.status.name} formattedStatusData={ModerationStatusesFormatted} />
                                </div>
                                {item.comment && (
                                    <div className='moderation-drawer__bottom'>
                                        {item.comment}
                                    </div>
                                )}
                            </span>
                        }
                    />
                </List.Item>
            )}
        />
    )
}

export default CommentList;
