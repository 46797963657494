import '../styles.scss'
import { Input, Form } from 'antd';
import CustomButton from '@components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import { usePasswordRecoverMutation } from '@store/main/-user/user.api';
import { useState } from 'react';
import RegistrationLayout from '@layouts/RegistrationLayout';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import PasswordRecoverConfirmedPage from './PasswordRecoverConfirmed';

interface ErrorsValues {
    email: string;
}

const errorsInitial = {
    email: '',
}
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: 'Enter a valid email!',
        number: 'Enter is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
/* eslint-enable no-template-curly-in-string */


const PasswordRecoverPage = () => {
    const [responseError, setResponseError] = useState<ErrorsValues>(errorsInitial)
    const [isLoading, setLoading] = useState(false)
    const [passwordRecover] = usePasswordRecoverMutation()
    const navigate = useNavigate()
    const [isShowConfirmed, setShowConfirmed] = useState(false)

    const [email, setEmail] = useState('')

    const onFinish = async () => {
        setLoading(true)
        try {
            const response = await passwordRecover({ email })

            if ('error' in response) {
                setLoading(false)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                setResponseError(errors as unknown as ErrorsValues)
            }

            if ('data' in response) {
                return setShowConfirmed(true)
            }

        } catch (error) {
            setLoading(false)
            console.log('SMTH WRONG');
        }
    };

    if (isShowConfirmed) {
        return <PasswordRecoverConfirmedPage email={email} />
    }

    return (
        <RegistrationLayout>
            <div className='auth'>
                <main className='auth__form'>
                    <p className='text-title'>
                        Recover your password
                    </p>
                    <p className='text-small auth__form-recover-desc'>
                        Enter the email that you used when register
                        to recover your password. You will receive
                        a password reset link.
                    </p>
                    <Form
                        name="basic"
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        autoComplete="off"
                        layout="vertical"
                        onChange={(e) => {
                            setResponseError(errorsInitial)
                            setEmail((e.target as HTMLInputElement).value)
                        }
                        }
                        validateMessages={validateMessages}

                    >
                        <Form.Item
                            name='email'
                            label="Email"
                            help={responseError.email}
                            validateStatus={responseError.email ? 'error' : ''}
                        >
                            <Input placeholder='Email' />
                        </Form.Item>

                        <Form.Item>
                            <CustomButton
                                title='Recover password'
                                classOptional='w-full'
                                type='primary'
                                size='large'
                                disabled={email.length < 4}
                                loading={isLoading}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={onFinish}
                            />
                        </Form.Item>
                    </Form>
                </main>
                <p className='auth__optional-footer'>
                    Don't need help anymore?{' '}
                    <Link to={'/login'}>
                        Log in
                    </Link>
                </p>
            </div>
        </RegistrationLayout>
    )
}

export default PasswordRecoverPage