//https://boost.allnetru.dev/api/documentation#/Notifications
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetNotificationsResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const notifications_list = 'notifications_list';

export const notificationsApi = createApi({
    reducerPath: notifications_list,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        notifications_list
    ],
    endpoints: build => ({
        getNotifications: build.query<GetNotificationsResponse, { page: number, status?: string }>({
            query: ({ page, status }) => {
                const statusesQueryString = status ? `filters[status]=${status}` : ''
                const pageQueryString = page ? `page=${page}` : ''
                const queryString = [
                    statusesQueryString,
                    pageQueryString
                ].filter(Boolean).join('&');
                return ({
                    url: `/notifications/${queryString.length ? "?" + queryString : ''}`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                })
            },
            providesTags: [notifications_list],
        }),
        postNotificationsAreRead: build.mutation<null, void>({
            query: () => ({
                method: 'POST',
                url: '/notifications',
                headers: {
                    Accept: 'application/json',
                },
            }),
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    usePostNotificationsAreReadMutation
} = notificationsApi;
