const MailIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="15" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9541_10698)">
                <path d="M13.5 1.49707H0.5C0.223437 1.49707 0 1.72051 0 1.99707V11.9971C0 12.2736 0.223437 12.4971 0.5 12.4971H13.5C13.7766 12.4971 14 12.2736 14 11.9971V1.99707C14 1.72051 13.7766 1.49707 13.5 1.49707ZM12.875 3.22832V11.3721H1.125V3.22832L0.69375 2.89238L1.30781 2.10332L1.97656 2.62363H12.025L12.6938 2.10332L13.3078 2.89238L12.875 3.22832ZM12.025 2.62207L7 6.52832L1.975 2.62207L1.30625 2.10176L0.692188 2.89082L1.12344 3.22676L6.46094 7.37676C6.61444 7.49601 6.80328 7.56074 6.99766 7.56074C7.19203 7.56074 7.38088 7.49601 7.53438 7.37676L12.875 3.22832L13.3062 2.89238L12.6922 2.10332L12.025 2.62207Z" fill={fillColor || "#252628"} />
            </g>
            <defs>
                <clipPath id="clip0_9541_10698">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MailIcon
