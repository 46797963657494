const MenuDotsHor = () => {
    return (
        <svg className='cursor-pointer' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" rx="5.6" fill="white" />
            <path d="M8.19291 15.5554C7.3338 15.5554 6.63735 14.859 6.63735 13.9999C6.63735 13.1408 7.3338 12.4443 8.19291 12.4443C9.05202 12.4443 9.74847 13.1408 9.74847 13.9999C9.74847 14.859 9.05202 15.5554 8.19291 15.5554Z" fill="#898A8C" />
            <path d="M14.0003 15.5554C13.1412 15.5554 12.4448 14.859 12.4448 13.9999C12.4448 13.1408 13.1412 12.4443 14.0003 12.4443C14.8594 12.4443 15.5559 13.1408 15.5559 13.9999C15.5559 14.859 14.8594 15.5554 14.0003 15.5554Z" fill="#898A8C" />
            <path d="M19.8077 15.5554C18.9486 15.5554 18.2522 14.859 18.2522 13.9999C18.2522 13.1408 18.9486 12.4443 19.8077 12.4443C20.6668 12.4443 21.3633 13.1408 21.3633 13.9999C21.3633 14.859 20.6668 15.5554 19.8077 15.5554Z" fill="#898A8C" />
        </svg>

    )
}

export default MenuDotsHor