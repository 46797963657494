
const PreviewBtnIcon = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8475_14786)">
                <path d="M8.5 0.5C3.80603 0.5 0 4.30603 0 9C0 13.694 3.80603 17.5 8.5 17.5C13.194 17.5 17 13.694 17 9C17 4.30603 13.194 0.5 8.5 0.5ZM11.234 9.13092L7.09029 12.1458C7.06757 12.1621 7.0408 12.1718 7.0129 12.1739C6.985 12.176 6.95707 12.1704 6.93217 12.1576C6.90727 12.1449 6.88636 12.1255 6.87175 12.1017C6.85713 12.0778 6.84938 12.0504 6.84933 12.0224V5.99654C6.84924 5.96851 6.85691 5.941 6.8715 5.91706C6.88608 5.89313 6.90701 5.8737 6.93196 5.86093C6.95691 5.84815 6.98491 5.84254 7.01286 5.84471C7.04081 5.84688 7.06761 5.85675 7.09029 5.87321L11.234 8.88616C11.2536 8.90001 11.2696 8.91834 11.2806 8.93964C11.2916 8.96093 11.2974 8.98456 11.2974 9.00854C11.2974 9.03252 11.2916 9.05614 11.2806 9.07744C11.2696 9.09873 11.2536 9.11707 11.234 9.13092Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_8475_14786">
                    <rect width="17" height="17" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default PreviewBtnIcon;
