import { Dropdown, MenuProps } from 'antd';
import React, { useMemo } from 'react'
import { FilterByEnumItem } from "@store/main/enums/models";

interface TagDropdownProps {
    children: React.ReactNode,
    onSentClick: ({ tagId, slug, statusId }: { tagId: string, statusId: string, slug: string }) => void
    tagId: string
    inviteTags: FilterByEnumItem[]
}
const TagDropdown = ({
    children,
    onSentClick,
    tagId,
    inviteTags
}: TagDropdownProps) => {
    const items: MenuProps['items'] = useMemo(() => {
        return inviteTags?.map(({ id, translate, slug }) => {
            return {
                key: id,
                label: (
                    <div
                        onClick={() => {
                            onSentClick({ tagId, statusId: id, slug })
                        }}
                    >
                        <span
                            className={`invitations-tab__tag invitations-tab__tag__drawer ${slug === 'sent' ? 'invitations-tab__tag-sent' : ''}`}

                        >
                            {translate}
                        </span>
                    </div>
                ),
            }
        })
    }, [inviteTags, onSentClick, tagId])

    return (
        <Dropdown
            menu={{ items }}
            overlayClassName='invitations-tab__tag__overlay'
        >
            {children}
        </Dropdown>
    )
}

export default TagDropdown
