import { Button, Progress, message } from 'antd';
import { twoColors } from '@pages/PartnerRolePages/BoostPro/constants';
import React, { useContext, useMemo, useState } from 'react';
import styles from './ProHeader.module.scss'
import cn from 'classnames';
import ProTooltip from '@components/ProTooltip/ProTooltip';
import { useAuth } from '@contexts/AuthContext';
import getProSubscription from '@pages/PartnerRolePages/BoostPro/helpers';
import EnableBoostProDrawer from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawer';
import SuccessModal from '@components/Subscriptions/BoostPro/SuccessModal';
import { StripeContext } from '@contexts/StripeContext';
import { usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import { usePostSubscriptionMutation } from '@store/main/subscription/subscription.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useBreakpoints } from '@hooks/browser';
import EnableBoostProDrawerMobile from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawerMobile';

const ProHeader = () => {
    const { user, reFetchUser } = useAuth()
    const isDesktop = useBreakpoints().md
    const [onSubscribe] = usePostSubscriptionMutation()
    const [postDefault] = usePostDefaultBillingMethodIdMutation()
    const { selectedCardId } = useContext(StripeContext);

    //TOFIX: ts error
    const { proSubscription, isProEnabled, isProVisible } = useMemo(() => user && getProSubscription(user), [user])
    const { money, percent, formattedDay } = proSubscription || {}
    const { spend, limit } = money || {}

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isProTooltipOpen, setIsProTooltipOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [successModalDate, setSuccessModalDate] = useState<string | boolean>('')

    const onCloseModal = () => {
        setIsModalOpen(false)
    }

    const onOpenModal = () => {
        setIsProTooltipOpen(false)
        setIsModalOpen(true)
    }
    const onEnablePro = async () => {
        setIsLoading(true)
        await postDefault({ id: selectedCardId })
        try {
            const response = await onSubscribe()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.enableProSubscription)
                setSuccessModalDate(response?.data?.data?.date || true)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }

    const tooltipTitle = isProEnabled ? (
        <span>
            Spend $
            {limit}
            {' '}
            before
            {' '}
            {formattedDay}
            {' '}
            to get a free Boost Pro for the next month
            this month to get PRO free of charge.
        </span>
    ) : (
        <span>
            Get expert campaign assistance with Boost PRO
            <Button
                className={cn("gradient-pro", styles.TooltipBtn)}
                size="large"
                onClick={onOpenModal}
            >
                Enable PRO
            </Button>
        </span>
    )
    return (
        isProVisible ? (
            <>
                <ProTooltip
                    isOpen={isProTooltipOpen}
                    onOpenChange={setIsProTooltipOpen}
                    title={tooltipTitle}
                >
                    <div className={cn(styles.Wrapper, isProEnabled ? styles.WrapperProEnabled : '')}>
                        <div className={cn(
                            "status-plug",
                            isProEnabled ? 'status-plug--green' : 'status-plug--red',
                            styles.Plug
                        )}
                        />
                        <span className={styles.Text}>PRO</span>
                        {isProEnabled && (
                            <>
                                <span className={styles.Divider} />
                                <div className={styles.ProgressWrapper}>
                                    <span className={styles.Amount}>
                                        $
                                        {spend}
                                        {' '}
                                        of $
                                        {limit}
                                    </span>
                                    <Progress
                                        className={styles.Progress}
                                        strokeColor={twoColors}
                                        showInfo={false}
                                        percent={+percent}
                                        size={[100, 2]}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </ProTooltip>
                <span className='settings-header__divider' />
                {isModalOpen && isDesktop
                    ?
                    <EnableBoostProDrawer
                        isOpen={isModalOpen}
                        onClose={onCloseModal}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onConfirm={onEnablePro}
                        isLoading={isLoading}
                    />
                    :
                    null}
                {isModalOpen && !isDesktop
                    ?
                    <EnableBoostProDrawerMobile
                        isOpen={isModalOpen}
                        onClose={onCloseModal}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onConfirm={onEnablePro}
                        isLoading={isLoading}
                    />
                    :
                    null}
                <SuccessModal
                    isOpen={Boolean(successModalDate)}
                    onClose={() => {
                        setSuccessModalDate('')
                        void reFetchUser()
                    }}
                    date={successModalDate}
                />
            </>
        ) : null
    )
}

export default ProHeader
