import { Subscription, UserType } from '@store/models-to replace/auth';
import { formatDateUtc } from '@shared/utils';
import { SubscriptionName, SubscriptionStatus } from '@store/main/subscription/models';

const getProSubscription = (user: UserType) => {
    const { organization } = user || {}
    if (!user || organization?.type === 'event') {
        return {
            proSubscription: null,
            isProEnabled: false,
            isProVisible: false
        }
    }

    let subscriptions = organization?.subscriptions || []
    if (!Array.isArray(organization?.subscriptions)) {
        subscriptions = [organization.subscriptions]
    }

    const proSubscription = subscriptions.find(({ type }: Subscription) => type?.name === SubscriptionName.boost_pro)
    const isProEnabled = proSubscription?.status?.name === SubscriptionStatus.active

    const { status, date = '', money } = proSubscription || {}
    const { name } = status || {}
    const { spend = 0, limit = 100 } = money || {}
    const percent = (spend * 100) / limit
    const statusName = name ? `${name[0].toUpperCase()}${name.slice(1, name.length)}` : ''
    const formattedDate = formatDateUtc(date)
    const formattedDay = formattedDate?.split(',')?.[0] || ''

    return {
        proSubscription: {
            ...proSubscription,
            percent,
            statusName,
            formattedDate,
            formattedDay,
            money: {
                spend,
                limit
            }
        },
        isProEnabled,
        isProVisible: organization?.type !== 'event'
    }
}

export default getProSubscription
