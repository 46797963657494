import CalendarIcon from '@assets/CalendarIcon'
import EnvironmentIcon from '@assets/EnvironmentIcon';
import ParticipantsIcon from '@assets/ParticipantsIcon';
import { formatDateRange } from '@shared/utils'
import './styles.scss'
interface Props {
    started_at: string;
    finished_at: string;
    location: string;
    participants: number
}
const EventInfoComponent = ({
    started_at,
    finished_at,
    location,
    participants
}: Props) => {
    return (
        <div className='flex'>
            <div className='event-info__item'>
                <CalendarIcon />
                <span className='event-info__location-text'>
                    {formatDateRange({ started_at, finished_at })}
                </span>
            </div>
            <div className='event-info__item'>
                <EnvironmentIcon />
                <span className='event-info__location-text'>
                    {location}
                </span>
            </div>
            <div className='event-info__item'>
                <ParticipantsIcon />
                <span className='event-info__location-text'>
                    {participants} participants
                </span>
            </div>
        </div>
    )
}

export default EventInfoComponent