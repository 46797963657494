import { Form, Input, Typography, Checkbox } from "antd";
import 'react-international-phone/style.css';
import { PhoneInput } from "react-international-phone";
import { ErrorsValues, FormCompanyValues } from '../models';
import RegistrationLayout from '@layouts/RegistrationLayout';
import CustomButton from '@components/CustomButton';
import './styles.scss'
import { InviteInfoResponse } from '@store/models-to replace/auth';
import { FORM_ITEM_PRIVACY, GOOGLE_PROVIDER, PRIVACY_LINK, TERMS_LINK } from "@pages/CommonPages/Authentification/Register/constants.ts";
import { Link } from 'react-router-dom';
import React from "react";
import LogoutDropdown from "@pages/CommonPages/Authentification/Register/components/LogoutDropdown/LogoutDropdown.tsx";

interface CreateCompanyStepProps {
    onSubmit: () => void;
    form: FormCompanyValues;
    formErrors: ErrorsValues;
    onFormChange: (value: Record<string, string>) => void;
    isLoading: boolean;
    inviteInfo?: InviteInfoResponse;
    provider?: string;
}

export const CreateCompanyStep = ({
    onSubmit,
    form,
    formErrors,
    onFormChange,
    isLoading,
    inviteInfo,
    provider
}: CreateCompanyStepProps) => {
    const MAX_PREFIX_NUMBER_PHONE = 4;
    const isDisableSubmitWithoutGoogleReg = !form.company_name || form.phone.length < MAX_PREFIX_NUMBER_PHONE || !form.name || !form.site || (provider === GOOGLE_PROVIDER && !form.privacy);
    const eventTitle = inviteInfo?.data?.event?.name || ''
    const eventImage = inviteInfo?.data?.event?.logo || ''

    // const errorText = formErrors.name || formErrors.company_name || formErrors.site || formErrors.phone
    return (
        <RegistrationLayout
            isCompleteRegistration
            headerInfo={{
                text: eventTitle,
                image: eventImage
            }}
            sidebar={(
                <LogoutDropdown />
            )}
        >
            <div className='auth'>
                <main className='auth__form'>
                    <p className='text-title mb-8'>
                        Complete your registration
                    </p>
                    <Typography.Text>
                        Please, provide some additional information to make our communication more personal
                    </Typography.Text>

                    <Form
                        layout='vertical'
                        name='create_company'
                        onValuesChange={onFormChange}
                        style={{ maxWidth: 600, marginTop: '24px' }}
                    >
                        <Form.Item
                            label={<span style={{ color: "rgba(89, 89, 89, 1)" }}>Full name</span>}
                            name='name'
                            help={formErrors.name}
                            validateStatus={formErrors.name ? "error" : ""}

                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<span style={{ color: "rgba(89, 89, 89, 1)" }}>Company</span>}
                            name='company_name'
                            help={formErrors.name}
                            validateStatus={formErrors.name ? "error" : ""}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<span style={{ color: "rgba(89, 89, 89, 1)" }}>Site</span>}
                            name='site'
                            help={formErrors.site}
                            validateStatus={formErrors.site ? "error" : ""}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<span style={{ color: "rgba(89, 89, 89, 1)" }}>Phone</span>}
                            name='phone'
                            help={formErrors.phone}
                            validateStatus={formErrors.phone ? "error" : ""}
                        >
                            <PhoneInput
                                inputClassName='registration-company_name-form__phone'
                                defaultCountry="us"
                            />
                        </Form.Item>
                        {provider === GOOGLE_PROVIDER && (
                            <Form.Item
                                name={FORM_ITEM_PRIVACY}
                                valuePropName="checked"
                            >
                                <Checkbox className='registration-account-form__checkbox'>
                                    {`I consent to Boost.Express processing my personal info as set out in the `}
                                    <Link
                                        to={`${PRIVACY_LINK}`}
                                        target="_blank"
                                    >
                                        Privacy
                                        {' '}
                                    </Link>
                                    and
                                    {' '}
                                    <Link
                                        to={`${TERMS_LINK}`}
                                        target="_blank"
                                    >
                                        Terms
                                    </Link>
                                </Checkbox>
                            </Form.Item>
                        )}
                        <Form.Item className='mt-24'>
                            <CustomButton
                                title='Continue'
                                htmlType="submit"
                                classOptional='w-full'
                                type='primary'
                                size='large'
                                loading={isLoading}
                                onClick={onSubmit}
                                disabled={isDisableSubmitWithoutGoogleReg}
                            />
                        </Form.Item>

                    </Form>
                </main>
            </div>
        </RegistrationLayout>

    )
};
