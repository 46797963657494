import { Button, Modal } from 'antd';

interface ModalDefaultCardProps {
    onCancel: () => void;
    onOk: () => void;
    paymentInfo: { last4: string, brand: string, id: string } | null;
    isLoading: boolean
}
const ModalDefaultCard = ({
    onCancel,
    onOk,
    paymentInfo,
    isLoading
}: ModalDefaultCardProps) => {
    if (!paymentInfo) {
        return null
    }
    return (
        <Modal
            title="Default payment method"
            open={!!paymentInfo}
            onOk={onOk}
            width={440}
            centered
            onCancel={onCancel}
            footer={[
                <footer className='flex items-center justify-end mt-24'>
                    <Button
                        key="back"
                        onClick={onCancel}
                        size='large'
                    >
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type='primary'
                        size='large'
                        loading={isLoading}
                        onClick={onOk}
                    >
                        Set as default
                    </Button>
                </footer>
            ]}
        >
            <p>{`Are you sure you want to set ${paymentInfo.brand} ending in ${paymentInfo.last4} as the default payment method?`}</p>
        </Modal>
    )
}

export default ModalDefaultCard