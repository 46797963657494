import React, { FC } from "react";
import { Modal } from "antd";
import { Link } from 'react-router-dom';
import ArrowIcon from '@assets/ArrowIcon';
import DrawerCommentIcon from "@assets/DrawerCommentIcon";
import CustomButton from "@components/CustomButton";
import { ModerationActionsFormatted, ModerationStatuses, ModerationStatusesFormatted } from "@shared/constants";
import StatusDescription from "@components/StatusDescription";
import { ModerationStatusAction, OrganizerLanding } from '@store/type-event/models';

type ChangeStatusModalPropsType = {
    isOpen: boolean;
    isDrawerOpen: boolean;
    onClose: () => void;
    onOpenDrawer: () => void;
    drawerData: OrganizerLanding | null;
    handleChangeStatus: ({ row, status }: { row: OrganizerLanding, status: ModerationStatusAction }) => void
}

const PreviewModal: FC<ChangeStatusModalPropsType> = ({ isOpen, isDrawerOpen, onClose, onOpenDrawer, drawerData, handleChangeStatus }) => {
    return (
        <Modal
            title="Landing page approval"
            open={isOpen}
            onCancel={onClose}
            className="preview-modal"
            centered
            closeIcon={false}
            width="100%"
            zIndex={1000}
            footer={(
                <>
                    <div className='preview-modal__back' onClick={onClose}>
                        <ArrowIcon fillColor='#C0C1C3' />
                        <span className='preview-modal__back-text'>
                            Back to Moderation
                        </span>
                    </div>
                    {!isDrawerOpen && (
                        <StatusDescription
                            status={drawerData?.status?.name}
                            formattedStatusData={ModerationStatusesFormatted}
                        />
                    )}
                    {drawerData?.status?.actions?.map((cta) => {
                        return (
                            <CustomButton
                                key={cta.id}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                title={ModerationActionsFormatted[cta.slug]}
                                type={cta.slug === ModerationStatuses.published ? 'primary' : 'default'}
                                block
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={() => handleChangeStatus({
                                    row: drawerData,
                                    status: cta
                                })}
                            />
                        )
                    })}
                </>
            )}
        >
            {Boolean(drawerData?.page) && (
                <Link to={drawerData.page} target="_blank">
                    <iframe
                        title={`moderation-table-preview_${drawerData.page}`}
                        className={`preview-modal__iframe ${isDrawerOpen ? 'preview-modal__iframe-width' : ''}`}
                        src={drawerData.page}
                    />
                </Link>
            )}
            {!isDrawerOpen && (
                <div className='preview-modal__comment-button' onClick={onOpenDrawer}>
                    <DrawerCommentIcon />
                </div>
            )}
        </Modal>
    )
}

export default PreviewModal;
