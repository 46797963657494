import CopyIcon from "@assets/CopyIcon";
import React, { useState, useMemo } from "react";
import { notification, Typography } from "antd";

const { Paragraph } = Typography

const InvitationCode = ({ link }: { link: string }) => {
    const [isCopyable, setIsCopyable] = useState(false)

    const copyable = useMemo(() => ({
        onCopy: () => {
            notification.open({
                message: (
                    <span>
                        The invitation code <b>{link}</b> has been copied to your clipboard
                    </span>
                ),
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'success',
            });
        },
        icon: [<CopyIcon />, <CopyIcon />],
        tooltips: false
    }), [link])

    return (
        <Paragraph
            onMouseEnter={() => {
                setIsCopyable(true)
            }}
            onMouseLeave={() => {
                setIsCopyable(false)
            }}
            className='invitation-code whitespace-nowrap'
            copyable={isCopyable ? copyable : null}
        >
            {link}
        </Paragraph>
    )
}

export default InvitationCode
