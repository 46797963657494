import EventCatalogTable from './components/EventCatalogTable'
import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb'
import MyEventFilterDrawer from './components/MyEventFilterDrawer'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { Input, DatePicker, Badge } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import CustomButton from '@components/CustomButton'
import ExitCrossIcon from '@assets/ExitCrossIcon'
import EventsPageLayout from '@layouts/EventsPageLayout'
import FilterButtonIcon from '@assets/FilterButtonIcon'
import MyEventsCatalogZeroState from './ZeroState'
import './styles.scss'
import dayjs from 'dayjs'
import { INIT_PAGE } from '@components/InfiniteScrollTable/constants';
import { debounce } from '../../../../../helpers/debounce';
import { useGetEventCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api'
import { EventCatalogFilter, GetEventCatalogDataItem } from '@store/type-partner/events-catalog/models'
import { useAuth } from "@contexts/AuthContext.tsx";
import { useNavigate } from "react-router-dom";
import { events } from "@pages/routes.ts";
import { useBreakpoints } from '@hooks/browser'
import FilterDrawer from './mobile/FilterDrawer'

const { RangePicker } = DatePicker;

const crumbs = [
    {
        url: '/events',
        label: 'My events',
        isZeroLevel: true
    },
    {
        url: '/events/catalog',
        label: 'Events catalog'
    },
]

const initialFilters: EventCatalogFilter = {
    search: '',
    date: {
        from: '',
        to: ''
    },
    participants: null,
    locations: [],
    categories: [],
    statuses: []
}
const MyEventsCatalogPage = () => {
    const isDesktop = useBreakpoints().md
    const [page, setPage] = useState(INIT_PAGE)
    const [filters, setFilters] = useState<EventCatalogFilter>(initialFilters)
    const [eventsList, setEventsList] = useState<GetEventCatalogDataItem[]>([])
    const [isOpenFilter, setOpenFilter] = useState(false)

    const { user } = useAuth()
    const navigate = useNavigate()

    const { data: res, isLoading, isFetching, refetch } = useGetEventCatalogQuery({ filters, page });

    const handleRefetch = useCallback(() => {
        // eslint-disable-next-line no-void
        void refetch({ filters, page });
    }, [filters, page, refetch])

    useEffect(handleRefetch, [page]);

    useEffect(() => {
        // todo remove after demo
        if (user?.id === 81) {
            navigate(events)
        }
    }, [navigate, user]);

    useEffect(() => {
        setEventsList((prevEvents) => {
            const from = res?.meta?.from || 0
            if (prevEvents.length < from) {
                return [...(prevEvents || []), ...(res?.data || [])]
            }
            return res?.data || []
        })
    }, [res])

    const onResetFilters = () => {
        setFilters(initialFilters)
        setPage(INIT_PAGE)
        onFilterUpdate(initialFilters)
    }

    const onFilterUpdate = (value: Partial<EventCatalogFilter>) => {
        setFilters((prev) => ({ ...prev, ...value }));
        setPage(INIT_PAGE)
    };

    const debouncedOnSearchUpdate = useCallback(
        debounce((search: { search: string }) => {
            onFilterUpdate(search);
        }, 500),
        [onFilterUpdate]
    );

    const onSearchChange = (search: { search: string }) => {
        debouncedOnSearchUpdate(search);
    }

    const countChangedFilters = () => {
        const { search, date, ...currentFilters } = filters;
        let changedFiltersCount = 0;

        Object.keys(currentFilters).forEach((key) => {
            const currentValue = filters[key as keyof EventCatalogFilter];
            const initialValue = initialFilters[key as keyof EventCatalogFilter];

            if (Array.isArray(currentValue) && Array.isArray(initialValue)) {
                if (
                    currentValue.length !== initialValue.length ||
                    !currentValue.every((value, index) => value === initialValue[index])
                ) {
                    changedFiltersCount++;
                }
            } else if (currentValue !== initialValue) {
                changedFiltersCount++;
            }
        });

        return changedFiltersCount;
    };

    useEffect(() => {
        void refetch()
    }, [])

    const isLastPage = useMemo(() => {
        const lastPage = res?.meta?.last_page || INIT_PAGE
        return page >= lastPage
    }, [page, res?.meta?.last_page])

    const onNextPage = useCallback(() => {
        setPage(page + 1)
    }, [page])

    if (isLoading) {
        return (
            <EventsPageLayout>
                <>
                    <HeaderBreadcrumb
                        crumbs={crumbs}
                    />
                    <article className='my-event-page'>
                        <MyEventsCatalogZeroState />
                    </article>
                </>
            </EventsPageLayout>
        )
    }

    return (
        <EventsPageLayout>
            <>
                {isDesktop && <HeaderBreadcrumb
                    crumbs={crumbs}
                />}
                <article className='my-event-page'>
                    {isDesktop
                        ?
                        <div className='my-event-page__filter-container flex'>
                            <div className='my-event-page__filter-container-text'>
                                <Input
                                    allowClear
                                    placeholder="Search by keywords"
                                    prefix={<SearchOutlined />}
                                    onChange={(e) => onSearchChange({ search: e.target.value })}
                                />
                            </div>
                            <div className='my-event-page__filter-container-range'>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    value={[filters?.date?.from ? dayjs(filters.date.from) : null, filters?.date?.to ? dayjs(filters.date.to) : null]}
                                    onChange={(dates, dateStrings) => onFilterUpdate({ date: { from: dateStrings[0], to: dateStrings[1] } })}
                                />
                            </div>
                            <div className='my-event-page__filter-container-filters-btn'>
                                <Badge color='geekblue' count={countChangedFilters()}>
                                    <CustomButton
                                        title='All filters'
                                        classOptional='black'
                                        prefix={<FilterButtonIcon />}
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={() => setOpenFilter(true)}
                                    />
                                </Badge>
                                {/* FilterBtnIcon */}
                            </div>
                            <div className='my-event-page__filter-container-filters-close-btn'>
                                <CustomButton
                                    type='text'
                                    size='large'
                                    // block
                                    title='Reset'
                                    classOptional='no-background'
                                    prefix={<ExitCrossIcon />}
                                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                    onClick={onResetFilters}
                                />
                            </div>
                        </div>
                        :
                        <FilterDrawer
                            filters={filters}
                            onResetFilters={onResetFilters}
                            debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                            onFilterUpdate={onFilterUpdate}
                        />
                    }

                    <div className='my-event-page__table'>
                        <EventCatalogTable
                            isLastPage={isLastPage}
                            onNextPage={onNextPage}
                            events={eventsList}
                            isLoading={isLoading || isFetching}
                            setPage={setPage}
                            page={page}
                        />
                    </div>

                </article>

                {isDesktop
                    ?
                    <MyEventFilterDrawer
                        isOpen={isOpenFilter}
                        onClose={() => setOpenFilter(false)}
                        filters={filters}
                        onResetFilters={onResetFilters}
                        onFilterUpdate={onFilterUpdate}
                    />
                    :
                    null}
            </>
        </EventsPageLayout>

    )
}

export default MyEventsCatalogPage