import { campaigns_create_new, events, events_edit } from "@pages/routes";
import { GetEventByIdFromCatalogResponse } from "@store/type-partner/events-catalog/models";

export const getCrumbs = (pathname: string, eventData: GetEventByIdFromCatalogResponse['data'] | undefined, campaignId: string) => {
    const id = eventData?.id || ''
    const firstItem = pathname.includes(events) ? (
        {
            url: id ? `${events_edit}/${id}` : events,
            label: eventData?.name || 'My events',
            idx: 1
        }
    ) : (
        {
            label: 'Campaign Editor',
            url: pathname?.includes(campaigns_create_new) ? `${campaigns_create_new}/${id}` : pathname.split('/landing')[0],
            idx: 1,
        }
    )
    const middleItem = pathname.includes(events) && campaignId ? [{
        label: 'Campaign Editor',
        url: pathname.split('/landing')[0],
        idx: 3,
    }] : []
    return ([
        firstItem,
        ...middleItem,
        {
            label: 'Landing Page Editor',
            url: pathname,
            idx: 2
        }
    ])
}

export const changeVideoUrl = (url?: string) => (url && url?.includes('youtube') ? url.replace('watch?v=', 'embed/') : url)
