const ThreeDotsIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.77805 11.6666C2.85757 11.6666 2.11138 10.9204 2.11138 9.99992C2.11138 9.07944 2.85757 8.33325 3.77805 8.33325C4.69852 8.33325 5.44471 9.07944 5.44471 9.99992C5.44471 10.9204 4.69852 11.6666 3.77805 11.6666Z" fill={fillColor || "#898A8C"}/>
            <path d="M10.0003 11.6666C9.0798 11.6666 8.3336 10.9204 8.3336 9.99992C8.3336 9.07944 9.0798 8.33325 10.0003 8.33325C10.9207 8.33325 11.6669 9.07944 11.6669 9.99992C11.6669 10.9204 10.9207 11.6666 10.0003 11.6666Z" fill={fillColor || "#898A8C"}/>
            <path d="M16.2225 11.6666C15.302 11.6666 14.5558 10.9204 14.5558 9.99992C14.5558 9.07944 15.302 8.33325 16.2225 8.33325C17.143 8.33325 17.8892 9.07944 17.8892 9.99992C17.8892 10.9204 17.143 11.6666 16.2225 11.6666Z" fill={fillColor || "#898A8C"}/>
        </svg>
    )
}

export default ThreeDotsIcon
