// https://boost.allnetru.dev/api/documentation/partner#/Events%20catalog
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EventCatalogFilter, GetEventByIdFromCatalogResponse, GetEventCatalogResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const partner_event_catalog = `partner_event_catalog`;

export const partnerEventCatalog = createApi({
    reducerPath: partner_event_catalog,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        partner_event_catalog
    ],
    endpoints: build => ({
        getEventCatalog: build.query<GetEventCatalogResponse, { filters: EventCatalogFilter, page: number }>({
            query: ({ filters, page }) => {
                const statusesQueryString = Array.isArray(filters?.statuses)
                    ? filters.statuses.map(id => `filters[statuses][]=${id}`).join('&')
                    : '';

                const categoriesQueryString = Array.isArray(filters?.categories)
                    ? filters.categories.map(id => `filters[categories][]=${id}`).join('&')
                    : '';

                const locationsQueryString = Array.isArray(filters?.locations)
                    ? filters.locations.map(id => `filters[locations][]=${id}`).join('&')
                    : '';
                const pageQueryString = page ? `page=${page}` : ''

                const queryString = [
                    statusesQueryString,
                    categoriesQueryString,
                    locationsQueryString,
                    pageQueryString
                ].filter(Boolean).join('&');

                const params = {
                    'filters[search]': filters?.search || undefined,
                    'filters[date][from]': filters?.date?.from ? filters?.date?.from : undefined,
                    'filters[date][to]': filters?.date?.to ? filters?.date?.to : undefined,
                };
                return {
                    url: `/partner/events/catalog${queryString.length ? "?" + queryString : ''}`,
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                    //ASAP: use queryString only? check for bugs
                    params,
                };
            },
            providesTags: [partner_event_catalog],
        }),
        getEventByIdFromCatalog: build.query<GetEventByIdFromCatalogResponse, { id: string }>({
            query: ({ id }) => ({
                url: `/partner/events/catalog/${id}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            providesTags: [partner_event_catalog],
        }),

    }),
});

export const {
    useGetEventByIdFromCatalogQuery,
    useGetEventCatalogQuery
} = partnerEventCatalog;