import MenuDotsHor from '@assets/billing/MenuDotsHor'
import VisaLogo from '@assets/cards/Visa';
import { Dropdown, MenuProps } from 'antd'
import IsDefaultPlug from '../components/IsDefaultPlug';
import MasterCardLogo from '@assets/cards/MasterCard';
import BrandedCardItem from '@pages/PartnerRolePages/Billing/components/BrandedCardItem';
import { useBreakpoints } from '@hooks/browser';

interface PaymentMethodRowProps {
    isDefault?: boolean;
    last4: string;
    brand: string;
    id: string;
    onDeleteClick: (payment: { last4: string, brand: string, id: string } | null) => void
    onDefaultClick: (payment: { last4: string, brand: string, id: string } | null) => void
}

export const CardLogo = ({ brand }: { brand: string }) => {
    switch (brand) {
        case 'mastercard':
            return <MasterCardLogo />;
        case 'visa':
            return <VisaLogo />;
        default:
            return <></>;
    }
}



const PaymentMethodRow = ({
    isDefault,
    last4,
    brand,
    id,
    onDeleteClick,
    onDefaultClick
}: PaymentMethodRowProps) => {
    const isDesktop = useBreakpoints().md

    const items: MenuProps['items'] = [
        {
            label: <div onClick={() => onDefaultClick({ last4, brand, id })}>Set as default</div>,

            key: 'default',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={() => onDeleteClick({ last4, brand, id })}>Delete card</div>,
            key: 'delete',
        },
    ]
    return (
        <div className='billing-page-content__payment-method__row'>

            {isDesktop
                ?
                <div className='billing-page-content__payment-method__row-title'>
                    {
                        isDefault
                            ?
                            <span>Payment method</span>
                            :
                            null
                    }

                </div>
                :
                null
            }
            <BrandedCardItem brand={brand} last4={last4} isDefault={isDefault} />
            <div className='billing-page-content__payment-method__row-cta'>

                <Dropdown
                    trigger={['click']}
                    menu={{ items }}
                    placement="bottomRight"
                >
                    <div onClick={(e) => e.preventDefault()}>
                        <MenuDotsHor />
                    </div>
                </Dropdown>

                {/* BLOCKED FOR THE MOMENT */}
                {/* <Button
                    type="link"
                    style={{ padding: '0px', marginLeft: '6px' }}
                // onClick={() => setSectionType('billing_info')}
                >
                    Edit
                </Button> */}
            </div>
        </div>
    )
}

export default PaymentMethodRow
