//https://boost.allnetru.dev/api/documentation#/Categories
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetCategorieByFiltersResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const categories_filter = `categories_filter`;

export const categories = createApi({
    reducerPath: categories_filter,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        categories_filter
    ],
    endpoints: build => ({
        getCategorieByFilters: build.query<GetCategorieByFiltersResponse, { search: string }>({
            query: ({ search }) => ({
                url: `/categories/filter/${search}`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
        }),
    }),
});

export const {
    useGetCategorieByFiltersQuery
} = categories;