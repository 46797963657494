import EventsPageLayout from '@layouts/EventsPageLayout'
import React, { useEffect } from "react";
import './styles.scss'
import UsersAndRoles from "@pages/PartnerRolePages/SettingsPage/components/UsersAndRoles.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { getSettingsList, SettingsIds } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import { settings } from "@pages/routes.ts";
import UserProfile from "@pages/CommonPages/UserProfile";
import AdAccountsPage from "@pages/EventRolePages/AdAccountsPage";
import { useAuth } from '@contexts/AuthContext';

type Props = {
    isEvent?: boolean
}

const SettingsPage = ({ isEvent }: Props) => {
    const { tabId } = useParams()
    const navigate = useNavigate()
    const { user: data, isUserLoading } = useAuth();

    const isAdmin = data?.organization?.type === 'admin'

    useEffect(() => {
        if (!tabId) {
            navigate(`${settings}/${SettingsIds.profile}`)
        }
    }, [tabId, navigate]);

    return (
        <EventsPageLayout>
            <div className="settings-page__wrapper">
                <div className="settings-page__list">
                    {getSettingsList({ isEvent, isAdmin }).map((item) => {
                        if (!item) return null
                        const { id, label } = item
                        return (
                            <div
                                key={id}
                                className={`settings-page__list-item ${tabId === id ? 'settings-page__list-item__active' : ''}`}
                                onClick={() => {
                                    navigate(`${settings}/${id}`)
                                }}
                            >
                                {label}
                            </div>
                        )
                    })}
                </div>
                <div className="settings-page__content">
                    {!isAdmin && tabId === SettingsIds.usersRoles && (
                        <UsersAndRoles isEvent={isEvent} />
                    )}
                    {tabId === SettingsIds.profile && (
                        <UserProfile />
                    )}
                    {tabId === SettingsIds.adAccounts && (
                        <AdAccountsPage />
                    )}
                </div>
            </div>
        </EventsPageLayout>
    )
}

export default SettingsPage
