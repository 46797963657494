import React, { useEffect, useState } from "react";
import { Select, Spin } from "antd";
import EnvironmentIcon from "@assets/EnvironmentIcon.tsx";
import styles from "./Locations.module.css";
import { debounce } from '../../../../helpers/debounce';
import { useGetCitiesBySearchQuery } from '@store/main/cities/cities.api';

export type LocationType = {
    id: number
    slug: string
}

type LocationsPropsType = {
    initialPlaceholder?: string;
    onLocationChange: (fieldName: string, newLocation: LocationType) => void
    className?: string
}
const Locations: React.FC<LocationsPropsType> = (props) => {
    const { onLocationChange, className = '' } = props;

    const [searchLocation, setSearchLocation] = useState("");

    const [query, setQuery] = useState('')
    const { data: locations, isLoading: locationsLoading } = useGetCitiesBySearchQuery({ search: query }, { skip: !searchLocation })


    const debouncedSetQuery = debounce((searchLocation: string) => {
        setQuery(searchLocation || '');
    }, 250);

    // Call the debounced function whenever searchLocation changes
    useEffect(() => {
        debouncedSetQuery(searchLocation);
    }, [searchLocation, debouncedSetQuery]);



    const getLocations = (arr: LocationType[]) => {
        return arr.map((location) => {
            return { label: location.slug, value: location.id }
        })
    }

    return (
        <div className={className}>
            <Select
                className={styles.Locations}
                filterOption={false}
                showSearch
                placeholder={props?.initialPlaceholder ? <span className='text-regular-14'>{props.initialPlaceholder}</span> : ''}
                onSearch={(value) => setSearchLocation(value)}
                options={locations?.data.length ? getLocations(locations?.data) : []}
                onSelect={(_, option) => onLocationChange("location", { id: option.value, slug: option.label })}
                notFoundContent={locationsLoading ? <Spin size="small" /> : null}
            />
            <div className={styles.Locations__iconBlock}>
                <EnvironmentIcon widthProp={"16"} heightProp={"16"} />
            </div>
        </div>
    )
}

export default Locations;
