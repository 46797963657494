import { useRef } from 'react';

const useSwipeDown = (onSwipeDown: () => void, threshold = 50) => {
    const touchStartY = useRef(0);
    const touchEndY = useRef(0);

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStartY.current = e.touches[0].clientY;
    };

    const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        touchEndY.current = e.changedTouches[0].clientY;
        handleSwipe();
    };

    const handleSwipe = () => {
        if (touchEndY.current - touchStartY.current > threshold) {
            onSwipeDown();
        }
    };

    return {
        onTouchStart,
        onTouchEnd,
    };
};

export default useSwipeDown;
