const GoogleIcon = () => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="42" height="42" rx="21" fill="#F2F3F5" />
            <path
                d="M9.97425 25.0141L17.6496 11.875C18.6245 12.4489 23.5408 15.1695 24.3348 15.6869L16.6595 28.8268C15.82 29.9356 8.9108 26.6968 9.97425 25.0133V25.0141Z"
                fill="#FBBC04"
            />
            <path
                d="M32.0283 25.0124L24.353 11.8741C23.28 10.0885 20.9689 9.43966 19.0708 10.4943C17.1727 11.5489 16.5948 13.8207 17.6678 15.686L25.3431 28.8259C26.4161 30.6106 28.7272 31.2595 30.6253 30.2049C32.4405 29.1503 33.1013 26.798 32.0283 25.014V25.0124Z"
                fill="#4285F4"
            />
            <path
                d="M13.2981 30.713C15.426 30.713 17.1509 29.031 17.1509 26.9561C17.1509 24.8812 15.426 23.1992 13.2981 23.1992C11.1703 23.1992 9.44531 24.8812 9.44531 26.9561C9.44531 29.031 11.1703 30.713 13.2981 30.713Z"
                fill="#34A853"
            />
        </svg>
    )
}

export default GoogleIcon
