import styles from './EmailConfirmation.module.scss'
import React, { useState } from 'react';
import { Button } from 'antd'
import WarningIcon from '@assets/WarningIcon';
import MailIcon from '@assets/MailIcon';
import cn from 'classnames';
import EmailConfirmationModal from '@components/EmailConfirmation/EmailConfirmationModal';
import { useAuth } from '@contexts/AuthContext';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { usePostEmailVerificationMutation } from '@store/main/email/email.api.ts';
import { ErrorDataResponse } from "@shared/utils.ts";
import { EmailStatuses } from "@store/main/email/models.ts";

const EmailConfirmationAlert = () => {
    const { emailConfirmedData, setEmailConfirmedData } = useAuth()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [postEmail] = usePostEmailVerificationMutation()

    const onClose = () => {
        setIsModalOpen(false)
    }

    const onConfirm = async () => {
        sendAnalytics(GTMEventName.confirmEmail)

        try {
            const response = await postEmail()

            if ('data' in response) {
                if (response?.data?.status === EmailStatuses.VerificationLinkSent) {
                    setEmailConfirmedData({ isConfirmed: true, error: '' })
                    setIsModalOpen(true)
                }
            }
            if ((response as ErrorDataResponse)?.error?.data?.message) {
                setEmailConfirmedData({
                    isConfirmed: false,
                    error: (response as ErrorDataResponse)?.error?.data?.message as string
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            {
                !emailConfirmedData?.isConfirmed ? (
                    <div className={cn(styles.Alert, 'email-confirmation-alert')}>
                        <WarningIcon />
                        <span className={styles.Description}>{emailConfirmedData?.error ? emailConfirmedData.error : 'Your e-mail requires confirmation'}</span>
                        <Button
                            title='Resend email confirmation'
                            onClick={onConfirm}
                            disabled={!!emailConfirmedData?.error}
                            type="default"
                            icon={<MailIcon />}
                        >
                            Resend email confirmation
                        </Button>
                    </div>
                ) : null
            }

            <EmailConfirmationModal
                isOpen={isModalOpen}
                onClose={onClose}
            />
        </>
    )
}

export default EmailConfirmationAlert
