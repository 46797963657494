import { ReactNode } from 'react'
import './styles.scss'
interface Props {
    children: ReactNode
}
const GoalBadges = ({ children }: Props) => {
    return (
        <div className='goal-badges'>
            {children}
        </div>
    )
}

export default GoalBadges