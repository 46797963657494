const LogoutIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.61363 9.0006C5.61363 8.59914 5.93908 8.27368 6.34055 8.27368L14.5143 8.27367L12.5639 6.32324C12.28 6.03936 12.28 5.5791 12.5639 5.29521C12.8478 5.01133 13.308 5.01133 13.5919 5.29522L16.7833 8.48658C16.9196 8.62291 16.9962 8.8078 16.9962 9.0006C16.9962 9.19339 16.9196 9.37828 16.7833 9.51461L13.5919 12.706C13.308 12.9898 12.8478 12.9898 12.5639 12.706C12.28 12.4221 12.28 11.9618 12.5639 11.6779L14.5143 9.72752L6.34055 9.72753C5.93908 9.72753 5.61363 9.40207 5.61363 9.0006Z"
                fill="#898A8C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.45763 2.28099V15.7202H9.5141V13.7083C9.5141 13.5936 9.60709 13.5006 9.72179 13.5006H10.7603C10.875 13.5006 10.9679 13.5936 10.9679 13.7083V15.7379C10.9679 16.5311 10.325 17.174 9.53183 17.174H2.4399C1.64675 17.174 1.00378 16.5311 1.00378 15.7379V2.26326C1.00378 1.47012 1.64676 0.827148 2.4399 0.827148H9.53183C10.325 0.827148 10.9679 1.47012 10.9679 2.26326V4.29291C10.9679 4.40762 10.875 4.5006 10.7603 4.5006H9.72179C9.60709 4.5006 9.5141 4.40762 9.5141 4.29291V2.28099H2.45763Z"
                fill="#898A8C"
            />
        </svg>

    )
}

export default LogoutIcon
