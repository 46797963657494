import StatusTag from '@components/StatusTag'
import './styles.scss'
import { CampaignStatuses } from '@shared/constants'
import CustomButton from '@components/CustomButton'
import { ErrorDataResponse, getTrimmedUrl } from '@shared/utils'
import { useState } from 'react'
import { Button, message } from 'antd'
import Loader from '@components/Loader'
import { useGetPartnerEventByIdQuery, usePatchPartnerEventStatusByIdMutation } from '@store/type-partner/events/partner-event.api'
import { GetPartnerEventByIdDataItem } from '@store/type-partner/events/models'
import EventInfoComponent from '@pages/CommonPages/components/events/EventInfoComponent'
import { useBreakpoints } from '@hooks/browser'

const EventInfo = ({ event }: { event: GetPartnerEventByIdDataItem }) => {
    const isDesktop = useBreakpoints().md

    const [patchEventStatus] = usePatchPartnerEventStatusByIdMutation()
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)
    const { refetch } = useGetPartnerEventByIdQuery({ id: event.id.toString() })

    const handlePatchEventStatus = async ({ event, status }: { event: number, status: number }) => {
        try {
            setIsLoadingStatus(true)
            const response = await patchEventStatus({ event, status });

            if ('error' in response) {
                setIsLoadingStatus(false)
                const err = response as unknown as ErrorDataResponse
                void message.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content: err?.error?.data?.message || 'OOOPS, something is wrong',
                });
                // Handle the error here
            } else {
                //TODO: add error handler
                refetch().finally(() => setIsLoadingStatus(false)
                )
                return void message.open({
                    type: 'success',
                    content: 'Status updated',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            console.log('SMTH WRONG');
            return void message.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
            // Handle the error here
        }
    }
    return (
        <article className='event-info'>
            {isLoadingStatus && <Loader />}
            <header className='event-info__header flex items-center justify-space-between'>
                <div className='flex items-center w-full'>
                    <img className='event-info__header-logo' src={event?.logo} alt="*" />
                    <h2 className='event-info__header-title'>{event?.name}</h2>

                    <StatusTag
                        status={event?.status?.name as CampaignStatuses}
                    />
                </div>

                {isDesktop && <div className='flex-center'>
                    {event.status.actions.length ?
                        event.status.actions.map(action => {
                            return (

                                <div key={action.id} className='ml-6'>
                                    <CustomButton
                                        title={action.transit}
                                        onClick={() => void handlePatchEventStatus({ event: event.id, status: action.id })}
                                    />
                                </div>
                            )
                        })
                        :
                        null}
                </div>}
            </header>
            <EventInfoComponent
                started_at={event.date.started_at}
                finished_at={event.date.finished_at}
                location={event.location}
                participants={event.participants}
            />

            <div className='event-info-desc flex items-center'>
                {event?.category && (
                    <span className='event-info__description-text'>
                        {event.category}
                    </span>
                )}

                {event.url && (
                    <>
                        <div className='text-divider-v' />
                        <span className='event-info__description-text'>
                            <a href={event.url} target='_blank' className='no-style company-pointer'>
                                {getTrimmedUrl(event.url)}
                            </a>
                        </span>
                    </>
                )}

                {event?.organization && (
                    <>
                        <div className='text-divider-v' />
                        <span className='event-info__description-text'>
                            Organized by {event.organization || ''}
                        </span>
                    </>
                )}
            </div>
            {!isDesktop && <div className='event-info__header__cta-container'>
                {event.status.actions.length ?
                    event.status.actions.map((action, idx) => {
                        return (

                            <Button
                                key={action.id}
                                className={`${idx === 0 && event.status.actions.length > 1 ? 'mr-6' : ''}`}
                                size='large'
                                onClick={() => void handlePatchEventStatus({ event: event.id, status: action.id })}
                            >
                                {action.transit}
                            </Button>
                        )
                    })
                    :
                    null}
            </div>}
        </article>
    )
}

export default EventInfo