import { Button } from 'antd';
import React from 'react';
import './styles.scss'
interface CustomButtonProps {
    shape?: 'round' | 'circle' | 'default' | undefined;
    title: string;
    type?: "default" | "primary" | "ghost" | "dashed" | "link" | "text";
    prefix?: JSX.Element;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    size?: "large" | "middle" | 'small';
    block?: boolean;
    classOptional?: "blue" | "danger" | 'w-full' | "black" | 'no-background' | '';
    htmlType?: "submit",
    ghost?: boolean,
    loading?: boolean
}

const CustomButton: React.FC<CustomButtonProps> = (
    {
        shape = 'default',
        title,
        type = 'default',
        prefix,
        onClick,
        disabled,
        size = "middle",
        block = false,
        classOptional,
        htmlType,
        ghost,
        loading
    }) => {
    return (
        <Button
            className={`btn-custom ${classOptional || ''}`}
            shape={shape}
            type={type}
            onClick={onClick}
            disabled={disabled}
            size={size}
            block={block}
            htmlType={htmlType}
            ghost={ghost}
            loading={loading}
        >
            <div className='flex-center'>
                {prefix
                    ?
                    <div className='btn-custom__prefix'>
                        {prefix}
                    </div>
                    :
                    null

                }
                <span>{title}</span>
            </div>
        </Button>
    );
};

export default CustomButton;
