export const initialEventErrors = {
    image_logo: '',
    category: '',
    'schedule.started_at': '',
    'schedule.finished_at': '',
    name: '',
    location: '',
    image_bg: '',
    description: '',
    participants: '',
    url: '',
    logo: '',
    image: '',
}

export const EventErrorFields: Record<string, Record<string, string>> = {
    eventName: { 'name': '' },
    avatarUrl: { 'logo': '' },
    heroImageUrl: { 'image': '' },
    overview: { 'description': '' },
    url: { 'url': '' },
    location: { 'location': '' },
    category: { 'category': '' },
    participants: { 'participants': '' },
    dates:
        {
            'schedule.finished_at': '',
            'schedule.started_at': ''
        }
}
