export const InitialUserEdit = {
    name: '',
    email: '',
    roles: [],
    password: '',
    new_password: '',
    isGeneratePassword: true,
    isChangePassword: false
}

export const InitialUserEditErrors = {
    name: '',
    email: '',
    roles: '',
    password: '',
    new_password: ''
}

export enum TabIds {
    UsersAndRoles = 'UsersAndRoles',
    Profile = 'Profile',
    AdAccounts = 'AdAccounts'
}

export const SettingsNames = {
    profile: 'User profile',
    company: 'Company profile',
    adAccounts: 'Ad Accounts',
    communications: 'Communications',
    usersRoles: 'Users & Roles',
}


export const SettingsIds = {
    profile: 'user-profile',
    company: 'company',
    adAccounts: 'ad-accounts',
    communications: 'communications',
    usersRoles: 'users-roles',
}

export const getSettingsList = ({
    isEvent,
    isAdmin
}: { isEvent?: boolean, isAdmin?: boolean }) => [
        {
            id: SettingsIds.profile,
            label: SettingsNames.profile,
        },
        // {
        //     id: SettingsIds.company,
        //     label: SettingsNames.company,
        // },
        isEvent || isAdmin ? {
            id: SettingsIds.adAccounts,
            label: SettingsNames.adAccounts,
        } : null,
        // {
        //     id: SettingsIds.communications,
        //     label: SettingsNames.communications,
        // },
        !isAdmin ? {
            id: SettingsIds.usersRoles,
            label: SettingsNames.usersRoles,
        } : null
    ]
