import './styles.scss'
import { useContext, useMemo, useState } from 'react'
import { UserBillingPageContext } from '@contexts/UserBillingPageContext'
import MenuDotsHor from '@assets/billing/MenuDotsHor'
import RocketProColored from '@assets/RocketProColored'
import { Dropdown, MenuProps, message } from 'antd'
import { useAuth } from '@contexts/AuthContext'
import CancelModal from '@components/Subscriptions/BoostPro/modals/CancelModal'
import getProSubscription from '@pages/PartnerRolePages/BoostPro/helpers'
import { UserType } from '@store/models-to replace/auth'
import { useDeleteSubscriptionMutation } from '@store/main/subscription/subscription.api'
import { userActions } from "@store/main/-user/user.slice";
import { SubscriptionName } from "@store/main/subscription/models";
import { useDispatch } from "react-redux";
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

const BoostProIsActive = () => {
    const { setSectionType } = useContext(UserBillingPageContext);
    const { user } = useAuth()
    const { proSubscription } = useMemo(() => getProSubscription(user as unknown as UserType), [user])
    const [onDeleteSubscription] = useDeleteSubscriptionMutation()
    const dispatch = useDispatch()

    const [subscriptionToDelete, setSubscriptionToDelete] = useState(null)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const proList = user && user?.organization?.subscriptions && user.organization.subscriptions.filter(item => item.status?.id === 1 && item.status.name === 'active')

    const onCancelSubscription = async () => {
        setIsLoading(true)
        try {
            const response = await onDeleteSubscription()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.cancelProSubscription)
                dispatch(userActions.cancelSubscription(SubscriptionName.boost_pro))
                setIsCancelModalOpen(false)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }

    const items: MenuProps['items'] = [
        {
            label: <div onClick={() => setIsCancelModalOpen(true)}>Cancel subscription</div>,
            key: 'unsubscribe',
        }
    ]

    return (
        <div
            className='banner-boost-pro-billing-is-active'
        >

            <header>
                <div className='flex justify-space-between mb-12'>
                    <div className='flex'>
                        <RocketProColored />
                        <span className='text-title-18 ml-6'>
                            Boost PRO
                        </span>

                    </div>
                    <aside>
                        <Dropdown
                            trigger={['click']}
                            menu={{ items }}
                            placement="bottomRight"
                        >
                            <div onClick={(e) => e.preventDefault()}>
                                <MenuDotsHor />
                            </div>
                        </Dropdown>
                    </aside>
                </div>

                <span className='text-regular-14 text-subdued'>
                    You will be automatically charged on the 1st of every month.
                </span>

                <footer className='flex justify-space-between mt-12'>
                    <div className='flex '>
                        <span className='text-regular-14 text-subdued'>
                            Next payment:

                        </span>
                        <span className='text-regular-14 ml-6'>
                            {proSubscription?.formattedDate}
                        </span>
                    </div>

                    <span className='text-title-18'>
                        $200,00
                    </span>
                </footer>
            </header>
            <CancelModal
                isOpen={isCancelModalOpen}
                onClose={() => setIsCancelModalOpen(false)}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onCancelSubscription={onCancelSubscription}
                isLoading={isLoading}
                date={proSubscription?.formattedDate || ''}
            />
        </div>
    )
}

export default BoostProIsActive