import SkeletonCustom from '@components/SkeletonCustom'
import EventItemCardSkeleton from './components/EventItemCardSkeleton'
import EventCatalogTableZeroState from '../../MyEventsCatalog/ZeroState/components/TableZeroState'
import EventItemFullCardSkeleton from './components/EventItemFullCardSkeleton'

const MyEventsSkeletonMobileZeroState = () => {
    return (
        <div>
            <div className='my-event-page__filter-container flex'>
                <div className='mr-6'>
                    <SkeletonCustom type='title' width='232px' />
                </div>
                {/* <div className='mr-6'>
                    <SkeletonCustom type='title' width='232px' />
                </div>
                <div className='mr-6'>
                    <SkeletonCustom type='title-m' width='112px' />
                </div>
                <div className='mx-6'>
                    <SkeletonCustom type='title-m' />
                </div> */}
            </div>

            <div className='flex-col'>
                <EventItemFullCardSkeleton />
                <EventItemFullCardSkeleton />
                <EventItemFullCardSkeleton />
            </div>
        </div>
    )
}

export default MyEventsSkeletonMobileZeroState