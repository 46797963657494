
const GoogleLogo = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9028_3671)">
                <path d="M8.5 6.5459V9.64409H12.8054C12.6164 10.6404 12.049 11.4841 11.1981 12.0514L13.7945 14.0659C15.3072 12.6696 16.18 10.6187 16.18 8.18234C16.18 7.61508 16.1291 7.06957 16.0345 6.54599L8.5 6.5459Z" fill="#4285F4" />
                <path d="M4.01576 9.52246L3.43018 9.97071L1.35742 11.5852C2.67378 14.1961 5.37176 15.9998 8.49901 15.9998C10.659 15.9998 12.4698 15.287 13.7935 14.0653L11.1972 12.0507C10.4844 12.5307 9.57533 12.8216 8.49901 12.8216C6.41902 12.8216 4.6518 11.418 4.01903 9.52708L4.01576 9.52246Z" fill="#34A853" />
                <path d="M1.35812 4.41406C0.812695 5.49038 0.5 6.70494 0.5 7.99947C0.5 9.29399 0.812695 10.5086 1.35812 11.5849C1.35812 11.5921 4.01998 9.51942 4.01998 9.51942C3.85998 9.03942 3.76541 8.53036 3.76541 7.99939C3.76541 7.46841 3.85998 6.95935 4.01998 6.47935L1.35812 4.41406Z" fill="#FBBC05" />
                <path d="M8.49918 3.18545C9.67737 3.18545 10.7246 3.59271 11.561 4.37818L13.8519 2.0873C12.4628 0.792777 10.6592 0 8.49918 0C5.37193 0 2.67378 1.79636 1.35742 4.41455L4.0192 6.48001C4.65189 4.58908 6.41919 3.18545 8.49918 3.18545Z" fill="#EA4335" />
            </g>
            <defs>
                <clipPath id="clip0_9028_3671">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default GoogleLogo