import Avatar from "@components/Avatar";
import tourAvatar from "@assets/png/tourAvatar.png";
import './styles.scss'
import { ConfigProvider, Tour, TourProps } from 'antd';
import React from 'react'

type Props = {
    totalCount: number;
} & TourProps

const TourComponent = (props: Props) => {
    const { totalCount, ...rest } = props

    const indicatorsRender = (current: number) => (
        <div className="tour-indicators">
            {current + 1}
            /
            {totalCount}
        </div>
    )

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorBgElevated: '#303953',
                    colorIcon: '#FFFFFF66',
                    colorIconHover: '#FFFFFF66',
                    colorPrimary: '#4E67EA',
                    colorText: '#FFFFFF'
                },
            }}
        >
            <Tour
                gap={{
                    offset: [-16, 0]
                }}
                indicatorsRender={indicatorsRender}
                prefixCls="tour-component"
                scrollIntoViewOptions={{ behavior: 'smooth' }}
                {...rest}
            />
        </ConfigProvider>
    )
}

const TourTitle = () => {
    return (
        <div className="flex gap-8">
            <Avatar url={tourAvatar} size="38" bordered={false} />
            <div className="tour-title">Danis from Boost.Express</div>
        </div>
    )
}

export { TourComponent, TourTitle }
