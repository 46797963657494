import Avatar from '@components/Avatar'
import Loader from '@components/Loader'
import StatusTag from '@components/StatusTag'
import { CampaignStatuses, EventCatalogItemStatuses, HELP_CENTER_LINK } from '@shared/constants'
import { getTrimmedUrl } from '@shared/utils'
import { Button, Dropdown, MenuProps, message, Modal } from 'antd'
import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { usePatchOrganizerEventStatusByIdMutation } from '@store/type-event/events/events.api'
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { EventChecklistName } from '../constants'
import './styles.scss'
import EventInfoComponent from '@pages/CommonPages/components/events/EventInfoComponent'
import EventProgressTooltip from './EventProgressTooltip'
import { GetEventCheckListByEventIdItem } from "@store/type-event/events/models.ts";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";
import ThreeDotsIcon from "@assets/pro/ThreeDotsIcon.tsx";
import Warning from "@assets/png/warning.png";

interface Event {
    id: string;
    actions: {
        id: string
        slug: string
        transit: string
    }[];
    logo: string
    title: string
    status: EventCatalogItemStatuses
    date: {
        started_at: string
        finished_at: string
    }
    location: string
    participants: string
    category: string
    organization_url: string;

}

export interface EventItemHeaderInfoProps {
    info: Event;
    checkList: GetEventCheckListByEventIdItem[]
    tooltipList: { id: string, name: EventChecklistName }[]
    refetch: () => void;
}

const EventItemHeaderInfo = ({ info, refetch, checkList, tooltipList }: EventItemHeaderInfoProps) => {
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
    const isPublished = info.status as string === 'published'
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_status' })

    const [patchEventStatus] = usePatchOrganizerEventStatusByIdMutation()

    const trimmedUrl = useMemo(() => {
        const { organization_url = '' } = info || {}
        return getTrimmedUrl(organization_url)
    }, [info])

    const onUpdateStatus = async () => {
        setIsLoadingStatus(true)
        try {
            const statusId = statuses?.data?.find(({ slug }) => (isPublished ? slug === 'draft' : slug === 'published'))?.id
            if (!statusId) {
                return message.open({
                    type: 'error',
                    content: 'Event update error',
                });
            }
            const response = await patchEventStatus({ eventId: info.id.toString(), status: statusId })
            if ('data' in response) {
                sendAnalytics(GTMEventName.publishEvent)
                // eslint-disable-next-line @typescript-eslint/await-thenable
                await refetch()
                setIsLoadingStatus(false)
                setIsModalOpen(false)
                return message.open({
                    type: 'success',
                    content: isPublished ? 'Event is published successfuly' : 'Event replaced to review',
                });
            }
            if ('error' in response) {
                setIsLoadingStatus(false)
                return message.open({
                    type: 'error',
                    content: 'Event update error',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            return message.open({
                type: 'error',
                content: 'Event Update error',
            });
        }
    }

    const dropdownItems: MenuProps['items'] = [
        {
            label: 'Unpublish',
            key: '1',
            onClick: () => {
                if (!info.actions.length) {
                    setIsWarningModalOpen(true)
                } else {
                    setIsModalOpen(true)
                }
            },
        }
    ];

    return (
        <article>
            {isLoadingStatus && <Loader />}
            <header className='event-info__header'>
                <div className='flex items-center event-info__header-info'>
                    <Avatar
                        url={info.logo}
                        size='68'
                    />
                    <h2 className='event-info__header-title mx-6'>{info.title}</h2>

                    <StatusTag
                        status={info.status as unknown as CampaignStatuses}
                    />
                </div>
                <div className='event-info__header__cta-container'>
                    <Button
                        className='mr-10'
                    >
                        <Link
                            to={`/events/edit/${info.id}`}
                            className='no-style'
                        >
                            Edit details
                        </Link>
                    </Button>
                    {Boolean(tooltipList.length) && (
                        <EventProgressTooltip eventId={info.id} progressItems={checkList}>
                            <Button
                                loading={isLoadingStatus}
                                type='primary'
                                disabled
                                onClick={() => void onUpdateStatus()}
                            >
                                Publish
                            </Button>
                        </EventProgressTooltip>
                    )}
                    {!tooltipList.length && isPublished && (
                        <Dropdown
                            className="dropdown"
                            menu={{ items: dropdownItems }}
                            trigger={['click']}
                            placement="bottomRight"
                        >
                            <Button icon={<ThreeDotsIcon />} />
                        </Dropdown>
                    )}
                    {!tooltipList.length && !isPublished && (
                        <Button
                            loading={isLoadingStatus}
                            type='primary'
                            onClick={() => void onUpdateStatus()}
                        >
                            Publish
                        </Button>
                    )}
                </div>
            </header>

            <EventInfoComponent
                started_at={info.date.started_at}
                finished_at={info.date.finished_at}
                location={info.location}
                participants={+info.participants}
            />

            <div className='event-info-desc flex items-center'>
                <span className='event-info__description-text'>
                    {info.category}
                </span>
                <div className='text-divider-v' />
                <span className='event-info__description-text'>
                    <Link
                        to={`//${trimmedUrl}`}
                        className='no-style'
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {trimmedUrl}
                    </Link>
                </span>
            </div>

            <Modal
                title="Unpublish the event"
                open={isModalOpen}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onOk={onUpdateStatus}
                onCancel={() => setIsModalOpen(false)}
                okButtonProps={{
                    loading: isLoadingStatus
                }}
                okText="Unpublish"
                className="organizer-event-item-details__modal"
                width={440}
            >
                Are you sure you want to unpublish the event?
            </Modal>

            <Modal
                title=""
                open={isWarningModalOpen}
                onCancel={() => setIsWarningModalOpen(false)}
                className="organizer-event-item-details__modal organizer-event-item-details__modal-warning"
                width={440}
                footer={null}
            >
                <img src={Warning} alt="Warning" />
                <div className="organizer-event-item-details__modal__title">Unpublish the event</div>
                <div className="organizer-event-item-details__modal__subtitle mb-10">
                    Unpublishing the event will stop all ad campaigns and take all sponsor landing pages off the
                    publication.
                </div>
                <div className="organizer-event-item-details__modal__subtitle">
                    To proceed, please
                    {' '}
                    <Link
                        className='link-style'
                        to={HELP_CENTER_LINK}
                        target="_blank"
                    >
                        contact our support team
                    </Link>
                </div>
            </Modal>
        </article>
    )
}

export default EventItemHeaderInfo
