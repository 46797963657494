export const theme = {
    token: {
        colorPrimary: '#4E67EA',
        colorPrimaryHover: '#3E52BB',
        colorPrimaryActive: '#3E52BB',
        colorLink: '#4E67EA',
        colorLinkHover: '#3E52BB',
        colorLinkActive: '#3E52BB',
    },
    components: {
        Button: {
            defaultActiveBorderColor: '#4E67EA',
            defaultActiveColor: '#4E67EA',
            borderColorDisabled: '#D9DADC',
            defaultBorderColor: '#D9DADC',
            defaultColor: '#252628',
            defaultHoverBorderColor: '#4E67EA',
            defaultHoverColor: '#4E67EA',
        },
    },
}