import { Table } from 'antd';
import { mockCatalog } from '../../mock';
import SkeletonCustom from '@components/SkeletonCustom';

const EventCatalogTableZeroState = ({ isInfinity }: { isInfinity?: boolean }) => {
    const columns = [
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button' width='136px' />
                    </div>
                )
            }
        },
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'event_info',
            key: 'event_info',
            width: 326,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button-l' />
                    </div>
                )
            }
        },
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'date',
            key: 'date',
            width: 190,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button-l' />
                    </div>
                )
            }
        },
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'organizer',
            key: 'organizer',
            width: 160,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button-l' />
                    </div>
                )
            }
        },
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'location',
            key: 'location',
            width: 140,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button-l' />
                    </div>
                )
            }
        },
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'category',
            key: 'category',
            width: 190,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button-l' />
                    </div>
                )
            }
        },
        {
            title: <SkeletonCustom type='button' />,
            dataIndex: 'participants',
            key: 'participants',
            width: 120,
            render: () => {
                return (
                    <div>
                        <SkeletonCustom type='button-l' />
                    </div>
                )
            }
        }
    ];

    return (
        // <div className='max-w-l'>
        <Table
            dataSource={mockCatalog}
            columns={columns}
            rowKey="id"
            pagination={!isInfinity}
        />
        // </div>
    );
};

export default EventCatalogTableZeroState;
