import React, { } from 'react'
import { Button, Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from '@components/CustomButton';
import { PatchBillingInfoUser } from '@store/models/billing';
import { ErrorsValues } from './models';
import BillingForm from '@components/BillingForm';
interface BillingInfoDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    form: PatchBillingInfoUser;
    onValuesChange: (field: Partial<PatchBillingInfoUser>) => void;
    errors: ErrorsValues;
    isLoading: boolean;
    onSubmit: () => void

}

const BillingInfoDrawer: React.FC<BillingInfoDrawerProps> = ({
    isOpen,
    onClose,
    form,
    onValuesChange,
    errors,
    isLoading,
    onSubmit
}) => {

    return (
        <Drawer
            title="Billing Info"
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            width={450}
            // className='drawer-presentation'
            mask
            maskClosable={false}
            onClose={onClose}
            // maskStyle={{ 'opacity': 0, 'top': '48px' }}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={
                <CloseOutlined
                    onClick={onClose}
                />
            }
        >
            {isOpen
                ?
                <div className='drawer-content'>

                    <BillingForm
                        form={form}
                        validationErrors={errors}
                        onFormChange={onValuesChange}
                    />
                </div>
                :
                null}
            <footer className='drawer-footer'>
                <Button
                    size='large'
                    block
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <div style={{ minWidth: '14px' }} />
                <Button
                    size='large'
                    block
                    type='primary'
                    onClick={onSubmit}
                    loading={isLoading}
                >
                    Save
                </Button>
            </footer>


        </Drawer>
    )
}

export default BillingInfoDrawer