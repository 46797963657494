const CommentIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.33333 3.09998C3.17862 3.09998 3.03025 3.16143 2.92085 3.27083C2.81146 3.38023 2.75 3.5286 2.75 3.68331V12.5393L4.13634 11.153C4.27699 11.0123 4.46775 10.9333 4.66667 10.9333H12.6667C12.8214 10.9333 12.9697 10.8719 13.0791 10.7625C13.1885 10.6531 13.25 10.5047 13.25 10.35V3.68331C13.25 3.5286 13.1885 3.38023 13.0791 3.27083C12.9697 3.16143 12.8214 3.09998 12.6667 3.09998H3.33333ZM1.86019 2.21017C2.2509 1.81947 2.7808 1.59998 3.33333 1.59998H12.6667C13.2192 1.59998 13.7491 1.81947 14.1398 2.21017C14.5305 2.60087 14.75 3.13077 14.75 3.68331V10.35C14.75 10.9025 14.5305 11.4324 14.1398 11.8231C13.7491 12.2138 13.2192 12.4333 12.6667 12.4333H4.97733L2.53033 14.8803C2.31583 15.0948 1.99324 15.159 1.71299 15.0429C1.43273 14.9268 1.25 14.6533 1.25 14.35V3.68331C1.25 3.13077 1.46949 2.60087 1.86019 2.21017Z" fill={fillColor ? fillColor : "#C0C1C3"} />
            </g>
        </svg>
    )
}

export default CommentIcon