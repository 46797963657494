import React, { FC } from 'react';
import { Button, Modal, } from 'antd';
import TourModalImage from "@assets/pro/TourModalImage.tsx";

type Props = {
    isOpen: boolean
    onClose: () => void
    onCreateCampaign: () => void
    onEnablePro: () => void
}

const TourModal: FC<Props> = ({ isOpen, onClose, onCreateCampaign, onEnablePro }) => {
    return (
        <Modal
            className="tour-modal"
            open={isOpen}
            onCancel={onClose}
            centered
            width={600}
            footer={null}
        >
            <div>
                <TourModalImage />
                <div className="tour-modal__title">Try all the benefits of Boost Express</div>
                <div className="tour-modal__subtitle">
                    Get full campaign assistance from our team
                    <br />
                    of experts with Boost PRO service
                </div>
                <div
                    className="flex justify-center tour-modal__footer"
                >
                    <Button
                        className="gradient-pro"
                        size="large"
                        onClick={onEnablePro}
                    >
                        Enable PRO
                    </Button>
                    <Button
                        size="large"
                        onClick={onCreateCampaign}
                    >
                        Create campaign from scratch
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default TourModal;
