export enum TabKeys {
    details = 'details',
    partners = 'partners',
    invitations = 'invitations',
    campaigns = 'campaigns',
    subdomain = 'subdomain',
    track = 'track',
    billing = 'billing',
    adAccounts = 'ad-accounts'
}

// export enum ListIds {
//     partners = 'partners-list',
//     invitations = 'invitations-list'
// }

export enum InvitationKeys {
    Sent = 'sent',
    Not_sent = 'not_sent'
}

export type EventChecklistName = 'subdomain_verified' | 'credential_granted' | 'track_installed' | 'billing_defined'

export const eventChecklistNames = {
    subdomain_verified: {
        url: (eventId: string) => `/events/info/${eventId}/subdomain`,
        name: 'Set up subdomain'
    },
    credential_granted: {
        url: () => `/settings/ad-accounts`,
        name: 'Provide access to Google Ads Account'
    },
    track_installed: {
        url: (eventId: string) => `/events/info/${eventId}/track`,
        name: 'Install tracking code'
    },
    billing_defined: {
        url: (eventId: string) => `/events/info/${eventId}/track`,
        name: 'Event billing'
    },
}

export const STATUSES = {
    Installed: 'installed',
    NotFound: 'Not found',
}
