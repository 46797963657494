import SkeletonCustom from '@components/SkeletonCustom'
import StripeCertifiedPlug from '@pages/PartnerRolePages/Billing/components/StripeCertifiedPlug'
import React from 'react'

const SkeletonStripe = () => {
    return (
        <>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='paragraph'
                    width='100%'
                />
            </div>
            <StripeCertifiedPlug />
        </>)
}

export default SkeletonStripe