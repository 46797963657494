
export const initialForm = {
    event_hash: "",
    name: '',
    company_name: '',
    country: null,
    city: null,
    postal_code: '',
    email: '',
    address: '',
    tax_code: '',
}
export const initialErrorForm = {
    name: '',
    event_hash: "",
    company_name: '',
    city: '',
    'city.id': '',
    postal_code: '',
    email: '',
    country: '',
    'country.id': '',
    address: '',
    tax_code: '',
}

export const steps = [
    {
        title: 'Invitation',
    },
    {
        title: 'Billing info',
    },
    {
        title: 'Payment Method',
    },
]