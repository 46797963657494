import styles from './EmailConfirmation.module.scss'
import Success from '@assets/png/success.png'
import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import LogoHeaderAuth from '@assets/LogoHeaderAuth';

const EmailConfirmationSuccessPage = () => {
    // todo email from back
    const navigate = useNavigate()
    const email = 'jannet.williams@thesummit.com'
    return (
        <div className={styles.EmailSuccessPage}>
            <header>
                <LogoHeaderAuth />
            </header>
            <div className={styles.Wrapper}>
                <img src={Success} alt="Success" />
                <span className={styles.Title}>Congratulations!</span>
                <span className={styles.Subtitle}>
                    Your e-mail
                    {email}
                    {' '}
                    has been successfully confirmed
                </span>
                <Button
                    size="large"
                    type="default"
                    onClick={() => navigate('/')}
                >
                    Go to the platform
                </Button>
            </div>
        </div>
    )
}

export default EmailConfirmationSuccessPage
