
const ExitCrossIcon = ({ fill = "#1F1F1F" }: { fill?: string }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.66 3.26001C11.9139 3.00617 11.9139 2.59461 11.66 2.34077C11.4062 2.08693 10.9946 2.08693 10.7408 2.34077L7.00039 6.08115L3.26001 2.34077C3.00617 2.08693 2.59461 2.08693 2.34077 2.34077C2.08693 2.59461 2.08693 3.00617 2.34077 3.26001L6.08115 7.00039L2.34077 10.7408C2.08693 10.9946 2.08693 11.4062 2.34077 11.66C2.59461 11.9139 3.00617 11.9139 3.26001 11.66L7.00039 7.91963L10.7408 11.66C10.9946 11.9139 11.4062 11.9139 11.66 11.66C11.9139 11.4062 11.9139 10.9946 11.66 10.7408L7.91963 7.00039L11.66 3.26001Z" fill={fill} />
        </svg>

    )
}

export default ExitCrossIcon