import React, { PropsWithChildren } from 'react';
import { Button } from 'antd';

type ExportToCsvButtonProps = {
    runExportReq: () => Promise<any>
    isLoading: boolean
    type?: string
    className?: string
    fileName?: string
} & PropsWithChildren

const ExportToCsvButton: React.FC<ExportToCsvButtonProps> = (props) => {
    const { runExportReq, isLoading, type = 'primary', children, className, fileName: fileNameProps } = props;

    function saveToFile(blob: Blob, fileName: string) {
        const file = new Blob([blob], { type: 'application/octet-stream' });
        // Create a link element, use it to download the file and remove it by setting a delay
        const fileDownloadUrl = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileDownloadUrl;
        link.setAttribute('download', fileName); // Set the file name
        document.body.appendChild(link);
        link.click();

        setTimeout(function () {
            window.URL.revokeObjectURL(fileDownloadUrl);  // Clean up and remove the link
            link.parentNode.removeChild(link);
        }, 100);
    }

    function getFilenameFromContentDisposition(contentDisposition: string) {
        const DEFAULT_FILE_NAME = 'Anonimus';
        if (!contentDisposition) {
            return DEFAULT_FILE_NAME
        }
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }
        return DEFAULT_FILE_NAME;
    }

    const exportToCsv = () => runExportReq().then(res => saveToFile(res.data, fileNameProps || getFilenameFromContentDisposition(res.headers['content-disposition'])))

    return (
        <Button
            type={type}
            className={className}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={exportToCsv}
            disabled={isLoading}
            loading={isLoading}
        >
            {/* Export */}
            {children}
        </Button>
    );
}

export default ExportToCsvButton;
