const TableZeroStateIcon = () => {
    return (
        <svg width="184" height="117" viewBox="0 0 184 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.875 83C9.85688 86.5947 0 91.4916 0 96.8884C0 107.921 41.1898 116.864 92 116.864C142.81 116.864 184 107.921 184 96.8884C184 91.4916 174.143 86.5947 158.125 83V91.708C158.125 97.7664 154.33 102.732 149.644 102.732H34.3563C29.67 102.732 25.875 97.7635 25.875 91.708V83Z" fill="#C4C9CD" />
            <path d="M119.637 48.3152C119.637 43.7351 122.495 39.954 126.04 39.9512H158.125V91.708C158.125 97.7663 154.33 102.732 149.644 102.732H34.3562C29.67 102.732 25.875 97.7634 25.875 91.708V39.9512H57.96C61.5049 39.9512 64.3626 43.7266 64.3626 48.3067V48.3695C64.3626 52.9496 67.252 56.6479 70.794 56.6479H113.206C116.748 56.6479 119.637 52.9153 119.637 48.3352V48.3152Z" fill="#EDEFF0" stroke="#889096" />
            <path d="M158.125 40.2662L128.955 7.44342C127.555 5.20615 125.511 3.85352 123.358 3.85352H60.6424C58.489 3.85352 56.4449 5.20615 55.0448 7.44056L25.875 40.2691" stroke="#889096" />
        </svg>
    )
}

export default TableZeroStateIcon