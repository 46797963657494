import React from "react";

type EnvironmentIconPropsType = {
    widthProp?: string
    heightProp?: string
}

const EnvironmentIcon:React.FC <EnvironmentIconPropsType> = (props) => {
    const {widthProp="20",heightProp="20"} = props;
    return (
        <svg width={widthProp} height={heightProp} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.6482 5.02595C17.2279 4.05693 16.622 3.17957 15.8647 2.44336C15.1048 1.7005 14.2091 1.1109 13.2263 0.706752C12.204 0.282645 11.1191 0.0683594 10.0008 0.0683594C8.88253 0.0683594 7.79771 0.282645 6.77539 0.70452C5.78655 1.113 4.90039 1.69782 4.137 2.44113C3.38013 3.17775 2.77431 4.05501 2.35352 5.02372C1.91825 6.02818 1.69727 7.09514 1.69727 8.19336C1.69727 9.76925 2.0745 11.3384 2.81557 12.8518C3.41155 14.0684 4.24414 15.2536 5.29325 16.3809C7.08566 18.305 8.96512 19.4813 9.49861 19.796C9.65003 19.8855 9.82274 19.9325 9.9986 19.9322C10.1727 19.9322 10.3446 19.8876 10.4986 19.796C11.0321 19.4813 12.9116 18.305 14.704 16.3809C15.7531 15.2559 16.5857 14.0684 17.1816 12.8518C17.9272 11.3407 18.3044 9.77372 18.3044 8.19559C18.3044 7.09738 18.0834 6.03041 17.6482 5.02595ZM10.0008 18.2201C8.52986 17.2849 3.30441 13.5818 3.30441 8.19559C3.30441 6.45675 3.99861 4.82282 5.25977 3.59068C6.52539 2.35631 8.20843 1.6755 10.0008 1.6755C11.7932 1.6755 13.4763 2.35631 14.7419 3.59291C16.0031 4.82282 16.6973 6.45675 16.6973 8.19559C16.6973 13.5818 11.4718 17.2849 10.0008 18.2201ZM10.0008 4.44336C7.83119 4.44336 6.07227 6.20229 6.07227 8.37193C6.07227 10.5416 7.83119 12.3005 10.0008 12.3005C12.1705 12.3005 13.9294 10.5416 13.9294 8.37193C13.9294 6.20229 12.1705 4.44336 10.0008 4.44336ZM11.7687 10.1398C11.5368 10.3723 11.2613 10.5567 10.9579 10.6824C10.6545 10.808 10.3292 10.8724 10.0008 10.8719C9.33343 10.8719 8.70619 10.6108 8.23298 10.1398C8.00044 9.90792 7.81605 9.63236 7.6904 9.32896C7.56475 9.02556 7.50033 8.70032 7.50084 8.37193C7.50084 7.70452 7.762 7.07729 8.23298 6.60407C8.70619 6.13086 9.33343 5.87193 10.0008 5.87193C10.6682 5.87193 11.2955 6.13086 11.7687 6.60407C12.2419 7.07729 12.5008 7.70452 12.5008 8.37193C12.5008 9.03934 12.2419 9.66657 11.7687 10.1398Z" fill="#BFBFBF" />
        </svg>
    )
}

export default EnvironmentIcon