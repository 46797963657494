export const removeHeightFromTooltipWrapper = () => {
    const proTooltip = document.getElementsByClassName("pro-tooltip") as HTMLCollectionOf<HTMLElement>;

    if (proTooltip.length > 0) {
        const proTooltipWrapper = proTooltip[0]?.parentNode as HTMLElement | null;

        if (proTooltipWrapper) {
            proTooltipWrapper.style.height = "0";
        }
    }
}
