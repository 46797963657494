import { useContext, useState } from 'react'
import EventsPageLayout from '@layouts/EventsPageLayout';

import './styles.scss'
import BannerBoostProNotActive from './components/Banners/BoostProNotActive';
import HelpBoostPro from './components/Banners/HelpBoostPro';
import BillingBalanceSection from './sections/balance';
import AutoPaymentsSection from './sections/auto-payment';
import PaymentMethodsSection from './sections/payment-methods';
import BillingInfoSection from './sections/billing-info';
import { UserBillingPageContext } from '@contexts/UserBillingPageContext';

// DRAWERS
import AutoPaymentDrawer from './drawers/AutoPayment';
import BillingInfoDrawer from './drawers/BillingInfo';
import AddPaymentDrawer from './drawers/AddPayment';
import { useAuth } from '@contexts/AuthContext';
import BoostProIsActive from './components/Banners/BoostProIsActive';
import { Divider, message, Typography } from 'antd';
import SuccessModal from '@components/Subscriptions/BoostPro/SuccessModal';
import EnableBoostProDrawer from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawer';
import BillingInfoTabs from './sections/billing-history';
import { StripeContext } from '@contexts/StripeContext';
import { usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import { usePostSubscriptionMutation } from '@store/main/subscription/subscription.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useBreakpoints } from '@hooks/browser';
import AddPaymentDrawerMobile from './mobile/AddPayment';
import EnableBoostProDrawerMobile from '@components/Subscriptions/BoostPro/drawers/EnableBoostProDrawerMobile';

const Billing = () => {
    const [onSubscribe] = usePostSubscriptionMutation()
    const [postDefault] = usePostDefaultBillingMethodIdMutation()
    const isDesktop = useBreakpoints().md
    const {
        sectionType,
        close,
        form,
        onFormChange,
        validationErrors,
        onBillingInfoUpdate,
        isLoading: isBillingLoading
    } = useContext(UserBillingPageContext);
    const { selectedCardId } = useContext(StripeContext);

    const { user, reFetchUser } = useAuth()

    //TOFIX: ts error
    const isPro = user && user?.organization?.subscriptions && user.organization.subscriptions.some(item => item.status?.id === 1 && item.status.name === 'active')

    const [isLoading, setIsLoading] = useState(false)
    const [successModalDate, setSuccessModalDate] = useState<string | boolean>('')

    const onSuccessModalClose = () => {
        setSuccessModalDate('')
        void reFetchUser()
    }

    const onEnablePro = async () => {
        setIsLoading(true)
        await postDefault({ id: selectedCardId })

        try {
            const response = await onSubscribe()
            if ('error' in response) {
                return void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Subscription error',
                });
            }
            if (response) {
                sendAnalytics(GTMEventName.enableProSubscription)
                setSuccessModalDate(response?.data?.data?.date || true)
            }
        } catch (error) {
            return void message.open({
                type: 'error',
                content: 'Subscription error',
            });
        }
        return setIsLoading(false)
    }
    return (
        <EventsPageLayout>
            {/* <Watermark content="Work in progress"> */}
            <div className='billing-page'>
                <div className='billing-page-content'>

                    {/* SECTIONS  */}
                    <BillingBalanceSection />
                    <Divider />
                    <AutoPaymentsSection />
                    {/* {user?.organization.subscriptions} */}
                    {!isPro
                        ?
                        <BannerBoostProNotActive />
                        :
                        <BoostProIsActive />
                    }
                    <PaymentMethodsSection />
                    <BillingInfoSection />
                    <HelpBoostPro />

                    <Typography.Title level={5} className='billing-section-title'>
                        Billing history
                    </Typography.Title>
                    <BillingInfoTabs />



                    {/* DRAWERS */}
                    <BillingInfoDrawer
                        isOpen={sectionType === 'billing_info'}
                        onClose={close}
                        form={form}
                        onValuesChange={onFormChange}
                        errors={validationErrors}
                        isLoading={isBillingLoading}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onSubmit={onBillingInfoUpdate}
                    />


                    {sectionType === 'add_payment_method' && isDesktop
                        ?
                        <AddPaymentDrawer
                            isOpen={sectionType === 'add_payment_method'}
                            onClose={close}
                            isLoading={isLoading}
                        />
                        :
                        null}
                    {sectionType === 'add_payment_method' && !isDesktop
                        ?
                        <AddPaymentDrawerMobile
                            isOpen={sectionType === 'add_payment_method'}
                            onClose={close}
                            isLoading={isLoading}
                        />
                        :
                        null}

                    {/* <PaymentMethodDrawer
                        isOpen={sectionType === 'payment_method'}
                        onClose={close}
                    /> */}

                    <AutoPaymentDrawer
                        isOpen={sectionType === 'auto_payments'}
                        onClose={close}
                    />


                    {sectionType === 'enable_pro' && isDesktop
                        ?
                        <EnableBoostProDrawer
                            isOpen={sectionType === 'enable_pro'}
                            onClose={close}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onConfirm={onEnablePro}
                            isLoading={isLoading}
                        />
                        :
                        null}

                    {sectionType === 'enable_pro' && !isDesktop
                        ?
                        <EnableBoostProDrawerMobile
                            isOpen={sectionType === 'enable_pro'}
                            onClose={close}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onConfirm={onEnablePro}
                            isLoading={isLoading}
                        />
                        :
                        null}

                    <SuccessModal isOpen={Boolean(successModalDate)} onClose={onSuccessModalClose} date={successModalDate} />
                </div>
            </div>
        </EventsPageLayout>
    )
}

export default Billing