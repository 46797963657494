import ContentLayout from '@layouts/ContentLayout'
import EventsPageLayout from '@layouts/EventsPageLayout'

const AudiencesEventPage = () => {
    return (
        <EventsPageLayout>
            <ContentLayout>
                <div>AudiencesEventPage</div>
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default AudiencesEventPage