import EventsPageLayout from '@layouts/EventsPageLayout'
// import CampaignsZeroState from './CampaignsZeroState'
import TableCampaignsMain from './components/Table'
import usePageAnalytics from "@hooks/sendAnalytics/usePageAnalytics";
import ContentLayout from "@layouts/ContentLayout.tsx";

const EventCampaignsPage = () => {
    usePageAnalytics('campaigns');

    return (
        <EventsPageLayout>
            <ContentLayout>
                {/* <CampaignsZeroState /> */}
                <TableCampaignsMain />
            </ContentLayout>
        </EventsPageLayout>

    )
}

export default EventCampaignsPage