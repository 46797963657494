import {
    EEventTypes,
} from './constants';
import { EventData } from "@hooks/sendAnalytics/types";

const sendAnalytics = (
    eventName: string,
    eventProps?: Record<string, string>
) => {
    if (window) {
        window.dataLayer = window.dataLayer || [];
        const data: EventData = {
            event: EEventTypes.GTM_UI_EVENT,
            event_name: eventName,
            event_properties: {
                page_title: window.document.title,
                page_path: window.location.pathname,
                page_location: window.location.href,
                ...eventProps
            },
            _clear: true,
        };
        window.dataLayer.push(data);
    }
};

export default sendAnalytics
