//https://boost.allnetru.dev/api/documentation#/Categories
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {PostEmailResponse} from "@store/main/email/models.ts";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const email_tag = `email_tag`;

export const email = createApi({
    reducerPath: email_tag,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        email_tag
    ],
    endpoints: build => ({
        postEmailVerification: build.mutation<PostEmailResponse, void>({
            query: () => ({
                method: 'POST',
                url: `/email/verification-notification`,
                headers: {
                    Accept: 'application/json', // Specify JSON response
                },
            }),
            invalidatesTags: [email_tag],
        }),
    }),
});

export const {
    usePostEmailVerificationMutation
} = email;