import { Dropdown } from 'antd';
import React from "react";

interface Props {
    children: React.ReactNode,
    onRevoke: () => void
}

const CredentialDropdown = ({
    children,
    onRevoke
}: Props) => {
    const dropdownItems = [
        {
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: (
                <div onClick={onRevoke}>
                    Revoke
                </div>
            ),
            key: 'revoke',

        },
    ];

    return (
        <Dropdown
            menu={{ items: dropdownItems }}
        >
            {children}
        </Dropdown>
    )
}

export default CredentialDropdown
