import { useAuth } from '@contexts/AuthContext';
import { UserBillingPageContext } from '@contexts/UserBillingPageContext';
import { formatNumberToAmerican } from '@shared/utils';
import { Button, Typography } from 'antd';
import React, { useContext } from 'react'

const AutoPaymentsSection
    = () => {
        const {
            setSectionType
        } = useContext(UserBillingPageContext);

        const { user } = useAuth()
        const billing = user?.organization?.billing

        if (!billing) {
            return null
        }

        return (
            <section className='billing-page-content__auto-payments'>
                <Typography.Title level={5} className='billing-section-title'>
                    Automatic payments
                </Typography.Title>
                {/* <h5 className='billing-section-title'>
                    Automatic payments
                </h5> */}
                <div className='billing-page-content__auto-payments-content'>
                    <span className='billing-page-content__auto-payments__desc-text'>
                        The automatic payment will be debited when your account balance reaches the $100 limit.
                    </span>

                    <div className='billing-page-content__auto-payments__cta'>
                        <Button
                            type="link"
                            style={{ padding: '0px' }}
                            onClick={() => { setSectionType('auto_payments') }}
                        >
                            Edit
                        </Button>
                        <p className='billing-info-page__text-24 m-0'>
                            ${formatNumberToAmerican(billing?.autodebit_amount || 0, 2)}
                        </p>
                    </div>
                </div>
            </section>
        )
    }

export default AutoPaymentsSection
