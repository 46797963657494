export const home = '/'
export const leads = '/leads'
export const campaigns = '/campaigns'
export const campaigns_create = '/campaigns/create'
export const campaigns_create_new = '/campaigns/create/new'
export const campaigns_ads = '/campaigns/ads'
export const campaigns_group = '/campaigns/group'
export const campaigns_edit = '/campaigns/edit'

//EVENTS
export const dashboard = '/dashboard'
export const audiences = '/audiences'
export const moderation = '/moderation'
export const analytics = '/analytics'
export const events_setup = '/events/setup'
export const ad_accounts = '/ad_accounts'

//PARTNER
export const events_catalog = '/events/catalog'
export const events_edit = '/events/edit'
export const events_create = 'events/create'
export const events_lp_create = '/landing/create'
export const pro = '/pro'
export const constructor = '/constructor'
export const settings = '/settings'

//COMMON
export const events = '/events'
export const partners = '/partners'
export const help = '/help'

export const billing = '/billing'
export const profile = '/profile'
export const notifications = '/notifications'

export const login = '/login'
export const logout = '/logout'
export const registration = '/registration'


