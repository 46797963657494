import React from 'react'

type Props = {
    html: string;
}

const NonEditableSection:React.FC <Props> = ({ html = '', ...rest }) => {
    return (
        <div className='non-editable-section' dangerouslySetInnerHTML={{ __html: html }} {...rest} />
    )
}

export default NonEditableSection
