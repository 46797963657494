import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Drawer, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { StripeContext } from '@contexts/StripeContext';
import BillingStripeFormWrapper from '@components/BillingStripeForm';
import StripeCertifiedPlug from '@pages/PartnerRolePages/Billing/components/StripeCertifiedPlug';
import { PaymentMethodItem } from '@store/models-to replace/paymentMethods';
import BrandedCardItem from '@pages/PartnerRolePages/Billing/components/BrandedCardItem';
import { useGetUserBillingPaymentsQuery } from '@store/main/payment/payment.api';
import { Link } from 'react-router-dom';
import { TERMS_LINK } from "@pages/CommonPages/Authentification/Register/constants.ts";

interface EnableBoostProDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean
}

const EnableBoostProDrawer: React.FC<EnableBoostProDrawerProps> = ({
    isOpen,
    onClose,
    onConfirm,
    isLoading
}) => {
    const {
        loading,
        intent,
        apiKey,
        selectedCardId,
        setSelectedCardId,
        onAddPaymentClick,
        onOpenStripeForm
    } = useContext(StripeContext)

    useEffect(() => {
        // eslint-disable-next-line no-void
        void onOpenStripeForm()
    }, [])

    const { data: paymentMethodsResponse, isLoading: paymentsIsLoading } = useGetUserBillingPaymentsQuery()
    const [cards, setCards] = useState<PaymentMethodItem[] | []>([])
    const [isUpdating, setisUpdating] = useState(false)

    const cardsList = cards.map(item => {
        return {
            value: item.id,
            label: (
                <BrandedCardItem {...item} isDefaultSubdued />
            )
        }
    })
    const options = [
        ...cardsList,
        {
            value: 'add',
            label: (
                <button
                    className='no-style w-full'
                >
                    + Add card
                </button>
            )
        }
    ]

    const onCardChange = useCallback((option: { value: string }) => {
        setSelectedCardId(option.value)
    }, [])

    useEffect(
        () => {
            if (paymentMethodsResponse?.data) {
                setCards(paymentMethodsResponse.data)
                const defaultCard = paymentMethodsResponse.data.find(card => card.isDefault) || null
                onCardChange({ value: defaultCard?.id || '' })
            }
        },
        [paymentMethodsResponse?.data]
    )

    const onSubmit = async () => {
        setisUpdating(true)
        if (selectedCardId === 'add') {
            return await onAddPaymentClick()
                .then(() => {
                    onConfirm()
                })
                .finally(() => setisUpdating(false))
        }
        setisUpdating(false)
        return onConfirm()
    }

    return (
        <Drawer
            title='Enabling Boost PRO'
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            // mask
            width={450}
            maskClosable={false}
            onClose={onClose}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={
                <CloseOutlined
                    onClick={onClose}
                />
            }
        >
            <div className='drawer-content'>
                <div className='flex justify-space-between mb-12'>
                    <article className='flex-col text-subdued'>
                        <span>
                            The monthly amount starting
                        </span>
                        <span>
                            Apr. 1, 2024
                        </span>

                    </article>
                    <article className='flex-col text-title'>
                        <span>

                        </span>
                        <span>
                            $200,00
                        </span>

                    </article>

                </div>

                <p className='mb-6 text-subdued'>
                    Payment card
                </p>

                <Select
                    labelInValue
                    onChange={onCardChange}
                    //TOFIX: ts error
                    value={selectedCardId}
                    style={{ width: '100%' }}
                    options={options}
                    className='mb-24'
                    // defaultActiveFirstOption
                    loading={paymentsIsLoading || loading}
                />


                <div className='drawer-disclaimer-grey mb-24'>
                    <span>
                        By enabling a subscription, you authorize <br /> Boost.Express to charge your card for current and future payments as per our <Link className='link-style' to={TERMS_LINK} target={"_blank"}>Terms and Conditions</Link>
                    </span>
                </div>

                {selectedCardId !== 'add' && <StripeCertifiedPlug />}
                {intent && <div className={selectedCardId === 'add' ? '' : 'drawer-content--billing-form-hidden'}>
                    <BillingStripeFormWrapper
                        intent={intent}
                        apiKey={apiKey}
                    />
                </div>}
            </div>

            <footer className='drawer-footer'>
                <Button
                    size='large'
                    block
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <div style={{ minWidth: '14px' }} />
                <Button
                    size='large'
                    block
                    type='primary'
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={onSubmit}
                    loading={isLoading || isUpdating}
                    disabled={!selectedCardId}
                >
                    Enable PRO
                </Button>
            </footer>


        </Drawer>
    )
}

export default EnableBoostProDrawer