import './style.scss'
import MessageErrorIcon from '@assets/MessageErrorIcon'

const ErrorBanner = ({ text, isWarning }: { text: string, isWarning?: boolean }) => {
    if (!text) {
        return null
    }
    return (
        <div className={`error-banner ${isWarning ? 'error-banner__warning' : ''}`}>
            <div className='error-banner__icon'>
                <MessageErrorIcon />
            </div>
            <article className='error-banner__text'>
                {text}
            </article>
        </div>
    )
}

export default ErrorBanner