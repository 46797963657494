import { Checkbox, Tooltip, Typography } from "antd";
import 'react-international-phone/style.css';
import { FormCompanyValues } from '../models';
import RegistrationLayout from '@layouts/RegistrationLayout';
import './styles.scss'
import InfoTooltipIcon from '@icons/InfoTooltipIcon';
import UserTypeEvent from '@assets/UserTypeEvent';
import UserTypePartner from '@assets/UserTypePartner';
import CustomButton from '@components/CustomButton';
import { InviteInfoResponse } from '@store/models-to replace/auth';
import { Link } from 'react-router-dom';
import { SERVICE_AGREEMENT_LINK } from '@pages/CommonPages/Authentification/Register/constants';
import React, { useState } from 'react'
import LogoutDropdown from "@pages/CommonPages/Authentification/Register/components/LogoutDropdown/LogoutDropdown.tsx";


interface CreateTypeStepProps {
    onSubmit: () => void;
    form: FormCompanyValues;
    onFormChange: (value: Record<string, "event" | 'partner'>) => void;
    isLoading: boolean;
    inviteInfo?: InviteInfoResponse;
}

export const CreateTypeStep = ({
    onSubmit,
    form,
    onFormChange,
    isLoading,
    inviteInfo
}: CreateTypeStepProps) => {
    const [isServiceAgreementSelected, setIsServiceAgreementSelected] = useState(false)

    const eventTitle = inviteInfo?.data?.event?.name || ''
    const eventImage = inviteInfo?.data?.event?.logo || ''

    const onChangeServiceAgreement = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsServiceAgreementSelected(e?.target?.checked)
    }

    return (
        <RegistrationLayout
            isCompleteRegistration
            headerInfo={{
                text: eventTitle,
                image: eventImage
            }}
            sidebar={(
                <LogoutDropdown />
            )}
        >
            <div className='auth'>
                <main className='auth__form'>
                    <p className='text-title mb-24'>
                        Welcome to Boost.Express
                    </p>
                    <Typography.Text className='mb-24 flex items-center'>
                        <span className='mr-6'>
                            Select user type, which is relevant for you
                        </span>
                        <Tooltip
                            title={(
                                <div className='registration-organization-type__tooltip-title'>
                                    <span>There are two key roles: event organizer and partner.</span>
                                    <ul>
                                        <li>
                                            <b>Event organizer</b>
                                            <br />
                                            <span>
                                                It is the company that creates and manages events. They are responsible for creating events, inviting partners to collaborate, moderating partner creative assets for ad campaigns, and publishing partner-created assets on their website and Ad Accounts.
                                            </span>
                                        </li>
                                        <li>
                                            <b>Partner</b>
                                            <br />
                                            <span>
                                                A partner is a company that participates in an event. They create creative assets and develop advertising campaigns to promote their own brand through collaborative ads.
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            overlayClassName='registration-organization-type__tooltip'
                        >
                            <div className='flex cursor-pointer'>
                                <InfoTooltipIcon />
                            </div>
                        </Tooltip>
                    </Typography.Text>
                    {/* {
                        errorText
                            ?
                            <ErrorBanner
                                text={errorText}
                            />
                            :
                            null
                    } */}

                    <section className='registration-organization-type__types'>
                        <article
                            className={`${form?.organization_type === 'event' ? 'selected' : ''} registration-organization-type__type`}
                            onClick={() => onFormChange({ organization_type: "event" })}
                        >
                            <UserTypeEvent isActive={form.organization_type === 'event'} />
                            <Typography.Text>
                                Event organizer
                            </Typography.Text>
                        </article>
                        <article
                            className={`${form.organization_type === 'partner' ? 'selected' : ''} registration-organization-type__type`}
                            onClick={() => onFormChange({ organization_type: "partner" })}
                        >
                            <UserTypePartner isActive={form.organization_type === 'partner'} />
                            <Typography.Text>
                                Partner
                            </Typography.Text>
                        </article>
                    </section>
                    {(form.organization_type === 'event' || form.organization_type === 'partner') && (
                        <Checkbox
                            checked={Boolean(isServiceAgreementSelected)}
                            onChange={onChangeServiceAgreement}
                            className='registration-organization-type__checkbox'
                        >
                            I consent to the
                            {' '}
                            <Link
                                to={SERVICE_AGREEMENT_LINK}
                                target="_blank"
                            >
                                {`${form.organization_type === 'event' ? 'Event Organizer' : 'Partner'} Service Agreement`}
                            </Link>
                        </Checkbox>
                    )}
                </main>
                <CustomButton
                    title='Continue'
                    htmlType="submit"
                    classOptional='w-full'
                    type='primary'
                    size='large'
                    disabled={(form.organization_type !== 'event' && form.organization_type !== 'partner') || !isServiceAgreementSelected}
                    loading={isLoading}
                    onClick={onSubmit}
                />
            </div>
        </RegistrationLayout>

    )
};
