import LogoHeaderAuth from '@assets/LogoHeaderAuth';
import React from "react";
import './styles.scss'
type AuthMonoLayoutPropsType = {
    children: React.ReactNode
}

const AuthMonoLayout: React.FC<AuthMonoLayoutPropsType> = ({
    children
}) => {

    return (
        <div className='registration-layout'>
            <header className='registration-layout__header'>
                <LogoHeaderAuth />
            </header>
            <section className='registration-layout__content grey'>
                {children}
            </section>
        </div>
    )
}

export default AuthMonoLayout;
