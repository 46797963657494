import React, { useMemo } from 'react';
import { Form, Input, Select, Spin, Tooltip } from "antd";
import SuggestSelectCities from '@components/SuggestSelectCities';
import SuggestSelectCountries from '@components/SuggestSelectCountries';
import InfoIcon from "@icons/header/InfoIcon.tsx";
import { useGetCurrenciesByISOQuery } from '@store/type-event/billing/billing.api.ts';
import { EventBilling } from "@store/type-event/billing/models.ts";
import { CityField, EventBillingFormErrors } from "@pages/EventRolePages/Billing/types.ts";
import { useGetCountriesBySearchQuery } from "@store/main/countries/countries.api.ts";
import { useBreakpoints } from "@hooks/browser";

interface BillingInfoProps {
    form: EventBilling;
    validationErrors: EventBillingFormErrors
    onFormChange: (field: Partial<EventBilling>) => void;
}

const EventBillingForm: React.FC<BillingInfoProps> = ({
    form,
    onFormChange,
    validationErrors
}) => {
    const isDesktop = useBreakpoints().md;

    const { data: countriesRes } = useGetCountriesBySearchQuery({ search: form.country?.slug || '' }, { skip: !form.country?.slug })
    const { data: currenciesRes, isLoading: isCurrenciesLoading, isFetching: isCurrenciesFetching } = useGetCurrenciesByISOQuery({ iso: countriesRes?.data?.[0]?.iso_code || '' })

    const formValuesHadler = (field: Partial<EventBilling>) => {
        if ('city' in field) {
            const values = field as CityField
            onFormChange({ country: { ...values?.city?.country || {} } })
            onFormChange({ city: values.city })
            return
        }
        if ('country' in field) {
            onFormChange(field)
            return
        }
        onFormChange(field)
    }

    const currenciesOptions = useMemo(() => {
        if (form.currency) {
            const foundCurrency = currenciesRes?.data?.find(({ currency: currencyValue }) => currencyValue === form.currency)
            if (!foundCurrency) {
                return [...(currenciesRes?.data || []), { currency: form.currency, translate: form.currency?.toUpperCase() }]
            }
        }
        return currenciesRes?.data
    }, [currenciesRes])

    return (
        <Form
            className="event-billing-form"
            name="eventBillingInfo"
            initialValues={form}
            autoComplete="off"
            layout="vertical"
            onValuesChange={(field: Partial<EventBilling>) => formValuesHadler(field)}
        >
            <div className="event-billing-form__title">
                Basic information
                <Tooltip
                    overlayClassName='tooltip-general event-billing-form__tooltip'
                    title={(
                        <div>
                            Specific countries may have their own unique instructions. Depending on the country you’re sending a wire to, you can reference the relevant information.
                        </div>
                    )}
                    placement={isDesktop ? "bottom" : "bottomRight"}
                    overlayStyle={{
                        width: '300px'
                    }}
                >
                    <div className='cursor-pointer ml-4'>
                        <InfoIcon width={18} height={18} fillColor="#57585A" />
                    </div>
                </Tooltip>
            </div>
            <Form.Item
                style={{ marginBottom: validationErrors?.name ? 'undefined' : '12px' }}
                help={validationErrors?.name && validationErrors?.name}
                validateStatus={validationErrors?.name ? 'error' : ''}
                name='name'
                label="Billing name"
            >
                <Input placeholder='Billing name' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['country.id'] ? 'undefined' : '12px' }}
                help={validationErrors?.country || validationErrors?.['country.id']}
                validateStatus={validationErrors?.country || validationErrors?.['country.id']
                    ? 'error' : ''}
                label="Country"
            >
                <SuggestSelectCountries
                    value={form.country}
                    placeholder={form?.country?.slug || 'Сhoose country'}
                    onChange={(country) => {
                        onFormChange({ country, city: null })
                    }}
                />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['city.id'] ? 'undefined' : '12px' }}
                help={validationErrors?.['city.id'] && validationErrors?.['city.id']}
                validateStatus={validationErrors?.['city.id'] ? 'error' : ''}
                name="city"
                label="City"
            >
                <SuggestSelectCities
                    value={form?.city || null}
                    countryValue={form.country}
                    placeholder={form?.city?.slug || 'Сhoose city'}
                    onChange={(city) => onFormChange({ city })}
                />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: validationErrors?.currency ? 'undefined' : '12px' }}
                help={validationErrors?.currency && validationErrors?.currency}
                validateStatus={validationErrors?.currency ? 'error' : ''}
                label="Currency"
            >
                <Select
                    options={currenciesOptions}
                    value={form.currency}
                    onChange={(value) => {
                        onFormChange({
                            currency: value || ''
                        })
                    }}
                    placeholder="Currency"
                    style={{ width: '100%' }}
                    filterOption={false}
                    notFoundContent={isCurrenciesLoading || isCurrenciesFetching ? <Spin size="small" /> :
                        <div>There is no currencies</div>}
                    loading={isCurrenciesLoading || isCurrenciesFetching}
                    fieldNames={{ value: 'currency', label: 'translate' }}
                    optionRender={({ data }) => {
                        const { translate, description = '' } = data as { translate: string, description: string }
                        return (
                            <div>
                                {translate}
                                {' '}
                                {description ? `(
                                ${description}
                                )` : ''}
                            </div>
                        )
                    }}
                />
            </Form.Item>

            <div className="event-billing-form__title">
                Beneficiary bank information
            </div>

            <Form.Item
                style={{ marginBottom: validationErrors?.['bank_details.account_number'] ? 'undefined' : '12px' }}
                help={validationErrors?.['bank_details.account_number']}
                validateStatus={validationErrors?.['bank_details.account_number'] ? 'error' : ''}
                name="bank_details.account_number"
                label="Nostro account number"
            >
                <Input placeholder='Nostro account number' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['bank_details.iban'] ? 'undefined' : '12px' }}
                help={validationErrors?.['bank_details.iban']}
                validateStatus={validationErrors?.['bank_details.iban'] ? 'error' : ''}
                name="bank_details.iban"
                label="SWIFT BIC"
            >
                <Input placeholder='SWIFT BIC' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['bank_details.bank_name'] ? 'undefined' : '12px' }}
                help={validationErrors?.['bank_details.bank_name']}
                validateStatus={validationErrors?.['bank_details.bank_name'] ? 'error' : ''}
                name="bank_details.bank_name"
                label="Bank name"
            >
                <Input placeholder='Bank name' />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: validationErrors?.['bank_details.bank_code'] ? 'undefined' : '12px' }}
                help={validationErrors?.['bank_details.bank_code']}
                validateStatus={validationErrors?.['bank_details.bank_code'] ? 'error' : ''}
                name="bank_details.bank_code"
                label="Bank code"
            >
                <Input placeholder='Bank code' />
            </Form.Item>

            <div className="event-billing-form__title">
                Ultimate beneficiary information
            </div>

            <Form.Item
                style={{ marginBottom: validationErrors?.postal_code ? 'undefined' : '12px' }}
                help={validationErrors?.postal_code && validationErrors?.postal_code}
                validateStatus={validationErrors?.postal_code ? 'error' : ''}
                name="postal_code"
                label="Postal code"
            >
                <Input placeholder='Your code' />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: validationErrors?.email ? 'undefined' : '12px' }}
                help={validationErrors?.email && validationErrors?.email}
                validateStatus={validationErrors?.email ? 'error' : ''}
                name='email'
                label="Email"
                rules={[{ type: 'email' }]}
            >
                <Input placeholder='Your email' />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: validationErrors?.address ? 'undefined' : '12px' }}
                help={validationErrors?.company_name && validationErrors?.company_name}
                validateStatus={validationErrors?.company_name ? 'error' : ''}
                name="company_name"
                label="Company name"
            >
                <Input placeholder='Company name' />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: validationErrors?.address ? 'undefined' : '12px' }}
                help={validationErrors?.address && validationErrors?.address}
                validateStatus={validationErrors?.address ? 'error' : ''}
                name="address"
                label="Organisation address"
            >
                <Input placeholder='Your address' />
            </Form.Item>

            <Form.Item
                help={validationErrors?.tax_code && validationErrors?.tax_code}
                validateStatus={validationErrors?.tax_code ? 'error' : ''}
                name="tax_code"
                label="VAT number"
            >
                <Input placeholder='VAT' type='numeric' />
            </Form.Item>
        </Form>
    );
};

export default EventBillingForm;
