import {Alert, AlertProps} from "antd";
import './styles.scss'

const StyledAlert = (props: AlertProps & { isError?: boolean }) => {
    const { className, isError, ...restProps } = props
    return (
        <Alert
            className={`styled-alert ${isError ? 'styled-alert__error' : ''} ${className}`}
            {...restProps}
        />
    )
}

export default StyledAlert