import React, { useCallback, useEffect, useState } from 'react';
import { Input, Drawer, Badge, Button, Select, DatePicker, Spin } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
// import '../styles.scss'
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';
import { useGetPartnerCampaignsQuery } from '@store/type-partner/campaigns/campaigns.api';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import useSwipeDown from '@hooks/useSwipeDown';
import { EventCatalogFilter } from '@store/type-partner/events-catalog/models';
import dayjs from 'dayjs';
import { useGetCitiesBySearchQuery } from '@store/main/cities/cities.api';
import { useGetCategorieByFiltersQuery } from '@store/main/categories/categories.api';
import { FilterByEnumItem } from '@store/main/enums/models';
import { statusesFormatted } from '@shared/constants';

// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (fn: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            fn(...args);
        }, delay);
    };
};

interface Props {
    filters: EventCatalogFilter;
    onFilterUpdate: (updatedFilters: Partial<EventCatalogFilter>) => void;
    onResetFilters: () => void;
    debouncedOnSearchUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterDrawer: React.FC<Props> = ({
    filters,
    onResetFilters,
    debouncedOnSearchUpdate,
    onFilterUpdate
}) => {

    const { data: optionsData, isLoading: filterIsLoading } = useGetFiltersByEnumQuery({ name: 'lead_type' })
    const { error: campaignError, data: campaignData } = useGetPartnerCampaignsQuery({ filter: undefined });


    const [citySearchValue, setCitySearchValue] = useState('')

    const [locations, setLocations] = useState<{ id: number; slug: string }[]>([])

    const { data: cities, isLoading: citiesLoading } = useGetCitiesBySearchQuery({ search: citySearchValue }, { skip: !citySearchValue })

    const { data: categories, isLoading: categoriesLoading } = useGetCategorieByFiltersQuery({ search: '' })
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_status' })


    const [isShowFilters, setShowFilters] = useState(false)
    const [drawerFilters, setDrawerFilters] = useState<Partial<EventCatalogFilter>>(filters)

    const onCloseFilters = () => {
        setShowFilters(false)
    }

    const mapTypesToOptions = () => {
        return optionsData?.data.map((option) => {
            return { value: option.id, label: option.slug }
        })
    }

    const mapCampaignsToOptions = () => {
        if (campaignData?.data?.length) {
            return campaignData.data.map(campaign => ({ value: campaign.id, label: campaign.name }))
        } else {
            return []
        }
    }


    const { onTouchStart, onTouchEnd } = useSwipeDown(onCloseFilters);

    const onDrawerFilterChange = () => {
        onFilterUpdate(drawerFilters)
        setShowFilters(false)
    }

    const onDrawerResetClick = () => {
        onResetFilters();
        setDrawerFilters(filters)
        setShowFilters(false)
    }

    const isFilterApplied =
        (filters?.search && filters.search.length > 3) ||
        (filters?.date?.from && filters.date.to) ||
        filters?.participants !== null ||
        (filters?.locations && filters.locations.length > 0) ||
        (filters?.categories && filters.categories.length > 0) ||
        (filters?.statuses && filters.statuses.length > 0);

    const optionsFormatter = (options: { id: number; slug: string }[] | undefined) => {
        if (!options) {
            return [];
        }

        return options.map(option => ({
            value: option.id,
            label: option.slug
        }));
    };

    const statusesFormatter = (statuses: FilterByEnumItem[]) => {
        return statuses.map(status => ({
            value: status.id,
            label: statusesFormatted[status.slug as keyof typeof statusesFormatted] || status.slug,
        }));
    };

    const debouncedCityUpdate = useCallback(
        debounce((search: string) => {
            setCitySearchValue(search);
        }, 500),
        [setCitySearchValue]
    );

    useEffect(() => {
        if (cities?.data) {
            setLocations(cities?.data)
        }
    }, [cities])
    return (
        <>
            <div className='flex items-center justify-space-between'>
                <h3>
                    Events catalog
                </h3>

            </div>
            <div className='my-event-page-mobile__filter-container'>
                <Input
                    className='my-event-page-mobile__filter-text'
                    allowClear
                    placeholder="Search by keywords"
                    prefix={<SearchOutlined />}
                    onChange={debouncedOnSearchUpdate}
                />
                <button
                    className='no-style'
                    onClick={() => setShowFilters(true)}
                >
                    <Badge
                        dot={!!isFilterApplied}
                        status="processing"

                    >
                        <FilterButtonMobileIcon
                            isActive={!!isFilterApplied}
                        />
                    </Badge>
                </button>



                <Drawer
                    closeIcon={null}
                    placement='bottom'
                    title={
                        <div
                            onTouchStart={onTouchStart}
                            onTouchEnd={onTouchEnd}
                            className='mobile-drawer__header'
                        >
                            <svg width="51" height="4" viewBox="0 0 51 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 2H49.5" stroke="#D9DADC" stroke-width="3" stroke-linecap="round" />
                            </svg>
                            <div className='mobile-drawer__header-title'>
                                <div></div>
                                <span className='mobile-drawer__header-title-text'>Filters</span>
                                <CloseOutlined
                                    onClick={onDrawerResetClick}
                                />
                            </div>
                        </div>}
                    onClose={onCloseFilters}
                    open={isShowFilters}
                    className='mobile-drawer'
                    footer={<footer className='mobile-drawer__footer flex'>
                        <Button
                            size='large'
                            block
                            style={{ marginRight: '6px' }}
                            onClick={onDrawerResetClick}
                        >
                            Reset
                        </Button>
                        <Button
                            block
                            type='primary'
                            size='large'
                            onClick={onDrawerFilterChange}
                        >
                            Apply
                        </Button>
                    </footer>}
                >
                    <div
                        onTouchStart={onTouchStart}
                        onTouchEnd={onTouchEnd}
                        className='flex flex-col drawer-filter-content'
                    >
                        <p>Date</p>
                        <div className='flex w-full'>
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={drawerFilters?.date?.from ? dayjs(drawerFilters.date.from) : null}
                                className='campaign-create__budget-form-date'
                                onChange={(_, i) => setDrawerFilters({ date: { from: i as unknown as string, to: drawerFilters.date?.to || '' } })}
                                placeholder='Finish Date'
                                allowClear
                            />
                            <DatePicker
                                format="YYYY-MM-DD"
                                value={drawerFilters?.date?.to ? dayjs(drawerFilters.date.to) : null}
                                className='campaign-create__budget-form-date'
                                onChange={(_, i) => setDrawerFilters({ date: { to: i as unknown as string, from: drawerFilters.date?.from || '' } })}
                                placeholder='Start Date'
                                allowClear
                            />
                        </div>

                        <p>
                            Locations
                        </p>
                        <Select
                            placeholder='All locations'
                            style={{ width: '100%' }}
                            mode="multiple"
                            allowClear
                            showSearch
                            maxTagCount={3}
                            filterOption={false}
                            notFoundContent={citiesLoading ? <Spin size="small" /> : null}
                            onSearch={debouncedCityUpdate}
                            loading={citiesLoading}
                            onChange={(locations: number[]) => onFilterUpdate({ locations })}
                            options={optionsFormatter(locations || [])}
                        />

                        <p>
                            Categories
                        </p>
                        <Select
                            // defaultValue=""
                            placeholder='All categories'
                            style={{ width: '100%' }}
                            mode="multiple"
                            allowClear
                            onChange={(categories: number[]) => onFilterUpdate({ categories })}
                            options={optionsFormatter(categories?.data || [])}
                        />

                        <p>
                            Participants
                        </p>
                        <Select
                            defaultValue="all"
                            placeholder='All participants'
                            style={{ width: '100%' }}
                            mode="multiple"
                            allowClear
                            onChange={(v) => console.log(v)}
                            options={[
                                { value: 'all', label: 'All participants' },
                            ]}
                            disabled
                        />

                        <p>
                            Status
                        </p>
                        <Select
                            style={{ width: '100%' }}
                            mode="multiple"
                            allowClear
                            onChange={(statuses: number[]) => onFilterUpdate({ statuses })}
                            options={statusesFormatter(statuses?.data || [])}
                        />
                    </div>
                </Drawer>
            </div>
        </>
    );
};

export default FilterDrawer;
