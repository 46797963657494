import React from "react";

type SearchIconPropsType = {
    widthProp?: string
    heightProp?: string
}
export const SearchIcon: React.FC<SearchIconPropsType> = (props) => {
    const {widthProp = "16", heightProp = '16'} = props;

    return (
        <svg width={widthProp} height={heightProp} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_7439_1073)">
                <g clipPath="url(#clip1_7439_1073)">
                    <path d="M15.0983 14.1182L10.4608 9.48074C11.1805 8.55039 11.5698 7.41289 11.5698 6.21646C11.5698 4.78431 11.0108 3.44146 10.0001 2.42896C8.9894 1.41646 7.64297 0.859314 6.21261 0.859314C4.78225 0.859314 3.43583 1.41824 2.42511 2.42896C1.41261 3.43967 0.855469 4.78431 0.855469 6.21646C0.855469 7.64681 1.4144 8.99324 2.42511 10.004C3.43583 11.0165 4.78047 11.5736 6.21261 11.5736C7.40904 11.5736 8.54475 11.1843 9.47511 10.4665L14.1126 15.1022C14.1262 15.1158 14.1424 15.1266 14.1601 15.1339C14.1779 15.1413 14.1969 15.1451 14.2162 15.1451C14.2354 15.1451 14.2545 15.1413 14.2722 15.1339C14.29 15.1266 14.3062 15.1158 14.3198 15.1022L15.0983 14.3254C15.1119 14.3118 15.1227 14.2956 15.1301 14.2779C15.1375 14.2601 15.1412 14.2411 15.1412 14.2218C15.1412 14.2026 15.1375 14.1835 15.1301 14.1658C15.1227 14.148 15.1119 14.1318 15.0983 14.1182ZM9.04118 9.04503C8.28404 9.80039 7.28047 10.2165 6.21261 10.2165C5.14475 10.2165 4.14118 9.80039 3.38404 9.04503C2.62868 8.28789 2.21261 7.28431 2.21261 6.21646C2.21261 5.1486 2.62868 4.14324 3.38404 3.38789C4.14118 2.63253 5.14475 2.21646 6.21261 2.21646C7.28047 2.21646 8.28583 2.63074 9.04118 3.38789C9.79654 4.14503 10.2126 5.1486 10.2126 6.21646C10.2126 7.28431 9.79654 8.28967 9.04118 9.04503Z" fill="#898A8C"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_7439_1073">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
                <clipPath id="clip1_7439_1073">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
