import EventsPageLayout from '@layouts/EventsPageLayout'
import React from "react";
import LeadsBlock from "@pages/PartnerRolePages/Leads/components/LeadsBlock";
import ContentLayout from "@layouts/ContentLayout.tsx";


const EventLeadsPage: React.FC = () => {
    return (
        <EventsPageLayout>
            <ContentLayout>
                <LeadsBlock />
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default EventLeadsPage