export enum TabKeys {
    all = 'All',
    unread = 'Unread',
    read = 'Read',
}

export const StatusLabels: Record<string, string> = {
    all: 'All notifications',
    unread: 'Unread',
    read: 'Read'
}