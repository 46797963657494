import React, { RefObject } from 'react'

const IslandLayout = ({ children, refProp }: { children: React.ReactNode, refProp?: RefObject<HTMLDivElement>; }) => {
    return (
        <div className='island' ref={refProp}>
            {children}
        </div>
    )
}

export default IslandLayout