import HeaderBreadcrumb from '@components/Header/HeaderBreadcrumb'
import './styles.scss'
import MyEventsCatalogZeroState from './ZeroState'
import EventsPageLayout from '@layouts/EventsPageLayout'
import EventItemHeaderInfo from './components/EventItemHeaderInfo'
import { useParams } from 'react-router-dom'
import NoEventsDataFound from '../MyEventsCardsPage/ZeroState/NoDataFound'
import { events, events_catalog } from '@pages/routes';
import { useGetEventByIdFromCatalogQuery } from '@store/type-partner/events-catalog/partner-event-catalog.api'
import { GetEventByIdFromCatalogDataItem } from '@store/type-partner/events-catalog/models';
import ContentLayout from "@layouts/ContentLayout.tsx";

const getCrumbs = (event: GetEventByIdFromCatalogDataItem) => [
    {
        url: events_catalog,
        label: 'Events catalog'
    },
    {
        url: event?.id ? `${events_catalog}/${event.id}` : events_catalog,
        label: event?.name || 'Event'
    },
]

const MyEventsCatalogEventItemPage = () => {
    const { id } = useParams()
    const { data: res, isLoading } = useGetEventByIdFromCatalogQuery(
        { id: id?.toString() || '' },
        { skip: !id }
    );

    const event = res?.data

    const breadcrumbs = getCrumbs(event)

    if (isLoading) {
        return (
            <EventsPageLayout>
                <HeaderBreadcrumb
                    crumbs={breadcrumbs}
                />
                <div className='catalog-event-item'>
                    <MyEventsCatalogZeroState />
                </div>
            </EventsPageLayout>
        )
    }
    if (!event) {
        return <NoEventsDataFound />
    }

    return (
        <EventsPageLayout>
            <ContentLayout>
                <HeaderBreadcrumb
                    crumbs={breadcrumbs}
                />
                <div className='catalog-event-item'>
                    <header className='catalog-event-item__header'>
                        <img src={event.image} />
                    </header>

                    <article className='catalog-event-item__body'>
                        <EventItemHeaderInfo
                            info={
                                {
                                    id: event.id,
                                    actions: event.status.actions,
                                    logo: event.logo,
                                    title: event.name,
                                    status: event.status.name,
                                    date: {
                                        started_at: event.date?.started_at || '',
                                        finished_at: event.date?.finished_at || '',
                                    },
                                    location: event.location,
                                    participants: (event.participants).toString(),
                                    category: event.category,
                                    organization_url: event.url,
                                }
                            }
                        />
                        <div className='divider' />

                        <p>{event?.description || ''}</p>
                    </article>
                </div>
            </ContentLayout>
        </EventsPageLayout>
    )
}

export default MyEventsCatalogEventItemPage