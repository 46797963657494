const RocketProColored = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.5403 3.54642C14.9644 3.98462 12.3763 6.24953 10.8922 8.75323C9.92493 10.385 9.30582 12.1585 9.06546 13.4223L12.5016 15.8284C13.607 15.1702 15.0618 13.9819 16.2644 12.5149C18.1093 10.2641 19.3517 7.0568 18.5403 3.54642ZM11.7902 17.5839L7.65911 14.6913L4.26899 13.6826C3.11678 13.3398 2.68297 11.9359 3.44089 11.0028L5.87181 8.01006C6.18857 7.6201 6.66268 7.39166 7.16506 7.38694L9.58127 7.36427C11.3566 4.61767 14.3725 2.11875 18.5426 1.68837C19.3464 1.60541 20.0885 2.125 20.2854 2.90871C21.3073 6.97439 19.9917 10.6638 18.0179 13.2717L18.823 15.5499C18.9904 16.0236 18.9379 16.5473 18.6798 16.9783L16.6989 20.2862C16.0813 21.3176 14.6138 21.3901 13.8975 20.4246L11.7902 17.5839ZM16.6246 14.8698C15.6886 15.8186 14.6923 16.6198 13.8002 17.1948L15.2378 19.1327L17.0573 16.0942L16.6246 14.8698ZM8.55609 9.22012L7.25746 9.23231L5.02458 11.9813L7.33727 12.6694C7.57253 11.6344 7.98464 10.4242 8.55609 9.22012Z" fill="url(#paint0_linear_9095_65694)" />
            <path d="M16.3944 9.82937C15.8096 10.6646 14.6584 10.8676 13.8232 10.2827C12.988 9.69792 12.785 8.54676 13.3698 7.71155C13.9546 6.87634 15.1058 6.67336 15.941 7.25818C16.7762 7.843 16.9792 8.99416 16.3944 9.82937Z" fill="url(#paint1_linear_9095_65694)" />
            <path d="M9.51532 20.4607C8.68212 21.5443 5.4189 22.2877 5.4189 22.2877C5.4189 22.2877 5.10012 18.9686 5.73461 17.8135C6.23987 16.8467 7.52867 16.2525 8.9486 17.2467C10.3642 18.238 10.1705 19.6087 9.51532 20.4607Z" fill="url(#paint2_linear_9095_65694)" />
            <defs>
                <linearGradient id="paint0_linear_9095_65694" x1="3.0625" y1="1.67969" x2="19.1162" y2="0.553799" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4A4BD9" />
                    <stop offset="1" stopColor="#A64AD9" />
                </linearGradient>
                <linearGradient id="paint1_linear_9095_65694" x1="3.0625" y1="1.67969" x2="19.1162" y2="0.553799" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4A4BD9" />
                    <stop offset="1" stopColor="#A64AD9" />
                </linearGradient>
                <linearGradient id="paint2_linear_9095_65694" x1="3.0625" y1="1.67969" x2="19.1162" y2="0.553799" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4A4BD9" />
                    <stop offset="1" stopColor="#A64AD9" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default RocketProColored;
