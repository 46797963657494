import LogoHeaderAuth from '@assets/LogoHeaderAuth';
import React from "react";
import './styles.scss'

type RegistrationLayoutPropsType = {
    headerSlot?: React.ReactNode | null;
    rightSlot?: React.ReactNode | null;
    headerInfo?: {
        text?: string;
        image?: string;
    };
    children: React.ReactNode
    sidebar?: React.ReactNode
    className?: string;
    isCompleteRegistration?: boolean;
}

const HeaderInfo = ({ text, image }: { text?: string, image?: string }) => {
    return (
        <div className='additional-header-info'>
            {image
                ?
                <img src={image} />
                :
                null}

        </div>
    )
}

const RegistrationLayout: React.FC<RegistrationLayoutPropsType> = ({
    children,
    headerInfo,
    sidebar,
    className,
    isCompleteRegistration
    // headerSlot,
    // rightSlot
}) => {
    return (
        <div className={`registration-layout ${className || ''}`}>
            <header className='registration-layout__header'>
                <LogoHeaderAuth isCompleteRegistration={isCompleteRegistration} />

                {headerInfo?.text || headerInfo?.image
                    ? (
                        <HeaderInfo
                            text={headerInfo.text}
                            image={headerInfo.image}
                        />
                    )
                    :
                    null}

                {sidebar}
            </header>
            <section className='registration-layout__content'>
                <article className='registration-layout__content-main'>
                    {children}
                </article>
                <aside className='registration-layout__content-aside'>
                    {/* {rightSlot} */}
                </aside>
            </section>
        </div>
    )
}

export default RegistrationLayout;
