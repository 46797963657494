import React, { FC, PropsWithChildren, useState } from 'react'
import SideBarMenu from '@components/SideBarMenu'
import SettingsHeader from '@components/SettingsHeader/SettingsHeader'

import './styles.scss'
import { useLocation, useParams } from 'react-router-dom'
import {
    analytics,
    billing,
    campaigns,
    campaigns_create,
    events,
    events_create,
    events_setup,
    leads,
    moderation,
    partners,
    profile,
    pro,
    notifications,
    settings,
} from '@pages/routes'
import HeaderSimple from '@components/Header/HeaderSimple'
import { useAuth } from "@contexts/AuthContext.tsx";
import EmailConfirmationAlert from '@components/EmailConfirmation/EmailConfirmationAlert';
import { useBreakpoints } from '@hooks/browser'
import SideBarMenuDrawer from '@components/mobile/SideBarMenuDrawer'
import { HeaderMainMobile } from '@components/mobile/HeaderMainMobile'
import EventBillingHeader from "@components/Header/EventBillingHeader.tsx";

interface Props extends PropsWithChildren {
    className?: string
    mobileHeaderSideBar?: React.ReactNode
}

const SettingsHeaderInner = () => {
    const { user } = useAuth()
    const { pathname } = useLocation()
    const { tabId, billingId = '' } = useParams()

    switch (pathname) {
        case billing:
        case `${billing}/${billingId}/${tabId || ''}`:
            return <EventBillingHeader />
        case profile:
            return <HeaderSimple title={'profile'} />
        //EVENT TYPE
        case campaigns_create:
            // case campaigns_create + `/${eventId}`:
            return <HeaderSimple title={'campaigns_create'} />

        // PARTNER TYPE
        case campaigns:
            return <HeaderSimple title='campaigns' />
        case leads:
            return <HeaderSimple title='leads' />

        case events_setup:
            return <HeaderSimple title='events' />
        case events:
            // todo remove after demo
            if (user?.id === 81) {
                return <HeaderSimple
                    title='events_demo'
                />
            }
            return <HeaderSimple title='my_events' />
        case events_create:
            return <HeaderSimple title='events' />

        case partners:
            return <HeaderSimple title='partners' />
        case analytics:
            return <HeaderSimple title='analytics' />
        case moderation:
            return <HeaderSimple title='moderation' />
        case notifications:
            return <HeaderSimple title='notifications' />
        case settings:
        case `${settings}/${tabId || ''}`:
            return <HeaderSimple title='settings' />
        case pro:
            return <HeaderSimple title='pro' />
        default:
            break;
    }
}
const EventsPageLayout: FC<Props> = ({ children, className, mobileHeaderSideBar }) => {
    const isDesktop = useBreakpoints().md;
    const [isOpenMenu, setOpenMenu] = useState(false)

    if (!isDesktop) {
        return (
            <React.Fragment>
                <SideBarMenuDrawer
                    open={isOpenMenu}
                    onClose={() => setOpenMenu(false)}
                />
                <HeaderMainMobile
                    setOpenMenu={() => setOpenMenu(true)}
                    setOpenNotifications={() => null}
                    mobileHeaderSideBar={mobileHeaderSideBar}
                />
                <EmailConfirmationAlert />
                <div className={`pagelayout-content-mobile ${className || ''}`}>
                    {children}
                </div>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <SideBarMenu />
            <SettingsHeader>
                <SettingsHeaderInner />
            </SettingsHeader>
            <EmailConfirmationAlert />
            <div className={`pagelayout-content events-page ${className || ''}`}>
                {children}
            </div>
        </React.Fragment>
    )
}

export default EventsPageLayout