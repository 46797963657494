import React from 'react';
import EyeIcon from "@assets/EyeIcon.tsx";

type Props = {
    onClick: () => void
}

const ErrorStatus = ({ onClick }: Props) => {
    return (
        <div className='table-campaigns--error-box' onClick={onClick}>
            <EyeIcon width="24" height="24" fillColor="#F5222D" withBorders={false} backgroundFillColor="transparent"/>
            View
        </div>
    )
}

export default ErrorStatus
