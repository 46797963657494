//https://boost.allnetru.dev/api/documentation#/Moderation%20reasons
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Options } from "@shared/types";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const moderation_reason = `moderation_reason`;

export const moderationReason = createApi({
    reducerPath: moderation_reason,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        moderation_reason
    ],
    endpoints: build => ({
        getOrganizerLandingReasons: build.query<{ data: Options }, void>({
            query: () => {
                return {
                    url: '/reasons/filter',
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    }
                };
            },
            providesTags: [moderation_reason],
        }),
    }),
});

export const {
    useGetOrganizerLandingReasonsQuery
} = moderationReason;