import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { userAuth } from './main/-user/user.api';
import { userReducer } from './main/-user/user.slice';
import { partnerCampaigns } from './type-partner/campaigns/campaigns.api';

import { partnerEventCatalog } from './type-partner/events-catalog/partner-event-catalog.api';
import { partnerEvent } from './type-partner/events/partner-event.api';
import { partnerLandings } from './type-partner/landings/landings.api';
import { partnerStatistics } from './type-partner/statistics/statistics.api';
import { organizerEvents } from './type-event/events/events.api';
import { organizerLandings } from './type-event/landings/landings.api';
import { organizerStatistics } from './type-event/statistics/statistics.api';
import { adOrganizerCredentials } from './type-event/credentials/credentials.api';
import { organizerCampaigns } from './type-event/campaigns/campaigns.api';
import { organizerBillingApi } from './type-event/billing/billing.api.ts';
import { organizerPartners } from './type-event/partners/partners.api';
import { organizerOnboarding } from './type-event/onboarding/organizer-onboarding.api';
import { categories } from './main/categories/categories.api';
import { countriesApi } from './main/countries/countries.api';
import { notificationsApi } from './main/notifications/notifications.api';
import { billingApi } from './main/billing/billing.api';
import { moderationReason } from './main/moderation-reasons/moderation-reasons.api';
import { citiesApi } from './main/cities/cities.api';
import { paymentApi } from './main/payment/payment.api';
import { organizationApi } from './main/organization/organization.api';
import { subscriptionApi } from './main/subscription/subscription.api';
import { usersRolesApi } from './main/users-roles/users.api.ts';
import { partnerCampaignsReducer } from './type-partner/campaigns/campaigns.slice';
import { partnerLandingsReducer } from './type-partner/landings/landings.slice';
import { filterEnumsApi } from './main/enums/enums.api';

export const store = configureStore({
    reducer: {
        // MAIN
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [categories.reducerPath]: categories.reducer,
        [countriesApi.reducerPath]: countriesApi.reducer,
        [citiesApi.reducerPath]: citiesApi.reducer,
        [billingApi.reducerPath]: billingApi.reducer,
        [moderationReason.reducerPath]: moderationReason.reducer,
        [paymentApi.reducerPath]: paymentApi.reducer,
        [organizationApi.reducerPath]: organizationApi.reducer,
        [subscriptionApi.reducerPath]: subscriptionApi.reducer,
        [usersRolesApi.reducerPath]: usersRolesApi.reducer,
        [filterEnumsApi.reducerPath]: filterEnumsApi.reducer,

        [userAuth.reducerPath]: userAuth.reducer,
        userAuth: userReducer,
        //PARTNER
        [partnerCampaigns.reducerPath]: partnerCampaigns.reducer,
        partnerCampaigns: partnerCampaignsReducer,

        [partnerEventCatalog.reducerPath]: partnerEventCatalog.reducer,
        [partnerEvent.reducerPath]: partnerEvent.reducer,
        [partnerLandings.reducerPath]: partnerLandings.reducer,
        partnerLandings: partnerLandingsReducer,
        [partnerStatistics.reducerPath]: partnerStatistics.reducer,

        //ORGANIZER aka EVENT
        [organizerPartners.reducerPath]: organizerPartners.reducer,
        [organizerEvents.reducerPath]: organizerEvents.reducer,
        [organizerLandings.reducerPath]: organizerLandings.reducer,
        [adOrganizerCredentials.reducerPath]: adOrganizerCredentials.reducer,
        [organizerCampaigns.reducerPath]: organizerCampaigns.reducer,
        [organizerBillingApi.reducerPath]: organizerBillingApi.reducer,
        //not active for moment
        [organizerOnboarding.reducerPath]: organizerOnboarding.reducer,
        [organizerStatistics.reducerPath]: organizerStatistics.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        //MAIN
        .concat(notificationsApi.middleware)
        .concat(categories.middleware)
        .concat(countriesApi.middleware)
        .concat(citiesApi.middleware)

        .concat(billingApi.middleware)
        .concat(moderationReason.middleware)
        .concat(paymentApi.middleware)
        .concat(organizationApi.middleware)

        .concat(subscriptionApi.middleware)
        .concat(usersRolesApi.middleware)
        .concat(filterEnumsApi.middleware)

        //user in needed to refactor
        .concat(userAuth.middleware)
        //COMMON

        //PARTNER
        .concat(partnerCampaigns.middleware)
        .concat(partnerEventCatalog.middleware)
        .concat(partnerEvent.middleware)
        .concat(partnerLandings.middleware)
        .concat(partnerStatistics.middleware)

        //EVENT
        .concat(organizerCampaigns.middleware)
        .concat(organizerBillingApi.middleware)
        .concat(organizerEvents.middleware)
        .concat(organizerLandings.middleware)
        .concat(adOrganizerCredentials.middleware)
        .concat(organizerPartners.middleware)
        .concat(organizerStatistics.middleware)
        //not active for moment
        .concat(organizerOnboarding.middleware)

});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
