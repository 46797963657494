import Avatar from '@components/Avatar'
import StatusDescription from '@components/StatusDescription'
import { CampaignStatuses } from '@shared/constants'
import { formatDateRange } from '@shared/utils'
import { OrganizerEventDataItem } from '@store/type-event/events/models'
import { Table } from 'antd'
import { Link } from 'react-router-dom'
import { TabKeys } from '@pages/EventRolePages/EventsPage/pages/info/constants';
import { formatToKPlus } from '@components/Analytics/helpers'

interface Props {
    events: OrganizerEventDataItem[]
}
const EventsTable = ({ events }: Props) => {

    const formattedNumber = (number: number) => new Intl.NumberFormat('fr', {
        useGrouping: true
    }).format(number);

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (status: { id: number, name: string }) => <StatusDescription status={status.name as CampaignStatuses} />
        },
        {
            title: 'Event name',
            dataIndex: 'event',
            key: 'name',
            width: 300,
            render: (event: OrganizerEventDataItem['event'], row: OrganizerEventDataItem) => {
                return (
                    <Link
                        to={`/events/info/${row.id}/${TabKeys.details}`}
                        className='no-style'
                    >
                        <div className='flex items-center'>
                            <Avatar
                                size='50'
                                url={event.logo || ''}
                            />
                            <h4 className='mx-6'>
                                {event.name}
                            </h4>
                        </div>
                    </Link>
                )
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (date: OrganizerEventDataItem['date']) => {
                return (
                    <div className='flex items-center'>
                        <p
                            className='text-regular-14 m-0'
                        >
                            {formatDateRange({ started_at: date.started_at, finished_at: date.started_at })}
                        </p>
                    </div>
                )
            }
        },
        {
            title: 'Sponsors',
            dataIndex: 'sponsors',
            key: 'sponsors',
            width: 120,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.partners || 0)}
                    </div>
                )
            }
        },
        {
            title: 'Campaigns',
            dataIndex: 'campaigns',
            key: 'campaigns',
            width: 120,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.campaigns || 0)}
                    </div>
                )
            }
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            width: 120,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        NO DATA
                    </div>
                )
            }
        },
        {
            title: 'Leads',
            dataIndex: 'leads',
            key: 'leads',
            width: 120,
            render: (_: any, row: OrganizerEventDataItem) => {
                return (
                    <div className='flex items-center'>
                        {formatToKPlus(row?.leads || 0)}
                    </div>
                )
            }
        },

    ];

    return (
        <div className='mt-16'>
            <Table
                dataSource={events}
                columns={columns}
                scroll={{ x: 1200 }}
                size='small'
            />
        </div>
    )
}

export default EventsTable