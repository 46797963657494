import "@components/Analytics/styles.scss";
import './styles.scss'
import { useBreakpoints } from '@hooks/browser';
import AnalyticsPageDesktop from './AnalyticsPageDesktop';
import AnalyticsPageMobile from './mobile/AnalyticsPageMobile';

const AnalyticsPage = () => {
    const isDesktop = useBreakpoints().md

    if (!isDesktop) {
        return <AnalyticsPageMobile />
    }
    return <AnalyticsPageDesktop />
}

export default AnalyticsPage
