import { createContext, useState, ReactNode } from 'react';

interface LoaderMainContextType {
    loading: boolean;
    setLoading: (loading: boolean) => void
}

export const LoaderMainContext = createContext<LoaderMainContextType>({
    loading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setLoading: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
});

interface LoaderMainProviderProps {
    children: ReactNode;
}

export const LoaderMainProvider: React.FC<LoaderMainProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState(false);

    return (
        <LoaderMainContext.Provider value={{
            loading,
            setLoading,

        }}>
            {children}
        </LoaderMainContext.Provider>
    );
};
