import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    useGetOrganizerCredentialsQuery,
    useGetOrganizerCredentialsByEventIdQuery,
    usePostOrganizerEventCredentialsByIdMutation,
} from "@store/type-event/credentials/credentials.api.ts";
import {Button, message, notification, Select, Spin, Tooltip} from "antd";
import InfoIcon from "@icons/header/InfoIcon.tsx";
import { useBreakpoints } from "@hooks/browser";
import emptyBilling from "@assets/png/emptyBilling.png";
import { PlusOutlined } from "@ant-design/icons";
import { settings } from "@pages/routes.ts";
import { SettingsIds } from "@pages/PartnerRolePages/SettingsPage/constants.ts";
import ChangeAdAccountsModal
    from "@pages/EventRolePages/EventsPage/pages/info/ad-accounts-tab/ChangeAdAccountsModal.tsx";

const AdAccountsTab = () => {
    const { eventId } = useParams()
    const navigate = useNavigate()

    const [changedBilling, setChangedBilling] = useState<{ label: string; value: number } | null>(null)
    const [selectedBilling, setSelectedBilling] = useState<{ value: number; label: string; }[] | null>(null)

    const [isLoading, setIsLoading] = useState(false)
    const { data: credentials, isLoading: isCredentialsLoading, isFetching: isCredentialsFetching, refetch: refetchCredentials } = useGetOrganizerCredentialsByEventIdQuery({ eventId: eventId || '' }, { skip: !eventId })
    const { data: allCredentials, isLoading: isAllCredentialsLoading, isFetching: isAllCredentialsFetching } = useGetOrganizerCredentialsQuery()

    const [postCredentials] = usePostOrganizerEventCredentialsByIdMutation()
    const isDesktop = useBreakpoints().md;

    useEffect(() => {
        if (credentials?.data?.length) {
            const mappedData = credentials.data.map(({ id, name }) => {
                return ({
                    value: id,
                    label: name
                })
            })
            setSelectedBilling(mappedData)
        }
    }, [credentials]);

    const isDataLoading = isAllCredentialsLoading || isAllCredentialsFetching || isCredentialsLoading || isCredentialsFetching

    const handleChangeBilling = async () => {
        if (!changedBilling?.value || !eventId) {
            return
        }
        setIsLoading(true)
        try {
            const response = await postCredentials({ credentialId: changedBilling?.value, eventId })

            if ('error' in response) {
                void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Billing error',
                });
            }

            if ('data' in response) {
                notification.open({
                    message: `Billing has been successfully changed to ${changedBilling?.label}`,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
                setChangedBilling(null)
                await refetchCredentials()
            }
        } catch (e) {
            notification.open({
                message: 'Billing has not been changed',
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'warning'
            });
        }
        setChangedBilling(null)
        setIsLoading(false)
    }

    const onCloseChangeBillingModal = () => {
        setChangedBilling(null)
    }
    return (
        <article className="ad-accounts-page">
            <div className='ad-accounts-page__header'>
                <div className='ad-accounts-page__header-title'>
                    <div>Ad accounts</div>
                    <Tooltip
                        overlayClassName='tooltip-general billing-tab-tooltip'
                        title={(
                            <div>
                                The same billing account can be used for multiple events
                            </div>
                        )}
                        placement={isDesktop ? "bottom" : "bottomRight"}
                        overlayStyle={{
                            width: '230px'
                        }}
                    >
                        <div className='cursor-pointer'>
                            <InfoIcon fillColor="#252628" width={18} height={18} />
                        </div>
                    </Tooltip>
                </div>
            </div>
            {isDataLoading && (
                <div className='flex flex-center ad-accounts-page__select-spin'>
                    <Spin size="small" />
                </div>
            )}
            {!isDataLoading && Boolean(allCredentials?.data?.length) && (
                <Select
                    allowClear={false}
                    mode="tags"
                    id="billing-tab__billing"
                    placeholder='Select from the existing accounts'
                    style={{ width: '100%' }}
                    filterOption={false}
                    notFoundContent={(isDataLoading || isLoading) ? <Spin size="small" /> :
                        <div>There is no billings</div>}
                    loading={isDataLoading || isLoading}
                    listHeight={160}
                    onSelect={(_, option) => {
                        setChangedBilling({ label: option.name, value: option.id } as { label: string; value: number })
                    }}
                    options={allCredentials?.data}
                    value={selectedBilling}
                    showSearch={false}
                    className="ad-accounts-tab__select"
                    fieldNames={{
                        value: 'id',
                        label: 'name'
                    }}
                />
            )}
            {!isDataLoading && !allCredentials?.data?.length && (
                <div className='ad-accounts-page__empty-credentials'>
                    <img src={emptyBilling} alt='emptyBilling' />
                    <span><b>You have no accounts</b></span>
                    <span>To add one of them, click on the button below</span>
                    <Button
                        type='primary'
                        onClick={() => navigate(`${settings}/${SettingsIds.adAccounts}`)}
                    >
                        <PlusOutlined />
                        Add account
                    </Button>
                </div>
            )}
            <ChangeAdAccountsModal
                isOpen={Boolean(changedBilling)}
                onClose={onCloseChangeBillingModal}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={handleChangeBilling}
                billing={changedBilling}
                isLoading={isLoading}
            />
        </article>

    )
}

export default AdAccountsTab
