import { Button, Dropdown, MenuProps, Modal } from "antd";
import LogoutIcon from "@assets/LogoutIcon.tsx";
import React, { useState } from "react";
import { useAuth } from "@contexts/AuthContext.tsx";

const LogoutDropdown = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { logout } = useAuth()

    const dropdownItems: MenuProps['items'] = [
        {
            label: 'Log out',
            key: '1',
            onClick: () => {
                setIsModalOpen(true)
            },
        }
    ];

    const onClose = () => {
        setIsModalOpen(false)
    }

    const onOk = () => {
        onClose()
        logout('/init')
    }

    return (
        <>
            <Dropdown
                overlayClassName='auth__sidebar-overlay'
                trigger={['click']}
                menu={{ items: dropdownItems }}
                placement="bottomCenter"
            >
                <Button className="auth__sidebar" icon={<LogoutIcon />} />
            </Dropdown>
            <Modal
                className="logout-dropdown__modal"
                title="Log out"
                open={isModalOpen}
                onOk={onOk}
                width={440}
                centered
                onCancel={onClose}
                footer={[
                    <footer className='flex items-center justify-end mt-24'>
                        <Button
                            key="back"
                            onClick={onClose}
                            size='large'
                        >
                            Cancel
                        </Button>
                        <Button
                            key="submit"
                            type='primary'
                            size='large'
                            onClick={onOk}
                        >
                            Log out
                        </Button>
                    </footer>
                ]}
            >
                Are you sure you want to log out? Your information will be saved
            </Modal>
        </>
    )
}

export default LogoutDropdown
