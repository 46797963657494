import { message } from 'antd';
import { createContext, useState, ReactNode } from 'react';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { Intent } from '@store/main/payment/models';
import { useLazyGetOpenStripeQuery, usePostBillingMethodIdMutation, usePostDefaultBillingMethodIdMutation } from '@store/main/payment/payment.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

interface StripeContextType {
    loading: boolean;
    onOpenStripeForm: () => void;
    onAddPaymentClick: (event?: { preventDefault: () => void; }, redirectTo?: string) => Promise<void>;
    stripe: Stripe | null;
    setStripe: (info: Stripe | null) => void
    // FROM STRIPE
    elements: StripeElements | null;
    setElements: (info: StripeElements | null) => void
    intent: Intent | null;
    setIntent: (intent: Intent | null) => void
    apiKey: string;
    setApiKey: (key: string) => void
    selectedCardId: string;
    setSelectedCardId: (key: string) => void
}

export const StripeContext = createContext<StripeContextType>({
    loading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onOpenStripeForm: () => { },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onAddPaymentClick: async (event?: { preventDefault: () => void; }, redirectTo?: string) => { },
    stripe: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setStripe: () => { },
    intent: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIntent: () => { },
    apiKey: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setApiKey: () => { },
    elements: null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setElements: () => { },
    selectedCardId: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setSelectedCardId: () => { }
});

interface StripeProviderProps {
    children: ReactNode;
}

export const StripeProvider: React.FC<StripeProviderProps> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [intent, setIntent] = useState<Intent | null>(null);
    const [apiKey, setApiKey] = useState('');
    const [elements, setElements] = useState<StripeElements | null>(null);
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [selectedCardId, setSelectedCardId] = useState('');

    const [onOpenForm] = useLazyGetOpenStripeQuery()
    const [postDefault] = usePostDefaultBillingMethodIdMutation()


    const onOpenStripeForm = async () => {
        setLoading(true);
        try {
            const response = await onOpenForm();
            if (response?.data?.data?.intent) {
                setLoading(false);
                setIntent(response.data.data.intent);
                setApiKey(response.data.data.api_key)
            }
        } catch (error) {
            console.error('rejected', error);
        } finally {
            setLoading(false);
        }
    };


    const [postAddCard] = usePostBillingMethodIdMutation()

    const handlePaymentMethodUpdate = async ({ id }: { id: string }) => {
        setLoading(true)
        try {
            const response = await postAddCard({ id });
            await postDefault({ id })

            setLoading(false)
            if ('data' in response) {
                setIntent(null)
                return message.open({
                    type: 'success',
                    content: 'Payment Method Added',
                });
            }

        } catch (error) {
            setLoading(false)
            throw Error(error as string)
            console.error('rejected', error);
        }
    }

    const onAddPaymentClick = async (event?: { preventDefault: () => void; }, redirectTo?: string) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event && event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: 'https://example.com/order/123/complete',
            },
            redirect: "if_required"
        })

        if (setupIntent && setupIntent?.payment_method as string) {
            sendAnalytics(GTMEventName.enterCardData)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return handlePaymentMethodUpdate({ id: setupIntent.payment_method?.toString() || '' })
                .then(res => {
                    redirectTo ? window.location.replace(redirectTo) : null
                })
        }


        if (error && error?.message) {
            void message.open({
                type: 'error',
                content: error.message,
            });
            throw Error(error?.message)
        }
    };

    return (
        <StripeContext.Provider value={{
            loading,
            intent,
            setIntent,
            apiKey,
            setApiKey,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onOpenStripeForm,
            onAddPaymentClick,
            stripe,
            setStripe,
            elements,
            setElements,
            selectedCardId,
            setSelectedCardId
        }}>
            {children}
        </StripeContext.Provider>
    );
};
