import { Button, Collapse } from 'antd';
import type { CollapseProps } from 'antd';
import React from 'react';
import './styles.scss'
import cn from "classnames";
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useAppSelector } from '@hooks/redux';
import { useDispatch } from 'react-redux';
import { partnerLandingsActions } from "@store/type-partner/landings/landings.slice";

const items: CollapseProps['items'] = [
    {
        key: '1',
        label: 'What is landing page?',
        children: 'A landing page is a customizable template branded with the event’s identity and hosted on the event’s website.',
    },
    {
        key: '2',
        label: 'Can I drive traffic from a landing page to my website?',
        children: 'A landing page is a customizable template branded with the event’s identity and hosted on the event’s website.',
    },
    {
        key: '3',
        label: 'How quickly will my landing page be published on the event website?',
        children: 'A landing page is a customizable template branded with the event’s identity and hosted on the event’s website.',
    },
];

const wrapperItems: CollapseProps['items'] = [
    {
        key: '1',
        label: <span className='landing-faq__header__title'>FAQ</span>,
        children: (
            <div className='landing-faq__inner popo'>
                <Collapse
                    expandIconPosition="end"
                    defaultActiveKey={['1']}
                    ghost
                    items={items}
                    bordered
                />
                <div className='landing-faq__video flex-col'>
                    <iframe
                        width="426"
                        height="240"
                        src="https://www.youtube.com/embed/kfXDjm-gopo?si=na5V97Iq01MJ2o4v"
                        title="How to create a landing page?"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    />
                </div>
            </div>
        ),
    },
];

const ExpandIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <Button
            type='link'
            size="small"
        >
            View
            {' '}
            {isActive ? 'less' : 'more'}
            {isActive ? <UpOutlined /> : <DownOutlined />}
        </Button>
    )
}

const LandingFAQ = () => {
    const { activeFaqKeys } = useAppSelector(state => state.partnerLandings)
    const dispatch = useDispatch()

    const handleChange = (newKeys: string[]) => {
        dispatch(partnerLandingsActions.setActiveFaqKeys(newKeys))
    }

    return (
        <div className={cn('landing-faq__wrapper', !activeFaqKeys?.length && 'landing-faq__wrapper__active')}>
            <Collapse
                items={wrapperItems}
                expandIconPosition="end"
                expandIcon={ExpandIcon}
                activeKey={activeFaqKeys}
                onChange={handleChange}
                ghost
            />
        </div>
    )
}

export default LandingFAQ
