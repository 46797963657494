import React, { useCallback, useState } from "react";
import styles from "./style.module.css";
import { Typography } from "antd";
import LeadsFilterPanel
    from "@pages/PartnerRolePages/Leads/components/LeadsFilterPanel";
import { useGetLeadsExported } from "@pages/PartnerRolePages/Leads/leads.api";
import LeadsTableViewManager from "@pages/PartnerRolePages/Leads/components/LeadsTableViewManager";
import ExportToCsvButton from "@pages/PartnerRolePages/Leads/components/ExportToCsvButton";
import { LeadsFilters } from "@store/models-to replace/leads";
import { debounce } from "@shared/utils.ts";
import { useBreakpoints } from '@hooks/browser';
import FilterDrawer from './mobile/FilterDrawer';
import { useGetPartnerLeadsQuery } from '@store/type-partner/events/partner-event.api';

export type PaginationConfig = {
    total: number
    current: number
    pageSize: number
    onChange: (newPage: number) => void
}

const DEFAULT_FILTERS: LeadsFilters = {
    search: '',
    types: [],
    campaigns: [],
}

const LeadsBlock: React.FC = () => {
    const isDesktop = useBreakpoints().md
    const [filters, setFilters] = useState<LeadsFilters>(DEFAULT_FILTERS);
    const [page, setPage] = useState<number | undefined>(undefined)

    //TOFIX: pagination
    const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
        total: 0,
        current: page || 1,
        pageSize: 15,
        onChange: (page) => setPage(page),
    })
    const { isLoading: leadsLoading, error: leadsError, data: leadsData } = useGetPartnerLeadsQuery({ filters, page });
    const { isLoading: isExportLoading, run: runExport } = useGetLeadsExported();

    const mapFilterValues = (newFilters: LeadsFilters): Partial<LeadsFilters> => {
        const result: Partial<LeadsFilters> = {};

        if (newFilters.search && newFilters.search.length > 2) {
            result.search = newFilters.search;
        }

        if (newFilters.types && newFilters.types.length > 0) {
            result.types = newFilters.types;
        }

        if (newFilters.campaigns && newFilters.campaigns.length > 0) {
            result.campaigns = newFilters.campaigns;
        }

        return result;
    }


    const onFilterUpdate = (value: Partial<LeadsFilters>) => {
        setFilters((prev) => ({ ...prev, ...value }));
    };



    const debouncedOnSearchUpdate = useCallback(
        debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            onFilterUpdate({ search: e.target.value });
        }, 500),
        [onFilterUpdate]
    );

    const resetFilters = () => {
        setFilters(DEFAULT_FILTERS);
    }

    return (
        <section className={styles.LeadsBlock}>
            {isDesktop
                ?
                <div>
                    <Typography.Title level={5} className={styles.LeadsBlock__title}>
                        My leads
                    </Typography.Title>

                    <LeadsFilterPanel
                        filters={filters}
                        onFilterChange={onFilterUpdate}
                        resetFilters={resetFilters}
                        errorTableData={leadsError}
                        extraActionsSlot={
                            <ExportToCsvButton
                                runExportReq={() => runExport({ filters: mapFilterValues(filters) })}
                                isLoading={isExportLoading}
                            >
                                Export CSV
                            </ExportToCsvButton>}
                        debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                    />
                </div>
                :
                <FilterDrawer
                    filters={filters}
                    searchValue={''}
                    onFilterUpdate={onFilterUpdate}
                    onResetFilters={resetFilters}
                    debouncedOnSearchUpdate={debouncedOnSearchUpdate}
                />
            }
            <LeadsTableViewManager
                data={leadsData?.data}
                loading={leadsLoading}
                error={leadsError ? leadsError : null}
                paginationConfig={paginationConfig}
            />

        </section>
    )
}

export default LeadsBlock;
