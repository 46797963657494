import { Statistics } from "@store/type-event/campaigns/models";
import { StatisticDate, StatisticsMetrics, StatisticsMetricTypes } from "@shared/statisticTypes.ts";
import { colors, colorsByNames, idMapping, initStatistics } from "@components/Analytics/constants.tsx";
import { formatByCurrency, formatDateToCommonFormat } from "@shared/utils.ts";

export const transformData = (statisticDates: StatisticDate[], set: string[]) => {
    return set.map(metric => ({
        id: idMapping[metric],
        data: statisticDates.map(item => ({
            x: item?.date,
            y: item?.statistics?.[metric as keyof Statistics]
        }))
    }));
}

interface DataPoint {
    x: string;
    y: number;
}

export interface LineData {
    id: string;
    data: DataPoint[];
}

const maxLength = 32;

export const formatY = (y: string | number, type: string, currency?: string) => {
    if (type === StatisticsMetricTypes.countable) {
        return formatNumberWithThousandsSeparator(y, true)
    }
    if (type === StatisticsMetricTypes.percent) {
        return `${formatNumberWithThousandsSeparator(Number(y || 0) * 100)}%`
    }
    if (type === StatisticsMetricTypes.money) {
        return formatByCurrency(y, currency)
    }
    return formatNumberWithThousandsSeparator(y, true)
}

const getFirstSerie = (s: LineData): LineData => {
    let filteredData = s.data.filter(point => point.y !== 0);
    const firstPoint = s.data[0] || { x: '', y: 0 };
    const lastPoint = s.data[s.data.length - 1] || { x: '', y: 0 };

    if (!filteredData.find(point => point.x === firstPoint.x)) {
        filteredData.unshift(firstPoint);
    }
    if (!filteredData.find(point => point.x === lastPoint.x)) {
        filteredData.push(lastPoint);
    }

    const minPoint = filteredData.reduce((min, p) => (p.y < min.y ? p : min), filteredData[0]);
    const maxPoint = filteredData.reduce((max, p) => (p.y > max.y ? p : max), filteredData[0]);

    if (!filteredData.find(point => point.x === minPoint.x)) {
        filteredData.push(minPoint);
    }
    if (!filteredData.find(point => point.x === maxPoint.x)) {
        filteredData.push(maxPoint);
    }

    filteredData.sort((a, b) => (a.x > b.x ? 1 : -1));

    if (filteredData.length > maxLength) {
        const step = Math.ceil(filteredData.length / maxLength);
        filteredData = filteredData.filter((_, index) => index % step === 0);
    }

    if (filteredData.length < maxLength) {
        const remainingData = s.data.filter(point => !filteredData.find(p => p.x === point.x));
        const step = Math.ceil(remainingData.length / (maxLength - filteredData.length));
        const additionalPoints = remainingData.filter((_, index) => index % step === 0);

        filteredData = [...filteredData, ...additionalPoints].slice(0, maxLength);
    }

    return {
        ...s,
        data: filteredData.sort((a, b) => (a.x > b.x ? 1 : -1)),
    };
};

export const filterData = (series: LineData[]): LineData[] => {
    let serieXArray: string[] = []
    return series.map((s, index) => {
        if (index === 0) {
            const firstSerie = getFirstSerie(s)
            serieXArray = firstSerie.data.map(({ x }) => x)
            return {
                id: firstSerie.id,
                data: firstSerie.data.map(point => ({
                    x: point.x,
                    y: point.y
                })),
            }
        }
        const filteredData = s.data.filter(({ x }) => serieXArray.includes(x))
        return {
            id: s.id,
            data: filteredData.sort((a, b) => (a.x > b.x ? 1 : -1)).map(point => {
                return ({
                    x: point.x,
                    y: point.y,
                })
            }),
        }
    });
};

export const formatXValue = (value: string) => {
    const formattedDate = formatDateToCommonFormat(value)
    return formattedDate?.substring(0, formattedDate.length - 6) || '';
}

export function formatNumberWithThousandsSeparator(string: string | number, isRound = false): string {
    const number = Number(string)
    if (number === 0 || !number) {
        return isRound ? '0' : '0.00';
    }

    const fractionDigits = isRound ? 0 : 2
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(number);
}

export const formatToKPlus = (number: number) => {
    if (number >= 1000) {
        const formattedNumber = (number / 1000).toFixed(number % 1000 >= 500 ? 1 : 0);
        return `${formattedNumber}k+`;
    }
    return number.toString();
};

export const getResponsiveLineData = ({
    statisticsDates, statisticsLeftValue, statisticsRightValue, currency
}: {
    statisticsDates: StatisticDate[]
    statisticsLeftValue: StatisticsMetrics[]
    statisticsRightValue: StatisticsMetrics[]
    currency?: string
}) => {
    const statisticsSelectedValuesLeft = [
        ...(Array.isArray(statisticsLeftValue) ? statisticsLeftValue : [statisticsLeftValue])]
    const statisticsIdsLeft = statisticsSelectedValuesLeft.map(({ metric }) => metric)
    const statisticsIdsSetLeft = new Set(statisticsIdsLeft)
    const statisticsSelectedValuesRight = [
        ...(Array.isArray(statisticsRightValue) ? statisticsRightValue : [statisticsRightValue])]
    const statisticsIdsRight = statisticsSelectedValuesRight.map(({ metric }) => metric)
    const statisticsIdsSetRight = new Set(statisticsIdsRight)
    const filteredStatisticsDates = [] as StatisticDate[]
    statisticsDates.forEach(({ statistics, date }) => {
        const withSameDate = filteredStatisticsDates.find(({ date: filteredDate }) => filteredDate === date)
        if (!withSameDate) {
            filteredStatisticsDates.push({
                statistics: statistics.currency === currency ? statistics : initStatistics,
                date
            })
        }
    })
    return {
        leftData: filterData(transformData(filteredStatisticsDates, [...statisticsIdsSetLeft])),
        rightData: filterData(transformData(filteredStatisticsDates, [...statisticsIdsSetRight])),
    }
}

export const colorById = (id: string) => colors[id] || '#000000';

export const colorByRowData = (rowData: { data?: { x: string, y: number }, id: string }) => {
    const { id } = rowData
    return colorsByNames[id] || '#000000';
};
