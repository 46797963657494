import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { message, Table } from 'antd';
import EventCatalogTableZeroState from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import StatusDescription from '@components/StatusDescription';
import { EventCatalogItemStatuses } from '@shared/constants';
import CustomButton from '@components/CustomButton';
import ZeroPartners from './ZeroState';
import { useGetOrganizerPartnersByEventQuery, usePatchOrganizerPartnersStatusByIdMutation } from '@store/type-event/partners/partners.api';
import { TabKeys } from '@pages/EventRolePages/EventsPage/pages/info/constants';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";

interface PartnersTableProps {
    onInviteClick: () => void
    isPublished?: boolean
    onChangeTab: (value: TabKeys) => void
}
const PartnersTable = ({
    isPublished,
    onInviteClick,
    onChangeTab
}: PartnersTableProps) => {
    const { eventId: id } = useParams()
    const { data: partners, isLoading: isFetching, refetch } = useGetOrganizerPartnersByEventQuery({ eventId: id?.toString() || '' }, { skip: !id })
    const [patchEvent] = usePatchOrganizerPartnersStatusByIdMutation()

    const [isStatusLoading, setIsStatusLoading] = useState(false)
    const [partnersList, setPartnersList] = useState(partners?.data || []);

    useEffect(() => {
        void refetch()
    }, [])

    useEffect(() => {
        if (partners?.data) {
            setPartnersList(partners.data)
        }
    }, [partners?.data])

    const onStatusClick = async ({ eventId, statusId, invite, statusSlug }: { eventId: string, statusId: number, invite: number, statusSlug?: string }) => {
        setIsStatusLoading(true)
        try {
            const response = await patchEvent({ eventId, statusId, invite })
            if ('data' in response) {
                sendAnalytics(`${GTMEventName.inviteStatus}_${statusSlug || ''}`)
                await refetch()
                setIsStatusLoading(false)
                return message.open({
                    type: 'success',
                    content: 'Status is changed'
                });
            }
            if ('error' in response) {
                setIsStatusLoading(false)
                return message.open({
                    type: 'error',
                    content: 'Status update error',
                });
            }
        } catch (error) {
            setIsStatusLoading(false)
            return message.open({
                type: 'error',
                content: 'Status Update error',
            });
        }
        return setIsStatusLoading(false)
    }

    const columns = [
        {
            title: 'Invitation status',
            dataIndex: 'status',
            key: 'status',
            width: 187,
            render: (status: {
                id: number;
                name: EventCatalogItemStatuses,
                actions: {
                    id: number
                    slug: EventCatalogItemStatuses
                    transit: string
                }[]
            }) => {
                return (
                    <div>
                        <StatusDescription status={status.name} />
                    </div>
                )
            }
        },
        {
            title: 'Invite',
            dataIndex: 'status',
            key: 'status',
            width: 195,
            render: (status: {
                id: number;
                name: EventCatalogItemStatuses,
                actions: {
                    id: number
                    slug: EventCatalogItemStatuses
                    transit: string
                }[]
            }, row: { id: number }) => {
                return (
                    <div className='partners-tab__status-actions'>
                        {status?.actions
                            ?
                            status?.actions.map(cta => {
                                return (
                                    <CustomButton
                                        key={cta.id}
                                        title={cta.transit}
                                        size='small'
                                        block
                                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                        onClick={() => onStatusClick({ eventId: id?.toString() || '', statusId: cta.id, invite: row.id, statusSlug: cta.slug })}
                                    />
                                )
                            })
                            :
                            null}
                    </div>
                )
            }
        },
        {
            title: 'Partner name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            render: (name: string) => name || ''
        },
        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            width: 200,
            render: (url: string) => url ? <Link className='no-style' to={url} target='_blank'>{url}</Link> : ''
        },

    ];

    if (isFetching || isStatusLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }
    return (
        <>
            {partnersList.length
                ?
                <Table
                    dataSource={partnersList}
                    columns={columns}
                    rowKey={'id'}
                    scroll={{ x: '50vh' }}

                />
                :
                <ZeroPartners
                    readonly
                    onCtaClick={onInviteClick}
                    isPublished={isPublished}
                    onChangeTab={onChangeTab}
                />
            }
        </>
    )
}

export default PartnersTable
