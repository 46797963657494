import './styles.scss'
const BadgeCustom = ({ count }: { count: string }) => {
    if (!count) {
        return null
    }
    return (
        <div className='badge-custom'>{count}</div>
    )
}

export default BadgeCustom