
const ZeroCampaignsIcon = () => {
    return (
        <svg width="210" height="160" viewBox="0 0 210 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="209" height="159" rx="11.5" fill="white" />
            <rect x="0.5" y="0.5" width="209" height="159" rx="11.5" stroke="#EBEDF0" />
            <g clipPath="url(#clip0_4736_64403)">
                <path d="M10 51H200" stroke="#EFF0F4" />
                <path d="M10 61H200" stroke="#EFF0F4" />
                <path d="M10 71H200" stroke="#EFF0F4" />
                <path d="M10 81H200" stroke="#EFF0F4" />
                <path d="M10 91H200" stroke="#EFF0F4" />
                <path d="M10 101H200" stroke="#EFF0F4" />
                <path d="M10 111H200" stroke="#EFF0F4" />
                <path d="M10 121H200" stroke="#EFF0F4" />
                <path d="M10 131H200" stroke="#EFF0F4" />
                <path d="M10 141H200" stroke="#EFF0F4" />
                <path d="M73.3281 51V141.5" stroke="#EFF0F4" />
                <path d="M136.672 51V141.5" stroke="#EFF0F4" />
            </g>
            <rect x="10" y="51" width="190" height="90" rx="2" stroke="#EFF0F4" />
            <path d="M10 33C10 31.8954 10.8954 31 12 31H198C199.105 31 200 31.8954 200 33V39C200 40.1046 199.105 41 198 41H12C10.8954 41 10 40.1046 10 39V33Z" fill="#EFF0F4" />
            <rect y="18" width="210" height="1" fill="#EBEDF0" />
            <ellipse cx="14" cy="9.5" rx="4" ry="4" fill="#EBEDF0" />
            <ellipse cx="26" cy="9.5" rx="4" ry="4" fill="#EBEDF0" />
            <ellipse cx="38" cy="9.5" rx="4" ry="4" fill="#EBEDF0" />
            <defs>
                <clipPath id="clip0_4736_64403">
                    <rect x="10" y="51" width="190" height="90" rx="2" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ZeroCampaignsIcon