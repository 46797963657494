const AudiencesIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.0004" cy="13.0004" r="10.4136" stroke="white" strokeWidth="1.9" />
            <path d="M12.3349 5.00391C12.178 5.00391 12.0508 4.87671 12.0508 4.71982L12.0508 0.7837C12.0508 0.626801 12.178 0.499609 12.3349 0.499609L13.6667 0.499609C13.8236 0.499609 13.9508 0.626801 13.9508 0.7837L13.9508 4.71982C13.9508 4.87671 13.8236 5.00391 13.6667 5.00391L12.3349 5.00391Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
            <path d="M12.3349 25.498C12.178 25.498 12.0508 25.3709 12.0508 25.214L12.0508 21.2782C12.0508 21.1213 12.178 20.9941 12.3349 20.9941L13.6667 20.9941C13.8236 20.9941 13.9508 21.1213 13.9508 21.2782L13.9508 25.214C13.9508 25.3709 13.8236 25.498 13.6667 25.498L12.3349 25.498Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
            <path d="M21 12.3329C21 12.176 21.1272 12.0488 21.2841 12.0488H25.2184C25.3753 12.0488 25.5025 12.176 25.5025 12.3329V13.6647C25.5025 13.8216 25.3753 13.9488 25.2184 13.9488H21.2841C21.1272 13.9488 21 13.8216 21 13.6647V12.3329Z" fill={isActive ? '#4E67EA' : '#F5F5F5'} fillOpacity={isActive ? '0.2' : '1'} />
            <path d="M0.5 12.3329C0.5 12.176 0.627192 12.0488 0.784091 12.0488H4.71591C4.87281 12.0488 5 12.176 5 12.3329V13.6647C5 13.8216 4.87281 13.9488 4.71591 13.9488H0.784091C0.627192 13.9488 0.5 13.8216 0.5 13.6647V12.3329Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
            <path d="M16.4833 11.5309C16.4833 13.4298 14.9439 14.9693 13.0448 14.9693C11.1457 14.9693 9.60625 13.4298 9.60625 11.5309C9.60625 9.63202 11.1457 8.09258 13.0448 8.09258C14.9439 8.09258 16.4833 9.63202 16.4833 11.5309Z" stroke="white" strokeWidth="1.9" />
            <path d="M20 20.8555C20 17.5642 16.4481 15.0508 13.0053 15.0508C9.50785 15.0508 6 17.5642 6 20.8555" stroke="white" strokeWidth="1.9" />
        </svg>
    )
}
export default AudiencesIcon