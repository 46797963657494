import React, { useCallback, useEffect, useState } from 'react'

import { Drawer, Select, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import CustomButton from '@components/CustomButton';
import ExitCrossIcon from '@assets/ExitCrossIcon';
import { statusesFormatted } from '@shared/constants';
import { EventCatalogFilter } from '@store/type-partner/events-catalog/models';
import { useGetCitiesBySearchQuery } from '@store/main/cities/cities.api';
import { useGetCategorieByFiltersQuery } from '@store/main/categories/categories.api';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import { FilterByEnumItem } from '@store/main/enums/models';

export interface CatalogDrawerFilters {
    locations: number[];
    categories: number[];
    participants?: {
        from: number,
        to?: number
    } | null;
    statuses: number[]
}

// eslint-disable-next-line @typescript-eslint/ban-types
const debounce = (fn: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            fn(...args);
        }, delay);
    };
};

const initialFilters = {
    locations: [],
    categories: [],
    statuses: [],
    participants: null
}
interface MyEventFilterDrawerProps {
    isOpen: boolean;
    onClose: () => void;
    filters: EventCatalogFilter;
    onResetFilters: (filters: CatalogDrawerFilters) => void;
    onFilterUpdate: (value: Partial<EventCatalogFilter>) => void;
}


const MyEventFilterDrawer: React.FC<MyEventFilterDrawerProps> = ({
    isOpen,
    onClose,
    // filters,
    onResetFilters,
    onFilterUpdate,
}) => {

    const [citySearchValue, setCitySearchValue] = useState('')

    const [locations, setLocations] = useState<{ id: number; slug: string }[]>([])

    const { data: cities, isLoading: citiesLoading } = useGetCitiesBySearchQuery({ search: citySearchValue }, { skip: !citySearchValue })

    const { data: categories, isLoading: categoriesLoading } = useGetCategorieByFiltersQuery({ search: '' })
    const { data: statuses } = useGetFiltersByEnumQuery({ name: 'event_status' })



    const optionsFormatter = (options: { id: number; slug: string }[] | undefined) => {
        if (!options) {
            return [];
        }

        return options.map(option => ({
            value: option.id,
            label: option.slug
        }));
    };

    const statusesFormatter = (statuses: FilterByEnumItem[]) => {
        return statuses.map(status => ({
            value: status.id,
            label: statusesFormatted[status.slug as keyof typeof statusesFormatted] || status.slug,
        }));
    };

    const debouncedCityUpdate = useCallback(
        debounce((search: string) => {
            setCitySearchValue(search);
        }, 500),
        [setCitySearchValue]
    );

    useEffect(() => {
        if (cities?.data) {
            setLocations(cities?.data)
        }
    }, [cities])



    return (
        <Drawer
            title="Filters"
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            mask={false}
            className='drawer-book'

            onClose={onClose}
            maskStyle={{ 'opacity': 0, 'top': '62px' }}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={
                <CloseOutlined
                    onClick={onClose}
                />
            }
        >
            <div className='drawer-book__content'>
                <p>
                    Locations
                </p>
                <Select
                    placeholder='All locations'
                    style={{ width: '100%' }}
                    mode="multiple"
                    allowClear
                    showSearch
                    maxTagCount={3}
                    filterOption={false}
                    notFoundContent={citiesLoading ? <Spin size="small" /> : null}
                    onSearch={debouncedCityUpdate}
                    loading={citiesLoading}
                    onChange={(locations: number[]) => onFilterUpdate({ locations })}
                    options={optionsFormatter(locations || [])}
                />

                <p>
                    Categories
                </p>
                <Select
                    // defaultValue=""
                    placeholder='All categories'
                    style={{ width: '100%' }}
                    mode="multiple"
                    allowClear
                    onChange={(categories: number[]) => onFilterUpdate({ categories })}
                    options={optionsFormatter(categories?.data || [])}
                />

                <p>
                    Participants
                </p>
                <Select
                    defaultValue="all"
                    placeholder='All participants'
                    style={{ width: '100%' }}
                    mode="multiple"
                    allowClear
                    onChange={(v) => console.log(v)}
                    options={[
                        { value: 'all', label: 'All participants' },
                    ]}
                    disabled
                />

                <p>
                    Status
                </p>
                <Select
                    style={{ width: '100%' }}
                    mode="multiple"
                    allowClear
                    onChange={(statuses: number[]) => onFilterUpdate({ statuses })}
                    options={statusesFormatter(statuses?.data || [])}
                />
                <CustomButton
                    type='text'
                    size='large'
                    title='Reset'
                    prefix={<ExitCrossIcon />}
                    onClick={() => {
                        onClose()
                        onResetFilters(initialFilters)
                    }
                    }
                />

            </div>
        </Drawer>
    )
}

export default MyEventFilterDrawer