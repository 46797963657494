import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PostSubscriptionResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const subscription = 'subscription';

export const subscriptionApi = createApi({
    reducerPath: subscription,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        subscription
    ],
    endpoints: build => ({
        //ASAP TO FIX AT BE - card id needed at request
        postSubscription: build.mutation<PostSubscriptionResponse, void>({
            query: () => {
                return {
                    method: 'POST',
                    url: `/subscription`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            invalidatesTags: [subscription],
        }),
        deleteSubscription: build.mutation<any, void>({
            query: () => {
                return {
                    method: 'DELETE',
                    url: '/subscription',
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            invalidatesTags: [subscription],
        }),
    }),
});

export const {
    usePostSubscriptionMutation,
    useDeleteSubscriptionMutation,
} = subscriptionApi;
