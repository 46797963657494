import './styles.scss'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { menuItemsEvent } from './constants'
import BoostLogo from '../../icons/BoostLogo';
import {
    home,
    events,
    campaigns,
    leads,
    analytics,
    // partners,
    dashboard,
    audiences,
    moderation,
    partners,
    pro,
    constructor
    // campaigns
} from '../../pages/routes';
import AnalyticsIcon from '@icons/sidebar/AnalyticsIcon';
import CarryOutIcon from '@assets/CarryOutIcon';
import RiseIcon from '@assets/sidemenu/RiseIcon';
import UserAddIcon from '@assets/sidemenu/UserAddIcon';
import { useAuth } from '@contexts/AuthContext';
import DashboardIcon from '@assets/sidemenu/DashboardIcon';
import AudiencesIcon from '@assets/sidemenu/AudiencesIcon';
import ModerationIcon from '@assets/sidemenu/ModerationIcon';
import PartnersIcon from '@assets/sidemenu/PartnersIcon';
import BoostProIcon from '@assets/sidemenu/BoostProIcon';
import React, {ReactNode, RefObject, useContext, useRef} from 'react';
import TempIcon from '@icons/sidebar/LPEditorIcon';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { TourComponent, TourTitle } from "@components/TourComponent/TourComponent.tsx";
import { TourContext } from "@contexts/TourContext.tsx";
import { EventsTourSteps } from "@components/TourComponent/constants.ts";

type MenuPath =
    '/' |
    '/events' |
    '/campaigns' |
    '/leads' |
    '/analytics' |
    '/audiences' |
    '/analytics' |
    '/moderation' |
    '/partners' |
    '/dashboard' |
    '/pro' |
    '/constructor'

const MenuItemIcon = ({ path }: { path: MenuPath }) => {
    switch (path) {
        case home:
        case events:
            return <CarryOutIcon
                isActive={false}
            />

        case campaigns:
            return <RiseIcon
                isActive={false}
            />
        case leads:
            return <UserAddIcon
                isActive={false}
            />

        case analytics:
            return <AnalyticsIcon
                isActive={false}
            />
        case pro:
            return <BoostProIcon
                isActive={false}
            />
        // TEMPORARY
        case constructor:
            return <TempIcon
            // isActive={false}
            />

        //EVENTS
        case dashboard:
            return (
                <DashboardIcon
                    isActive={false}
                />
            )
        case audiences:
            return (
                <AudiencesIcon
                    isActive={false}
                />
            )
        case moderation:
            return (
                <ModerationIcon
                    isActive={false}
                />
            )
        case partners:
            return (
                <PartnersIcon
                    isActive={false}
                />
            )
        default:
            break;
    }
}

type Props = {
    path: MenuPath;
    title: string | ReactNode;
    refProp?: RefObject<HTMLAnchorElement>;
}

const MenuItem = ({ path, title, refProp }: Props) => {
    const { pathname } = useLocation()

    const {
        isTourOpen,
        tourStep,
        setTourStep,
    } = useContext(TourContext);

    const formattedPath = path === '/' ? '/' : `${path}`;

    const onClick = () => {
        sendAnalytics(`${GTMEventName.menuItemClick}_${path.replace('/', '')}`)
        if (isTourOpen && tourStep > EventsTourSteps.myCampaigns) {
            setTourStep(tourStep + 1)
        }
    }

    return (
        <Link ref={refProp} onClick={onClick} className={`events-sidebar-menu__item ${pathname === formattedPath || formattedPath !== '/' && pathname.includes(formattedPath) ? 'isActive' : ''}`} to={path}>
            <div className='events-sidebar-menu__item-icon'>
                <MenuItemIcon path={path} />
            </div>
            {title}
        </Link>
    );
};

const SideBarMenu = () => {
    const navigate = useNavigate()

    //FOR TESTS ONLY
    // const isEvent = true
    // const isPartner = false

    const { user } = useAuth()
    const isPartner = user?.organization.type === 'partner'
    const isEvent = user?.organization.type === 'event'

    const menuItemCampaignsRef = useRef(null);
    const menuItemLeadsRef = useRef(null);
    const menuItemAnalyticsRef = useRef(null);
    const menuItemBoostProRef = useRef(null);

    const onLogoClick = () => {
        navigate('/events')
    }

    const {
        isTourOpen,
        setIsTourOpen,
        tourStep,
        setTourStep,
    } = useContext(TourContext);

    const menuItemsPartner = {
        events: {
            path: events as MenuPath,
            title: 'My events'
        },
        campaigns: {
            path: campaigns as MenuPath,
            title: 'Campaigns',
            refProp: menuItemCampaignsRef
        },
        leads: {
            path: leads as MenuPath,
            title: 'Leads',
            refProp: menuItemLeadsRef

        },
        analytics: {
            path: analytics as MenuPath,
            title: 'Analytics',
            refProp: menuItemAnalyticsRef

        },
        pro: {
            path: pro as MenuPath,
            title: (
                <div className='flex'>
                    <span>Boost </span>
                    <span className='events-sidebar-menu__boost-pro'>PRO</span>
                </div>
            ),
            refProp: menuItemBoostProRef
        },
    }

    return (
        <main
            className='events-sidebar-menu'
        >
            <div
                className='events-sidebar-menu__controls'
            >
                <div
                    onClick={onLogoClick}
                    className='events-sidebar-menu__logo'
                >
                    <BoostLogo />
                </div>
                <div className='events-sidebar-menu__links'>
                    {isPartner && Object.entries(menuItemsPartner).map(([key, item]) => (
                        <MenuItem key={key} {...item} />
                    ))}
                    {isEvent && Object.entries(menuItemsEvent).map(([key, item]) => (
                        <MenuItem key={key} path={item.path as MenuPath} title={item.title} />
                    ))}
                </div>
            </div>
            <TourComponent
                totalCount={7}
                open={(tourStep > EventsTourSteps.myCampaigns) && (tourStep <= EventsTourSteps.menuItemBoostPro) && isTourOpen}
                onClose={() => {
                    setIsTourOpen(false)
                }}
                gap={{
                    offset: [0, -20]
                }}
                current={tourStep}
                onChange={(step) => {
                    setTourStep(step)
                    if (step > EventsTourSteps.menuItemBoostPro) {
                        navigate(pro)
                    }
                }}
                prefixCls="menu-item-tour-component"
                steps={[
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                On the “Campaigns” tab you can find and<br/>
                                manage all your campaigns.
                            </div>
                        ),
                        target: () => menuItemCampaignsRef.current,
                        placement: 'right',
                        mask: false,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                You can find all the information of your<br/>
                                leads inside the “Leads” tab.
                            </div>
                        ),
                        target: () => menuItemLeadsRef.current,
                        placement: 'right',
                        mask: false,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                The analytics tab contains information<br/>
                                about spent, impressions, clicks, CPM,<br/>
                                CPC, conversions and CTR of your<br/>
                                campaigns and landing pages.
                            </div>
                        ),
                        target: () => menuItemAnalyticsRef.current,
                        placement: 'right',
                        mask: false,
                    },
                    {
                        title: <TourTitle />,
                        description: (
                            <div>
                                Get full campaign assistance from our<br/>
                                team of experts. Open <b>Boost PRO tab</b> to<br/>
                                finish the tour.
                            </div>
                        ),
                        target: () => menuItemBoostProRef.current,
                        placement: 'right',
                        mask: false,
                        nextButtonProps: {
                            children: 'More about Boost PRO'
                        }
                    },
                    {
                        title: <TourTitle />,
                    },
                ]}
            />
        </main>
    )
}

export default SideBarMenu