
const LPEditorIcon = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2151 1.07129H1.78655C1.39146 1.07129 1.07227 1.39049 1.07227 1.78557V18.2142C1.07227 18.6092 1.39146 18.9284 1.78655 18.9284H18.2151C18.6102 18.9284 18.9294 18.6092 18.9294 18.2142V1.78557C18.9294 1.39049 18.6102 1.07129 18.2151 1.07129ZM2.67941 2.67843H5.71512V17.3213H2.67941V2.67843ZM17.3223 17.3213H7.1437V7.14272H17.3223V17.3213ZM7.1437 5.71415V2.67843H17.3223V5.71415H7.1437Z" fill="white" />
        </svg>

    );
};

export default LPEditorIcon;
