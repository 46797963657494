import React from 'react'

const SuccessBoostProIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 10.9592L7.42857 14.7992L16 5.19922" stroke="url(#paint0_linear_9095_67592)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_9095_67592" x1="4" y1="5.19922" x2="14.8982" y2="4.07913" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4A4BD9" />
                    <stop offset="1" stopColor="#A64AD9" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default SuccessBoostProIcon