import SkeletonCustom from '@components/SkeletonCustom'
import EventItemCardSkeleton from './components/EventItemCardSkeleton'
import EventCatalogTableZeroState from '../../MyEventsCatalog/ZeroState/components/TableZeroState'

const MyEventsSkeletonZeroState = () => {
    return (
        <div>
            <div className='my-event-page__filter-container flex'>
                <div className='mr-6'>
                    <SkeletonCustom type='title' width='232px' />
                </div>
                <div className='mr-6'>
                    <SkeletonCustom type='title' width='232px' />
                </div>
                <div className='mr-6'>
                    <SkeletonCustom type='title-m' width='112px' />
                </div>
                <div className='mx-6'>
                    <SkeletonCustom type='title-m' />
                </div>
            </div>
            <div className='flex'>
                <EventItemCardSkeleton />
                <EventItemCardSkeleton />
                <EventItemCardSkeleton />
            </div>

            <div>
                <EventCatalogTableZeroState />
            </div>
        </div>
    )
}

export default MyEventsSkeletonZeroState