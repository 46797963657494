const StripeCertifiedPlug = () => {
    return (
        <div className='flex items-center mt-24'>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.7144 7.14509H11.5001V3.14509C11.5001 1.88259 10.4769 0.859375 9.21436 0.859375H4.78578C3.52328 0.859375 2.50007 1.88259 2.50007 3.14509V7.14509H1.28578C0.969713 7.14509 0.714355 7.40045 0.714355 7.71652V14.5737C0.714355 14.8897 0.969713 15.1451 1.28578 15.1451H12.7144C13.0304 15.1451 13.2858 14.8897 13.2858 14.5737V7.71652C13.2858 7.40045 13.0304 7.14509 12.7144 7.14509ZM3.78578 3.14509C3.78578 2.5933 4.234 2.14509 4.78578 2.14509H9.21436C9.76614 2.14509 10.2144 2.5933 10.2144 3.14509V7.14509H3.78578V3.14509ZM12.0001 13.8594H2.00007V8.4308H12.0001V13.8594ZM6.50007 11.3772V12.3237C6.50007 12.4022 6.56436 12.4665 6.64293 12.4665H7.35721C7.43579 12.4665 7.50007 12.4022 7.50007 12.3237V11.3772C7.64748 11.2714 7.7575 11.1215 7.8143 10.9492C7.87111 10.7768 7.87176 10.5909 7.81618 10.4182C7.76059 10.2455 7.65162 10.0948 7.50497 9.98794C7.35831 9.88107 7.18153 9.82349 7.00007 9.82349C6.81861 9.82349 6.64183 9.88107 6.49517 9.98794C6.34852 10.0948 6.23955 10.2455 6.18397 10.4182C6.12838 10.5909 6.12903 10.7768 6.18584 10.9492C6.24264 11.1215 6.35267 11.2714 6.50007 11.3772V11.3772Z" fill="#252628" />
            </svg>

            <span className='text-small ml-6'>
                Guaranteed <strong>safe & secure</strong> payments
            </span>
            <a href='https://stripe.com' target="_blank" className='no-style ml-12'>
                <svg width="133" height="30" viewBox="0 0 133 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="132" height="29" rx="3.5" stroke="#252628" />
                    <path d="M15.2087 9.69727H11.4146V19.1914H13.1087V15.5208H15.2087C17.3263 15.5208 18.6498 14.4973 18.6498 12.609C18.6498 10.7208 17.3263 9.69727 15.2087 9.69727ZM15.1204 14.109H13.1087V11.1973H15.1469C16.3646 11.1973 17.0087 11.7179 17.0087 12.6531C17.0087 13.5884 16.3381 14.0649 15.1469 14.0649L15.1204 14.109Z" fill="#252628" />
                    <path d="M22.2964 12.1311C21.8325 12.1257 21.3726 12.2174 20.9463 12.4003C20.52 12.5832 20.1366 12.8533 19.8209 13.1932C19.5051 13.533 19.2639 13.9351 19.1128 14.3737C18.9617 14.8123 18.904 15.2777 18.9434 15.7399C18.902 16.2047 18.9579 16.673 19.1074 17.115C19.2569 17.557 19.4967 17.963 19.8117 18.3072C20.1267 18.6515 20.5098 18.9264 20.9368 19.1145C21.3639 19.3026 21.8253 19.3997 22.2919 19.3997C22.7585 19.3997 23.22 19.3026 23.647 19.1145C24.074 18.9264 24.4572 18.6515 24.7722 18.3072C25.0872 17.963 25.327 17.557 25.4765 17.115C25.626 16.673 25.6818 16.2047 25.6405 15.7399C25.6812 15.2782 25.6247 14.813 25.4746 14.3744C25.3245 13.9358 25.0842 13.5335 24.7691 13.1935C24.4541 12.8535 24.0712 12.5833 23.6453 12.4003C23.2194 12.2173 22.7599 12.1256 22.2964 12.1311ZM22.2964 18.0164C21.2199 18.0164 20.5316 17.134 20.5316 15.7399C20.5316 14.3458 21.2022 13.4634 22.2964 13.4634C23.3905 13.4634 24.0611 14.3458 24.0611 15.7399C24.0611 17.134 23.364 18.0076 22.2964 18.0076V18.0164Z" fill="#252628" />
                    <path d="M32.6008 16.85L31.3566 12.2441H30.0508L28.8155 16.85L27.5625 12.2441H25.9302L28.0214 19.1971H29.3978L30.6684 14.6441L31.939 19.1971H33.3155L35.4066 12.2441H33.8361L32.6008 16.85Z" fill="#252628" />
                    <path d="M38.9722 12.1309C38.5144 12.1394 38.0631 12.2408 37.6457 12.429C37.2283 12.6173 36.8535 12.8884 36.544 13.2259C36.2346 13.5634 35.997 13.9602 35.8456 14.3923C35.6942 14.8245 35.6322 15.2829 35.6634 15.7397C35.6265 16.2036 35.6868 16.67 35.8405 17.1093C35.9942 17.5485 36.2379 17.9508 36.556 18.2905C36.8741 18.6302 37.2595 18.8997 37.6877 19.0819C38.1159 19.2641 38.5775 19.3549 39.0428 19.3485C39.6825 19.383 40.3169 19.2159 40.8567 18.8708C41.3964 18.5258 41.8144 18.0201 42.0516 17.425L40.7369 16.8779C40.6337 17.2189 40.4177 17.5146 40.1243 17.7167C39.8309 17.9187 39.4775 18.015 39.1222 17.9897C38.8691 17.9957 38.6174 17.9503 38.3824 17.8562C38.1473 17.7622 37.9338 17.6213 37.7548 17.4423C37.5758 17.2633 37.435 17.0498 37.3409 16.8148C37.2468 16.5798 37.2014 16.3281 37.2075 16.075H42.0781V15.5456C42.0957 13.6397 41.0192 12.1309 38.9722 12.1309ZM37.2692 14.8926C37.3311 14.4875 37.5377 14.1184 37.8509 13.854C38.164 13.5895 38.5624 13.4476 38.9722 13.4544C39.1669 13.4379 39.3629 13.4631 39.5471 13.5283C39.7313 13.5934 39.8995 13.6971 40.0406 13.8323C40.1817 13.9675 40.2923 14.1312 40.3652 14.3125C40.4381 14.4938 40.4716 14.6886 40.4633 14.8838L37.2692 14.8926Z" fill="#252628" />
                    <path d="M44.8739 13.2775V12.2451H43.2856V19.1892H44.8739V15.5451C44.8597 15.3097 44.8957 15.0739 44.9794 14.8534C45.063 14.6329 45.1925 14.4326 45.3593 14.2658C45.5261 14.0991 45.7264 13.9696 45.9469 13.8859C46.1674 13.8022 46.4031 13.7662 46.6386 13.7804C46.8325 13.7667 47.0271 13.7667 47.2209 13.7804V12.1922C47.0974 12.1922 46.9562 12.1922 46.7709 12.1922C46.384 12.1721 45.9994 12.2638 45.663 12.4562C45.3267 12.6486 45.0527 12.9337 44.8739 13.2775Z" fill="#252628" />
                    <path d="M50.8667 12.1309C50.4089 12.1394 49.9576 12.2408 49.5402 12.429C49.1228 12.6173 48.748 12.8884 48.4386 13.2259C48.1291 13.5634 47.8915 13.9602 47.7401 14.3923C47.5887 14.8245 47.5267 15.2829 47.5579 15.7397C47.521 16.2036 47.5813 16.67 47.7351 17.1093C47.8888 17.5485 48.1324 17.9508 48.4505 18.2905C48.7686 18.6302 49.1541 18.8997 49.5823 19.0819C50.0105 19.2641 50.472 19.3549 50.9373 19.3485C51.5711 19.3755 52.1977 19.2049 52.7304 18.8603C53.2631 18.5157 53.6755 18.0142 53.9108 17.425L52.552 16.9044C52.4488 17.2454 52.2328 17.5411 51.9394 17.7431C51.646 17.9452 51.2926 18.0414 50.9373 18.0162C50.6894 18.0139 50.4444 17.9624 50.2166 17.8646C49.9887 17.7668 49.7826 17.6247 49.6102 17.4465C49.4378 17.2683 49.3025 17.0577 49.2122 16.8268C49.122 16.5959 49.0785 16.3493 49.0844 16.1014H53.9726V15.572C53.9726 13.6397 52.8785 12.1309 50.8667 12.1309ZM49.1638 14.8926C49.2273 14.4906 49.4335 14.1249 49.7446 13.8624C50.0557 13.5999 50.4509 13.4582 50.8579 13.4632C51.0526 13.4468 51.2486 13.4719 51.4328 13.5371C51.617 13.6023 51.7853 13.7059 51.9263 13.8411C52.0674 13.9763 52.178 14.14 52.2509 14.3213C52.3238 14.5026 52.3573 14.6974 52.3491 14.8926H49.1638Z" fill="#252628" />
                    <path d="M59.7595 13.0149C59.5146 12.7276 59.2083 12.4991 58.863 12.3463C58.5178 12.1936 58.1427 12.1205 57.7654 12.1326C55.8154 12.1326 54.7036 13.7649 54.7036 15.7414C54.7036 17.7179 55.8154 19.3502 57.7654 19.3502C58.1429 19.3642 58.5187 19.292 58.8642 19.1391C59.2098 18.9862 59.5159 18.7567 59.7595 18.4679V19.1914H61.3477V9.69727H59.7595V13.0149ZM59.7595 15.9708C59.7951 16.2224 59.7759 16.4787 59.7033 16.7222C59.6307 16.9657 59.5064 17.1906 59.3388 17.3817C59.1713 17.5727 58.9644 17.7252 58.7325 17.829C58.5005 17.9327 58.2489 17.9851 57.9948 17.9826C56.8389 17.9826 56.2301 17.1002 56.2301 15.759C56.2301 14.4179 56.8477 13.5355 57.9948 13.5355C58.9742 13.5355 59.7595 14.2502 59.7595 15.5561V15.9708Z" fill="#252628" />
                    <path d="M70.127 12.1326C69.7426 12.1157 69.3594 12.1864 69.0063 12.3393C68.6533 12.4921 68.3395 12.7231 68.0887 13.0149V9.69727H66.5005V19.1914H68.0887V18.459C68.3349 18.747 68.6428 18.9759 68.9896 19.1287C69.3363 19.2814 69.713 19.3541 70.0917 19.3414C72.0328 19.3414 73.1446 17.7002 73.1446 15.7326C73.1446 13.7649 72.0681 12.1326 70.127 12.1326ZM69.8534 17.9561C69.5993 17.9586 69.3477 17.9062 69.1158 17.8025C68.8838 17.6988 68.677 17.5462 68.5094 17.3552C68.3418 17.1642 68.2175 16.9392 68.1449 16.6957C68.0723 16.4522 68.0531 16.1959 68.0887 15.9443V15.5296C68.0887 14.2237 68.8299 13.509 69.8534 13.509C71.0005 13.509 71.6181 14.3914 71.6181 15.7326C71.6181 17.0737 70.9564 17.9561 69.8534 17.9561Z" fill="#252628" />
                    <path d="M76.8526 17.1236L75.1496 12.2441H73.4731L76.032 18.9412L75.7673 19.5941C75.7223 19.8178 75.5924 20.0154 75.4048 20.1454C75.2173 20.2754 74.9866 20.3276 74.7614 20.2912C74.5851 20.3041 74.4082 20.3041 74.232 20.2912V21.6236C74.4452 21.6553 74.6606 21.67 74.8761 21.6677C75.427 21.7077 75.9744 21.5529 76.4228 21.2304C76.8711 20.9078 77.1919 20.438 77.329 19.903L80.1879 12.2971H78.5202L76.8526 17.1236Z" fill="#252628" />
                    <path d="M110.44 10.7481C110.092 10.7461 109.747 10.8128 109.424 10.9446C109.102 11.0763 108.808 11.2704 108.561 11.5157L108.437 10.9069H106.329V22.3069L108.729 21.7863V19.0246C109.206 19.4243 109.809 19.643 110.432 19.6422C112.143 19.6422 113.714 18.2393 113.714 15.1334C113.714 12.2922 112.126 10.7481 110.44 10.7481ZM109.867 17.4804C109.656 17.4926 109.446 17.4577 109.25 17.3783C109.055 17.299 108.88 17.1771 108.737 17.0216V13.3952C108.88 13.234 109.058 13.1067 109.256 13.0228C109.455 12.9388 109.669 12.9002 109.884 12.9099C110.767 12.9099 111.367 13.9069 111.367 15.1863C111.367 16.4657 110.758 17.4804 109.867 17.4804Z" fill="#252628" />
                    <path d="M118.145 10.748C115.833 10.748 114.43 12.7422 114.43 15.2569C114.43 18.2304 116.089 19.7392 118.454 19.7392C119.377 19.7543 120.288 19.5325 121.101 19.0951V17.1098C120.363 17.4823 119.545 17.67 118.718 17.6569C117.765 17.6569 116.954 17.3128 116.83 16.1569H121.577C121.577 16.0245 121.577 15.5039 121.577 15.2745C121.657 12.7422 120.457 10.748 118.145 10.748ZM116.848 14.3392C116.848 13.2275 117.527 12.7598 118.127 12.7598C118.727 12.7598 119.363 13.2275 119.363 14.3392H116.848Z" fill="#252628" />
                    <path d="M99.8538 11.5654L99.7038 10.8418H97.6567V19.4095H100.021V13.6036C100.235 13.3443 100.523 13.1564 100.846 13.0649C101.169 12.9735 101.513 12.9829 101.83 13.0918V10.8418C101.469 10.7004 101.069 10.6959 100.705 10.8292C100.341 10.9625 100.038 11.2243 99.8538 11.5654Z" fill="#252628" />
                    <path d="M87.9074 13.4219C87.9074 13.0337 88.2251 12.8837 88.728 12.8837C89.5616 12.9016 90.3786 13.1195 91.1104 13.519V11.1984C90.3538 10.8925 89.5441 10.7395 88.728 10.7484C86.778 10.7484 85.481 11.7896 85.481 13.5366C85.481 16.2719 89.1339 15.8308 89.1339 17.0043C89.1339 17.4631 88.7457 17.6131 88.2515 17.6131C87.3193 17.5586 86.4128 17.2867 85.6045 16.819V19.1925C86.4375 19.5641 87.3394 19.7565 88.2515 19.7572C90.2457 19.7572 91.6221 18.7425 91.6221 16.969C91.578 14.0043 87.9074 14.5337 87.9074 13.4219Z" fill="#252628" />
                    <path d="M94.8091 8.81445L92.4708 9.32622V17.2056C92.4588 17.5377 92.5146 17.8687 92.6348 18.1785C92.755 18.4883 92.9371 18.7703 93.17 19.0073C93.4029 19.2443 93.6816 19.4313 93.9893 19.557C94.2969 19.6826 94.6269 19.7442 94.9591 19.738C95.5388 19.7639 96.1163 19.652 96.6444 19.4115V17.4262C96.3356 17.5586 94.8267 18.0086 94.8267 16.5439V13.0145H96.6444V10.9497H94.8267L94.8091 8.81445Z" fill="#252628" />
                    <path d="M102.723 10.1022L105.132 9.59924V7.69336L102.723 8.1963V10.1022Z" fill="#252628" />
                    <path d="M105.132 10.9043H102.723V19.4102H105.132V10.9043Z" fill="#252628" />
                </svg>

            </a>
        </div>
    )
}

export default StripeCertifiedPlug