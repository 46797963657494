import { Tooltip } from 'antd';
import React, { PropsWithChildren, useEffect } from 'react'
import { TooltipProps } from 'antd/es/tooltip';
import './styles.scss'
import cn from 'classnames';
import { ActionType } from '@rc-component/trigger/lib/interface';
import { removeHeightFromTooltipWrapper } from "@components/ProTooltip/helpers.ts";

const ProTooltip: React.FC<PropsWithChildren & {
    title: TooltipProps['title']
    overlayClassName?: TooltipProps['overlayClassName']
    isOpen: boolean
    onOpenChange: (isOpen: boolean) => void
    trigger?: ActionType | ActionType[];
}> = ({ title, overlayClassName, children, isOpen, onOpenChange, trigger }) => {
    useEffect(() => {
        removeHeightFromTooltipWrapper()
    }, [isOpen]);

    return (
        <Tooltip
            arrow={false}
            overlayClassName={cn('pro-tooltip', overlayClassName)}
            title={title}
            open={isOpen}
            onOpenChange={onOpenChange}
            trigger={trigger}
        >
            {children}
        </Tooltip>
    )
}

export default ProTooltip
