import React, { useContext, useState } from 'react'
import { Radio, RadioChangeEvent, Input } from 'antd';
import './styles.scss'
import ImageUploader from "@components/Uploader";
import { LandingSectionCategoryOption } from '@pages/PartnerRolePages/LandingPageConstructor/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { OptionName, imageAction } from '@pages/PartnerRolePages/LandingPageConstructor/constants';
import { HexColorPicker } from 'react-colorful';
import { PartnerLandingSectionLayout } from '@store/type-partner/landings/models';



type Props = {
    id: string;
    layout: PartnerLandingSectionLayout;
    optionsList: LandingSectionCategoryOption[]
}

const SettingsItem: React.FC<Props> = ({
    id,
    layout,
    optionsList
}) => {
    const { updateSectionOptionById } = useContext(LandingEditorContext);

    const [selectedOption, setSelectedOption] = useState<LandingSectionCategoryOption>(optionsList[0])

    const [imageError, setImageError] = useState(false)

    const handleOptionTypeChange = (e: RadioChangeEvent) => {
        setSelectedOption(e.target.value as LandingSectionCategoryOption)
        setImageError(false)
    }

    const onOptionValueChange = (option: { name: LandingSectionCategoryOption, value: string }) => {
        updateSectionOptionById(option)
    }

    const onChangeImageUrl = (value: string) => {
        updateSectionOptionById({
            name: 'image',
            value
        })
        setImageError(false)
    }
    const onChangeImagesListUrl = ({ url, idx }: { url: string, idx: number }) => {
        if (!layout?.options?.images) return null
        const next = [...layout.options.images.value];
        next[idx] = url;
        updateSectionOptionById({
            name: 'images',
            value: next
        });
        setImageError(false);
    };

    const onChangeBackgroundImageUrl = (value: string) => {
        updateSectionOptionById({
            name: 'backgroundImage',
            value
        })
        setImageError(false)
    }
    const onChangeVideoUrl = (value: string) => {
        updateSectionOptionById({
            name: 'video',
            value
        })
        setImageError(false)
    }


    return (
        <>
            <div className='setting-drawer__type-label'>{OptionName[selectedOption]}</div>
            {selectedOption && optionsList.length > 1 && (
                <Radio.Group
                    onChange={handleOptionTypeChange}
                    value={selectedOption}
                    style={{ marginBottom: 8 }}
                >
                    {optionsList.map((name) => {
                        return (
                            <Radio.Button
                                key={name}
                                value={name}
                            >
                                {OptionName[name]}
                            </Radio.Button>
                        )
                    }
                    )
                    }
                </Radio.Group>
            )}
            <div>
                {selectedOption === 'backgroundColor' && (
                    <HexColorPicker
                        className='custom-color-picker'

                        color={layout?.options?.backgroundColor?.value || '#1677ff'}
                        onChange={(color) => {
                            onOptionValueChange({
                                name: 'backgroundColor',
                                value: color
                            })
                        }}
                    />
                )}
                {selectedOption === 'image' && (
                    <>
                        <div className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}>
                            <ImageUploader
                                action={imageAction}
                                image={layout.options.image?.value || ''}
                                handleResponse={onChangeImageUrl}
                                errorCb={() => {
                                    setImageError(true)
                                }}
                            />
                        </div>
                    </>
                )}
                {selectedOption === 'backgroundImage' && (
                    <>
                        <div className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}>
                            <ImageUploader
                                action={imageAction}
                                image={layout.options.backgroundImage?.value || ''}
                                handleResponse={onChangeBackgroundImageUrl}
                                errorCb={() => {
                                    setImageError(true)
                                }}
                            />
                        </div>
                    </>
                )}
                {selectedOption === 'images' && (
                    <>
                        {layout.options.images && layout.options.images.value.map((url, idx) => {
                            return (
                                <>
                                    <div className='text-regular-14 mb-4'>
                                        Image № {idx + 1}
                                    </div>
                                    <div key={id} className={`setting-drawer__background-type__image ${imageError ? 'setting-drawer__background-type__image-error' : ''}`}>
                                        <ImageUploader
                                            action={imageAction}
                                            image={url || ''}
                                            handleResponse={(url) => onChangeImagesListUrl({ url, idx })}
                                            errorCb={() => {
                                                setImageError(true)
                                            }}
                                        />
                                    </div>
                                </>
                            )
                        })}
                    </>
                )}
                {selectedOption === 'video' && (
                    <Input
                        placeholder='Video URL'
                        value={layout.options.video?.value || ''}
                        onChange={e => onChangeVideoUrl(e.target.value)}
                    />
                )}
                {selectedOption === 'pdf' && (
                    <Input
                        placeholder='PDF URL'
                        value={layout.options.pdf}
                        onChange={e => onChangeVideoUrl(e.target.value)}
                    />
                )}
            </div>

        </>
    )
}

export default SettingsItem
