import { useAuth } from '@contexts/AuthContext'
import { useBreakpoints } from '@hooks/browser'
import { formatNumberToAmerican } from '@shared/utils'
import { Typography } from 'antd'

const BillingBalanceSection
    = () => {
        const { user } = useAuth()
        const isDesktop = useBreakpoints().md
        const balance = user?.organization?.billing?.balance || 0

        return (
            <section className='flex items-center justify-space-between'>
                <Typography.Title level={isDesktop ? 2 : 4}>
                    Balance
                </Typography.Title>
                <Typography.Title level={isDesktop ? 2 : 4}>
                    ${formatNumberToAmerican(balance)}
                </Typography.Title>
            </section>
        )
    }

export default BillingBalanceSection
