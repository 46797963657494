import TableZeroStateIcon from '@assets/TableZeroStateIcon'

const LeadsZeroState = () => {
    return (
        <div className='flex-col items-center text-center p-64'>
            <TableZeroStateIcon />
            <p> You don't have any leads yet </p>
        </div>
    )
}

export default LeadsZeroState