import React from "react";
import styles from "./ErrorView.module.css";
import {Typography} from "antd";
import ScreenErrorViewIcon from "@assets/ScreenErrorViewIcon";

const ErrorView: React.FC = () => {
    return (
        <div className={styles.ErrorView__container}>
            <section className={styles.ErrorView__error}>
                <ScreenErrorViewIcon/>
                <Typography.Title level={3} className={styles.ErrorView__errorTitle}>
                    Oh no, something went wrong!
                </Typography.Title>
                <Typography.Text className={styles.ErrorView__errorText}>
                    An unexpected error has occurred, sorry about that. We’ve been notified and will get started on a fix
                </Typography.Text>
            </section>
        </div>

    )
}

export default ErrorView;
