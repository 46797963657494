import './styles.scss'

import StatusDescription from '@components/StatusDescription'
import { ModerationStatusesFormatted } from '@shared/constants'
import { Link } from 'react-router-dom';
import LandingPageCardPreview from './LandingPageCardPreview';
import { Button, Dropdown, MenuProps } from 'antd';
import EditButtonIcon from '@assets/buttons/EditButtonIcon';
import PreviewButtonIcon from '@assets/buttons/PreviewButtonIcon';
import MenuPointsHIcon from '@assets/MenuPointsHIcon';
import { DeleteFilled } from '@ant-design/icons';
import InfoIcon from '@icons/header/InfoIcon';
import { formatDateToCommonFormat } from '@shared/utils';
import { PartnerLandingItemResponseData } from '@store/type-partner/landings/models';

interface LandingPageCardProps {
    landingData: PartnerLandingItemResponseData;
    landingId: string;
    eventId: string;
    onDeleteLanding: (id: string) => void
    setRejectionComment: (comment: string) => void
    setRejectionReason: (reason: string) => void
}

const LandingPageCard = ({
    landingData,
    landingId,
    eventId,
    onDeleteLanding,
    setRejectionComment,
    setRejectionReason
}: LandingPageCardProps) => {
    const items: MenuProps['items'] = [
        {
            label: (
                <Link
                    to={`/events/${eventId}/landing/${landingId}/edit`}
                    className='no-style'
                >
                    <div className='flex items-center'>
                        <div className='mr-6'>
                            <EditButtonIcon />
                        </div>
                        Edit
                    </div>
                </Link>
            ),
            key: '0',
        },
        {
            label: (
                <Link
                    to={`/preview/event/${eventId}/landing/${landingId}`}
                    className='no-style'
                >
                    <div className='flex items-center'>
                        <div className='mr-6'>
                            <PreviewButtonIcon />
                        </div>
                        Preview
                    </div>
                </Link>
            ),
            key: '1',
            // disabled: true
        },
        {
            type: 'divider',
        },
        {
            label: (

                <div
                    className='flex items-center cursor-pointer'
                    onClick={() => onDeleteLanding(landingId)}
                >
                    <div className='mr-6'>
                        <DeleteFilled />
                    </div>
                    Delete
                </div>
            ),
            key: '2',
            // disabled: true
        },
    ]

    const onInfoIconClick = () => {
        setRejectionComment(landingData?.status?.comment || '')
        setRejectionReason(landingData?.status?.reason || '')
    }
    return (
        <div
            className='my-events-landing-pages-card'
        // onClick={onDeleteLanding}
        >
            <Link to={`/events/${eventId}/landing/${landingId}/edit`} className='no-style'>
                <LandingPageCardPreview
                    landingData={landingData}
                />
            </Link>

            <article
                className='my-events-landing-pages-card__desc flex-col justify-space-between'

            >
                <div className='text-overflow'>
                    <div className='flex items-center justify-space-between'>
                        <h3 className='text-overflow'>
                            {landingData?.title}
                        </h3>
                        <aside className='cursor-pointer menu-drop-down-icon'>
                            <Dropdown
                                menu={{ items }}
                                placement='bottomRight'
                                overlayStyle={{ width: '122px' }}

                            >
                                <Button type="text" shape="circle">
                                    <MenuPointsHIcon />
                                </Button>
                                {/* <Button>BR</Button> */}
                            </Dropdown>
                        </aside>
                    </div>

                    {landingData?.url ? (
                        <Link to={landingData.url} target='_blank' className='text-overflow cursor-pointer'>
                            {landingData?.url?.replace(/https?:\/\//, '') || ''}
                        </Link>
                    ) : null}
                </div>

                <div className='flex justify-space-between'>
                    <div className='my-events-landing-pages-card__status whitespace-nowrap'>
                        <StatusDescription
                            status={landingData?.status?.name}
                            formattedStatusData={ModerationStatusesFormatted}
                            size="small"
                        />
                        {landingData?.status?.comment && (
                            <div onClick={onInfoIconClick}>
                                <InfoIcon fillColor="#252628" width={16} height={16} />
                            </div>
                        )}
                    </div>

                    <span>
                        <span >
                            {
                                landingData.published_at
                                    ?
                                    formatDateToCommonFormat(landingData.published_at)
                                    :
                                    'Not published'
                            }
                        </span>
                    </span>
                </div>
            </article>

        </div>
    )
}

export default LandingPageCard
