import { useState } from 'react';
import { Alert, Button, Col, InputNumber, message, Modal, Row, Select, Slider } from 'antd';
import CustomButton from '@components/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import EventCatalogTableZeroState
    from '@pages/PartnerRolePages/MyEvents/pages/MyEventsCatalog/ZeroState/components/TableZeroState';
import './styles.scss'
import { EventStatuses } from '@shared/constants';
import {
    useGetOrganizerEventByIdQuery,
    usePostCreateOrganizerEventInvitationsMutation
} from '@store/type-event/events/events.api';
import { TabKeys } from '@pages/EventRolePages/EventsPage/pages/info/constants';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import PartnersTable from '../table';

type Props = {
    onChangeTab: (value: TabKeys) => void
}

const PartnersTabMobile = ({ onChangeTab }: Props) => {
    const { eventId: id, tabId } = useParams()

    const navigate = useNavigate()

    const { data: res } = useGetOrganizerEventByIdQuery({ eventId: id?.toString() || '' }, { skip: !id })
    const isPublished = res?.data?.status?.name === EventStatuses.published
    // const { isLoading: isExportLoading, run: runExport } = useGetInvitationsExported(id?.toString() || '');

    const [count, setCount] = useState(5)
    const [isLoading, setLoading] = useState(false)
    // const [patchPartnerStatus] = usePatchPartnerStatusPartnerMutation()

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [onInviteClick] = usePostCreateOrganizerEventInvitationsMutation()

    const handleOk = async () => {
        setLoading(true)
        try {
            const response = await onInviteClick({ eventId: id!, count })
            if ('data' in response) {
                sendAnalytics(GTMEventName.addInvitations)
                setLoading(false)
                setIsModalOpen(false);
                navigate(`/events/info/${id || ''}/${tabId || ''}`)
                return message.open({
                    type: 'success',
                    content: 'Invitations added',
                });
            }
            if ('error' in response) {
                setLoading(false)
                setIsModalOpen(false);
                return message.open({
                    type: 'error',
                    content: 'Request error',
                });
            }
        } catch (error) {
            setLoading(false)
            setIsModalOpen(false);
            return message.open({
                type: 'error',
                content: 'Request error',
            });

        }
        setIsModalOpen(false);

    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setCount(5)
    };
    if (isLoading) {
        return (
            <EventCatalogTableZeroState />
        )
    }

    const onEventDetails = () => {
        onChangeTab(TabKeys.details)
    }

    return (
        <div className='px-24 organizer-event-tab__partners'>
            <div className='flex w-full items-center justify-space-between'>
                <h3>Partner</h3>
                <CustomButton
                    disabled={!isPublished}
                    title=' +Add invitation'
                    type='primary'
                    onClick={() => setIsModalOpen(true)}
                />

            </div>
            <Select
                placeholder='All statuses'
                maxTagCount={1}
                mode="multiple"
                showSearch={false}
                style={{ width: '100%' }}
                allowClear
                filterOption={false}
                className='my-16'
            // notFoundContent={isStatusesLoading ? <Spin size="small" /> : null}
            // loading={isStatusesLoading}
            // value={filters.statuses}
            // onChange={(statuses) => handleChangeFilter({ statuses })}
            // fieldNames={{ label: 'slug', value: 'id' }}
            // options={mappedStatusesData}
            // popupClassName='moderation-table__popup-select moderation-table__popup-select__status'
            // menuItemSelectedIcon={statusMenuItemSelectedIcon}
            />


            {!isPublished && (
                <Alert
                    className='organizer-event-tab__partners__alert'
                    message={(
                        <span>To add invitations, you should first publish the event on the <span onClick={onEventDetails} className='text-link cursor-pointer'>Event details</span> page</span>
                    )}
                    type="warning"
                    showIcon
                />
            )}
            <div className='my-8'>
                <PartnersTable onInviteClick={() => setIsModalOpen(true)} isPublished={isPublished} onChangeTab={onChangeTab} />
            </div>

            <Modal
                title=""
                className='modal-footer-flex'
                open={isModalOpen}
                onCancel={handleCancel}
                width={430}
                footer={[
                    <Button
                        type="primary"
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={handleOk}
                    >
                        Add Invitations
                    </Button>
                ]}
            >
                {/* <p>Enter partner e-mail</p> */}
                <div className='organizer-event-tab__partners-invitation-modal-body'>
                    <Row>
                        <Col span={12}>
                            <Slider
                                min={1}
                                max={100}
                                onChange={setCount}
                                value={typeof count === 'number' ? count : 0}
                            />
                        </Col>
                        <Col span={4}>
                            <InputNumber
                                min={1}
                                max={100}
                                style={{ margin: '0 16px' }}
                                value={count}
                                onChange={(value: unknown) => setCount(value as number)}
                            />
                        </Col>
                    </Row>
                    <p>
                        Нow many invitations do you want to add?
                    </p>
                </div>
            </Modal>
        </div>
    )
}

export default PartnersTabMobile