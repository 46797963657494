
const ZeroTableIcon = () => {
    return (
        <svg width="427" height="287" viewBox="0 0 427 287" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="52.9" cy="191.55" rx="6.65" ry="6.64999" fill="#4E67EA" fillOpacity="0.12" />
            <path fillRule="evenodd" clipRule="evenodd" d="M3.42109 78.5997C9.26795 55.0851 47.5709 46.0573 68.3226 33.4904C83.9314 24.038 98.7931 8.76273 121 4C143.207 -0.762737 163.417 5.64869 180.894 20.017C201.467 36.9304 224.68 62.9463 216.27 88.182C207.818 113.548 167.755 105.4 146.867 122.13C126.932 138.096 118 185.841 92.6083 188.793C67.2959 191.735 60.1145 157.305 44.0975 137.515C28.4768 118.216 -2.56484 102.674 3.42109 78.5997Z" fill="#4E67EA" fillOpacity="0.12" />
            <rect x="103" y="82" width="266" height="202" rx="12" fill="#FAFBFD" />
            <rect x="103.5" y="82.5" width="265" height="201" rx="11.5" stroke="#D5D6DB" strokeOpacity="0.6" />
            <rect x="117" y="144" width="75.333" height="38" rx="6" fill="white" />
            <rect x="117.5" y="144.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="125" y="152" width="24" height="8" rx="4" fill="#ECF0F4" />
            <rect x="125" y="166" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="165" y="166" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="198.333" y="144" width="75.333" height="38" rx="6" fill="white" />
            <rect x="198.833" y="144.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="206.333" y="152" width="30" height="8" rx="4" fill="#ECF0F4" />
            <rect x="206.333" y="166" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="246.333" y="166" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="279.667" y="144" width="75.333" height="38" rx="6" fill="white" />
            <rect x="280.167" y="144.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="287.667" y="152" width="24" height="8" rx="4" fill="#ECF0F4" />
            <rect x="287.667" y="166" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="327.667" y="166" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="117" y="188" width="75.333" height="38" rx="6" fill="white" />
            <rect x="117.5" y="188.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="125" y="196" width="30" height="8" rx="4" fill="#ECF0F4" />
            <rect x="125" y="210" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="165" y="210" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="198.333" y="188" width="75.333" height="38" rx="6" fill="white" />
            <rect x="198.833" y="188.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="206.333" y="196" width="24" height="8" rx="4" fill="#ECF0F4" />
            <rect x="206.333" y="210" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="246.333" y="210" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="279.667" y="188" width="75.333" height="38" rx="6" fill="white" />
            <rect x="280.167" y="188.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="287.667" y="196" width="30" height="8" rx="4" fill="#ECF0F4" />
            <rect x="287.667" y="210" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="327.667" y="210" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="117" y="232" width="75.333" height="38" rx="6" fill="white" />
            <rect x="117.5" y="232.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="125" y="240" width="24" height="8" rx="4" fill="#ECF0F4" />
            <rect x="125" y="254" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="165" y="254" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="198.333" y="232" width="75.333" height="38" rx="6" fill="white" />
            <rect x="198.833" y="232.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="206.333" y="240" width="30" height="8" rx="4" fill="#ECF0F4" />
            <rect x="206.333" y="254" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="246.333" y="254" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="279.667" y="232" width="75.333" height="38" rx="6" fill="white" />
            <rect x="280.167" y="232.5" width="74.333" height="37" rx="5.5" stroke="#D5D6DB" strokeOpacity="0.4" />
            <rect x="287.667" y="240" width="24" height="8" rx="4" fill="#ECF0F4" />
            <rect x="287.667" y="254" width="36" height="8" rx="4" fill="#ECF0F4" />
            <rect x="327.667" y="254" width="19.33" height="8" rx="4" fill="#ECF0F4" />
            <rect x="117" y="121" width="238" height="13" rx="2" fill="#D5D6DB" fillOpacity="0.35" />
            <rect x="103" y="106" width="266" height="1" fill="#D5D6DB" fillOpacity="0.6" />
            <ellipse cx="122" cy="95" rx="5" ry="5" fill="#D5D6DB" fillOpacity="0.5" />
            <ellipse cx="137" cy="95" rx="5" ry="5" fill="#D5D6DB" fillOpacity="0.5" />
            <ellipse cx="152" cy="95" rx="5" ry="5" fill="#D5D6DB" fillOpacity="0.5" />
            <path d="M418.774 284.282L423.718 279.338C424.931 278.125 424.882 276.143 423.61 274.992L397.735 251.581L391.017 258.299L414.428 284.174C415.579 285.446 417.561 285.495 418.774 284.282Z" fill="#E4E4E8" />
            <g filter="url(#filter0_b_972_30837)">
                <ellipse cx="35.15" cy="35.15" rx="35.15" ry="35.15" transform="matrix(-0.707107 0.707107 0.707107 0.707107 370.043 181.291)" fill="#E7E7F1" fillOpacity="0.3" />
                <path d="M345.895 255.148C359.232 268.484 380.854 268.484 394.191 255.148C407.527 241.811 407.527 220.189 394.191 206.852C380.854 193.516 359.232 193.516 345.895 206.852C332.559 220.189 332.559 241.811 345.895 255.148Z" stroke="#E4E4E8" strokeWidth="2" />
            </g>
            <path d="M346.355 254.688C359.437 267.771 380.648 267.771 393.731 254.688C406.814 241.606 406.814 220.395 393.731 207.312C380.648 194.23 359.437 194.23 346.355 207.312C333.272 220.395 333.272 241.606 346.355 254.688Z" stroke="#EEEEF2" />
            <defs>
                <filter id="filter0_b_972_30837" x="333.393" y="194.35" width="73.3008" height="73.2998" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.75" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_972_30837" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_972_30837" result="shape" />
                </filter>
            </defs>
        </svg>


    )
}

export default ZeroTableIcon