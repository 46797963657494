//https://boost.allnetru.dev/api/documentation/organizer#/User%20invites
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PostOrganizationOnboardingResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organizer_onboarding = `organizer_onboarding`;

export const organizerOnboarding = createApi({
    reducerPath: organizer_onboarding,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organizer_onboarding
    ],
    endpoints: build => ({
        postOrganizationOnboarding: build.mutation<PostOrganizationOnboardingResponse, { name: string, description: string }>({
            query: (body) => {
                return {
                    body,
                    method: 'POST',
                    url: '/organizer/onboarding',
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            },
            invalidatesTags: [organizer_onboarding],
        }),
    }),
});

export const {
    usePostOrganizationOnboardingMutation
} = organizerOnboarding;