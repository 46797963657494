import AuthMonoLayout from '@layouts/AuthMonoLayout'
import '../styles.scss'
import { Link } from 'react-router-dom'
const PasswordUpdateConfirmed = () => {
    return (
        <AuthMonoLayout>
            <div className='confimed-content'>
                <div className='confimed-content__card'>
                    <div>
                        <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="104" height="104" rx="52" fill="#17CC80" fillOpacity="0.06" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M77 52C77 65.8071 65.8071 77 52 77C38.1929 77 27 65.8071 27 52C27 38.1929 38.1929 27 52 27C65.8071 27 77 38.1929 77 52ZM81 52C81 68.0163 68.0163 81 52 81C35.9837 81 23 68.0163 23 52C23 35.9837 35.9837 23 52 23C68.0163 23 81 35.9837 81 52ZM68.9142 42.9142C69.6953 42.1332 69.6953 40.8668 68.9142 40.0858C68.1332 39.3047 66.8668 39.3047 66.0858 40.0858L46.0367 60.1348L37.95 51.6225C37.1892 50.8217 35.9233 50.7892 35.1225 51.55C34.3217 52.3108 34.2892 53.5767 35.05 54.3775L44.55 64.3775L45.9633 65.8652L47.4142 64.4142L68.9142 42.9142Z" fill="#17CC80" />
                        </svg>


                    </div>
                    <h1 className='confimed-content__card-title'>
                        Password updated
                    </h1>
                    <p className='confimed-content__card-description'>
                        Your password has been updated successfully. Use you password to{' '}
                        <Link to={'/login'}>
                            Log in
                        </Link>
                    </p>
                </div>
            </div>
        </AuthMonoLayout>
    )
}

export default PasswordUpdateConfirmed