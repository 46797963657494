import React, { ReactNode, useMemo, useState } from 'react';
import { Badge, Select, Spin } from 'antd';
import '../styles.scss'
import FilterButtonMobileIcon from '@assets/buttons/mobile/FilterButtonMobileIcon';
import { Filters } from '@store/type-event/models';
import { useGetOrganizerEventsFilterOptionsQuery } from '@store/type-event/events/events.api';
import { useGetOrganizerPartnersFiltersQuery } from '@store/type-event/partners/partners.api';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import { FilterByEnumItem } from '@store/main/enums/models';
import { ModerationStatus, ModerationStatusesFormatted } from '@shared/constants';
import MobileDrawer from '@components/mobile/Drawer';

type ModerationStatusKeys = keyof typeof ModerationStatusesFormatted;
interface Props {
    filters: Filters;
    onFilterUpdate: (updatedFilters: Partial<Filters>) => void;
    onResetFilters: () => void;

    eventsSearchValue: string;
    partnersSearchValue: string;
    debouncedEventsUpdate: (value: string) => void;
    debouncedPartnersUpdate: (value: string) => void;
    onEventsDropdownVisibleChange: (isOpen: boolean) => void;
    onPartnersDropdownVisibleChange: (isOpen: boolean) => void;

    statusMenuItemSelectedIcon: ({ isSelected }: { isSelected: boolean }) => ReactNode
}

const FilterDrawer: React.FC<Props> = ({
    filters,
    onResetFilters,
    onFilterUpdate,

    eventsSearchValue,
    partnersSearchValue,

    debouncedEventsUpdate,
    debouncedPartnersUpdate,
    onEventsDropdownVisibleChange,
    onPartnersDropdownVisibleChange,
    statusMenuItemSelectedIcon
}) => {

    const [isShowFilters, setShowFilters] = useState(false)
    const [drawerFilters, setDrawerFilters] = useState<Partial<Filters>>(filters)

    const { data: events, isLoading: isEventsLoading } = useGetOrganizerEventsFilterOptionsQuery({ search: eventsSearchValue })
    const { data: partners, isLoading: isPartnersLoading } = useGetOrganizerPartnersFiltersQuery({ filters: { search: partnersSearchValue, page: 0 } })
    const { data: statuses, isLoading: isStatusesLoading } = useGetFiltersByEnumQuery({ name: 'landing_status' })

    const mappedStatusesData = useMemo(() => {
        return statuses?.data?.map((item: FilterByEnumItem) => ({
            ...item,
            slug: (ModerationStatusesFormatted[item.slug as unknown as ModerationStatusKeys] as unknown as ModerationStatus) || item.translate
        })) || [];
    }, [statuses]);

    // const onCloseFilters = () => {
    //     setShowFilters(false)
    // }

    const onDrawerFilterChange = () => {
        onFilterUpdate(drawerFilters)
        setShowFilters(false)
    }

    const onDrawerResetClick = () => {
        onResetFilters();
        setDrawerFilters(filters)
        setShowFilters(false)
    }

    const isFilterApplied = filters.search.length > 3 || !!filters.events.length || !!filters.statuses.length;

    return (
        <>
            <div className='flex items-center justify-space-between'>
                <h3>
                    Moderation
                </h3>
                <button
                    className='no-style'
                    onClick={() => setShowFilters(true)}
                >
                    <Badge
                        dot={!!isFilterApplied}
                        status="processing"

                    >
                        <FilterButtonMobileIcon
                            isActive={isFilterApplied}
                        />
                    </Badge>
                </button>

            </div>

            <MobileDrawer
                title='Filters'
                onClose={onDrawerResetClick}
                onCancel={onDrawerResetClick}
                isOpen={isShowFilters}
                onOk={onDrawerFilterChange}
                applyButtonText="Apply"
                resetButtonText="Reset"
            >
                <div className='drawer-content w-full'>
                    <p>
                        Event
                    </p>
                    <Select
                        placeholder='All events'
                        mode="multiple"
                        maxTagCount={1}
                        style={{ width: '100%' }}
                        allowClear
                        showSearch
                        filterOption={false}
                        notFoundContent={isEventsLoading ? <Spin size="small" /> : null}
                        onSearch={debouncedEventsUpdate}
                        onDropdownVisibleChange={onEventsDropdownVisibleChange}
                        loading={isEventsLoading}
                        value={drawerFilters.events}
                        onChange={(events) => setDrawerFilters({ events })}
                        fieldNames={{ label: 'slug', value: 'id' }}
                        options={events?.data || []}
                        popupClassName='moderation-table__popup-select'
                        menuItemSelectedIcon={statusMenuItemSelectedIcon}
                    />

                    <p>
                        Partner
                    </p>
                    <Select
                        placeholder='All partners'
                        style={{ width: '100%' }}
                        maxTagCount={1}
                        mode="multiple"
                        allowClear
                        showSearch
                        filterOption={false}
                        notFoundContent={isPartnersLoading ? <Spin size="small" /> : null}
                        onSearch={debouncedPartnersUpdate}
                        onDropdownVisibleChange={onPartnersDropdownVisibleChange}
                        loading={isPartnersLoading}
                        value={drawerFilters.partners}
                        onChange={(partners) => setDrawerFilters({ partners })}
                        fieldNames={{ label: 'slug', value: 'id' }}
                        options={partners?.data || []}
                        popupClassName='moderation-table__popup-select'
                        menuItemSelectedIcon={statusMenuItemSelectedIcon}
                    />
                    <p>
                        Status
                    </p>
                    <Select
                        placeholder='All statuses'
                        maxTagCount={1}
                        mode="multiple"
                        showSearch={false}
                        style={{ width: '100%' }}
                        allowClear
                        filterOption={false}
                        notFoundContent={isStatusesLoading ? <Spin size="small" /> : null}
                        loading={isStatusesLoading}
                        value={drawerFilters.statuses}
                        onChange={(statuses) => setDrawerFilters({ statuses })}
                        fieldNames={{ label: 'slug', value: 'id' }}
                        options={mappedStatusesData}
                        popupClassName='moderation-table__popup-select moderation-table__popup-select__status'
                        menuItemSelectedIcon={statusMenuItemSelectedIcon}
                    />
                </div>
            </MobileDrawer>
        </>
    );
};

export default FilterDrawer;
