import React from "react";
import ScreenErrorViewIcon from "@assets/ScreenErrorViewIcon";
import {Typography} from "antd";
import styles from "./style.module.css";

const LeadsErrorView: React.FC = () => {
    return(
        <section className={styles.LeadsErrorView__error}>
            <ScreenErrorViewIcon/>
            <Typography.Title level={3} className={styles.LeadsErrorView__errorTitle}>
                Oh no, something went wrong!
            </Typography.Title>
            <Typography.Text className={styles.LeadsErrorView__errorText}>
                An unexpected error has occurred, sorry about that. We’ve been notified and will get started on a fix
            </Typography.Text>
        </section>
    )
}

export default LeadsErrorView;