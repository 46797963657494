import SkeletonCustom from '@components/SkeletonCustom'
import './styles.scss'

const SkeletonEventDetailsState = () => {
    return (
        <div>
            <SkeletonCustom
                type='image'
                height='250px'
                width='100%'
            />
            <div className='catalog-event-item__body'>
                <div className='flex justify-space-between'>
                    <div className='flex'>
                        <SkeletonCustom
                            type='avatar'
                            width='68px'
                            height='68px'
                        />
                        <div className='mx-6'>
                            <SkeletonCustom
                                type='title'
                            />
                        </div>
                        <SkeletonCustom
                            type='button'
                        />
                    </div>
                    <SkeletonCustom
                        type='button'
                    />

                </div>
                <div className='my-events-edit-zero-state__event-info'>
                    <article >
                        <div className='flex mb-12'>
                            <SkeletonCustom
                                type='paragraph-m'
                            />
                            <div className='mx-6'>
                                <SkeletonCustom
                                    type='paragraph-m'
                                />
                            </div>
                            <SkeletonCustom
                                type='paragraph-m'
                            />
                        </div>
                        <div className='flex'>
                            <SkeletonCustom
                                type='paragraph-m'
                            />
                            <div className='mx-6'>
                                <SkeletonCustom
                                    type='paragraph-m'
                                />
                            </div>
                        </div>
                    </article>
                </div>
                <div className='divider' />

                <article>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='100%'
                        />
                    </div>
                    <div className='my-8'>
                        <SkeletonCustom
                            type='paragraph'
                            width='70%'
                        />
                    </div>
                </article>
            </div>
        </div>
    )
}

export default SkeletonEventDetailsState