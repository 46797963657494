import { Button, Modal } from "antd";
import React, { FC } from "react";
import styles from "@components/Subscriptions/BoostPro/BoostPro.module.scss";
import { InvitationKeys } from "@pages/EventRolePages/EventsPage/pages/info/constants";
import { FilterByEnumItem } from "@store/main/enums/models";

type Props = {
    isOpen: boolean
    onClose: () => void
    tagModalData: FilterByEnumItem | null
    onApply: () => void
}

const TagModal: FC<Props> = ({ isOpen, onClose, tagModalData, onApply }) => {
    return (
        <Modal
            title={`Apply “${tagModalData?.translate || ''}” tag to invite codes`}
            className={styles.SuccessModal}
            open={isOpen}
            onCancel={onClose}
            centered
            width={440}
            footer={(
                <div
                    className="flex invitation-tag-modal__footer"
                >
                    <Button
                        size="large"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        onClick={onApply}
                    >
                        Apply
                    </Button>
                </div>
            )}
        >
            Please note that the “
            {tagModalData?.translate || ''}
            ” tag will be applied only to invitation codes with an “
            {tagModalData?.slug === InvitationKeys.Sent ? 'Active' : 'Not active'}
            ” status
        </Modal>
    )
}

export default TagModal
