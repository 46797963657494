import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserType } from "@store/models-to replace/auth";
import { SubscriptionName } from "@store/main/subscription/models";

interface UserState {
    user: UserType,
    isUserLoading: boolean,
    userError: string
}

const initialState: UserState = {
    user: {
        email: '',
        name: '',
        phone: ''
    } as UserType,
    isUserLoading: true,
    userError: ''
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserState: (
            state,
            action: PayloadAction<UserType>
        ) => {
            state.user = action.payload
        },
        patchUserState: (
            state,
            action: PayloadAction<UserType>
        ) => {
            state.user = { ...(state.user || {}), ...(action.payload || {}) }
        },
        cancelSubscription: (
            state,
            action: PayloadAction<SubscriptionName>
        ) => {
            const newSubscriptions = state.user?.organization?.subscriptions?.filter(({ type }) => type.name !== action.payload)
            state.user = { ...state.user, organization: { ...state.user?.organization || {}, subscriptions: newSubscriptions || [] } }
        },
        setIsUserLoading: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.isUserLoading = action.payload
        },
        setUserError: (
            state,
            action: PayloadAction<string>
        ) => {
            state.userError = action.payload
        },
    },
});

export const userActions = userSlice.actions
export const userReducer = userSlice.reducer

export default userSlice.reducer;