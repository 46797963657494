import { useApiReq, UseApiReqParams } from "@hooks/useApiRequest.ts";
import { LeadsRequest, LeadsOptionsType, LeadsResponse } from "@store/models-to replace/leads";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;

export const useGetLeadsData = (params?: UseApiReqParams<ResDataType>) => {
    return useApiReq<LeadsResponse, LeadsRequest>(
        {
            reqConfig: {
                method: 'GET',
                url: `${apiBaseUrl}/partner/leads`
            },
            ...(params || {})
        }
    )
}

export const useGetLeadTypeOptions = () => {
    return useApiReq<LeadsOptionsType>(
        {
            reqConfig: {
                method: 'GET',
                url: `${apiBaseUrl}/enums/lead_type/filter`
            }
        }
    )
}

export const useGetLeadsExported = () => {
    return useApiReq<never, LeadsRequest>(
        {
            reqConfig: {
                method: 'GET',
                url: `${apiBaseUrl}/partner/leads/export`
            }
        }
    )
}
