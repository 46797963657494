const RocketIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9619 3.28886C13.7293 3.70241 11.3866 5.75657 10.0391 8.02974C9.1639 9.50626 8.60135 11.109 8.37777 12.2582L11.4704 14.4237C12.4738 13.8206 13.7875 12.7438 14.8757 11.4163C16.5507 9.3728 17.679 6.46818 16.9619 3.28886ZM10.7779 16.1362L7.00542 13.4947L3.90619 12.5725C2.81321 12.2473 2.4017 10.9156 3.12066 10.0305L5.349 7.2871C5.64947 6.91718 6.09922 6.70049 6.57577 6.69601L8.76169 6.6755C10.3979 4.15697 13.17 1.86789 17.0001 1.4726C17.7636 1.3938 18.4684 1.88732 18.6555 2.63168C19.5941 6.36591 18.3922 9.75439 16.5851 12.1535L17.3135 14.2147C17.4723 14.664 17.4225 15.1607 17.1776 15.5696L15.3618 18.6018C14.776 19.5802 13.3839 19.6489 12.7045 18.7331L10.7779 16.1362ZM15.2254 13.708C14.3994 14.5367 13.5256 15.2399 12.7359 15.7544L13.9715 17.42L15.585 14.7256L15.2254 13.708ZM7.76609 8.48492L6.68681 8.49505L4.70678 10.9327L6.69451 11.5242C6.9079 10.6061 7.26986 9.54452 7.76609 8.48492Z" fill={fillColor || 'white'} />
            <path d="M15.0359 8.99175C14.4998 9.75736 13.4446 9.94343 12.679 9.40734C11.9134 8.87126 11.7273 7.81603 12.2634 7.05042C12.7995 6.28481 13.8547 6.09874 14.6203 6.63483C15.3859 7.17091 15.572 8.22614 15.0359 8.99175Z" fill={fillColor || 'white'} />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.36176 20.0197C7.25309 19.7234 8.32005 19.2769 8.73368 18.739C9.33423 17.958 9.51183 16.7014 8.2142 15.7928C6.91259 14.8814 5.7312 15.4261 5.26804 16.3123C4.95314 16.8856 4.89439 18.0403 4.90673 18.9789C4.91718 19.7738 4.97864 20.4137 4.97864 20.4137C4.97864 20.4137 5.60728 20.2705 6.36176 20.0197Z" fill={fillColor || 'white'} />
        </svg>
    )
}

export default RocketIcon
