import GoogleIcon from "@assets/GoogleIcon";
import LinkedinIcon from "@assets/campaign-settings/LinkedinIcon.tsx";
import FacebookIcon from '@icons/social/FacebookIcon'
import React from "react";

export const CredentialsIcons: Record<string, React.ReactNode> = {
    google_ads: <GoogleIcon />,
    'linkedin_ads': <LinkedinIcon />,
    'facebook_ads': <FacebookIcon />,
}

export const CredentialsNames: Record<string, string> = {
    google_ads: 'Google Ads',
    'linkedin_ads': 'Linkedin',
    'facebook_ads': 'Facebook Ads',
}