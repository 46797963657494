import React, { useEffect, useState } from 'react';
import './styles.scss';
import TargetCPCicon from '@assets/goals/TargetCPCicon';
import TargetCPLicon from '@assets/goals/TargetCPLicon';
import MaxLeadsIcon from '@assets/goals/MaxLeadsIcon';
import MaxClicksIcon from '@assets/goals/MaxClicksIcon';
import { useGetFiltersByEnumQuery } from '@store/main/enums/enums.api';
import { FilterByEnumItem } from '@store/main/enums/models';
import GoalCard from './GoalCard';

interface RadioGoalProps {
    goalValue: number;
    onClick: (goal: number) => void
    onAddSubscription: (id: string) => void
}


export const RadioGoalIcon = ({ goal }: {
    goal: number
}) => {
    switch (goal) {
        case 1:
            return <TargetCPCicon />
        case 2:
            return <TargetCPLicon />
        case 3:
            return <MaxLeadsIcon />
        case 4:
            return <MaxClicksIcon />
        default:
            break;
    }
}

const RadioGoal: React.FC<RadioGoalProps> = ({
    goalValue,
    onClick,
    onAddSubscription
}) => {
    const [goals, setGoals] = useState<FilterByEnumItem[]>([])
    const { data, isLoading } = useGetFiltersByEnumQuery({ name: 'campaign_goal' })

    useEffect(() => {
        if (data?.data) {
            setGoals(data.data)
        }
    }, [data])

    if (isLoading) {
        return (
            <h3>Loading...</h3>
        )
    }
    return (
        <>
            <div className='radio-goal'>
                {goals.map((goal) => {
                    return (
                        <GoalCard
                            goal={goal}
                            isSelected={goalValue === +goal.id}
                            onClick={onClick}
                            onAddSubscription={onAddSubscription}
                        />
                    )
                })}
            </div>
        </>
    );
}

export default RadioGoal;
