import './styles.scss';
import MsgErrorIcon from '@assets/MsgErrorIcon';
import { Notification } from "@store/main/notifications/models";
import { formatNotificationDate } from "@components/NotificationsHeader/helpers";

const StatusIcon = ({ status }: { status: 'error' | 'info' | 'warning' | null }) => {
    switch (status) {
        case 'error':
            return <MsgErrorIcon />;
        default:
            return null;
    }
}

const NotificationCard = ({ notification }: { notification: Notification }) => {
    return (
        <div className='notification-card'>
            <div className='notification-card__inner'>
                <header>
                    <div className='flex'>
                        {notification.status
                            ?
                            <StatusIcon status={notification.status} />
                            :
                            null}
                        <p>{notification.header}</p>
                    </div>
                </header>
                <p className="notification-card__message">{notification.message}</p>
                <p className="notification-card__created-at">{formatNotificationDate(notification?.created_at as string)}</p>
            </div>
            {!notification?.is_viewed && <div className='notification-card__view-status' />}
        </div>
    );
}

export default NotificationCard;
