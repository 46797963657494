import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useGetOrganizerEventByIdQuery } from "@store/type-event/events/events.api.ts";
import './styles.scss';
import DetailsTab from './details-tab';
import PartnersTab from './parters-tab';
import CampaignsTab from './campaigns-tab';
import SubdomainTab from "@pages/EventRolePages/EventsPage/components/SubdomainTab";
import { TabKeys } from '@pages/EventRolePages/EventsPage/pages/info/constants';
import HeaderBreadcrumb from "@components/Header/HeaderBreadcrumb.tsx";
import EventsPageLayout from '@layouts/EventsPageLayout';
import { useBreakpoints } from '@hooks/browser';
import PartnersTabMobile from './parters-tab/mobile';
import TrackTab from './track-tab';
import InvitationsTab from './invitations-tab';
import InvitationsTabMobile from './invitations-tab/mobile';
import BillingTab from "@pages/EventRolePages/EventsPage/pages/info/billing-tab/BillingTab.tsx";
import AdAccountsTab from "@pages/EventRolePages/EventsPage/pages/info/ad-accounts-tab/AdAccountsTab.tsx";

const MyEventInfoPage = () => {
    const isDesktop = useBreakpoints().md;

    const { eventId, tabId } = useParams()
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(false);

    const { data: res, isLoading: isEventLoading, isFetching, refetch } = useGetOrganizerEventByIdQuery({ eventId: eventId?.toString() || '' }, { skip: !eventId });

    useEffect(() => {
        if (!eventId) return;
        setLoading(true);
        refetch().finally(() => setLoading(false));
    }, [eventId, refetch]);

    const onChange = useCallback((value: string) => {
        // const listId = value === TabKeys.partners ? `/${ListIds.partners}` : '';
        navigate(`/events/info/${eventId || ''}/${value}`);
    }, [eventId, navigate]);

    const items: TabsProps['items'] = useMemo(() => [
        {
            key: TabKeys.details,
            label: 'Event details',
            children: (
                <DetailsTab
                    event={res?.data}
                    isLoading={isLoading}
                    isEventLoading={isEventLoading}
                    isFetching={isFetching}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    refetch={refetch}
                />
            ),
        },
        {
            key: TabKeys.partners,
            label: 'Partners',
            children: isDesktop ? <PartnersTab onChangeTab={onChange} /> : <PartnersTabMobile onChangeTab={onChange} />,
        },
        {
            key: TabKeys.invitations,
            label: 'Invitations',
            children: isDesktop ? <InvitationsTab onChangeTab={onChange} /> : <InvitationsTabMobile onChangeTab={onChange} />,
        },
        {
            key: TabKeys.campaigns,
            label: 'Campaigns',
            children: <CampaignsTab />,
        },
        {
            key: TabKeys.subdomain,
            label: 'Subdomain',
            children: <SubdomainTab />,
        },
        {
            key: TabKeys.track,
            label: 'Tracking Code',
            children: <TrackTab />,
        },
        {
            key: TabKeys.billing,
            label: 'Billing',
            children: <BillingTab />,
        },
        {
            key: TabKeys.adAccounts,
            label: 'Ad accounts',
            children: <AdAccountsTab />,
        },
    ], [onChange, res, isLoading, isEventLoading, isFetching, refetch]);

    const breadCrumbs = useMemo(() => [
        {
            url: '/events',
            label: 'My events',
            isZeroLevel: true,
        },
        {
            label: res?.data?.name || 'Event',
        },
    ], [res]);

    const activeKey = useMemo(() => {
        if (location.pathname.includes('/subdomain')) {
            return TabKeys.subdomain;
        }
        if (location.pathname.includes('/campaigns')) {
            return TabKeys.campaigns;
        }
        if (location.pathname.includes('/details')) {
            return TabKeys.details;
        }
        if (location.pathname.includes('/track')) {
            return TabKeys.track;
        }
        if (location.pathname.includes('/partners')) {
            return TabKeys.partners;
        }
        if (location.pathname.includes('/invitations')) {
            return TabKeys.invitations;
        }
        if (location.pathname.includes('/ad-accounts')) {
            return TabKeys.adAccounts;
        }
        return tabId;
    }, [location.pathname, tabId]);

    return (
        <EventsPageLayout>
            <>
                {isDesktop && <HeaderBreadcrumb
                    crumbs={breadCrumbs}
                />}
                <Tabs
                    activeKey={activeKey}
                    onChange={onChange}
                    items={items}
                    className={`${isDesktop ? 'ml-24 mr-6' : 'px-12'}`}
                />
            </>
        </EventsPageLayout>
    );
};

export default MyEventInfoPage;
