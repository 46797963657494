
const RiseIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.8483 3.93947L17.5126 4.58233C17.3358 4.60376 17.2608 4.82072 17.3867 4.94661L18.9751 6.53501L12.9215 12.5886L10.1947 9.86447C10.026 9.69572 9.75544 9.6984 9.58937 9.86447L0.972406 18.4841C0.932527 18.5244 0.910156 18.5788 0.910156 18.6355C0.910156 18.6921 0.932527 18.7465 0.972406 18.7868L2.17776 19.9975C2.2608 20.0805 2.39741 20.0805 2.48044 19.9975L9.89205 12.5886L12.6162 15.3127C12.7849 15.4788 13.0554 15.4788 13.2215 15.3127L20.4912 8.0484L22.0795 9.63679C22.108 9.66512 22.1439 9.68488 22.183 9.69385C22.2221 9.70281 22.263 9.70061 22.301 9.68749C22.3389 9.67437 22.3724 9.65087 22.3977 9.61965C22.4229 9.58843 22.4389 9.55075 22.4438 9.5109L23.0867 4.17518C23.1054 4.03858 22.9876 3.92072 22.8483 3.93947Z" fill={isActive ? '#4E67EA' : '#FFFFFF'} fillOpacity={isActive ? '0.2' : '1'} />
        </svg>

    )
}

export default RiseIcon