import React, { FC } from 'react'
import './styles.scss'
interface Layout700CenterProps {
    children: React.ReactNode,
}

const Layout700Center: FC<Layout700CenterProps> = ({ children }) => {
    return (
        <div
            className='layout-700-center'
        >
            <div className='layout-700-center__body'>
                {children}
            </div>
        </div>
    )
}

export default Layout700Center