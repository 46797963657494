const NotificationIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 15V16C7 16.7956 7.31607 17.5587 7.87868 18.1213C8.44129 18.6839 9.20435 19 10 19C10.7956 19 11.5587 18.6839 12.1213 18.1213C12.6839 17.5587 13 16.7956 13 16V15M8 3C8 2.46957 8.21071 1.96086 8.58579 1.58579C8.96086 1.21071 9.46957 1 10 1C10.5304 1 11.0391 1.21071 11.4142 1.58579C11.7893 1.96086 12 2.46957 12 3C13.1484 3.54303 14.1274 4.38833 14.8321 5.4453C15.5367 6.50227 15.9404 7.73107 16 9V12C16.0753 12.6217 16.2954 13.2171 16.6428 13.7381C16.9902 14.2592 17.4551 14.6914 18 15H2C2.54494 14.6914 3.00981 14.2592 3.35719 13.7381C3.70457 13.2171 3.92474 12.6217 4 12V9C4.05956 7.73107 4.4633 6.50227 5.16795 5.4453C5.8726 4.38833 6.85159 3.54303 8 3Z"
                stroke={fillColor || '#252628'} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default NotificationIcon