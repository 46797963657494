import '../styles.scss'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from "@contexts/AuthContext";
import { useEffect, useState } from 'react';
import { useCreateUserAccountMutation, useGetInviteByEventHashQuery, usePatchUserProfileMutation } from '@store/main/-user/user.api';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import CreateAccountStep from './steps/CreateAccountStep';
import LoadingView from '@components/common/LoadingView';
import { CreateCompanyStep } from './steps/CreateCompanyStep';
import { ErrorsValues, FormAccountValues, FormCompanyValues } from './models';
import { errorFormInitial, formAccountInitial, formCompanyInitial } from './constants';
import { CreateTypeStep } from './steps/CreateTypeStep';
import { InviteInfoResponse } from '@store/models-to replace/auth';
import { usePatchUserOragnizationTypeMutation } from '@store/main/organization/organization.api';
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import { useDispatch } from "react-redux";
import { userActions } from "@store/main/-user/user.slice";
import { message } from 'antd';

// const apiBaseUrl = import.meta.env.VITE_API_URL as string;

const RegistrationPage = () => {

  // invited user
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()

  const eventHashFromLocalStorage = localStorage.getItem('event_hash') || '';
  const eventHashFromSearch = searchParams.get('invite') || '';

  const event_hash = eventHashFromSearch || eventHashFromLocalStorage;

  const [eventInfo, setEventInfo] = useState<InviteInfoResponse>()
  const [inviteHash, setInviteHash] = useState('')

  const {
    data: inviteInfo,
    isLoading: isInviteLoading,
  } = useGetInviteByEventHashQuery(inviteHash, { skip: !inviteHash });

  useEffect(() => {
    if (inviteInfo?.data) {
      setEventInfo(inviteInfo)
    }
  }, [inviteInfo?.data])

  useEffect(() => {
    if (event_hash) {
      setInviteHash(event_hash)
      if (!eventHashFromLocalStorage) {
        localStorage.setItem('event_hash', event_hash);
      }// see submit of the last step in registration
    }
  }, [event_hash]);
  //


  const [formErrors, setFormErrors] = useState<ErrorsValues>(errorFormInitial)
  const [isLoading, setLoading] = useState(false)

  const [formAccount, setFormAccount] = useState<FormAccountValues>(formAccountInitial)
  const [formCompany, setFormCompany] = useState<FormCompanyValues>(formCompanyInitial)

  const navigate = useNavigate()
  const { user, reFetchUser, isAuthenticated } = useAuth();

  const [createAccount] = useCreateUserAccountMutation()
  const [createCompany] = usePatchUserProfileMutation()
  const [patchOrganizationType] = usePatchUserOragnizationTypeMutation()

  // const [step, setStep] = useState<'account' | 'company' | 'type'>('account')


  const onFormAccountChange = (value: Record<string, string>) => {
    setFormErrors(errorFormInitial)
    setFormAccount(prev => ({ ...prev, ...value }))
  }
  const onFormCompanyChange = (value: Record<string, string>) => {
    setFormErrors(errorFormInitial)
    setFormCompany(prev => ({ ...prev, ...value }))
  }

  const onCreateAccountClick = async () => {
    setLoading(true)
    try {
      const response = await createAccount(formAccount)

      if ('error' in response) {
        setLoading(false)
        const errors = errorsToFormAdapter(response as ErrorDataResponse)
        setFormErrors(errors as unknown as ErrorsValues)
      }

      if ('data' in response) {
        dispatch(userActions.setUserState(response?.data?.data))
        dispatch(userActions.setUserError(''))
        sendAnalytics(GTMEventName.generalRegistration)
        setLoading(false)
        return navigate(`/registration`)
      }
      console.log('Success:');

    } catch (error) {
      setLoading(false)
      console.log('SMTH WRONG');
    }
  }

  const onCreateCompanyClick = async (isFinal?: boolean) => {
    setLoading(true)
    const site = formCompany?.site || user?.organization?.site || ''
    const { name, company_name, phone, organization_type } = formCompany

    const campaignData = { name, company_name, site, phone }
    const payload = campaignData

    const invitedPayload = { ...campaignData, organization_type: 'partner', site }

    try {
      const eventId = eventInfo?.data?.event?.id
      const body = eventId ? invitedPayload : payload
      const response = !isFinal ? await createCompany(body) : await patchOrganizationType({ organization_type })

      if ('error' in response) {
        setLoading(false)
        const errors = errorsToFormAdapter(response as ErrorDataResponse)
        setFormErrors(errors as unknown as ErrorsValues)
      }

      if ('data' in response) {
        return await reFetchUser()
          .then(() => {
            if (isFinal) {
              if (organization_type === 'partner') {
                sendAnalytics(GTMEventName.partnerRegistration)
              } else {
                sendAnalytics(GTMEventName.eventRegistration)
              }
              return navigate('/onboarding/event');
            }
            sendAnalytics(GTMEventName.createCompanyStep)
            return navigate('/');
          })
          .catch(error => {
            console.log('SMTH WRONG');
            return message.open({
              type: 'error',
              content: 'Try later',
            });
          })
          .finally(() => {
            setLoading(false)
          })
      }
      console.log('Success:');

    } catch (error) {
      setLoading(false)
      console.log('SMTH WRONG');
    }
  }


  if (isInviteLoading) {
    //TODO: check styles
    return <LoadingView />
  }




  if (!isAuthenticated || !user?.email) {
    return (
      <CreateAccountStep
        form={formAccount}
        formErrors={formErrors}
        onFormChange={onFormAccountChange}
        onSubmit={() => void onCreateAccountClick()}
        inviteInfo={eventInfo}
        isLoading={isLoading}
      />
    )
  }

  if (!user?.organization?.name) {
    return (
      <CreateCompanyStep
        form={formCompany}
        formErrors={formErrors}
        onFormChange={onFormCompanyChange}
        onSubmit={() => void onCreateCompanyClick()}
        isLoading={isLoading}
        inviteInfo={eventInfo}
        provider={user?.provider}
      />
    )
  }
  if (!user?.organization?.type || user?.organization?.type === 'none') {
    return (
      <CreateTypeStep
        form={formCompany}
        onFormChange={onFormCompanyChange}
        onSubmit={() => void onCreateCompanyClick(true)}
        isLoading={isLoading}
        inviteInfo={eventInfo}
      />
    )
  }

  return <Navigate to={'/events'} />
}

export default RegistrationPage