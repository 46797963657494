export interface NotificationTexts {
    lp: {
        sectionSuccess: string;
        sectionError: string;
    };
}

export const notificationTexts: NotificationTexts = {
    lp: {
        sectionSuccess: 'Section Saved Successfully',
        sectionError: 'Section Saved Error',
    },
};

export const statusesFormatted = {
    active: 'Active',
    activated: 'Activated',
    paused: 'Paused',
    draft: 'Draft',
    enabled: 'Enabled',
    in_progress: 'In progress',
    pending: 'Pending',
    remove: 'Remove',
    removed: 'Removed',
    review: 'On Review',
    reviewed: 'On Review',
    published: 'Published',
    scheduled: 'Scheduled',
    needs_attention: 'Needs attention',
    // catalog items
    not_available: 'Not Available',
    awaiting_approval: 'Awaiting approval',
    awaiting_invitation: 'Awaiting invitation',
    invitation_pending: 'Invitation Pending',
    available: 'Available',
    cancelled: 'Cancelled',
    reject: 'Reject',
    rejected: 'Rejected',
    suspend: 'Suspend',
    suspended: 'Suspended',
    archive: 'Archive',
    archived: 'Archived',
    on_review: 'On review',
    unknown: 'Unknown',
    revoked: 'Revoked',
    none: 'None',
    error: 'Error',
    completed: 'Completed',
    requested: 'Requested',
}

export enum CampaignStatuses {
    active = 'active',
    activated = 'activated',
    available = 'available',
    archive = 'archive',
    archived = 'archived',
    cancelled = 'cancelled',
    enabled = 'enabled',
    in_progress = 'in_progress',
    invitation_pending = 'invitation_pending',
    needs_attention = 'needs_attention',
    not_available = 'not_available',
    paused = 'paused',
    pending = 'pending',
    published = 'published',
    reject = 'reject',
    rejected = 'rejected',
    remove = 'remove',
    removed = 'removed',
    review = 'review',
    reviewed = 'reviewed',
    scheduled = 'scheduled',
    error = 'error',
}

export enum WithdrawalStatuses {
    completed = 'completed',
    requested = 'requested',
    in_progress = 'in_progress',
    cancelled = 'cancelled',
}

export const InvitationStatuses = {
    activated: 'Used',
    enabled: 'Active'
}

export enum EventCatalogItemStatuses {
    enabled = 'enabled',
    not_available = 'not_available',
    awaiting_approval = 'awaiting_approval',
    awaiting_invitation = 'awaiting_invitation',
    available = 'available',
    cancelled = 'cancelled',
    reject = 'reject',
    rejected = 'rejected',
    suspend = 'suspend',
    suspended = 'suspended',
    archive = 'archive',
    archived = 'archived',
    on_review = 'on_review',
    invitation_pending = 'invitation_pending',
    active = 'active'
}

export enum EventStatuses {
    published = 'published'
}

export enum ModerationStatuses {
    draft = 'draft',
    published = 'published',
    on_review = 'on_review',
    rejected = 'rejected',
    under_appeal = 'under_appeal',
}

export enum CredentialsStatuses {
    revoked = 'revoked',
    enabled = 'enabled',
}

export enum ModerationStatusesFormatted {
    draft = 'Draft',
    published = 'Approved',
    on_review = 'On moderation',
    rejected = 'Rejected',
    under_appeal = 'Under appeal',
}

export type ModerationStatus = 'draft' | 'published' | 'on_review' | 'rejected' | 'under_appeal'

export enum ModerationActionsFormatted {
    published = 'Approve',
    rejected = 'Reject',
    draft = 'Draft',
}

export const HELP_CENTER_LINK = 'https://help.boost.express/knowledge-base'
export const PDF_LINK = 'https://help.boost.express/knowledge-base/set-up-subdomain-for-landing-pages'

export const GAP_INIT = '1'

export const INIT_CURRENT_PAGE = 1
export const INIT_PAGE_SIZE = 15
export const INIT_TOTAL = 15
