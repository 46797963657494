const MaxClicksIcon = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11L10.07 27.97L12.58 20.58L19.97 18.07L3 11Z" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13 21L19 27" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <g clipPath="url(#clip0_10261_11364)">
                <path d="M15.4473 5.55078L22.9218 15.2506L23.2627 10.0631L27.5848 7.17404L15.4473 5.55078Z" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M23.6055 10.2617L28.5003 13.0878" stroke="#889096" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_10261_11364">
                    <rect width="15.9866" height="15.9866" fill="white" transform="translate(13 4.13672) rotate(-15)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MaxClicksIcon