import React, { useEffect, useState } from "react";
import { message, Typography, Upload, UploadFile } from "antd";
import styles from './HeroImage.module.css';
import { HeroImageDropDownIcon } from "@icons/HeroImageDropDownIcon.tsx";
import { EventInfoFormStateType } from "@components/EventInfoForm/types.ts";

type HeroImagePropsType = {
    url?: string;
    onChangeFormState: (fieldName: keyof EventInfoFormStateType, value: string) => void
}

const baseUrl = import.meta.env.VITE_API_URL as string;
const imageUploadUrl = baseUrl + '/partner/landings/image'

const HeroImage: React.FC<HeroImagePropsType> = (props) => {
    const { onChangeFormState } = props;
    const [fileList, setFileList] = useState<UploadFile[]>([])

    useEffect(() => {
        if (props?.url) {
            setFileList([
                {
                    uid: '-1',
                    name: '',
                    status: 'done',
                    url: props.url
                }
            ])
        }
    }, [props?.url])

    const [showDropZone, setShowDropZone] = useState(true);

    const beforeUpload = (file: { type: 'image/jpeg' | 'image/jpeg', status: string, size: number, response?: any }) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            // setFileList([])
            file.status = 'error'
            void message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            // setFileList([])
            void message.error('Image must smaller than 10MB!');
            file.status = 'error'
        }
        return isJpgOrPng && isLt2M;
    };

    return (
        <Upload
            name="image"
            maxCount={1}
            action={`${imageUploadUrl}?folder=event&resize=1000`}
            listType="picture"
            beforeUpload={beforeUpload}
            accept=".jpg,.jpeg,.png,.webp,.svg"
            fileList={fileList}
            onChange={(e) => {
                if (e.file.status === 'done') {
                    setShowDropZone(false)
                    onChangeFormState('heroImageUrl', e.file?.response?.data)
                    setFileList(e.fileList);
                }
                if (e.file.status === "removed") {
                    onChangeFormState('heroImageUrl', '')
                    setShowDropZone(true)
                    return setFileList([]);
                }
                setShowDropZone(false)
                onChangeFormState('heroImageUrl', e.file?.response?.data)
                setFileList(e?.fileList || []);

            }}
        >
            {showDropZone && !fileList.length ?
                <div className={styles.HeroImage__content}>
                    <div className={styles.HeroImage__rulesBlock}>
                        <HeroImageDropDownIcon />
                        <div className={styles.HeroImage__textBlock}>
                            <Typography.Title className={styles.HeroImage__title} level={4}>
                                Click or drag file to this area to upload
                            </Typography.Title>
                            <Typography.Text className={styles.HeroImage__text}>
                                Supports: .jpg, .png. Max size: 10MB
                            </Typography.Text>
                        </div>
                    </div>
                </div>
                :
                null}
        </Upload>
    )
}

export default HeroImage;
