import { FC, useEffect, useRef } from 'react';
import lottie, { AnimationConfigWithData, AnimationItem } from 'lottie-web';

interface LottieAnimationProps {
    animationData: any;
    options?: AnimationConfigWithData;
    width?: string | number;
    height?: string | number;
}

const LottieAnimation: FC<LottieAnimationProps> = ({ animationData, options = {}, width, height }) => {
    const container = useRef<HTMLDivElement | null>(null);
    const animationRef = useRef<AnimationItem | null>(null);

    useEffect(() => {
        if (container.current) {
            // Destroy any existing animation
            if (animationRef.current) {
                animationRef.current.destroy();
            }

            // Load the new animation
            animationRef.current = lottie.loadAnimation({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                animationData,
                container: container.current,
                renderer: 'svg',
                ...options,
            });
        }

        // Cleanup on unmount
        return () => {
            if (animationRef.current) {
                animationRef.current.destroy();
            }
        };
    }, [animationData, options]);

    return <div ref={container} style={{ width, height }} />;
};

export default LottieAnimation;
