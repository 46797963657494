import SkeletonCustom from '@components/SkeletonCustom'

const EventItemFullCardSkeleton = () => {
    return (
        <div>

            <header
                className='mb-24'
            >
                <SkeletonCustom type='button-l' />
            </header>
            <div className='organizer-my-event-card flex-col justify-space-between'>
                <header >

                    <div
                        className='organizer-my-event-card-link'
                    >
                        <SkeletonCustom
                            type='image-rounded'
                            height='275px'
                        />
                    </div>
                    <article
                        className='organizer-my-event-card-info'
                    >
                        <header
                            className='organizer-my-event-card-info__header'
                        >
                            <SkeletonCustom type='button-l' />
                            <SkeletonCustom type='button' />
                        </header>
                        <div>
                            <SkeletonCustom type='button-l' />
                        </div>
                        <div>
                            <SkeletonCustom type='button-l' />
                        </div>
                        <div>
                            <SkeletonCustom type='button-l' />
                        </div>
                    </article>
                </header>
            </div>
        </div>
    )
}

export default EventItemFullCardSkeleton