import React from "react";

const ScreenNotFoundView: React.FC = () => {
    return (
        <svg width="240" height="182" viewBox="0 0 240 182" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4142_62063)">
                <rect width="240" height="182" fill="#F8F9FB" />
                <rect y="22" width="240" height="1" fill="#E4E4E8" />
                <ellipse cx="16.5" cy="11.5" rx="4.5" ry="4.5" fill="#E6E7EA" />
                <ellipse cx="29.5" cy="11.5" rx="4.5" ry="4.5" fill="#E6E7EA" />
                <ellipse cx="42.5" cy="11.5" rx="4.5" ry="4.5" fill="#E6E7EA" />
                <path d="M69.7805 101.713V96.0561L86.4446 69.7273H91.1619V77.7812H88.2855L77.0675 95.5575V95.8643H100.328V101.713H69.7805ZM88.5156 109V99.9872L88.5923 97.456V69.7273H95.304V109H88.5156ZM119.891 109.748C116.734 109.748 114.023 108.949 111.761 107.351C109.511 105.74 107.778 103.42 106.564 100.39C105.362 97.3473 104.761 93.6847 104.761 89.402C104.774 85.1193 105.381 81.4759 106.583 78.4716C107.797 75.4545 109.53 73.1534 111.78 71.5682C114.043 69.983 116.746 69.1903 119.891 69.1903C123.036 69.1903 125.74 69.983 128.003 71.5682C130.266 73.1534 131.998 75.4545 133.199 78.4716C134.414 81.4886 135.021 85.1321 135.021 89.402C135.021 93.6974 134.414 97.3665 133.199 100.409C131.998 103.439 130.266 105.753 128.003 107.351C125.753 108.949 123.049 109.748 119.891 109.748ZM119.891 103.746C122.346 103.746 124.283 102.538 125.702 100.121C127.133 97.6925 127.849 94.1193 127.849 89.402C127.849 86.2827 127.523 83.6619 126.871 81.5398C126.219 79.4176 125.299 77.8196 124.11 76.7457C122.921 75.6591 121.515 75.1158 119.891 75.1158C117.449 75.1158 115.519 76.3303 114.1 78.7592C112.681 81.1754 111.965 84.723 111.952 89.402C111.94 92.5341 112.253 95.1676 112.892 97.3026C113.544 99.4375 114.464 101.048 115.653 102.135C116.842 103.209 118.255 103.746 119.891 103.746ZM139.603 101.713V96.0561L156.267 69.7273H160.984V77.7812H158.108L146.89 95.5575V95.8643H170.151V101.713H139.603ZM158.338 109V99.9872L158.415 97.456V69.7273H165.126V109H158.338Z" fill="#DCDDE1" />
                <path fillRule="evenodd" clipRule="evenodd" d="M103.495 139.547C105.431 140.664 106.094 143.14 104.976 145.076L61.1274 221.024L-23 172.453L16.1266 104.684L26.0583 110.418C27.0263 110.977 28.264 110.645 28.8229 109.677L34.5569 99.7453L103.495 139.547Z" fill="white" />
                <path d="M16.1266 104.684L34.5569 99.7453L28.8229 109.677C28.264 110.645 27.0263 110.977 26.0583 110.418L16.1266 104.684Z" fill="#EEEFF2" />
                <circle cx="4.04758" cy="4.04758" r="4.04758" transform="matrix(-0.866025 -0.5 -0.5 0.866025 71.5088 143.693)" fill="#E6E6E9" />
                <circle cx="4.04758" cy="4.04758" r="4.04758" transform="matrix(-0.866025 -0.5 -0.5 0.866025 45.8027 128.852)" fill="#E6E6E9" />
                <path d="M20.5211 151.054C17.177 156.846 28.4978 160.025 37.3259 165.122C46.1541 170.219 54.4216 178.685 57.9111 172.641C61.4005 166.597 59.4278 150.685 49.1029 144.723C38.7779 138.762 23.8652 145.262 20.5211 151.054Z" fill="#E6E6E9" />
                <rect x="0.5" y="0.5" width="239" height="181" rx="11.5" stroke="#E4E4E8" />
            </g>
            <defs>
                <clipPath id="clip0_4142_62063">
                    <rect width="240" height="182" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ScreenNotFoundView;
