import { Modal } from "antd";
import { useContext, useEffect, useState } from "react";
import HtmlRenderer from "@pages/PartnerRolePages/LandingPageConstructor/components/HtmlRenderer/HtmlRenderer";
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { sectionTitle } from '../../constants';
import { useGetLandingsLayoutsSectionsQuery } from '@store/type-partner/landings/landings.api';
import { GetLandingLayoutsDataItem } from '@store/type-partner/landings/models';
import { useParams } from 'react-router-dom';



const AddSectionModal = () => {
    const {
        isAddSectionModalOpen,
        setIsAddSectionModalOpen,
        setSelectedSectionIndex,
        onSectionAddToList,
    } = useContext(LandingEditorContext);
    const { eventId = '' } = useParams()

    const { data: layoutsResponse } = useGetLandingsLayoutsSectionsQuery({ id: eventId }, { skip: !eventId })
    const [layouts, setLayouts] = useState<GetLandingLayoutsDataItem[]>([])
    const [clickedCategoryData, setClickedCategoryData] = useState<GetLandingLayoutsDataItem | null>(null);

    useEffect(() => {
        if (layoutsResponse?.data) {
            setLayouts(layoutsResponse.data)
        }
    }, [layoutsResponse?.data])

    const onCategoryBtnClick = (item: GetLandingLayoutsDataItem) => {
        setClickedCategoryData(item)
    }

    const onClose = () => {
        setIsAddSectionModalOpen(false)
        setSelectedSectionIndex(null)

    }

    useEffect(() => {
        if (layouts) {
            setClickedCategoryData(layouts[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layouts])


    return (
        <Modal
            title='Add a section'
            wrapClassName="add-constructor-section-modal"
            open={isAddSectionModalOpen}
            onCancel={onClose}
            centered
            width={750}
            footer={null}
        >
            <div className='add-constructor-section__block'>
                <div className='add-constructor-section__left'>
                    {/* todo remove comments after demo */}
                    {/*<Button*/}
                    {/*    className='ai-generator-button'*/}
                    {/*    title='AI Generator'*/}
                    {/*    type='text'*/}
                    {/*    size='small'*/}
                    {/*>*/}
                    {/*    AI Generator*/}
                    {/*    <div className='ml-6'>*/}
                    {/*        <AIIcon />*/}
                    {/*    </div>*/}
                    {/*</Button>*/}
                    <div className='add-constructor-section__sections'>Sections</div>
                    {layouts?.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={`add-constructor-section__label ${item.section === clickedCategoryData?.section ? 'add-constructor-section__label_active' : ''}`}
                                onClick={() => onCategoryBtnClick(item)}
                            >
                                {sectionTitle[item.section]}
                            </div>
                        )
                    })}
                </div>
                <div className='add-constructor-section__wrapper'>
                    {clickedCategoryData && clickedCategoryData.layouts?.map((item) => {
                        return (
                            <HtmlRenderer
                                key={item.name}
                                title={item.name}
                                layout={item}
                                onLayoutSelect={onSectionAddToList}
                            />
                        )
                    })}
                </div>
            </div>
        </Modal>
    )
}

export default AddSectionModal
