export const initialBillingErrorsValues = {
    name: '',
    email: '',
    company_name: '',
    country: '',
    'country.id': '',
    city: '',
    'city.id': '',
    address: '',
    postal_code: '',
    tax_code: '',
    //optional
    event_hash: ''
}
export const initialBillingValues = {
    name: '',
    email: '',
    company_name: '',
    country: null,
    city: null,
    address: '',
    postal_code: '',
    tax_code: '',
    //optional
    event_hash: ''
}

