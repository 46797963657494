import { Modal, Progress } from 'antd'
import { aiGenerationStepsText } from '../../constants'
import { LandingAiGenerationSteps } from '../../models'
import TextTyper from '../../../../../components/TextTyper/TextTyper'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    isOpen: boolean,
    step: LandingAiGenerationSteps;
}

// aiGenerationStepsText
const LoaderAi = ({
    isOpen,
    step,
}: Props) => {
    //TODO: pass from props
    const steps: LandingAiGenerationSteps[] = ['title', 'description', 'images', 'finish'];
    const stepNumber = steps.findIndex(item => item === step) + 1
    return (
        <Modal
            title={<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            }
            open={isOpen}
            closable={false}
            footer={null}
            centered
            width={1200}
            zIndex={10000}
        >
            <div
                className='flex-col flex-center'
                style={{
                    height: '70vh',
                    padding: '120px 0'
                }}
            >
                <article className='text-title text-center mb-24 flex-center'>
                    <TextTyper
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        text={aiGenerationStepsText[step]}
                    />
                </article>

                <Progress
                    type="circle"
                    percent={(100 / steps.length) * stepNumber} // Calculated percentage based on current step
                    size={80}
                />
            </div>
        </Modal>
    )
}

export default LoaderAi