
const EditButtonIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5589_3904)">
                <path d="M2.59392 9.21303C2.6207 9.21303 2.64749 9.21035 2.67427 9.20633L4.92695 8.81124C4.95374 8.80589 4.97919 8.79383 4.99794 8.77375L10.6752 3.09651C10.6876 3.08412 10.6974 3.06941 10.7042 3.0532C10.7109 3.037 10.7143 3.01963 10.7143 3.00209C10.7143 2.98455 10.7109 2.96718 10.7042 2.95098C10.6974 2.93478 10.6876 2.92006 10.6752 2.90767L8.44928 0.680441C8.42383 0.654994 8.39035 0.641602 8.35419 0.641602C8.31803 0.641602 8.28454 0.654994 8.2591 0.680441L2.58186 6.35767C2.56177 6.37776 2.54972 6.40187 2.54436 6.42866L2.14927 8.68133C2.13625 8.75308 2.1409 8.82692 2.16284 8.89647C2.18477 8.96601 2.22333 9.02916 2.27517 9.08044C2.36356 9.16616 2.47472 9.21303 2.59392 9.21303ZM3.4966 6.87732L8.35419 2.02107L9.33588 3.00276L4.47829 7.85901L3.28767 8.06928L3.4966 6.87732ZM10.9283 10.338H1.07115C0.834096 10.338 0.642578 10.5295 0.642578 10.7666V11.2487C0.642578 11.3077 0.690792 11.3559 0.749721 11.3559H11.2497C11.3087 11.3559 11.3569 11.3077 11.3569 11.2487V10.7666C11.3569 10.5295 11.1653 10.338 10.9283 10.338Z" fill="#252628" />
            </g>
            <defs>
                <clipPath id="clip0_5589_3904">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default EditButtonIcon