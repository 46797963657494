import './styles.scss'
import { Input, Form, Checkbox, Popover, Typography } from 'antd';
import CustomButton from '@components/CustomButton';
import GoogleLogo from '@assets/GoogleLogo';
import { Link } from 'react-router-dom';
import RegistrationLayout from '@layouts/RegistrationLayout';
import ErrorBanner from '@pages/CommonPages/components/banners/ErrorBanner';
import { InviteInfoResponse } from '@store/models-to replace/auth';
import { CheckCircleSuccessIcon } from '@icons/CheckCircleSuccessIcon';
import PasswordPopover from '../components/PasswordPopover';
import { useState } from 'react';
import { ErrorsValues } from '../models';
import {PRIVACY_LINK, TERMS_LINK} from "@pages/CommonPages/Authentification/Register/constants.ts";

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
interface CreateAccountStepProps {
  form: {
    email: string;
    password: string;
    privacy: boolean;
  };
  formErrors: ErrorsValues;
  onFormChange: (value: Record<string, string>) => void;
  onSubmit: () => void;
  inviteInfo?: InviteInfoResponse;
  isLoading: boolean
}



const CreateAccountStep = ({
  form,
  formErrors,
  onFormChange,
  onSubmit,
  inviteInfo,
  isLoading
}: CreateAccountStepProps) => {

  const [isSuccessStatus, setIsSuccessStatus] = useState(false);
  const isSuccessStatusHandler = (statusState: boolean) => {
    setIsSuccessStatus(statusState)
  }
  const eventTitle = inviteInfo?.data?.event?.name || ''
  const eventImage = inviteInfo?.data?.event?.logo || ''
  return (
    <RegistrationLayout
      headerInfo={{
        text: eventTitle,
        image: eventImage
      }}
    >
      <div className='auth'>
        <main className='auth__form'>
          {inviteInfo?.data?.input?.name
            ?
            <>
              <Typography.Title
                level={4}
              >
                {`Dear ${inviteInfo?.data?.input?.name}`}
              </Typography.Title>
              <Typography.Paragraph
              >
                {`${inviteInfo?.data?.event?.name || 'Event'} invites you to join Boost.Express`}
              </Typography.Paragraph>
            </>
            :
            <p className='text-title mb-24'>
              Create account
            </p>
          }
          {
            formErrors.email || formErrors.password
              ?
              <ErrorBanner
                  isWarning={formErrors.email === 'The email has already been taken.'}
                  text={formErrors.email || formErrors.password}
              />
              :
              null
          }
          <Form
            name="create_account"
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            onValuesChange={onFormChange}
          >
            <Form.Item
              name='email'
              label="Email"
              validateStatus={formErrors.email ? "error" : ""}
            >
              <Input placeholder='Your email' />
            </Form.Item>

            <Popover
              content={() => (
                <PasswordPopover
                  password={form.password}
                  isSuccessStatusHandler={isSuccessStatusHandler}
                />
              )}
              placement="bottomLeft"
              trigger={'focus'}
            >
              <Form.Item
                label={'Password'}
                name='password'
                validateStatus={formErrors.password ? "error" : ""}
              >
                <Input.Password prefix={isSuccessStatus ? <CheckCircleSuccessIcon /> : null} />
              </Form.Item>
            </Popover>

            <Form.Item
              name='privacy'
              valuePropName="checked"
            >
              <Checkbox
                className='registration-account-form__checkbox'
              >
                {`I agree to the `}
                <Link to={`${PRIVACY_LINK}`} target={"_blank"}
                >
                  Privacy Policy{' '}
                </Link>
                and{' '}
                <Link to={`${TERMS_LINK}`} target={"_blank"}
                >
                  Terms and Conditions
                </Link>
              </Checkbox>
            </Form.Item>

            <Form.Item className='mt-24'>
              <CustomButton
                title='Sign up'
                htmlType="submit"
                classOptional='w-full'
                type='primary'
                size='large'
                loading={isLoading}
                onClick={onSubmit}
                disabled={!form.privacy}
              />
            </Form.Item>
          </Form>

        </main>
        <footer className='auth__footer flex-col items-center'>
          <span className='auth__footer-title mb-24'>
            or
          </span>

          <div className='auth__footer-btn'>
            <a href={`${apiBaseUrl}/auth/google`}>
              <CustomButton
                title='Google'
                prefix={<GoogleLogo />}
                size='large'
                classOptional='w-full'
              />
            </a>
          </div>
        </footer>

        <p className='auth__optional-footer'>
          Already have an account?{' '}
          <Link to={'/login'}>
            Sign in
          </Link>
        </p>

      </div>
    </RegistrationLayout>
  )
}

export default CreateAccountStep