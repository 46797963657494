import RocketIcon from '@assets/pro/RocketIcon'

const BadgePro = () => {
    return (
        <span className='boost-pro-item'>
            <RocketIcon />
            <span className='boost-pro-item-title'>PRO</span>
        </span>)
}

export default BadgePro