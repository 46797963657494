import { Table, Drawer, Switch } from "antd";
import type { ColumnsType } from 'antd/es/table';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { formatByCurrency } from "@shared/utils.ts";
import { formatNumberWithThousandsSeparator } from "@components/Analytics/helpers.ts";
import { useGetUserOrganizerBillingReportsQuery, useGetUserOrganizerBillingCurrentQuery } from '@store/type-event/billing/billing.api.ts';
import { Filters, FiltersKeys } from "@pages/EventRolePages/Billing/types.ts";
import { initialFilters, TabNames } from "@pages/EventRolePages/Billing/constants.ts";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";
import {
    UserBillingOrganizerEvent, UserBillingOrganizerPartner,
    UserBillingOrganizerReports
} from "@store/type-event/billing/models.ts";
import Avatar from "@components/Avatar";
import { formatReportDate } from "@pages/EventRolePages/Billing/helpers.ts";
import { CloseOutlined } from "@ant-design/icons";
import RevenueReportsFilters from "@pages/EventRolePages/Billing/components/RevenueReportsFilters.tsx";
import { useBreakpoints } from "@hooks/browser";
import { useParams } from "react-router-dom";
import TotalNumbers from './TotalNumbers.tsx';
import emptyBilling from "@assets/png/emptyBilling.png";
import MobileDrawer from '@components/mobile/Drawer';

const RevenueReportsTable = () => {
    const isMd = useBreakpoints().md

    const { billingId = '' } = useParams()

    const [filters, setFilters] = useState<Filters>(initialFilters)
    const [isOpenFilter, setOpenFilter] = useState(false)

    const { data: billingRes, isLoading: isBillingLoading, isFetching: isBillingFetching } = useGetUserOrganizerBillingReportsQuery({ ...filters, billingId }, { skip: !filters?.dateFrom || !billingId })
    const { data: billingCurrentRes, isLoading: isBillingCurrentLoading, isFetching: isBillingCurrentFetching } = useGetUserOrganizerBillingCurrentQuery({ billingId }, { skip: !billingId })
    const { data: gapsRes } = useGetFiltersByEnumQuery({ name: 'gap' })

    const setInitialFilters = useCallback(() => {
        const today = dayjs();
        const thirtyDaysAgo = dayjs().subtract(30, 'day');
        const todayFormatted = today.format('YYYY-MM-DD');
        const thirtyDaysAgoFormatted = thirtyDaysAgo.format('YYYY-MM-DD');
        setFilters({
            ...initialFilters,
            dateFrom: thirtyDaysAgoFormatted,
            dateTo: todayFormatted
        })
    }, [])

    useEffect(() => {
        setInitialFilters()
    }, []);

    const dataSource = useMemo(() => {
        const totalData = { date: 'Total', reports: { ...billingRes?.data?.sums?.[0] || {} } }
        const predictData = { date: 'Forecast for the month if existing average daily spend is maintained',
            reports: {
                cost: billingCurrentRes?.data?.sums?.[0]?.cost_predict || 0,
                revshare: billingCurrentRes?.data?.sums?.[0]?.revshare_predict || 0,
                comission: billingCurrentRes?.data?.sums?.[0]?.comission_predict || 0,
                isPredict: true
            } }
        return billingRes?.data?.dates?.length ? [...billingRes?.data?.dates || [], totalData, predictData] : []
    }, [
        billingCurrentRes,
        billingRes?.data?.dates,
        billingRes?.data?.sums
    ])

    const columns: ColumnsType = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 200,
            render: (date: string, _, index) => {
                return {
                    children: (
                        <span
                            className={`organizer-billing-page__reports__date 
                            ${index === dataSource.length - 2 ? 'organizer-billing-page__reports__penult' : ''}
                            `}
                        >
                            {date === 'Total' || date?.startsWith('Forecast') ? date : formatReportDate(date, filters?.gap)}
                        </span>
                    ),
                    props: {
                        colSpan: index === dataSource.length - 1 ? 2 : 1,
                    },
                }
            }
        },
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
            width: 400,
            render: (event: UserBillingOrganizerEvent, _, index) => {
                return {
                    children: (event ? (
                        <div className={`flex items-center gap-6 
                        ${index === dataSource.length - 2 ? 'organizer-billing-page__reports__penult' : ''}
                        `}
                        >
                            <Avatar
                                size='24'
                                url={event?.logo || ''}
                                bordered
                            />
                            <div className="organizer-billing-page__reports__event">
                                {event?.name}
                            </div>
                        </div>
                    ) : null),
                    props: {
                        colSpan: index === dataSource.length - 1 ? 0 : 1,
                    },
                }
            }
        },
        {
            title: 'Sponsor',
            dataIndex: 'partner',
            key: 'partner',
            width: 238,
            hidden: !filters?.group_by_partner,
            render: (partner: UserBillingOrganizerPartner, _, index) => (
                <span className={`organizer-billing-page__reports__date
                        ${index === dataSource.length - 2 ? 'organizer-billing-page__reports__penult' : ''}
                `}
                >
                    {partner?.name || ''}
                </span>
            )
        },
        {
            title: 'Spent',
            dataIndex: 'reports',
            key: 'cost',
            align: 'right',
            width: 238,
            render: (reports: UserBillingOrganizerReports, _, index) => (
                <span className={`organizer-billing-page__reports__number
                        ${index === dataSource.length - 2 ? 'organizer-billing-page__reports__penult' : ''}
                `}
                >
                    {formatByCurrency(reports.cost || 0, reports.currency)}
                </span>
            )
        },
        {
            title: 'Revshare',
            dataIndex: 'reports',
            key: 'revshare',
            align: 'right',
            width: 238,
            render: (reports: UserBillingOrganizerReports, _, index) => (
                <span className={`organizer-billing-page__reports__number
                        ${index === dataSource.length - 2 ? 'organizer-billing-page__reports__penult' : ''}
                `}
                >
                    {formatNumberWithThousandsSeparator((reports.revshare || 0) * 100)}
                    %
                </span>
            )
        },
        {
            title: 'Commision',
            dataIndex: 'reports',
            key: 'comission',
            width: 238,
            align: 'right',
            render: (reports: UserBillingOrganizerReports, _, index) => (
                <span className={`organizer-billing-page__reports__number
                        ${index === dataSource.length - 2 ? 'organizer-billing-page__reports__penult' : ''}
                `}
                >
                    {formatByCurrency(reports.comission || 0, reports.currency)}
                </span>
            )
        },
    ];

    const handleGapChange = useCallback((value: string | number) => {
        setFilters((prev) => ({ ...prev, gap: value }))
    }, [])

    useEffect(() => {
        const id = gapsRes?.data?.[0]?.id
        if (id) {
            handleGapChange(id)
        }
    }, [gapsRes, handleGapChange]);
    const segmentedOptions = gapsRes?.data?.map(({ translate, id }) => ({ label: translate, value: id })) || []

    const currency = billingRes?.data?.sums?.[0]?.currency

    const handleChangeFilter = useCallback((field: FiltersKeys, value: string[] | boolean) => {
        setFilters((prev) => ({ ...prev, [field]: value }))
    }, [])

    return (
        <>
            <TotalNumbers currency={currency} />
            {isMd ? (
                <div className="organizer-billing-page__title">{TabNames.RevenueReports}</div>
            ) : (
                <div className="organizer-billing-page__revenue-reports-title">
                    <div className="organizer-billing-page__title">{TabNames.RevenueReports}</div>
                    <div className="flex gap-8 organizer-billing-page__view-by-sponsors">
                        <div>View by sponsors</div>
                        <Switch
                            checked={filters.group_by_partner}
                            onChange={(isChecked) => handleChangeFilter(FiltersKeys.group_by_partner, isChecked)}
                            id="viewBySponsors"
                        />
                    </div>
                </div>
            )}
            <RevenueReportsFilters
                filters={filters}
                setInitialFilters={setInitialFilters}
                setFilters={setFilters}
                handleGapChange={handleGapChange}
                setOpenFilter={setOpenFilter}
                segmentedOptions={segmentedOptions}
            />
            <div className="mb-24">
                <Table
                    className={`organizer-billing-page__reports__table ${dataSource?.length ? '' : 'organizer-billing-page__table-empty'}`}
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="id"
                    loading={isBillingFetching || isBillingLoading || isBillingCurrentLoading || isBillingCurrentFetching}
                    pagination={false}
                    locale={{
                        emptyText: isBillingFetching || isBillingLoading || isBillingCurrentLoading || isBillingCurrentFetching ? ' ' : (
                            <div className='ad-accounts-page__empty-credentials'>
                                <img src={emptyBilling} alt='emptyBilling' />
                                <span><b>You have no revenue reports</b></span>
                                <span>It will be displayed here when you have your first revenue reports</span>
                            </div>
                        )
                    }}
                    scroll={{ x: true }}
                />
            </div>
            {isMd ? (
                <Drawer
                    title="Filters"
                    closeIcon={false}
                    placement="right"
                    open={isOpenFilter}
                    autoFocus={false}
                    mask={false}
                    onClose={() => setOpenFilter(false)}
                    maskStyle={{ 'opacity': 0, 'top': '62px' }}
                    rootStyle={{ 'top': '62px' }}
                    rootClassName="organizer-billing-page__drawer"
                    contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
                    extra={(
                        <CloseOutlined
                            onClick={() => setOpenFilter(false)}
                        />
                    )}
                >
                    <RevenueReportsFilters
                        isDrawer
                        filters={filters}
                        setInitialFilters={setInitialFilters}
                        setFilters={setFilters}
                        handleGapChange={handleGapChange}
                        setOpenFilter={setOpenFilter}
                        segmentedOptions={segmentedOptions}
                    />
                </Drawer>
            )
                : (
                    <MobileDrawer
                        title='Filters'
                        onClose={() => setOpenFilter(false)}
                        onCancel={setInitialFilters}
                        isOpen={isOpenFilter}
                        onOk={() => setOpenFilter(false)}
                        applyButtonText="Apply"
                        resetButtonText="Reset"
                        propClassNames={{
                            footer: 'organizer-billing-page__filter-drawer-footer'
                        }}
                    >
                        <RevenueReportsFilters
                            isDrawer
                            filters={filters}
                            setInitialFilters={setInitialFilters}
                            setFilters={setFilters}
                            handleGapChange={handleGapChange}
                            setOpenFilter={setOpenFilter}
                            segmentedOptions={segmentedOptions}
                        />
                    </MobileDrawer>
                )}
        </>
    )
}

export default RevenueReportsTable
