import React, {useState} from 'react';
import styles from './CompanyInfoForm.module.css';
import {Button, Form, Input, Typography} from "antd";
import FooterPanel from "../FooterPanel";
import {UpdateCompanyDataBodyType} from "@pages/Onboarding/EventOnboarding/onboarding.api.ts";

type CompanyInfoFormPropsType = {
  companyName: string | null
  updateCompany: (companyInfo: UpdateCompanyDataBodyType) => void
}

type CompanyInfoFormStateType = {
  companyName: string
  overview: string
}

const CompanyInfoForm: React.FC<CompanyInfoFormPropsType> = props => {
  const { companyName, updateCompany } = props;

  const [formState, setFormState] = useState<CompanyInfoFormStateType>({
    companyName: companyName || "",
    overview: ""
  });

  const isSubmitDisabled = !formState.companyName.length || !formState.overview.length;

  const onFieldChange = (fieldName:string, value:string) => {
    setFormState(prev => ({ ...prev, [fieldName]: value }));
  };

  const onSubmit = () => {
    updateCompany({
      name: formState.companyName,
      description: formState.overview
    })
  };

  return (
      <>
        <div className={styles.CompanyInfoForm}>
          <Typography.Title level={4} className={styles.CompanyInfoForm__title}>
            Fill in the company details
          </Typography.Title>
          <Form
              layout={'vertical'}
          >
            <Form.Item
                name={'companyName'}
                label={<span className={styles.CompanyInfoForm__inputName}>Company name</span>}
                htmlFor={'CompanyNameField'}
            >
              <Input
                  id={"CompanyNameField"}
                  size={'large'}
                  defaultValue={formState.companyName}
                  onChange={(e) => onFieldChange("companyName",e.target.value)}
              />
            </Form.Item>
            <Form.Item
                name={'overview'}
                label={<span className={styles.CompanyInfoForm__inputName}>Overview</span>}
                htmlFor={'OverviewField'}
            >
              <Input.TextArea
                  id={"OverviewField"}
                  rows={4}
                  placeholder={'Enter your overview...'}
                  className={styles.CompanyInfoForm__overview}
                  onChange={(e)=> onFieldChange("overview", e.target.value)}
              />
            </Form.Item>
          </Form>
          <FooterPanel>
            <Button
                disabled={isSubmitDisabled}
                onClick={onSubmit}
                type={'primary'}
                size={'large'}
                className={styles.CompanyInfoForm__submitButton}
            >
              Continue
            </Button>
          </FooterPanel>
        </div>
      </>
  )
};

export default CompanyInfoForm;
