import { useDeleteCampaignByIdMutation, usePatchPartnerCampaignsStatusByIdMutation } from '@store/type-partner/campaigns/campaigns.api';
import { Dropdown, MenuProps, message } from 'antd';
import { Link } from 'react-router-dom'
import sendAnalytics from "@hooks/sendAnalytics/sendAnalytics";
import { GTMEventName } from "@hooks/sendAnalytics/constants";
import {CampaignDataItem} from "@store/type-partner/campaigns/models.ts";

interface DropDownSettingsProps {
    children: React.ReactNode,
    eventId: string
    campaignId: string
    row: CampaignDataItem
    onCampaignStatusChange: (status: number, campaignId: string) => void
}
const DropDownSettings = ({
    children,
    eventId,
    campaignId,
    row,
    onCampaignStatusChange
}: DropDownSettingsProps) => {
    const [onDelete] = useDeleteCampaignByIdMutation()

    const onDeleteClick = async () => {
        // dispatch(campaignsActions.removeCampaignsById({ campaignIds: [campaignId] }))
        try {
            const response = await onDelete({ id: campaignId });

            if ('data' in response) {
                sendAnalytics(GTMEventName.deleteCampaign)
                return message.open({
                    type: 'success',
                    content: 'Campaign Deleted',
                });
            }
            if ('error' in response) {
                console.log('SMTH WRONG');
                // Handle the error here
            } else {
                // navigate('/campaigns');
                // login(values);
                console.log('Success:');
            }
        } catch (error) {
            console.log('SMTH WRONG');
            // Handle the error here
        }
    }

    const startAction = row?.status?.actions?.find(({ slug }) => slug === 'enabled')
    const pauseAction = row?.status?.actions?.find(({ slug }) => slug === 'paused')

    const items: MenuProps['items'] = [
        {
            key: 'settings',
            label: (
                <Link to={`/campaigns/edit/${eventId}/campaign/${campaignId}`}>
                    Settings
                </Link>
            ),
        },
        startAction ?
            {
                key: 'start',
                label: (
                    <div onClick={() => onCampaignStatusChange(startAction.id, campaignId)}>
                        Start
                    </div>
                ),
            } : null,
        pauseAction ?
            {
                key: 'pause',
                label: (
                    <div onClick={() => onCampaignStatusChange(pauseAction.id, campaignId)}>
                        Pause
                    </div>
                ),
            } : null,
        {
            type: 'divider',
        },
        {
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: <span onClick={onDeleteClick}>
                Delete
            </span>
            ,
            key: 'delete',

        },
    ];
    const itemsDeleteOnly: MenuProps['items'] = [
        {
            danger: true,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            label: <span onClick={onDeleteClick}>
                Delete
            </span>
            ,
            key: 'delete',

        },
    ];

    return (
        <Dropdown
            menu={eventId ? { items } : { items: itemsDeleteOnly }}
        >
            {children}
        </Dropdown>
    )
}

export default DropDownSettings