import IsDefaultPlug from '@pages/PartnerRolePages/Billing/sections/components/IsDefaultPlug';
import { CardLogo } from '@pages/PartnerRolePages/Billing/sections/payment-methods/PaymentMethodRow';
import React from 'react'
import '../styles.scss'

type Props = {
    brand: string
    last4: string
    isDefault?: boolean
    isDefaultSubdued?: boolean
}

const BrandedCardItem = ({ brand, last4, isDefault, isDefaultSubdued }: Props) => {
    return (
        <div className='billing-page-content__payment-method__row-method'>
            <CardLogo brand={brand} />
            <span className='mx-6'>
                ending in
                {' '}
                {last4}
            </span>
            {isDefault && <IsDefaultPlug isDefaultSubdued={isDefaultSubdued} />}
        </div>
    )
}

export default BrandedCardItem
