
const PartnersIcon = ({ isActive }: { isActive: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2543_70404)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.900747 11.139C0.530527 10.7688 0.530598 10.1686 0.900747 9.79847L8.36824 2.33097C8.73504 1.96418 9.32928 1.96474 9.69552 2.33097L15.9766 8.612C16.8819 9.51739 16.8819 10.9853 15.9766 11.8907C15.0712 12.7961 13.6033 12.7961 12.6979 11.8907L8.63284 7.82567C8.26672 7.45955 8.26672 6.86596 8.63284 6.49985C8.99895 6.13373 9.59254 6.13373 9.95866 6.49985L14.0237 10.5649C14.1968 10.738 14.4776 10.738 14.6507 10.5649C14.8239 10.3917 14.8239 10.111 14.6507 9.93783L9.03188 4.31898L2.88212 10.4687L4.29873 11.8854C4.38009 11.9667 4.38009 12.0986 4.29873 12.18L3.26753 13.2112C3.18617 13.2925 3.05426 13.2925 2.9729 13.2112L0.900747 11.139Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.9352 2.57653C16.3053 2.20638 16.9055 2.20631 17.2757 2.57653L25.1378 10.4386C25.508 10.8088 25.508 11.409 25.1378 11.7792L13.4389 23.4781L12.2983 22.2741L23.1565 11.1089L16.6055 4.5579L13.5995 7.56391L12.2736 6.23808L15.9352 2.57653Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
                <path d="M8.89844 10.7871L4.89069 14.7949C4.33643 15.3491 4.33643 16.2477 4.89069 16.802V16.802C5.44495 17.3563 6.34358 17.3563 6.89784 16.802L10.9056 12.7943" stroke="white" strokeWidth="1.66667" strokeLinecap="round" />
                <path d="M11.4955 13.3842C11.8209 13.0587 11.8209 12.5311 11.4955 12.2057C11.1701 11.8802 10.6424 11.8802 10.317 12.2057L11.4955 13.3842ZM12.3241 14.2128L8.3164 18.2206L9.49491 19.3991L13.5027 15.3913L12.3241 14.2128ZM7.48776 17.3919L11.4955 13.3842L10.317 12.2057L6.30925 16.2134L7.48776 17.3919ZM7.48776 18.2206C7.25894 17.9917 7.25894 17.6207 7.48776 17.3919L6.30925 16.2134C5.42955 17.0931 5.42955 18.5194 6.30925 19.3991L7.48776 18.2206ZM8.3164 18.2206C8.08758 18.4494 7.71658 18.4494 7.48776 18.2206L6.30925 19.3991C7.18894 20.2788 8.61521 20.2788 9.49491 19.3991L8.3164 18.2206Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
                <path d="M12.9102 14.8008L8.90241 18.8085C8.34815 19.3628 8.34815 20.2614 8.90241 20.8157V20.8157C9.45667 21.3699 10.3553 21.3699 10.9096 20.8157L14.9173 16.8079" stroke="white" strokeWidth="1.66667" strokeLinecap="round" />
                <path d="M15.5072 17.3978C15.8327 17.0724 15.8327 16.5448 15.5072 16.2193C15.1818 15.8939 14.6542 15.8939 14.3287 16.2193L15.5072 17.3978ZM16.3359 18.2265L12.3281 22.2342L13.5066 23.4127L17.5144 19.405L16.3359 18.2265ZM11.4995 21.4056L15.5072 17.3978L14.3287 16.2193L10.321 20.2271L11.4995 21.4056ZM11.4995 22.2342C11.2707 22.0054 11.2707 21.6344 11.4995 21.4056L10.321 20.2271C9.44127 21.1068 9.44127 22.5331 10.321 23.4127L11.4995 22.2342ZM12.3281 22.2342C12.0993 22.4631 11.7283 22.4631 11.4995 22.2342L10.321 23.4127C11.2007 24.2924 12.6269 24.2924 13.5066 23.4127L12.3281 22.2342Z" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
            </g>
            <defs>
                <clipPath id="clip0_2543_70404">
                    <rect width="26" height="26" fill={isActive ? '#4E67EA' : '#FFF'} fillOpacity={isActive ? '0.2' : '1'} />
                </clipPath>
            </defs>
        </svg>

    )
}

export default PartnersIcon