
const MasterCardLogo = () => {
    return (
        <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9095_63084)">
                <rect width="33" height="22" rx="1.88571" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7425 15.7272C15.619 16.6696 14.1619 17.2385 12.5696 17.2385C9.01627 17.2385 6.13574 14.4055 6.13574 10.9107C6.13574 7.41603 9.01627 4.58301 12.5696 4.58301C14.1619 4.58301 15.619 5.15189 16.7425 6.09427C17.8659 5.15189 19.3231 4.58302 20.9153 4.58302C24.4687 4.58302 27.3492 7.41604 27.3492 10.9108C27.3492 14.4055 24.4687 17.2385 20.9153 17.2385C19.3231 17.2385 17.8659 16.6696 16.7425 15.7272Z" fill="#ED0006" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7397 15.7272C18.1234 14.5666 19.0007 12.8394 19.0007 10.9107C19.0007 8.98204 18.1234 7.25489 16.7398 6.09426C17.8632 5.15189 19.3204 4.58301 20.9126 4.58301C24.4659 4.58301 27.3465 7.41603 27.3465 10.9107C27.3465 14.4055 24.4659 17.2385 20.9126 17.2385C19.3204 17.2385 17.8632 16.6696 16.7397 15.7272Z" fill="#F9A000" />
                <path fillRule="evenodd" clipRule="evenodd" d="M16.7366 15.7272C18.1202 14.5666 18.9976 12.8394 18.9976 10.9107C18.9976 8.98203 18.1202 7.25486 16.7366 6.09424C15.353 7.25486 14.4756 8.98203 14.4756 10.9107C14.4756 12.8394 15.353 14.5666 16.7366 15.7272Z" fill="#FF5E00" />
            </g>
            <rect x="0.5" y="0.5" width="32" height="21" rx="3.5" stroke="#E8E9EB" />
            <defs>
                <clipPath id="clip0_9095_63084">
                    <rect width="33" height="22" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MasterCardLogo