import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import React, { useContext } from 'react'
import './styles.scss'
import SettingsItem from './SettingsItem';
import { LandingSectionCategoryOption } from '@pages/PartnerRolePages/LandingPageConstructor/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { PartnerLandingSectionLayout } from '@store/type-partner/landings/models';

interface Props {
    clickedSectionId: string;
    isOpen: boolean;
    layoutData: PartnerLandingSectionLayout
}

const SectionSettingsDrawer: React.FC<Props> = ({
    clickedSectionId,
    isOpen,
    layoutData
}) => {
    const {
        isLoading,
        setLayoutSettingsData,
        onSaveSectionSettingsClick
    } = useContext(LandingEditorContext);

    // const options = Object.entries(layoutData.options)
    //     .filter(([_, value]) => value !== null)
    //     .map(([key, value]) => ({
    //         key: key as LandingSectionCategoryOption,
    //         value: value as string
    //     }));

    const optionsList = Object.entries(layoutData.options)
        .map(([key]) => (key as LandingSectionCategoryOption));

    return (
        <Drawer
            title="Settings"
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            mask
            maskClosable
            className='editor-drawer'
            onClose={() => setLayoutSettingsData(null)}
            bodyStyle={{
                'display': 'flex',
                'flexDirection': 'column',
                'justifyContent': 'space-between',
            }}
            maskStyle={{ 'opacity': 0, 'top': '62px' }}
            rootStyle={{ 'top': '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={(
                <CloseOutlined
                    onClick={() => setLayoutSettingsData(null)}
                />
            )}
        >
            <div className='editor-book__content'>
                {optionsList.length
                    ?
                    <SettingsItem
                        id={clickedSectionId}
                        layout={layoutData}
                        optionsList={optionsList}
                    />
                    :
                    null

                }
            </div>

            <div className='editor-book__cta'>
                <Button
                    onClick={onSaveSectionSettingsClick}
                    type="primary"
                    size="large"
                    loading={isLoading}
                    disabled={isLoading}
                >
                    Save changes
                </Button>
            </div>
        </Drawer>
    )
}

export default SectionSettingsDrawer
