import { addHours, addMinutes, format, differenceInDays, isSameMonth, isSameYear } from 'date-fns';

export function isValidEmail(email: string): boolean {
    // Regular expression for email validation
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    return emailRegex.test(email);
}

export const formatNumberToAmerican = (number: number, decimalPlaces = 2) => {
    if (isNaN(number)) {
        return "Invalid Number";
    }

    // Convert the number to a string and split it into integer and decimal parts
    const parts = Number(number).toFixed(decimalPlaces).split(".");
    const integerPart = parts[0];
    const decimalPart = parts[1] || "";

    // Add commas for thousands separator
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Combine the integer and decimal parts
    const formattedNumber = `${formattedInteger},${decimalPart}`;

    return formattedNumber;
}


export function formatEuCurrency(amount: number): string {
    if (amount === 0 || !amount) {
        return '€0.00';
    }

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
    }).format(amount);
}

export function formatByCurrency(amount: number | string, currency?: string): string {
    const amountNumber = isNaN(Number(amount)) ? 0 : Number(amount)
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
        minimumFractionDigits: 2,
    }).format(amountNumber || 0);
}

export function formatKNumber(value: number): string {
    if (value === 0) {
        return '0';
    } if (value > 999) {
        const formattedValue = (value / 1000).toFixed(1);
        return `${formattedValue}K`;
    }
    return value.toString();
}

export function formatCurrency(input: string): string {
    // Convert the input string to a number
    const numberValue = parseFloat(input);

    // Check if the conversion was successful
    if (isNaN(numberValue)) {
        throw new Error("Invalid input. Please provide a valid number string.");
    }

    // Use Intl.NumberFormat to format the number as currency
    const formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(numberValue);

    return formattedCurrency;
}

export const formatDate = (inputDate: string, year?: string) => {
    if (!inputDate) {
        return ''
    }

    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'long', year: year || '2-digit' };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const formattedDate = date.toLocaleDateString('en-US', options as any);
    return formattedDate;
};

export const formatDateToCommonFormat = (inputDate: string) => {
    if (!inputDate) {
        return ''
    }
    return format(new Date(inputDate), "MMM. d, yyyy");
}

const formatSingleDate = (date: Date) => format(date, "MMM. d, yyyy");

export const formatDateRangeToCommonFormat = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
        return '';
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start.getTime() === end.getTime()) {
        return formatSingleDate(start);
    }

    const daysDifference = differenceInDays(end, start);

    if (daysDifference === 1) {
        return formatSingleDate(start);
    }

    if (isSameMonth(start, end)) {
        return `${format(start, "MMM. d")}–${format(end, "d, yyyy")}`;
    }

    if (isSameYear(start, end)) {
        return `${formatSingleDate(start)} – ${format(end, "MMM. d, yyyy")}`;
    }

    return `${formatSingleDate(start)} – ${format(end, "MMM. d, yyyy")}`;
};

export const formatDateUtc = (dateString: string) => {
    if (!dateString) {
        return ''
    }
    const isIncreased = dateString.includes('+')
    const [dateWithoutUtc, UTC] = dateString.split(isIncreased ? '+' : '-')
    const [hours, minutes] = UTC.split(':').map(Number);
    const userTimeZoneOffsetInMinutes = new Date().getTimezoneOffset() * -1;
    const increasedMinutes = isIncreased ? minutes : minutes * -1
    let resultDate = new Date(dateWithoutUtc)
    resultDate = addHours(resultDate, isIncreased ? hours : hours * -1);
    resultDate = addMinutes(resultDate, increasedMinutes + userTimeZoneOffsetInMinutes);

    return formatDate(resultDate as unknown as string, 'numeric');
}

export function formatDateRange(dateObject: { started_at: string, finished_at: string }) {
    const options = { month: 'short', day: 'numeric' };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const startedAt = new Date(dateObject.started_at).toLocaleDateString('en-US', options as any);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const finishedAt = new Date(dateObject.finished_at).toLocaleDateString('en-US', options as any);

    return `${startedAt || ''} – ${finishedAt || ''}`;
}

export interface ErrorDataResponse {
    error: {
        data: {
            errors: { [key: string]: string[] };
            message?: string
            data?: { field: string, messages: string[] }[]
        }
    }
}
export const errorsToFormAdapter = (response: ErrorDataResponse) => {
    const errors = response?.error?.data?.errors || null
    if (errors) {
        const errorsObj: Record<string, string> = {};
        // Iterate over each key in the errors object
        Object.keys(errors).forEach((key) => {
            const errorMessage = errors[key]?.[0]; // Get the first error message for the key
            if (errorMessage) {
                errorsObj[key] = errorMessage;
            }
        });
        return errorsObj;
    }
    // If there are no errors in the response, return an empty object
    return {};
};

interface ErrorResponseItem {
    field: string;
    messages: string[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout>;

    return (...args: any[]) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            fn(...args);
        }, delay);
    };
};

export const getQueryStringFromParams = (params: string[] = [], key: string) => {
    return Array.isArray(params)
        ? params.map(id => `filters[${key}][]=${id}`).join('&')
        : '';
}

export const getSearchValue = (search = '') => {
    return search?.length > 0 && search?.length < 3 ? '' : search
}

export const getTrimmedUrl = (url?: string) => {
    if (!url) {
        return ''
    }
    let newUrl = url?.replace('http://', '')?.replace('https://', '')
    if (newUrl.charAt(0) === '/') {
        newUrl = newUrl.slice(1);
    }
    if (newUrl.charAt(newUrl.length - 1) === '/') {
        newUrl = newUrl.slice(0, -1);
    }
    return newUrl
}

export const generateUniqueNumericId = (): number => {
    const timestamp = Date.now();
    const randomPart = Math.floor(Math.random() * 10000);
    return timestamp * 10000 + randomPart;
}
export const generateUniqueStringId = (): string => {
    const timestamp = Date.now();
    const randomPart = Math.floor(Math.random() * 10000);
    const result = timestamp * 10000 + randomPart;
    return result.toString()
}

export const removeImageStylesFromHtml = (html: string) => {
    if (!html) {
        return ''
    }
    const regex = /(<img[^>]+?)\s+style\s*=\s*(['"])(.*?)\2/gi;
    return html.replace(regex, '$1')
}

export const getCurrencySymbol = (currencyCode?: string) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode || 'USD',
        minimumFractionDigits: 2,
    });

    const parts = formatter.formatToParts(0);

    return parts?.find(part => part.type === 'currency')?.value || '$';
};
