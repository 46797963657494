import React from "react";
import {Input, InputProps} from "antd";
import {SearchIcon} from "@assets/SearchIcon";
import styles from "./style.module.css";

const InputSearch: React.FC<InputProps> = (props) => {
    return (
        <div className={styles.InputSearchBlock}>
            <Input
                className={styles.SearchIcon}
                {...props}
            />
            <div className={styles.SearchIcon__iconBlock}>
                <SearchIcon/>
            </div>
        </div>
    )
}

export default InputSearch;
