import CustomButton from '@components/CustomButton'
import LandingPageCard from './components/LandingPageCard'

import './styles.scss'
import { Link } from 'react-router-dom'
import { Modal, Tooltip } from 'antd';
import React, { RefObject, useState } from 'react';
import Loader from '@components/Loader';
import ZeroLandings from '../../ZeroState/ZeroLandings';
import { ExclamationCircleFilled } from '@ant-design/icons';
import RejectionReasonModal
    from '@pages/PartnerRolePages/MyEvents/pages/MyEventEditPage/components/EventLandingPages/components/RejectionReasonModal';
import { PartnerLandingItemResponseData } from "@store/type-partner/landings/models";

interface EventLandingPagesProps {
    eventId: string;
    eventLandings: PartnerLandingItemResponseData[];
    isActive: boolean
    onDeleteLanding: (id: string) => void
    isDeleting: boolean
    landingPagesRef: RefObject<HTMLAnchorElement>;
}

const { confirm } = Modal;

const EventLandingPages = ({ eventId, eventLandings = [], isActive, onDeleteLanding, isDeleting, landingPagesRef }: EventLandingPagesProps) => {
    const [rejectionComment, setRejectionComment] = useState('')
    const [rejectionReason, setRejectionReason] = useState('')

    const showConfirm = (id: string) => {
        confirm({
            title: 'Do you Want to delete this landing page?',
            icon: <ExclamationCircleFilled />,
            okType: 'danger',
            closable: true,
            centered: true,
            onOk() {
                onDeleteLanding(id)
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const onCloseRejectionReasonModal = () => {
        setRejectionComment('')
        setRejectionReason('')
    }

    // ZERO STATE
    if (!eventLandings.length) {
        return (
            <article className='event-info' ref={landingPagesRef}>
                {isDeleting && <Loader />}
                <header className='event-info__header flex items-center justify-space-between'>
                    <h3>
                        My landing pages
                    </h3>
                </header>
                <ZeroLandings
                    eventId={eventId}
                    isActive={isActive}
                />
            </article>
        )
    }

    return (
        <article className='event-info' ref={landingPagesRef}>
            {isDeleting && <Loader />}
            <header className='event-info__header flex items-center justify-space-between'>
                <h3>
                    My landing pages
                </h3>

                {eventId && isActive
                    ? (
                        <Link to={`/events/${eventId}/landing`} className='no-style'>
                            {/* <Link to={`/events/${eventId}/landing/create`} className='no-style'> */}
                            <CustomButton
                                type="primary"
                                title='+ Create landing'
                            />
                        </Link>
                    )
                    : (
                        <Tooltip
                            title={'only for status "Active"'}
                        >
                            <CustomButton
                                disabled
                                type="primary"
                                title='+ Create landing'
                            />
                        </Tooltip>
                    )}
            </header>

            <div
                className={`event-info__landing-page-cards flex items-center ${eventLandings.length > 2 ? 'justify-space-between' : 'justify-center'}`}
            >
                {
                    eventLandings.map(landing => {
                        return (
                            <LandingPageCard
                                key={landing.id}
                                landingId={`${landing.id}`}
                                eventId={eventId}
                                landingData={landing}
                                onDeleteLanding={showConfirm}
                                setRejectionComment={setRejectionComment}
                                setRejectionReason={setRejectionReason}
                            />
                        )
                    })
                }
            </div>
            <RejectionReasonModal
                isOpen={Boolean(rejectionComment)}
                onClose={onCloseRejectionReasonModal}
                comment={rejectionComment}
                reason={rejectionReason}
            />
        </article>
    )
}

export default EventLandingPages
