import EventsPageLayout from '@layouts/EventsPageLayout'
import {
    useGetNotificationsQuery,
    usePostNotificationsAreReadMutation
} from "@store/main/notifications/notifications.api";
import React, { useEffect, useMemo, useState } from "react";
import './styles.scss'
import ContentLayout from "@layouts/ContentLayout.tsx";
import MarkIcon from "@assets/MarkIcon";
import { Button, Tabs, TabsProps, Skeleton, List } from "antd";
import { StatusLabels } from "@pages/PartnerRolePages/Notifications/constants";
import NotificationCard from "@components/NotificationsHeader/NotificationCard";
import InfiniteScroll from 'react-infinite-scroll-component';
import { Notification } from "@store/main/notifications/models";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api";

const NotificationsPage = () => {
    const [page, setPage] = useState(1)
    const [tabId, setTabId] = useState<string>()
    const [notificationItems, setNotificationItems] = useState<Notification[]>([])
    const [isMarkAllReadLoading, setIsMarkAllReadLoading] = useState(false)
    const [isInfiniteLoading, setIsInfiniteLoading] = useState(false)
    const [isTabChangingLoading, setIsTabChangingLoading] = useState(false)
    const [onReadAllNotifications] = usePostNotificationsAreReadMutation()

    const { data, refetch, isLoading, isFetching } = useGetNotificationsQuery({ page, status: tabId }, { pollingInterval: 60000, skip: !tabId });
    const { data: statuses, isLoading: isStatusesLoading } = useGetFiltersByEnumQuery({ name: 'notification_status' })

    useEffect(() => {
        if (statuses) {
            setTabId(statuses?.data?.[0]?.id)
        }
    }, [statuses])

    const onMarkAllClick = async () => {
        try {
            setIsMarkAllReadLoading(true)
            const response = await onReadAllNotifications();
            if (response) {
                setPage(1)
                void refetch();
            }
        } catch (error) {
            // Handle error if needed
        }
        setIsMarkAllReadLoading(false)
    }

    useEffect(() => {
        setNotificationItems((prevNotifications) => {
            const from = data?.meta?.from
            if (from && (prevNotifications.length < from)) {
                return [...(prevNotifications || []), ...(data?.data || [])]
            }
            return data?.data || []
        })
        setIsInfiniteLoading(false)
        setIsTabChangingLoading(false)
    }, [data])

    const isDataLoading = isStatusesLoading || isLoading || isFetching || isTabChangingLoading

    const loadMoreData = () => {
        if (data?.meta?.last_page && (data?.meta?.last_page <= page || isDataLoading)) {
            return;
        }
        setPage(page + 1)
        setIsInfiniteLoading(true)
    };

    const items: TabsProps['items'] = useMemo(() => {
        return statuses?.data?.map((item) => ({ ...item, label: StatusLabels[item.slug], key: item.id }))
    }, [statuses])

    const onTabChange = (value: string) => {
        setTabId(value)
        setPage(1)
        setIsTabChangingLoading(true)
    }

    const selectedStatusItem = statuses?.data?.find(({ id: itemId }) => itemId === tabId)

    const content = useMemo(() => {
        if (isDataLoading && !isInfiniteLoading) {
            return (
                <div className="notification-page__loader">
                    <Skeleton.Button active size="large" shape="square" block/>
                    <Skeleton.Button active size="large" shape="square" block/>
                    <Skeleton.Button active size="large" shape="square" block/>
                </div>
            )
        }
        if (!notificationItems.length && !isDataLoading) {
            return (
                <div className='notification-card__empty-title'>
                    {selectedStatusItem?.translate}
                    {' '}
                    notifications list is empty
                </div>
            )
        }
        if (Boolean(notificationItems.length) && !(isDataLoading && !isInfiniteLoading)) {
            return (
                <div
                    id="scrollableDiv"
                    style={{
                        height: 'calc(100vh - 330px)',
                        overflow: 'auto',
                    }}
                >
                    <InfiniteScroll
                        dataLength={notificationItems?.length}
                        next={loadMoreData}
                        hasMore={(data?.meta?.last_page || 0) > (data?.meta?.current_page || 0)}
                        loader={<Skeleton paragraph={{rows: 1}} active/>}
                        endMessage={null}
                        scrollableTarget="scrollableDiv"
                    >
                        <List
                            dataSource={notificationItems}
                            renderItem={(item: Notification) => (
                                <List.Item key={item.id}>
                                    <NotificationCard
                                        notification={item}
                                    />
                                </List.Item>
                            )}
                            bordered={false}
                        />
                    </InfiniteScroll>
                </div>
            )
        }
        return null
    }, [data?.meta?.current_page, data?.meta?.last_page, isDataLoading, isInfiniteLoading, loadMoreData, notificationItems, selectedStatusItem?.translate])

    return (
        <EventsPageLayout>
            <ContentLayout>
                <div className='notification-page__inner'>
                    <div className='notification-card__title'>
                        <p>
                            Messages
                        </p>
                        <div className='notification-card__title-total-count'>
                            {data?.meta?.total || ''}
                        </div>
                        <Button
                            className='notification-card__mark-button'
                            icon={<MarkIcon/>}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={onMarkAllClick}
                            loading={isMarkAllReadLoading}
                        >
                            Mark all as read
                        </Button>
                    </div>
                    <Tabs
                        activeKey={tabId || items?.[0]?.key}
                        onChange={onTabChange}
                        items={items}
                    />
                    {content}
                </div>
            </ContentLayout>
        </EventsPageLayout>

    )
}

export default NotificationsPage
