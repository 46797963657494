import React, { useContext, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';
import './styles.scss'
import UpArrowIcon from './assets/UpArrowIcon';
import DownArrowIcon from './assets/DownArrowIcon';
// import MenuHorIcon from './assets/MenuHorIcon';
import DeleteRedIcon from './assets/DeleteRedIcon';
import SettingsIcon from './assets/SettingsIcon';
import { JoditOptionName } from './assets/models';
import { LandingEditorContext } from '@contexts/LandingEditorContext';
import { joditButtons, joditControls } from './constants';
import { PartnerLandingSectionLayout } from "@store/type-partner/landings/models.ts";

interface HtmlEditorProps {
    id: string;
    tooltipVisible: boolean;
    onCustomOptionClick: ({ option, id }: { option: JoditOptionName, id: string }) => void;
    layout: PartnerLandingSectionLayout
}

const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    editHTMLDocumentMode: true,
    showWordsCounter: false,
    showCharsCounter: false,
    beautifyHTML: false,
    hidePoweredByJodit: true,
    statusbar: false,
    width: 'auto',
    toolbarAdaptive: true,
    buttons: joditButtons,
    controls: joditControls,
    uploader: {
        insertImageAsBase64URI: true
    },
    editorClassName: 'html-editor',
    image: {
        useImageEditor: false,
        editImage: false,
        editSize: false,
        editStyle: false,
    },
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({
    id,
    tooltipVisible,
    onCustomOptionClick,
    layout,
}) => {
    const {
        updateSectionHtmlById,
    } = useContext(LandingEditorContext);
    const editor = useRef(null);

    const configMemo = useMemo(() => {
        return {
            ...config,
            toolbar: tooltipVisible,
        }
    }, [tooltipVisible])

    return (
        <div className={`jodit-editor-wrapper ${tooltipVisible ? 'isSelected' : ''}`}>
            <JoditEditor
                ref={editor}
                value={layout.html}
                config={configMemo}
                onBlur={(newContent) => {
                    const isSame = newContent.replace('<body>', '').replace('</body>', '') === layout.html
                    if (!isSame) {
                        updateSectionHtmlById({ id, html: newContent })
                    }
                }}
            // onChange={(newContent) => {
            //     console.log('=========================================');
            //     console.log(`WE GOT: `, newContent);
            //     console.log('=========================================');
            // }}
            />

            {/* TODO: check if condition is needed in future  */}
            {tooltipVisible
                ? (
                    <CustomOptions
                        id={id}
                        onOptionClick={onCustomOptionClick}
                        layout={layout}

                    />
                )
                :
                null}
        </div>
    );
};

export default HtmlEditor;

const CustomOptions = (
    {
        id,
        onOptionClick,
        layout
    }
        :
        {
            id: string;
            onOptionClick: ({ option, id }: { option: JoditOptionName, id: string }) => void;
            layout: PartnerLandingSectionLayout
        }
) => {
    const {
        setLayoutSettingsData
    } = useContext(LandingEditorContext);

    return (
        <aside className='jodit-toolbar__box-aside'>

            <article
                className='jodit-toolbar__box-aside__settings'
            >
                {/* not active for the moment */}
                {/* <button
        onClick={() => onCustomOptionClick({ option: JoditOptionName.Clone, id })}
        className='jodit-toolbar__box-aside__settings__cta'>
        <CloneIcon />
    </button> */}
                <button
                    onClick={() => {
                        setLayoutSettingsData(layout)
                    }}
                    className='jodit-toolbar__box-aside__settings__cta'
                >
                    <SettingsIcon />
                </button>
                <button
                    onClick={() => onOptionClick({ option: JoditOptionName.Delete, id })}
                    className='jodit-toolbar__box-aside__settings__cta'
                >
                    <DeleteRedIcon />
                </button>
                {/* not active for the moment */}
                {/* <button
                    onClick={() => onOptionClick({ option: JoditOptionName.Menu, id })}
                    className='jodit-toolbar__box-aside__settings__cta'>
                    <MenuHorIcon />
                </button> */}
            </article>

            <article
                className='jodit-toolbar__box-aside__order-updater'
            >
                <button
                    onClick={() => onOptionClick({ option: JoditOptionName.Up, id })}
                    className='jodit-toolbar__box-aside__order-updater__cta'
                >
                    <UpArrowIcon />
                </button>
                <button
                    onClick={() => onOptionClick({ option: JoditOptionName.Down, id })}
                    className='jodit-toolbar__box-aside__order-updater__cta'
                >
                    <DownArrowIcon />
                </button>

            </article>
        </aside>
    )
}
