//https://boost.allnetru.dev/api/documentation#/Organization
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const organization = `organization`;

export const organizationApi = createApi({
    reducerPath: organization,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        organization
    ],
    endpoints: build => ({
        patchUserOragnizationType: build.mutation<void, { organization_type: string }>({
            query: (body) => {
                return {
                    body,
                    method: 'PATCH',
                    url: `/organization/set-type`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                };
            },
            invalidatesTags: [organization],
        }),
    }),
});

export const {
    usePatchUserOragnizationTypeMutation,
} = organizationApi;