import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import BillingStripeForm from './form'
import { Intent } from '@store/main/payment/models';

interface BillingStripeFormWrapperProps {
    intent: Intent;
    apiKey: string;
}


const BillingStripeFormWrapper = ({
    intent,
    apiKey,
}: BillingStripeFormWrapperProps) => {
    const stripeoOtions = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        clientSecret: intent?.client_secret || '',
        // Fully customizable with appearance API.
        appearance: {/*...*/ },
    };
    return (
        <Elements
            stripe={loadStripe(apiKey || '')}
            options={stripeoOtions}
        >
            <BillingStripeForm />
        </Elements>
    )
}

export default BillingStripeFormWrapper