import React from "react";
import {Typography} from "antd";
import ScreenNotFoundView from "@assets/ScreenNotFoundViewIcon";
import styles from "./NotFoundPage.module.css";

const NotFoundPage: React.FC = () => {
    return (
        <div className={styles.NotFoundPage__container}>
            <section className={styles.NotFoundPage__error}>
                <ScreenNotFoundView/>
                <Typography.Title level={3} className={styles.NotFoundPage__errorTitle}>
                    Oops! Page not found
                </Typography.Title>
                <Typography.Text className={styles.NotFoundPage__errorText}>
                    The page doesn’t exist or has been moved
                </Typography.Text>
            </section>
        </div>
    )
}

export default NotFoundPage;
