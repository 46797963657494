import React from 'react'
import { Button } from 'antd';
import RocketIcon from '@assets/pro/RocketIcon';
import cn from 'classnames';
import styles from './BoostProBanner.module.scss'
import CheckIcon from '@assets/CheckIcon';
import Image from '@assets/pro/boostProBannerImage.png'

const BoostProBanner = ({ onEnablePro }: { onEnablePro: () => void }) => {
    return (
        <div className={styles.Wrapper}>
            <div>
                <Button
                    size="large"
                    className={cn(styles.Button, "boost-pro-gradient")}
                >
                    <RocketIcon fillColor="#504BD9" />
                    Boost PRO
                </Button>
                <span className={styles.Title}>
                    Get full campaign assistance from our team of experts with Boost PRO service
                </span>
                <div className={styles.List}>
                    <div className={styles.ListItem}>
                        <CheckIcon />
                        <span>Customized ad strategies tailored to your goals</span>
                    </div>
                    <div className={styles.ListItem}>
                        <CheckIcon />
                        <span>Continuous campaign monitoring and optimization</span>
                    </div>
                    <div className={styles.ListItem}>
                        <CheckIcon />
                        <span>Landing pages and creatives production</span>
                    </div>
                </div>
                <div className={styles.Footer}>
                    <Button
                        className={cn("gradient-pro", styles.EnableProButton)}
                        size="large"
                        onClick={onEnablePro}
                    >
                        Enable PRO
                    </Button>
                    <ul>
                        <li>Monthly fee – $200,00</li>
                        <li>If monthly spend more than $5,000 – free</li>
                    </ul>
                </div>
            </div>
            <div className={styles.Image}>
                <img src={Image} alt="image" />
            </div>
        </div>

    )
}

export default BoostProBanner
