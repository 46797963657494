import React from 'react';
import styles from './EventInfoFormHeader.module.css';
import {Typography} from "antd";

const EventInfoFormHeader: React.FC = () => {
    return (
        <Typography.Title level={4} className={styles.EventInfoFormHeader}>
            Add your first event
        </Typography.Title>
    );
}

export default EventInfoFormHeader;
