import { FC } from 'react';
import { Button, Modal, } from 'antd';
import styles from './BoostPro.module.scss';
import { formatDate } from '@shared/utils';

type Props = {
    isOpen: boolean
    onClose: () => void
    date: string | boolean
}

const SuccessModal: FC<Props> = ({ isOpen, onClose, date }) => {
    return (
        <Modal
            title={(
                <div className={styles.SuccessModalTitle}>
                    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="96" height="96" rx="48" fill="#17CC80" fillOpacity="0.06" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M71.0883 47.9999C71.0883 60.745 60.7565 71.0769 48.0114 71.0769C35.2664 71.0769 24.9345 60.745 24.9345 47.9999C24.9345 35.2549 35.2664 24.923 48.0114 24.923C60.7565 24.923 71.0883 35.2549 71.0883 47.9999ZM74.7806 47.9999C74.7806 62.7842 62.7957 74.7692 48.0114 74.7692C33.2272 74.7692 21.2422 62.7842 21.2422 47.9999C21.2422 33.2157 33.2272 21.2307 48.0114 21.2307C62.7957 21.2307 74.7806 33.2157 74.7806 47.9999ZM63.6161 39.6131C64.3371 38.8921 64.3371 37.7232 63.6161 37.0022C62.8952 36.2812 61.7262 36.2812 61.0053 37.0022L42.4984 55.509L35.0338 47.6515C34.3315 46.9123 33.163 46.8823 32.4238 47.5845C31.6846 48.2868 31.6546 49.4553 32.3569 50.1945L41.1261 59.4253L42.4306 60.7985L43.77 59.4592L63.6161 39.6131Z" fill="#17CC80" />
                    </svg>

                    <span>Subscription enabled</span>
                </div>
            )}
            className={styles.SuccessModal}
            open={isOpen}
            onCancel={onClose}
            centered
            width={440}
            footer={(
                <div
                    className="flex justify-center"
                >
                    <Button
                        size="large"
                        type="primary"
                        onClick={onClose}
                    >
                        Got it
                    </Button>
                </div>
            )}
        >
            <div className={styles.SuccessModalText}>
                <span>
                    Your subscription has been enabled successfully.
                    {typeof date === 'string' ? (
                        <>
                            {' '}The next payment will be made on
                            &nbsp;
                            {formatDate(date)}
                            .
                        </>
                    ) : null}
                </span>
            </div>
        </Modal>
    )
}

export default SuccessModal;
