import React, { useEffect, useState, useMemo } from "react";
import { Button, notification, Tooltip, message, Spin } from 'antd';
import '../styles.scss'
import InfoIcon from "@icons/header/InfoIcon";
import { PlusOutlined } from '@ant-design/icons';
import { HELP_CENTER_LINK, INIT_CURRENT_PAGE } from "@shared/constants.ts";
import ChangeBillingModal from "@pages/EventRolePages/EventsPage/pages/info/billing-tab/ChangeBillingModal.tsx";
import { useBreakpoints } from "@hooks/browser";
import './styles.scss'
import { useGetUserOrganizerBillingByEventIdQuery, useGetUserOrganizerBillingQuery, usePostChangeBillingMutation } from '@store/type-event/billing/billing.api.ts';
import { Link, useParams } from "react-router-dom";
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import ArrowIcon from "@assets/ArrowIcon.tsx";
import StyledAlert from "@components/StyledAlert/StyledAlert.tsx";
import EmptyBilling from "@pages/EventRolePages/EventsPage/pages/info/billing-tab/EmptyBilling.tsx";
import EventBillingDrawer from "@pages/EventRolePages/Billing/components/EventBillingDrawer.tsx";

const BillingTab = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [billingsPage, setBillingsPage] = useState(INIT_CURRENT_PAGE)
    const [changedBilling, setChangedBilling] = useState<{ label: string; value: number } | null>(null)
    const [selectedBilling, setSelectedBilling] = useState<string | number | null>(null)
    const [isEventBillingDrawerOpen, setIsEventBillingDrawerOpen] = useState(false)

    const [postChangeBilling] = usePostChangeBillingMutation()

    const isDesktop = useBreakpoints().md;
    const { eventId } = useParams()
    // todo add state when back is ready
    const isDisabled = false

    const { data: billingByIdRes, isLoading: isBillingByIdLoading, isFetching: isBillingByIdFetching } = useGetUserOrganizerBillingByEventIdQuery({ eventId: eventId || '' }, { skip: !eventId })
    const { data: billingsRes, isLoading: isBillingsResLoading, isFetching: isBillingsResFetching } = useGetUserOrganizerBillingQuery({ page: billingsPage })

    useEffect(() => {
        if (billingByIdRes?.data?.id) {
            setSelectedBilling(billingByIdRes?.data?.id ? { value: billingByIdRes?.data?.id, label: billingByIdRes?.data?.name } : null)
        }
    }, [billingByIdRes]);

    const handleChangeBilling = async () => {
        if (!changedBilling?.value || !eventId) {
            return
        }
        setIsLoading(true)
        try {
            const response = await postChangeBilling({ billingId: changedBilling?.value, eventId })

            if ('error' in response) {
                void message.open({
                    type: 'error',
                    content: (response as { error: { data: { message: string } } })?.error?.data?.message || 'Billing error',
                });
            }

            if ('data' in response) {
                notification.open({
                    message: `Billing has been successfully changed to ${changedBilling?.label}`,
                    placement: 'bottomLeft',
                    closeIcon: false,
                    type: 'success'
                });
                setChangedBilling(null)
                setSelectedBilling(changedBilling?.value)
            }
        } catch (e) {
            notification.open({
                message: 'Billing has not been changed',
                placement: 'bottomLeft',
                closeIcon: false,
                type: 'warning'
            });
        }
        setChangedBilling(null)
        setIsLoading(false)
    }

    const onCloseChangeBillingModal = () => {
        setChangedBilling(null)
    }

    const onCreateBilling = () => {
        setIsEventBillingDrawerOpen(true)
    }

    const isDataLoading = isBillingsResLoading || isBillingsResFetching || isBillingByIdLoading || isBillingByIdFetching
    return (
        <>
            <article className="ad-accounts-page">
                <div className='ad-accounts-page__header'>
                    <div className='ad-accounts-page__header-title'>
                        <div>Billing</div>
                        <Tooltip
                            overlayClassName='tooltip-general billing-tab-tooltip'
                            title={(
                                <div>
                                    The same billing account can be used for multiple events
                                </div>
                            )}
                            placement={isDesktop ? "bottom" : "bottomRight"}
                            overlayStyle={{
                                width: '230px'
                            }}
                        >
                            <div className='cursor-pointer'>
                                <InfoIcon fillColor="#252628" width={18} height={18}/>
                            </div>
                        </Tooltip>
                    </div>
                    {Boolean(billingsRes?.data?.length) && (
                        <Button
                            type='primary'
                            onClick={onCreateBilling}
                        >
                            <PlusOutlined />
                            Create billing
                        </Button>
                    )}
                </div>
                {isDataLoading && (
                    <div className='flex flex-center ad-accounts-page__select-spin'>
                        <Spin size="small" />
                    </div>
                )}
                {!isDataLoading && Boolean(billingsRes?.data?.length) && (
                    <>
                        <StyledAlert
                            className="billing-tab__alert"
                            message={(
                                <div>
                                    Billing cannot be changed after ad campaigns have been launched.
                                    <br />
                                    Learn more
                                    {' '}
                                    <Link
                                        to={HELP_CENTER_LINK} target="_blank">
                                        <span className='text-link'>in our Help Center</span>
                                        <ArrowIcon />
                                    </Link>
                                </div>
                            )}
                            type="info"
                            showIcon
                        />
                        {isDisabled ? (
                            <Tooltip
                                overlayClassName='tooltip-general billing-tab-tooltip'
                                title={(
                                    <div>
                                        You can’t change billing due to sponsors have already launched ad campaigns
                                    </div>
                                )}
                                placement={isDesktop ? "bottom" : "bottomRight"}
                                overlayStyle={{
                                    width: '230px'
                                }}
                            >
                                <InfiniteSelect
                                    disabled
                                    id="billing-tab__billing"
                                    placeholder='Select from the existing billings'
                                    style={{ width: '100%' }}
                                    filterOption={false}
                                    notFoundContent={isBillingsResLoading || isBillingsResFetching ? <Spin size="small" /> :
                                        <div>There is no billings</div>}
                                    isLoading={isBillingsResLoading || isBillingsResFetching}
                                    listHeight={160}
                                    onChange={(_, option) => {
                                        setChangedBilling(option as { label: string; value: number })
                                    }}
                                    page={billingsPage}
                                    setPage={setBillingsPage}
                                    data={billingsRes}
                                    value={selectedBilling}
                                    allowClear
                                    showSearch={false}
                                    optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                                />
                            </Tooltip>
                        ) : (
                            <InfiniteSelect
                                id="billing-tab__billing"
                                placeholder='Select from the existing billings'
                                style={{ width: '100%' }}
                                filterOption={false}
                                notFoundContent={isBillingsResLoading || isBillingsResFetching ? <Spin size="small" /> :
                                    <div>There is no billings</div>}
                                isLoading={isBillingsResLoading || isBillingsResFetching}
                                listHeight={160}
                                onChange={(_, option) => {
                                    setChangedBilling(option as { label: string; value: number })
                                }}
                                page={billingsPage}
                                setPage={setBillingsPage}
                                data={billingsRes}
                                value={selectedBilling}
                                allowClear
                                showSearch={false}
                                optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                            />
                        )}

                    </>
                )}
                {!isDataLoading && !billingsRes?.data?.length && (
                    <EmptyBilling onCreateBilling={onCreateBilling} />
                )}
            </article>
            <ChangeBillingModal
                isOpen={Boolean(changedBilling)}
                onClose={onCloseChangeBillingModal}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onChange={handleChangeBilling}
                billing={changedBilling}
                isLoading={isLoading}
            />
            <EventBillingDrawer
                isOpen={isEventBillingDrawerOpen}
                onClose={() => setIsEventBillingDrawerOpen(false)}
            />
        </>
    )
}

export default BillingTab
