import { LandingDataByEventId } from '@store/events/models'
import './styles.scss'

interface LandingPageCardPreviewProps {
    landingData: LandingDataByEventId
}
const LandingPageCardPreview = ({ landingData }: LandingPageCardPreviewProps) => {
    return (
        <div
            className='my-events-landing-pages-card-preview'
        >
            <iframe
                title='landing-page-card-preview'
                className='moderation-table__landing-page__iframe'
                src={landingData.page}
            />
        </div>
    )
}

export default LandingPageCardPreview