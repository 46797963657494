import { FilterByEnumItem } from '@store/main/enums/models';
import { Subscription } from '@store/models-to replace/auth';
import { Button, Dropdown } from 'antd';
import React, { ReactNode } from 'react'

interface Props {
    children: ReactNode;
    goal: FilterByEnumItem;
    onSubmit: (id: string) => void;
    subscription: Subscription
}

const subscriptionTranslate = (name: string) => {
    switch (name) {
        case 'boost_pro':
            return 'Bost PRO'
        default:
            break;
    }
}

const GoalDropdown = ({
    goal,
    onSubmit,
    children,
    subscription
}: Props) => {

    const items = [
        {
            key: '1',
            label: (
                <span className='radio-goal__boost-pro-tooltip'>
                    <span>
                        {goal.translate} can be specified for your campaigns with {subscriptionTranslate(subscription.type.name)} enabled
                    </span>
                    <Button
                        size="large"
                        className="boost-pro-gradient radio-goal__boost-pro-button"
                        onClick={() => {
                            onSubmit(subscription.type.id)
                        }}
                    >
                        Enable PRO
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <Dropdown
            overlayClassName="radio-goal__boost-pro-tooltip"
            menu={{ items }}
            placement="top"
            trigger={['click']}
        >
            {children}
        </Dropdown>

    )
}

export default GoalDropdown