const CarryOutIcon = ({ isActive }: { isActive?: boolean }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.8576 3.21763H17.3576V1.50335C17.3576 1.38549 17.2611 1.28906 17.1433 1.28906H15.6433C15.5254 1.28906 15.429 1.38549 15.429 1.50335V3.21763H8.57185V1.50335C8.57185 1.38549 8.47542 1.28906 8.35756 1.28906H6.85756C6.7397 1.28906 6.64328 1.38549 6.64328 1.50335V3.21763H2.14328C1.66917 3.21763 1.28613 3.60067 1.28613 4.07478V21.8605C1.28613 22.3346 1.66917 22.7176 2.14328 22.7176H21.8576C22.3317 22.7176 22.7147 22.3346 22.7147 21.8605V4.07478C22.7147 3.60067 22.3317 3.21763 21.8576 3.21763ZM20.7861 20.7891H3.2147V5.14621H6.64328V6.43192C6.64328 6.54978 6.7397 6.64621 6.85756 6.64621H8.35756C8.47542 6.64621 8.57185 6.54978 8.57185 6.43192V5.14621H15.429V6.43192C15.429 6.54978 15.5254 6.64621 15.6433 6.64621H17.1433C17.2611 6.64621 17.3576 6.54978 17.3576 6.43192V5.14621H20.7861V20.7891ZM16.7147 9.53906H15.2361C15.0995 9.53906 14.9683 9.60603 14.8879 9.71585L10.846 15.2819L9.11292 12.898C9.03256 12.7882 8.90399 12.7212 8.76471 12.7212H7.28613C7.11203 12.7212 7.01024 12.9194 7.11203 13.0614L10.4977 17.7221C10.5378 17.7769 10.5902 17.8215 10.6507 17.8522C10.7112 17.8829 10.7781 17.8989 10.846 17.8989C10.9138 17.8989 10.9807 17.8829 11.0412 17.8522C11.1017 17.8215 11.1541 17.7769 11.1942 17.7221L16.8888 9.88192C16.9906 9.73728 16.8888 9.53906 16.7147 9.53906Z" fill={isActive ? '#4E67EA' : '#FFFFFF'} fillOpacity={isActive ? '0.2' : '1'} />
        </svg>

    );
};

export default CarryOutIcon;
