const FilterButtonIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00065 3.50016V2.3335M7.00065 3.50016C6.35632 3.50016 5.83398 4.0225 5.83398 4.66683C5.83398 5.31116 6.35632 5.8335 7.00065 5.8335M7.00065 3.50016C7.64498 3.50016 8.16732 4.0225 8.16732 4.66683C8.16732 5.31116 7.64498 5.8335 7.00065 5.8335M3.50065 10.5002C4.14498 10.5002 4.66732 9.97783 4.66732 9.3335C4.66732 8.68916 4.14498 8.16683 3.50065 8.16683M3.50065 10.5002C2.85632 10.5002 2.33398 9.97783 2.33398 9.3335C2.33398 8.68916 2.85632 8.16683 3.50065 8.16683M3.50065 10.5002V11.6668M3.50065 8.16683V2.3335M7.00065 5.8335V11.6668M10.5007 10.5002C11.145 10.5002 11.6673 9.97783 11.6673 9.3335C11.6673 8.68916 11.145 8.16683 10.5007 8.16683M10.5007 10.5002C9.85632 10.5002 9.33398 9.97783 9.33398 9.3335C9.33398 8.68916 9.85632 8.16683 10.5007 8.16683M10.5007 10.5002V11.6668M10.5007 8.16683V2.3335" stroke="#1F1F1F" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default FilterButtonIcon;
// const FilterBtnIcon = () => {
//     return (
//         <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M7.00065 3.50016V2.3335M7.00065 3.50016C6.35632 3.50016 5.83398 4.0225 5.83398 4.66683C5.83398 5.31116 6.35632 5.8335 7.00065 5.8335M7.00065 3.50016C7.64498 3.50016 8.16732 4.0225 8.16732 4.66683C8.16732 5.31116 7.64498 5.8335 7.00065 5.8335M3.50065 10.5002C4.14498 10.5002 4.66732 9.97783 4.66732 9.3335C4.66732 8.68916 4.14498 8.16683 3.50065 8.16683M3.50065 10.5002C2.85632 10.5002 2.33398 9.97783 2.33398 9.3335C2.33398 8.68916 2.85632 8.16683 3.50065 8.16683M3.50065 10.5002V11.6668M3.50065 8.16683V2.3335M7.00065 5.8335V11.6668M10.5007 10.5002C11.145 10.5002 11.6673 9.97783 11.6673 9.3335C11.6673 8.68916 11.145 8.16683 10.5007 8.16683M10.5007 10.5002C9.85632 10.5002 9.33398 9.97783 9.33398 9.3335C9.33398 8.68916 9.85632 8.16683 10.5007 8.16683M10.5007 10.5002V11.6668M10.5007 8.16683V2.3335" stroke="#1F1F1F" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
//         </svg>

//     );
// };

// export default FilterBtnIcon;
