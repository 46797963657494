import {FC} from 'react'
import {useNavigate} from "react-router-dom";
import {Button} from "antd";

type FooterPropsType = {
    onSubmit: () => void
    isSubmitDisabled: boolean
}

export const Footer: FC<FooterPropsType> = ({
   onSubmit,
   isSubmitDisabled
}) => {
    const navigate = useNavigate()

    return (
        <div className='event-editor-page__footer'>
            <div></div>
            <aside className='event-editor-page__footer-aside flex'>
                <Button
                    onClick={() => navigate(-1)}
                    size={'large'}
                >
                    Cancel
                </Button>
                <Button
                    disabled={isSubmitDisabled}
                    onClick={onSubmit}
                    type={'primary'}
                    size={'large'}
                >
                    Save
                </Button>
            </aside>
        </div>
    );
}