import { UserBillingPageContext } from '@contexts/UserBillingPageContext';
import { Button, Typography } from 'antd';
import { useContext, useState } from 'react';

const BillingInfoSection
    = () => {
        const { setSectionType, form } = useContext(UserBillingPageContext);
        const [isShowMore, setShowMore] = useState(false)

        return (
            <section className='billing-page-content__billinginfo'>
                <Typography.Title level={5} className='billing-section-title'>
                    Billing information
                </Typography.Title>
                <div className='billing-page-content__payment-method__row'>
                    <div className='billing-page-content__payment-method__row-title'>
                        Billing contact
                    </div>
                    <div className='billing-page-content__payment-method__row-info'>
                        {form.name}
                    </div>
                    <div className='billing-page-content__payment-method__row-cta'>
                        <Button
                            type="link"
                            style={{ padding: '0px' }}
                            onClick={() => setSectionType('billing_info')}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
                <div className='billing-page-content__payment-method__row'>
                    <div className='billing-page-content__payment-method__row-title'>
                        Company name
                    </div>
                    <div className='billing-page-content__payment-method__row-info'>
                        {form.company_name}
                    </div>
                    <div className='billing-page-content__payment-method__row-cta'>
                    </div>
                </div>
                <div className='billing-page-content__payment-method__row'>
                    <div className='billing-page-content__payment-method__row-title'>
                        City
                    </div>
                    <div className='billing-page-content__payment-method__row-info'>
                        {form.city?.slug}
                    </div>
                    <div className='billing-page-content__payment-method__row-cta'>
                    </div>
                </div>

                {isShowMore
                    ?
                    <div>
                        <div className='billing-page-content__payment-method__row'>
                            <div className='billing-page-content__payment-method__row-title'>
                                Postal code
                            </div>
                            <div className='billing-page-content__payment-method__row-info'>
                                {form.postal_code}
                            </div>
                            <div className='billing-page-content__payment-method__row-cta'>
                            </div>
                        </div>
                        <div className='billing-page-content__payment-method__row'>
                            <div className='billing-page-content__payment-method__row-title'>
                                E-mail
                            </div>
                            <div className='billing-page-content__payment-method__row-info'>
                                {form.email}
                            </div>
                            <div className='billing-page-content__payment-method__row-cta'>
                            </div>
                        </div>
                        <div className='billing-page-content__payment-method__row'>
                            <div className='billing-page-content__payment-method__row-title'>
                                Country
                            </div>
                            <div className='billing-page-content__payment-method__row-info'>
                                {form.country?.slug}
                            </div>
                            <div className='billing-page-content__payment-method__row-cta'>
                            </div>
                        </div>
                        <div className='billing-page-content__payment-method__row'>
                            <div className='billing-page-content__payment-method__row-title'>
                                Organization address
                            </div>
                            <div className='billing-page-content__payment-method__row-info'>
                                {form.address}
                            </div>
                            <div className='billing-page-content__payment-method__row-cta'>
                            </div>
                        </div>
                        <div className='billing-page-content__payment-method__row'>
                            <div className='billing-page-content__payment-method__row-title'>
                                VAT number
                            </div>
                            <div className='billing-page-content__payment-method__row-info'>
                                {form.tax_code}
                            </div>
                            <div className='billing-page-content__payment-method__row-cta'>
                            </div>
                        </div>
                    </div>
                    :
                    null}
                <div className='flex items-center cursor-pointer'>
                    {isShowMore
                        ?
                        <Button
                            type="link"
                            style={{ padding: '0px' }}
                            onClick={() => { setShowMore(false) }}
                        >
                            View less
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.9126 9.16252C11.1404 8.93471 11.1404 8.56537 10.9126 8.33756L7.41264 4.83756C7.18484 4.60976 6.81549 4.60976 6.58768 4.83756L3.08768 8.33756C2.85988 8.56537 2.85988 8.93471 3.08768 9.16252C3.31549 9.39033 3.68484 9.39033 3.91264 9.16252L7.00016 6.075L10.0877 9.16252C10.3155 9.39033 10.6848 9.39033 10.9126 9.16252Z" fill="#4E67EA" />
                            </svg>
                        </Button>
                        :
                        <Button
                            type="link"
                            style={{ padding: '0px' }}
                            onClick={() => { setShowMore(true) }}
                        >
                            View more
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M3.08736 4.8376C2.85955 5.06541 2.85955 5.43475 3.08736 5.66256L6.58736 9.16256C6.81516 9.39037 7.18451 9.39037 7.41232 9.16256L10.9123 5.66256C11.1401 5.43475 11.1401 5.06541 10.9123 4.8376C10.6845 4.6098 10.3152 4.6098 10.0874 4.8376L6.99984 7.92512L3.91232 4.8376C3.68451 4.6098 3.31516 4.6098 3.08736 4.8376Z" fill="#4E67EA" />
                            </svg>
                        </Button>
                    }
                </div>
            </section>
        )
    }

export default BillingInfoSection
