export const isHubSpotScriptLoaded = () => !!window.HubSpotConversations;

export const toggleHubspotStyles = (isOpen: boolean) => {
    const hubspotContainer = document.querySelector('#hubspot-messages-iframe-container')
    if (hubspotContainer) {
        if (isOpen) {
            hubspotContainer.classList.remove('visible')
        } else {
            hubspotContainer.classList.add('visible')
        }
    }
}

export const toggleHubspotChat = (isOpen: boolean) => {
    toggleHubspotStyles(isOpen)
    if (isOpen) {
        window.HubSpotConversations.widget.close();
    } else {
        window.HubSpotConversations.widget.open();
    }
}

export const isMobileDevice = () => {
    // @ts-ignore
    if (navigator.userAgentData) {
        // @ts-ignore
        return navigator.userAgentData.mobile;
    }
    return /Mobi|Android/i.test(navigator.userAgent);
}