import SkeletonCustom from '@components/SkeletonCustom'
import './styles.scss'
import LPCard from './components/LPCard'

const MyEventEditPageZeroState = () => {
    return (
        <div className='my-events-edit-zero-state'>
            <div className='flex justify-space-between'>
                <div className='flex'>
                    <SkeletonCustom
                        type='avatar'
                    />
                    <article className='my-events-edit-zero-state__header-title'>
                        <SkeletonCustom
                            type='title'
                        />
                    </article>
                    <SkeletonCustom
                        type='button'
                    />
                </div>

                <SkeletonCustom
                    type='paragraph'
                />
            </div>

            <div className='my-events-edit-zero-state__event-info'>
                <article >
                    <div className='flex mb-12'>
                        <SkeletonCustom
                            type='paragraph-m'
                        />
                        <div className='mx-6'>
                            <SkeletonCustom
                                type='paragraph-m'
                            />
                        </div>
                        <SkeletonCustom
                            type='paragraph-m'
                        />
                    </div>
                    <div className='flex'>
                        <SkeletonCustom
                            type='paragraph-m'
                        />
                        <div className='mx-6'>
                            <SkeletonCustom
                                type='paragraph-m'
                            />
                        </div>
                        <SkeletonCustom
                            type='paragraph-m'
                        />
                    </div>
                </article>
            </div>
            {/* <SkeletonCustom
                type='title'
            />
            <div className='my-events-edit-zero-state__key-cards-container'>
                <KeyCard />
                <KeyCard />
                <KeyCard />
            </div> */}

            <div className='flex justify-space-between'>

                <SkeletonCustom
                    type='title'
                />
                <SkeletonCustom
                    type='button-l'
                />
            </div>

            <div className='my-events-edit-zero-state__lp-cards-container'>
                <LPCard />
                <LPCard />
                <LPCard />
            </div>

            <div className='flex justify-space-between'>

                <SkeletonCustom
                    type='title'
                />
                <SkeletonCustom
                    type='button-l'
                />
            </div>
        </div>
    )
}

export default MyEventEditPageZeroState

