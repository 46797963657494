import axios, {AxiosError, AxiosRequestConfig} from "axios";
import {useState} from "react";

export type UseApiReqParams<D> = {
    reqConfig: AxiosRequestConfig<D>,
    isInitLoading?: boolean
}
export function useApiReq<ResDataType, ReqParamType = any>(params: UseApiReqParams<ResDataType>) {
    const [isLoading, setLoading] = useState<boolean>(!!params.isInitLoading);
    const [error, setError] = useState<AxiosError>();
    const [data, setData] = useState<ResDataType>();

    const run = (requestParam?: ReqParamType) => {
        const reqConfig = {...params.reqConfig};
        if (requestParam) {
            reqConfig[reqConfig.method === "GET" ? 'params' : 'data'] = requestParam;
        }
        setLoading(true);
        return axios.request(reqConfig)
            .then(res => {
                setData(res.data);
                setError(undefined);
                return res;
            })
            .catch(err => {
                setError(err);
                throw err;
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return {
        isLoading,
        data,
        error,
        run
    }
}