type Props = {
    fillColor?: string
    width?: number
    height?: number
    className?: string
}

const InfoIcon = ({ fillColor = 'white', width = 21, height = 20, className = '' }: Props) => {
    return (
        <svg width={width} height={height} className={className} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8339_3138)">
                <path d="M10.5 0C4.97768 0 0.5 4.47768 0.5 10C0.5 15.5223 4.97768 20 10.5 20C16.0223 20 20.5 15.5223 20.5 10C20.5 4.47768 16.0223 0 10.5 0ZM10.5 18.3036C5.91518 18.3036 2.19643 14.5848 2.19643 10C2.19643 5.41518 5.91518 1.69643 10.5 1.69643C15.0848 1.69643 18.8036 5.41518 18.8036 10C18.8036 14.5848 15.0848 18.3036 10.5 18.3036Z" fill={fillColor} />
                <path d="M12.9902 5.6409C12.3206 5.05385 11.4367 4.73242 10.4992 4.73242C9.56166 4.73242 8.67773 5.05608 8.00809 5.6409C7.31166 6.25028 6.92773 7.06948 6.92773 7.94671V8.11635C6.92773 8.21457 7.00809 8.29492 7.10631 8.29492H8.17773C8.27595 8.29492 8.35631 8.21457 8.35631 8.11635V7.94671C8.35631 6.96233 9.31836 6.16099 10.4992 6.16099C11.68 6.16099 12.642 6.96233 12.642 7.94671C12.642 8.6409 12.1509 9.27707 11.3898 9.56948C10.9166 9.75028 10.5148 10.0672 10.2268 10.4824C9.93443 10.9065 9.78264 11.4155 9.78264 11.9311V12.411C9.78264 12.5092 9.863 12.5896 9.96122 12.5896H11.0326C11.1309 12.5896 11.2112 12.5092 11.2112 12.411V11.9043C11.2124 11.6876 11.2788 11.4763 11.4018 11.2979C11.5249 11.1195 11.6988 10.9824 11.9009 10.9043C13.2179 10.3976 14.0684 9.23689 14.0684 7.94671C14.0706 7.06948 13.6867 6.25028 12.9902 5.6409ZM9.60631 14.911C9.60631 15.1478 9.70037 15.3749 9.86782 15.5423C10.0353 15.7098 10.2624 15.8039 10.4992 15.8039C10.736 15.8039 10.9631 15.7098 11.1305 15.5423C11.298 15.3749 11.392 15.1478 11.392 14.911C11.392 14.6742 11.298 14.4471 11.1305 14.2796C10.9631 14.1122 10.736 14.0181 10.4992 14.0181C10.2624 14.0181 10.0353 14.1122 9.86782 14.2796C9.70037 14.4471 9.60631 14.6742 9.60631 14.911Z" fill={fillColor} />
            </g>
            <defs>
                <clipPath id="clip0_8339_3138">
                    <rect width="20" height="20" fill={fillColor} transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default InfoIcon