import './styles.scss';
import { Link } from 'react-router-dom';
import ChevronLinkRightIcon from '@assets/ChevronLinkRightIcon';
import { ReactNode } from 'react';
import { ChevronLeftIcon } from "@assets/ChevronLeftIcon.tsx";

interface HeaderBreadcrumbProps {
    crumbs: {
        url?: string;
        label: string;
        isZeroLevel?: boolean
    }[];
    withEndArrow?: boolean;
    rightSide?: ReactNode
    leftSide?: ReactNode
}

const HeaderBreadcrumb = ({ crumbs, withEndArrow, rightSide, leftSide }: HeaderBreadcrumbProps) => {
    if (!crumbs.length) {
        return <></>
    }

    return (
        <header className='header header__breadcrumb'>
            <div className='header__content'>
                {crumbs.map((item, idx) => {
                    return (
                        <Link
                            key={idx}
                            to={item.url || ''}
                        >
                            {item.isZeroLevel ? (
                                <div className='flex-center header__title-breadcrumb cursor-pointer'>
                                    <ChevronLeftIcon width="24" height="24" fillColor="#C0C1C3" />
                                </div>
                            ) : (
                                <div className={`flex-center header__title-breadcrumb ${crumbs.length - 1 === idx ? 'header__title-breadcrumb__active' : ''} cursor-pointer ml-4`}>
                                    <span className='header__title text-overflow text-title'>
                                        {item.label}
                                    </span>
                                    {(idx < crumbs.length - 1 || withEndArrow) && <ChevronLinkRightIcon className="ml-4" fillColor="#C0C1C3" />}
                                </div>
                            )}
                        </Link>
                    )
                })}
                {leftSide}
            </div>
            {rightSide}
        </header>
    );
};

export default HeaderBreadcrumb;
