import React, { useEffect, useMemo } from 'react';
import { ResponsiveLine, SliceTooltipProps } from '@nivo/line';
import { GetStatisticsMetricsResponse, StatisticsMetrics } from "@shared/statisticTypes.ts";
import { colorByRowData, formatXValue, formatY, LineData } from "@components/Analytics/helpers.ts";
import {
    idMapping,
    metricsTypes,
    statusMenuItemSelectedIcon,
    tagRender
} from "@components/Analytics/constants.tsx";
import { Select, Segmented } from "antd";
import { formatDateToCommonFormat } from "@shared/utils.ts";
import { useGetFiltersByEnumQuery } from "@store/main/enums/enums.api.ts";

interface CustomResponsiveLineMobileProps {
    leftData: LineData[];
    rightData: LineData[];
    statisticsLeftValue: StatisticsMetrics[];
    statisticsRightValue: StatisticsMetrics[];
    setStatisticsLeftValue: (value: StatisticsMetrics[] | ((value: StatisticsMetrics[]) => StatisticsMetrics[])) => void
    setStatisticsRightValue: (value: StatisticsMetrics[] | ((value: StatisticsMetrics[]) => StatisticsMetrics[])) => void
    tickRotation: number;
    statisticsMetrics?: GetStatisticsMetricsResponse
    gap: string
    handleGapChange: (value: string) => void
    currenciesOptions: {
        value?: string;
        label: string;
    }[]
    currency?: string;
    setCurrency: (value: string) => void
}

export const customSliceTooltip = (props: SliceTooltipProps, data: LineData[]) => {
    const { slice } = props
    const xValue = slice?.points?.[0]?.data?.x.toString()
    const mappedData = data.map(({ id, data: pointsData }) => {
        const y = pointsData?.find(({ x }: { x: string }) => x.toString() === xValue)?.y || 0
        return (
            <div className="analytics-page__slice-tooltip__list-item" key={id}>
                <div style={{ background: colorByRowData({ id: id?.toString() }) }} className="status-plug" />
                <div>
                    {idMapping[id] || id}
                    :
                </div>
                {formatY(y, metricsTypes[id])}
            </div>
        )
    })
    return (
        <div
            className="analytics-page__slice-tooltip tets"
        >
            <span className="analytics-page__slice-tooltip__date">{formatDateToCommonFormat(xValue)}</span>
            {mappedData}
        </div>
    );
}

const CustomResponsiveLineMobile: React.FC<CustomResponsiveLineMobileProps> = ({
    leftData,
    rightData,
    statisticsLeftValue,
    statisticsRightValue,
    setStatisticsLeftValue,
    setStatisticsRightValue,
    tickRotation,
    statisticsMetrics,
    gap,
    handleGapChange,
    currenciesOptions,
    currency,
    setCurrency
}) => {
    const { data: gapsRes } = useGetFiltersByEnumQuery({ name: 'gap' })

    useEffect(() => {
        const id = gapsRes?.data?.[0]?.id
        if (id) {
            handleGapChange(id)
        }
    }, [gapsRes]);

    const statisticsMetricsOptions = useMemo(() => {
        const mappedStatisticsMetrics = statisticsMetrics?.data?.map((item) => ({ ...item, value: item.metric, label: item.translate })) || []
        const groups = [...new Set(mappedStatisticsMetrics?.map(({ type }) => type) || [])]
        return groups.map((group) => {
            const label = `${group.slice(0, 1).toUpperCase()}${group.slice(1)}`
            return {
                key: label,
                label: <span>{label}</span>,
                title: label,
                options: mappedStatisticsMetrics?.filter(({ type }) => type === group)
            }
        })
    }, [statisticsMetrics])

    const segmentedOptions = gapsRes?.data?.map(({ translate, id }) => ({ label: translate, value: id })) || []

    return (
        <>
            <div className="w-full my-16">
                <Segmented
                    value={gap}
                    onChange={handleGapChange}
                    options={segmentedOptions}
                    block
                />
            </div>

            <div className=''>

                <div className='flex items-center'>
                    <div className='w30p mr-6'>
                        <div className='mb-6 text-neutral-8'>Left:</div>
                        <Select
                            mode="tags"
                            allowClear
                            tagRender={tagRender}
                            maxTagCount="responsive"
                            style={{ width: '100%' }}
                            // @ts-ignore
                            options={statisticsMetricsOptions}
                            value={statisticsLeftValue}
                            onDeselect={(deselected) => {
                                setStatisticsLeftValue((prev) => prev.filter(({ metric }) => metric !== deselected as unknown as string))
                            }}
                            onClear={() => {
                                setStatisticsLeftValue([])
                            }}
                            onSelect={(_, selected: StatisticsMetrics) => {
                                setStatisticsLeftValue((prev) => {
                                    return [...prev, selected].filter(({ type }) => type === selected.type)
                                })
                                setStatisticsRightValue((prev) => {
                                    return prev.filter(({ metric }) => metric !== selected.metric)
                                })
                            }}
                            menuItemSelectedIcon={statusMenuItemSelectedIcon}
                            popupClassName='analytics__popup-select'
                            placeholder='Select left side'
                            fieldNames={{ value: 'metric', label: 'translate' }}
                        />
                    </div>

                    <div className='w30p mr-6'>
                        <div className='mb-6 text-neutral-8'>Right:</div>
                        <Select
                            mode="tags"
                            tagRender={tagRender}
                            allowClear
                            maxTagCount="responsive"
                            style={{ width: '100%' }}
                            // @ts-ignore
                            options={statisticsMetricsOptions}
                            value={statisticsRightValue}
                            onDeselect={(deselected) => {
                                setStatisticsRightValue((prev) => prev.filter(({ metric }) => metric !== deselected as unknown as string))
                            }}
                            onClear={() => {
                                setStatisticsRightValue([])
                            }}
                            onSelect={(_, selected: StatisticsMetrics) => {
                                setStatisticsRightValue((prev) => {
                                    return [...prev, selected].filter(({ type }) => type === selected.type)
                                })
                                setStatisticsLeftValue((prev) => {
                                    return prev.filter(({ metric }) => metric !== selected.metric)
                                })
                            }}
                            menuItemSelectedIcon={statusMenuItemSelectedIcon}
                            popupClassName='analytics__popup-select'
                            placeholder='Select right side'
                            fieldNames={{ value: 'metric', label: 'translate' }}
                        />
                    </div>

                    <div className="w30p">
                        <div className='mb-6 text-neutral-8'>Currency:</div>
                        <Select
                            style={{ width: '100px' }}
                            options={currenciesOptions}
                            value={currency}
                            onChange={setCurrency}
                            popupClassName='analytics__popup-select'
                            placeholder='Select currency'
                        />
                    </div>
                </div>

            </div>

            <div
                style={{ position: 'relative', width: 'calc(100vw - 108px)', height: '500px' }}
                className="analytics__responsive-line"
            >
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <ResponsiveLine
                        data={leftData}
                        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 'auto',
                            stacked: true,
                            reverse: false,
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        isInteractive
                        sliceTooltip={(props) => customSliceTooltip(props, [...leftData, ...rightData])}
                        axisBottom={{
                            tickPadding: 14,
                            tickRotation,
                            legendOffset: 36,
                            legendPosition: 'middle',
                            format: formatXValue
                        }}
                        axisLeft={{
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: -40,
                            legendPosition: 'middle',
                            format: (value) => formatY(value, statisticsLeftValue?.[0]?.type)
                        }}
                        axisRight={statisticsRightValue?.length ? null : {
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: 40,
                            legendPosition: 'middle',
                            format: (value: string) => formatY(value, statisticsLeftValue?.[0]?.type)
                        }}
                        pointSize={10}
                        pointBorderWidth={2}
                        pointLabel="data.yFormatted"
                        pointLabelYOffset={-12}
                        enableTouchCrosshair
                        useMesh
                        crosshairType="cross"
                        legends={[]}
                        enableSlices="x"
                        colors={colorByRowData}
                        curve="monotoneX"
                        theme={{
                            crosshair: {
                                line: {
                                    stroke: 'blue',
                                    strokeWidth: 1,
                                    strokeOpacity: 0.75,
                                }
                            }
                        }}
                    />
                </div>
                {Boolean(statisticsRightValue?.length) && (
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                        <ResponsiveLine
                            data={rightData}
                            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                            xScale={{ type: 'point' }}
                            yScale={{
                                type: 'linear',
                                min: 'auto',
                                max: 'auto',
                                stacked: true,
                                reverse: false,
                            }}
                            yFormat=" >-.2f"
                            isInteractive
                            sliceTooltip={(props) => customSliceTooltip(props, [...leftData, ...rightData])}
                            axisTop={null}
                            axisRight={{
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendOffset: 40,
                                legendPosition: 'middle',
                                format: (value) => formatY(value, statisticsRightValue?.[0]?.type)
                            }}
                            axisBottom={statisticsLeftValue?.length ? null : {
                                tickPadding: 14,
                                tickRotation,
                                legendOffset: 36,
                                legendPosition: 'middle',
                                format: formatXValue
                            }}
                            axisLeft={statisticsLeftValue?.length ? null : {
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: '',
                                legendOffset: -40,
                                legendPosition: 'middle',
                                format: (value) => formatY(value, statisticsRightValue?.[0]?.type)
                            }}
                            pointSize={10}
                            pointBorderWidth={2}
                            pointLabel="data.yFormatted"
                            pointLabelYOffset={-12}
                            enableTouchCrosshair
                            useMesh
                            crosshairType="cross"
                            legends={[]}
                            enableSlices="x"
                            colors={colorByRowData}
                            curve="monotoneX"
                            theme={{
                                crosshair: {
                                    line: {
                                        stroke: 'red',
                                        strokeWidth: 1,
                                        strokeOpacity: 0.75,
                                    }
                                }
                            }}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomResponsiveLineMobile;
