import { FC } from "react";
import { Button, Modal, } from "antd";
import { PatchLandingStatusType } from "@pages/EventRolePages/ModerationPage/components/ModerationTable";
import { ModerationStatusAction, OrganizerLanding } from '@store/type-event/models';

type ChangeStatusModalPropsType = {
    isOpen: boolean
    onClose: () => void
    data: {
        row: OrganizerLanding,
        status: ModerationStatusAction
    }
    handlePatchLandingStatus: ({ landing, status, reason, statusSlug }: PatchLandingStatusType) => void
}

const ApproveModal: FC<ChangeStatusModalPropsType> = ({ isOpen, onClose, data, handlePatchLandingStatus }) => {
    const handleOk = () => {
        handlePatchLandingStatus({
            landing: data.row.id,
            partner: data.row.partner,
            status: data.status.id,
            statusSlug: data.status.slug
        })
        onClose()
    }

    return (
        <Modal
            title="Landing page approval"
            open={isOpen}
            onCancel={onClose}
            className="approval-modal"
            centered
            zIndex={1001}
            footer={
                <>
                    <div
                        className="approval-modal__footer"
                    >
                        <Button
                            size={'large'}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size={'large'}
                            type={'primary'}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick={handleOk}
                        >
                            Approve
                        </Button>
                    </div>
                </>
            }
        >
            <span>Are you sure that you want to approve the <b>{data?.row?.partner}</b> landing page?</span>
        </Modal>
    )
}

export default ApproveModal;
