
const MsgErrorIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM9 16.4732C4.87366 16.4732 1.52679 13.1263 1.52679 9C1.52679 4.87366 4.87366 1.52679 9 1.52679C13.1263 1.52679 16.4732 4.87366 16.4732 9C16.4732 13.1263 13.1263 16.4732 9 16.4732Z" fill="#FF4D4F" />
            <path d="M8.0354 12.5357C8.0354 12.7915 8.13699 13.0367 8.31783 13.2176C8.49867 13.3984 8.74394 13.5 8.99969 13.5C9.25543 13.5 9.5007 13.3984 9.68154 13.2176C9.86238 13.0367 9.96397 12.7915 9.96397 12.5357C9.96397 12.28 9.86238 12.0347 9.68154 11.8539C9.5007 11.673 9.25543 11.5714 8.99969 11.5714C8.74394 11.5714 8.49867 11.673 8.31783 11.8539C8.13699 12.0347 8.0354 12.28 8.0354 12.5357ZM8.51754 10.2857H9.48183C9.57022 10.2857 9.64254 10.2134 9.64254 10.125V4.66071C9.64254 4.57232 9.57022 4.5 9.48183 4.5H8.51754C8.42915 4.5 8.35683 4.57232 8.35683 4.66071V10.125C8.35683 10.2134 8.42915 10.2857 8.51754 10.2857Z" fill="#FF4D4F" />
        </svg>
    )
}

export default MsgErrorIcon