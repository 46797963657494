import './styles.scss'
import InfiniteSelect from "@components/InfiniteSelect/InfiniteSelect.tsx";
import { Button, Spin } from 'antd';
import { useGetUserOrganizerBillingQuery } from "@store/type-event/billing/billing.api.ts";
import React, { useEffect, useState } from "react";
import { INIT_CURRENT_PAGE } from "@shared/constants.ts";
import { useNavigate, useParams } from "react-router-dom";
import { billing } from "@pages/routes.ts";
import ArrowIcon from '@assets/ArrowIcon';
import { PlusOutlined } from "@ant-design/icons";
import EventBillingDrawer from "@pages/EventRolePages/Billing/components/EventBillingDrawer.tsx";
import { menuItemSelectedIcon } from "@shared/menuItemSelectedIcon.tsx";
import { useBreakpoints } from "@hooks/browser";

const EventBillingHeader = () => {
    const { billingId } = useParams()
    const navigate = useNavigate()

    const isDesktop = useBreakpoints().md;

    const [billingsPage, setBillingsPage] = useState(INIT_CURRENT_PAGE)
    const [isEventBillingDrawerOpen, setIsEventBillingDrawerOpen] = useState(false)

    const { data: billingsRes, isLoading: isBillingsResLoading, isFetching: isBillingsResFetching } = useGetUserOrganizerBillingQuery({ page: billingsPage })

    useEffect(() => {
        if (!billingId || billingId === 'undefined') {
            const firstBillingId = billingsRes?.data?.[0]?.id
            if (firstBillingId) {
                navigate(`${billing}/${firstBillingId}/revenue-reports`)
            }
        }
    }, [billingId, billingsRes?.data, navigate]);

    const onCreateBilling = () => {
        setIsEventBillingDrawerOpen(true)
    }

    return (
        <div className='header__content event-billing-header__content'>
            <p className='header__title text-title'>
                Billing
            </p>
            {Boolean(billingId && billingId !== 'undefined') && (
                <>
                    <div className='vertical-divider' />
                    {((billingsRes?.data?.length || 0) > 1) ? (
                        <InfiniteSelect
                            suffixIcon={<ArrowIcon fillColor="#C0C1C3" width="28" height="28" />}
                            id="event-billing-header__selector"
                            className="event-billing-header__selector"
                            placeholder='Select from the existing billings'
                            filterOption={false}
                            notFoundContent={isBillingsResLoading || isBillingsResFetching ? <Spin size="small" /> :
                                <div>There is no billings</div>}
                            isLoading={isBillingsResLoading || isBillingsResFetching}
                            listHeight={160}
                            onChange={(value: string) => {
                                navigate(`${billing}/${value}/revenue-reports`)
                            }}
                            page={billingsPage}
                            setPage={setBillingsPage}
                            data={billingsRes}
                            value={!billingId || billingId === 'undefined' ? null : billingId}
                            showSearch={false}
                            optionsMapper={({ name, id }) => ({ value: id.toString(), label: name })}
                            popupClassName="event-billing-header__selector__popup organizer-billing-page__select-with-icon"
                            menuItemSelectedIcon={menuItemSelectedIcon}
                        />
                    ) : (
                        <div className="event-billing-header__selector__title">
                            {billingsRes?.data?.[0]?.name}
                        </div>
                    )}
                </>
            )}
            {isDesktop && (
                <Button
                    type='primary'
                    className="ml-auto mr-6 event-billing-header__create-button"
                    onClick={onCreateBilling}
                >
                    <PlusOutlined />
                    Create billing
                </Button>
            )}
            {isDesktop && (
                <div className='vertical-divider' />
            )}
            <EventBillingDrawer
                isOpen={isEventBillingDrawerOpen}
                onClose={() => setIsEventBillingDrawerOpen(false)}
            />
        </div>
    )
}

export default EventBillingHeader
