import SkeletonCustom from '@components/SkeletonCustom'
import { Divider } from 'antd'

const SkeletonEventsSetupPage = () => {
    return (
        <div>
            <div className='mb-10'>
                <SkeletonCustom
                    type='title'
                />
            </div>
            <div className='flex mb-24'>
                <SkeletonCustom
                    type='avatar'
                    width='100px'
                    height='100px'
                />
            </div>
            <Divider />

            <div className='flex mb-24'>
                <SkeletonCustom
                    type='title'
                />
            </div>
            <div className='my-8'>
                <SkeletonCustom
                    type='title-m'
                    width='100%'
                />
            </div>

            <div className='flex mb-24 mt-24'>
                <SkeletonCustom
                    type='title'
                />
            </div>
            <div className='my-8 mb-24'>
                <SkeletonCustom
                    type='title-m'
                    width='100%'
                />
            </div>

            <div className='flex mb-24 mt-24'>
                <SkeletonCustom
                    type='title'
                />
            </div>
            <div className='mt-24 mb-24'>
                <SkeletonCustom
                    type='title-m'
                    width='100%'
                />
            </div>

            <div className='flex mb-24 mt-24'>
                <SkeletonCustom
                    type='title'
                />
            </div>
            <div className='mt-24 mb-24'>
                <SkeletonCustom
                    type='image'
                    width='100%'
                    height='60px'
                />
            </div>

            <div className='flex mb-24 mt-24'>
                <SkeletonCustom
                    type='title'
                />
            </div>
            <div className='mt-24 mb-24'>
                <SkeletonCustom
                    type='image'
                    width='100%'
                    height='100px'
                />
            </div>
        </div>
    )
}

export default SkeletonEventsSetupPage