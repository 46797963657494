import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import './styles.scss';
import TagCustom from './TagCustom';

interface TagInputProps {
  options: { id: number; name: string }[];
  onChange: (ids: number[]) => void;
  formIdsValues: number[]
}


const TagInput: React.FC<TagInputProps> = ({
  options,
  onChange,
  formIdsValues
}) => {

  const [selectedTags, setSelectedTags] = useState<{ id: number; name: string }[]>([]);
  const [tagList, setTagList] = useState<{ id: number; name: string }[]>(options)


  const handleTagSelect = (id: number) => {
    onChange([...formIdsValues, id])
  };

  const handleRemoveTag = (id: number) => {
    const filteredIds = formIdsValues.filter(item => item !== id)
    onChange(filteredIds)
  };

  useEffect(() => {
    setTagList(options)
  }, [options])


  useEffect(() => {
    if (!formIdsValues.length) {
      setSelectedTags([])
      return setTagList(options)
    }
    if (formIdsValues) {

      const filteredTags = options.filter(item => !formIdsValues.includes(item.id))
      const selectedTagsNext = options.filter(item => formIdsValues.includes(item.id))

      setSelectedTags(selectedTagsNext);
      setTagList(filteredTags)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIdsValues])


  const menu = (
    <Menu onClick={(e) => handleTagSelect(+e.key)}>
      {tagList.map((tag) => (
        <Menu.Item key={tag.id}>{tag.name}</Menu.Item>
      ))}
    </Menu>
  );

  if (!tagList.length) {
    return (
      <div className='tag-input'>
        {selectedTags.map((tag) => (
          <TagCustom
            key={tag.id}
            id={tag.id}
            name={tag.name}
            onClose={() => handleRemoveTag(tag.id)}
          />
        ))}
      </div>
    )
  }

  return (
    <Dropdown
      overlay={menu}
    >
      <div className='tag-input'>
        {selectedTags.map((tag) => (
          <TagCustom
            key={tag.id}
            id={tag.id}
            name={tag.name}
            onClose={() => handleRemoveTag(tag.id)}
          />
        ))}
      </div>
    </Dropdown>
  );
};

export default TagInput;
