
const CalendarIcon = ({ fillColor }: { fillColor?: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2151 2.68136H14.4651V1.25279C14.4651 1.15458 14.3848 1.07422 14.2866 1.07422H13.0366C12.9383 1.07422 12.858 1.15458 12.858 1.25279V2.68136H7.14369V1.25279C7.14369 1.15458 7.06334 1.07422 6.96512 1.07422H5.71512C5.61691 1.07422 5.53655 1.15458 5.53655 1.25279V2.68136H1.78655C1.39146 2.68136 1.07227 3.00056 1.07227 3.39565V18.2171C1.07227 18.6122 1.39146 18.9314 1.78655 18.9314H18.2151C18.6102 18.9314 18.9294 18.6122 18.9294 18.2171V3.39565C18.9294 3.00056 18.6102 2.68136 18.2151 2.68136ZM17.3223 17.3242H2.67941V8.84208H17.3223V17.3242ZM2.67941 7.32422V4.2885H5.53655V5.35993C5.53655 5.45815 5.61691 5.5385 5.71512 5.5385H6.96512C7.06334 5.5385 7.14369 5.45815 7.14369 5.35993V4.2885H12.858V5.35993C12.858 5.45815 12.9383 5.5385 13.0366 5.5385H14.2866C14.3848 5.5385 14.4651 5.45815 14.4651 5.35993V4.2885H17.3223V7.32422H2.67941Z" fill={fillColor ? fillColor : "#BFBFBF"} />
        </svg>

    )
}

export default CalendarIcon