import '../styles.scss'
import { Input, Form, Popover } from 'antd';
import CustomButton from '@components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { usePasswordChangeMutation } from '@store/main/-user/user.api';
import { useState } from 'react';
import RegistrationLayout from '@layouts/RegistrationLayout';
import { ErrorDataResponse, errorsToFormAdapter } from '@shared/utils';
import PasswordUpdateConfirmed from './PasswordUpdateConfirmed';
import ErrorBanner from '@pages/CommonPages/components/banners/ErrorBanner';
import PasswordPopover from '../Register/components/PasswordPopover';
import { CheckCircleSuccessIcon } from '@icons/CheckCircleSuccessIcon';

type FieldType = {
    name?: string;
    password?: string;
    remember?: string;
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: 'Enter a valid email!',
        number: 'Enter a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

interface ErrorsValues {
    password: string;
    password_confirmation: string;
}

const errorsInitial = {
    password: '',
    password_confirmation: '',
}

const PasswordChangePage = () => {
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const [responseError, setResponseError] = useState<ErrorsValues>(errorsInitial)
    const [isLoading, setLoading] = useState(false)

    const [passwordChange] = usePasswordChangeMutation()
    const navigate = useNavigate()
    const [isShowConfirmed, setShowConfirmed] = useState(false)

    const [isSuccessStatus, setIsSuccessStatus] = useState(false);
    const isSuccessStatusHandler = (statusState: boolean) => {
        setIsSuccessStatus(statusState)
    }

    const onSubmit = async () => {
        setLoading(true)
        try {
            const response = await passwordChange({
                password,
                password_confirmation: passwordConfirm
            })

            if ('error' in response) {
                setLoading(false)
                setShowConfirmed(true)
                const errors = errorsToFormAdapter(response as ErrorDataResponse)
                setResponseError(errors as unknown as ErrorsValues)
            }

            if ('data' in response) {
                return navigate('/pass_recover_confirm')
            }

        } catch (error) {
            setLoading(false)
            console.log('SMTH WRONG');
        }
    };

    if (isShowConfirmed) {
        return <PasswordUpdateConfirmed />
    }

    return (
        <RegistrationLayout>
            <div className='auth'>
                <main className='auth__form'>
                    <p className='text-title mb-24'>
                        Create a new password
                    </p>
                    {
                        responseError.password || responseError.password_confirmation
                            ?
                            <ErrorBanner
                                text={responseError.password || responseError.password_confirmation}
                            />
                            :
                            null
                    }
                    <Form
                        name="basic"
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        autoComplete="off"
                        layout="vertical"
                        validateMessages={validateMessages}

                    >
                        <Popover
                            content={() => (
                                <PasswordPopover
                                    password={password}
                                    isSuccessStatusHandler={isSuccessStatusHandler}
                                />
                            )}
                            placement="bottomLeft"
                            trigger={'focus'}
                        >
                            <Form.Item<FieldType>
                                label="Password"
                            >
                                <Input.Password
                                    onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
                                    prefix={isSuccessStatus ? <CheckCircleSuccessIcon /> : null}
                                />
                            </Form.Item>
                        </Popover>
                        <Form.Item<FieldType>
                            label="Confirm password"
                        >
                            <Input.Password
                                onChange={(e) => setPasswordConfirm((e.target as HTMLInputElement).value)}
                            />
                        </Form.Item>

                        <Form.Item>
                            <CustomButton
                                title='Recover password'
                                classOptional='w-full'
                                type='primary'
                                size='large'
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={onSubmit}
                                loading={isLoading}
                                disabled={password.length < 8 || password !== passwordConfirm || !isSuccessStatus}
                            />
                        </Form.Item>
                    </Form>

                </main>

            </div>
        </RegistrationLayout>
    )
}

export default PasswordChangePage