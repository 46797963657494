import CalendarIcon from '@assets/CalendarIcon'
import EnvironmentIcon from '@assets/EnvironmentIcon'
import Avatar from '@components/Avatar'
import CustomButton from '@components/CustomButton'
import Loader from '@components/Loader'
import StatusTag from '@components/StatusTag'
import EventInfoComponent from '@pages/CommonPages/components/events/EventInfoComponent'
import { CampaignStatuses } from '@shared/constants'
import { ErrorDataResponse, formatDateRange } from '@shared/utils'
import { GetEventByIdFromCatalogDataItem } from '@store/type-partner/events-catalog/models'
import { usePatchPartnerEventStatusByIdMutation } from '@store/type-partner/events/partner-event.api'
import { message } from 'antd'
import { useState } from 'react'
import { Link } from 'react-router-dom'


export interface EventItemHeaderInfoProps {
    info: GetEventByIdFromCatalogDataItem
}

const EventItemHeaderInfo = ({ info }: EventItemHeaderInfoProps) => {
    const [eventItem, setEventItem] = useState<GetEventByIdFromCatalogDataItem>(info)
    const [isLoadingStatus, setIsLoadingStatus] = useState(false)
    const [patchEventStatus] = usePatchPartnerEventStatusByIdMutation()

    const handlePatchEventStatus = async ({ event, status }: { event: number, status: string }) => {
        try {
            setIsLoadingStatus(true)
            const response = await patchEventStatus({ event, status });

            if ('error' in response) {
                setIsLoadingStatus(false)
                const err = response as unknown as ErrorDataResponse
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                const content = err?.error?.data?.message || 'OOOPS, something is wrong'
                void message.open({
                    type: 'error',
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    content
                });
                // Handle the error here
            } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const event = response?.data?.data
                event && setEventItem({
                    id: event.id,
                    actions: event.status?.actions || [],
                    logo: event.logo,
                    title: event.name,
                    status: event.status,
                    date: {
                        started_at: event.date?.started_at || '',
                        finished_at: event.date?.finished_at || '',
                    },
                    location: event.location,
                    participants: event.participants,
                    category: event.category,
                    organization_url: event.url,
                })
                setIsLoadingStatus(false)
                return void message.open({
                    type: 'success',
                    content: 'Status updated',
                });
            }
        } catch (error) {
            setIsLoadingStatus(false)
            console.log('SMTH WRONG');
            return void message.open({
                type: 'error',
                content: 'OOOPS, something is wrong',
            });
            // Handle the error here
        }
    }

    return (
        <article>
            {isLoadingStatus && <Loader />}
            <header className='event-info__header flex items-center justify-space-between'>
                <div className='flex items-center'>
                    <Avatar
                        url={eventItem.logo}
                        size='68'
                    />
                    <h2 className='event-info__header-title mx-6'>{eventItem.name}</h2>

                    <StatusTag
                        status={eventItem.status as unknown as CampaignStatuses}
                    />
                </div>
                <div className='flex-center event-info__header__cta-container'>
                    {eventItem?.status?.actions && eventItem.status.actions.map(action => {
                        return (
                            <div key={action.id} className='mx-6'>
                                <CustomButton
                                    title={action.transit}
                                    onClick={() => void handlePatchEventStatus({ event: eventItem.id, status: action.id })}
                                />
                            </div>
                        )
                    })}
                </div>
            </header>

            <EventInfoComponent
                started_at={eventItem.date.started_at}
                finished_at={eventItem.date.finished_at}
                location={eventItem.location}
                participants={eventItem.participants}
            />

            <div className='event-info-desc flex items-center'>
                <span className='event-info__description-text'>
                    {eventItem.category}
                </span>
                <div className='text-divider-v' />
                <span className='event-info__description-text'>
                    <Link
                        to={eventItem.url}
                        className='no-style'
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {eventItem.url}
                    </Link>
                </span>
            </div>
        </article>
    )
}

export default EventItemHeaderInfo