
const VisaLogo = () => {
    return (
        <svg width="33" height="22" viewBox="0 0 33 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="32" height="21" rx="3.5" fill="white" />
            <rect x="0.5" y="0.5" width="32" height="21" rx="3.5" stroke="#E8E9EB" />
            <path d="M14.1193 14.7426H12.2534L13.4205 7.72681H15.2863L14.1193 14.7426Z" fill="#00579F" />
            <path d="M20.8818 7.8983C20.5138 7.75635 19.9301 7.59961 19.2083 7.59961C17.3657 7.59961 16.0681 8.5549 16.0601 9.92069C16.0448 10.9284 16.9891 11.488 17.6954 11.824C18.4173 12.1674 18.6627 12.3915 18.6627 12.6975C18.6554 13.1675 18.0794 13.3841 17.5422 13.3841C16.7972 13.3841 16.3981 13.2724 15.7915 13.011L15.5458 12.8989L15.2847 14.4738C15.7223 14.6676 16.5286 14.8396 17.3657 14.8471C19.3235 14.8471 20.5981 13.9066 20.6132 12.4512C20.6206 11.6525 20.122 11.0406 19.047 10.5405C18.3944 10.2195 17.9947 10.003 17.9947 9.67457C18.0023 9.37598 18.3327 9.07015 19.0694 9.07015C19.676 9.05517 20.1217 9.19692 20.4593 9.33878L20.6281 9.41328L20.8818 7.8983Z" fill="#00579F" />
            <path d="M23.3646 12.2572C23.5183 11.8541 24.1096 10.2942 24.1096 10.2942C24.1018 10.3092 24.2629 9.88371 24.3551 9.62251L24.4855 10.227C24.4855 10.227 24.8388 11.9064 24.9155 12.2572C24.6239 12.2572 23.7332 12.2572 23.3646 12.2572ZM25.6678 7.72681H24.2246C23.7795 7.72681 23.4414 7.85358 23.2493 8.30891L20.4778 14.7425H22.4356C22.4356 14.7425 22.7579 13.8766 22.8272 13.6901C23.042 13.6901 24.9466 13.6901 25.2229 13.6901C25.2764 13.9364 25.4455 14.7425 25.4455 14.7425H27.1732L25.6678 7.72681Z" fill="#00579F" />
            <path d="M10.6949 7.72681L8.86754 12.5109L8.66786 11.5406C8.33003 10.4211 7.2705 9.20468 6.08813 8.59986L7.7619 14.7352H9.73503L12.6679 7.72681H10.6949Z" fill="#00579F" />
            <path d="M7.16874 7.72681H4.1667L4.13599 7.86858C6.47777 8.45085 8.02868 9.85439 8.66589 11.5414L8.01328 8.31662C7.90584 7.86848 7.57565 7.74159 7.16874 7.72681Z" fill="#FAA61A" />
        </svg>
    )
}

export default VisaLogo