import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { useBreakpoints } from "@hooks/browser";

interface TourContextType {
    isTourOpen: boolean;
    setIsTourOpen: (value: boolean) => void;
    tourStep: number;
    setTourStep: (value: number) => void;
    eventId: number | null;
    setEventId: (value: number | null) => void;
    isCampaignTourOpen: boolean;
    setIsCampaignTourOpen: (value: boolean) => void;
    campaignTourStep: number;
    setCampaignTourStep: (value: number) => void;
}

export const TourContext = createContext<TourContextType>({} as TourContextType);

export const TourProvider: React.FC<PropsWithChildren> = (props) => {
    const [isTourOpen, setIsTourOpen] = useState<boolean>(true);
    const [tourStep, setTourStep] = useState(0);
    const [eventId, setEventId] = useState<number | null>(null);
    const [isCampaignTourOpen, setIsCampaignTourOpen] = useState<boolean>(true);
    const [campaignTourStep, setCampaignTourStep] = useState(0);

    const isLg = useBreakpoints().lg

    useEffect(() => {
        const isTourClosed = localStorage.getItem('is_tour_closed') || '';
        const isCampaignTourClosed = localStorage.getItem('is_campaign_tour_closed') || '';
        if (isTourClosed || isCampaignTourClosed) {
            setIsTourOpen(false)
            setIsCampaignTourOpen(false)
        }
    }, []);

    useEffect(() => {
        if (!isTourOpen || !isCampaignTourOpen || !isLg) {
            localStorage.setItem('is_tour_closed', 'true')
            localStorage.setItem('is_campaign_tour_closed', 'true')
        }
    }, [isTourOpen, isCampaignTourOpen, isLg]);

    const { children } = props

    return (
        <TourContext.Provider value={{
            isTourOpen,
            setIsTourOpen,
            tourStep,
            setTourStep,
            isCampaignTourOpen,
            setIsCampaignTourOpen,
            campaignTourStep,
            setCampaignTourStep,
            eventId,
            setEventId
        }}
        >
            {children}
        </TourContext.Provider>
    );
}
