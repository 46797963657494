import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetFiltersByEnumResponse } from './models';

const apiBaseUrl = import.meta.env.VITE_API_URL as string;
const filter_enums = 'filter_enums';

export const filterEnumsApi = createApi({
    reducerPath: filter_enums,
    baseQuery: fetchBaseQuery({
        baseUrl: apiBaseUrl,
    }),
    tagTypes: [
        filter_enums
    ],
    endpoints: build => ({
        //ASAP TO FIX AT BE - card id needed at request
        getEnumsList: build.query<string[], { type: string }>({
            query: ({ type }) => {
                return {
                    url: `/enums/${type}/list`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            }
        }),
        getFiltersByEnum: build.query<GetFiltersByEnumResponse, { name: string }>({
            query: ({ name }) => {
                return {
                    url: `/enums/${name}/filter`,
                    headers: {
                        Accept: 'application/json', // Specify JSON response
                    },
                }
            }
        }),
    }),
});

export const {
    useGetEnumsListQuery,
    useGetFiltersByEnumQuery
} = filterEnumsApi;
