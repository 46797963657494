import React from "react";
import './styles.scss'

type PartnerOnboardingLayoutPropsType = {
    headerSlot: React.ReactNode | null;
    leftSlot: React.ReactNode | null;
    rightSlot: React.ReactNode | null;
}

const PartnerOnboardingLayout: React.FC<PartnerOnboardingLayoutPropsType> = (props) => {
    const { headerSlot, leftSlot, rightSlot } = props;

    return (
        <div className='layout-partner-onboarding'>
            <header className='layout-partner-onboarding__header'>
                {headerSlot}
            </header>
            <section className='layout-partner-onboarding__content'>
                <article className='layout-partner-onboarding__content-main'>
                    {leftSlot}
                </article>
                <article className='layout-partner-onboarding__content-aside'>
                    {rightSlot}
                </article>
            </section>
        </div>
    )
}

export default PartnerOnboardingLayout;
