const GoogleIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#F2F3F5"/>
            <path d="M5.70013 14.2932L10.086 6.78516C10.6431 7.11312 13.4524 8.66775 13.9061 8.96337L9.52025 16.4719C9.04057 17.1055 5.09245 15.2548 5.70013 14.2928V14.2932Z" fill="#FBBC04"/>
            <path d="M18.3027 14.2924L13.9168 6.78478C13.3037 5.76445 11.9831 5.39367 10.8985 5.9963C9.81383 6.59893 9.48357 7.89712 10.0967 8.96299L14.4826 16.4715C15.0958 17.4914 16.4164 17.8622 17.501 17.2595C18.5382 16.6569 18.9159 15.3127 18.3027 14.2933V14.2924Z" fill="#4285F4"/>
            <path d="M7.60004 17.5494C8.81595 17.5494 9.80164 16.5883 9.80164 15.4026C9.80164 14.217 8.81595 13.2559 7.60004 13.2559C6.38413 13.2559 5.39844 14.217 5.39844 15.4026C5.39844 16.5883 6.38413 17.5494 7.60004 17.5494Z" fill="#34A853"/>
        </svg>
    )
}

export default GoogleIcon