import React, { FC } from 'react';
import { Modal, } from 'antd';
import Email from '@assets/png/email.png'
import styles from './EmailConfirmation.module.scss'

type Props = {
    isOpen: boolean
    onClose: () => void
}

const EmailConfirmationModal: FC<Props> = ({ isOpen, onClose }) => {
    return (
        <Modal
            title={(
                <div className={styles.ModalTitle}>
                    <img src={Email} alt="Email" />
                    <span>Confirm your e-mail address</span>
                </div>
            )}
            className={styles.EmailConfirmationModal}
            open={isOpen}
            onCancel={onClose}
            centered
            width={440}
            footer={null}
        >
            <span>To complete the process, please click the link in the e-mail we have sent to your inbox.</span>
        </Modal>
    )
}

export default EmailConfirmationModal;
