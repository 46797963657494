import React, { FC, useMemo } from 'react'
import { Col, Drawer, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from '@components/CustomButton';
import { ModerationActionsFormatted, ModerationStatuses, ModerationStatusesFormatted } from '@shared/constants';
import Avatar from '@components/Avatar';
import ArrowIcon from '@assets/ArrowIcon';
import { Link } from 'react-router-dom';
import { formatDate } from '@shared/utils';
import StatusDescription from '@components/StatusDescription';
import CommentList from '@pages/EventRolePages/ModerationPage/components/CommentList';
import HideIcon from "@assets/HideIcon";
import { ModerationStatusAction, OrganizerLanding } from '@store/type-event/models';

interface ModerationDrawerProps {
    isOpen: boolean;
    isPreviewModalOpen: boolean;
    onClose: () => void;
    drawerData: OrganizerLanding | null;
    handleChangeStatus: ({ row, status }: { row: OrganizerLanding, status: ModerationStatusAction }) => void
}

const ModerationDrawer: FC<ModerationDrawerProps> = ({
    isOpen,
    isPreviewModalOpen,
    onClose,
    drawerData,
    handleChangeStatus
}) => {
    const {
        event, partner, details, status
    } = drawerData || {}

    const handleStatusClick = ({ row, status }: { row: OrganizerLanding | null, status: ModerationStatusAction }) => {
        if (row) {
            handleChangeStatus({ row, status })
        }
    }

    const link = useMemo(() => {
        const splitted = details?.url_path?.link?.split('/') || ''
        return `/${splitted[splitted.length - 1]}`
    }, [details])

    const filteredHistory = useMemo(() => {
        return drawerData?.details.history.filter(({ status: historyStatus }) => historyStatus.name !== ModerationStatuses.draft)
    }, [drawerData?.details.history])

    return (
        <Drawer
            title="Landing page details"
            closeIcon={false}
            placement="right"
            open={isOpen}
            autoFocus={false}
            mask={false}
            width={450}
            zIndex={1001}
            onClose={onClose}
            maskStyle={{ 'opacity': 0, 'top': '62px' }}
            rootStyle={{ 'top': isPreviewModalOpen ? '73px' : '62px' }}
            contentWrapperStyle={{ boxShadow: 'none', borderLeft: '2px solid #f7f7f7' }}
            extra={isPreviewModalOpen ? (
                <div onClick={onClose} className='cursor-pointer'>
                    <HideIcon />
                </div>
            ) : (
                <CloseOutlined
                    onClick={onClose}
                />
            )}
        >
            {drawerData && (
                <div>
                    <div className='flex moderation-drawer__header'>
                        <Avatar
                            size='32'
                            url={event?.logo || ''}
                        />
                        <h4 className='mx-6'>{event?.name || '---'}</h4>
                    </div>
                    <Row gutter={[8, 16]}>
                        <Col span={12}>
                            <span className='moderation-drawer__title'>Partner</span>
                        </Col>
                        <Col span={12}>
                            {partner}
                        </Col>
                        <Col span={12}>
                            <span className='moderation-drawer__title'>Url</span>
                        </Col>
                        <Col span={12}>
                            {details?.url_path?.link ? (
                                <Link to={details?.url_path?.link} className='moderation-drawer__link' target="_blank">
                                    <span className='text-link campaign-title'>{link}</span>
                                    <div className='moderation-table__landing-page-title__card__arrow' >
                                        <ArrowIcon />
                                    </div>
                                </Link>
                            ) : '---'}
                        </Col>
                        <Col span={12}>
                            <span className='moderation-drawer__title'>Last modified</span>
                        </Col>
                        <Col span={12}>
                            {formatDate(details?.updated_at || '')}
                        </Col>
                        <Col span={12}>
                            <span className='moderation-drawer__title'>Status</span>
                        </Col>
                        <Col span={12}>
                            <div>
                                <StatusDescription
                                    status={status?.name || ''}
                                    formattedStatusData={ModerationStatusesFormatted}
                                />
                                {status?.actions && !isPreviewModalOpen
                                    ? (
                                        <div className='flex moderation-drawer__btns'>
                                            {status?.actions.map((cta) => {
                                                return (
                                                    <div className='my-8' key={cta.id}>
                                                        <CustomButton
                                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                                            title={ModerationActionsFormatted[cta.slug]}
                                                            type={cta.slug === ModerationStatuses.published ? 'primary' : 'default'}
                                                            block
                                                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                            onClick={() => handleStatusClick({
                                                                row: drawerData,
                                                                status: cta
                                                            })}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )
                                    :
                                    null}
                            </div>
                        </Col>
                    </Row>
                    <div className={`moderation-drawer__footer ${isPreviewModalOpen ? 'moderation-drawer__footer-height' : ''}`}>
                        <span className="moderation-drawer__footer__communication">Communication:</span>
                        <span
                            className="moderation-drawer__footer__communication moderation-drawer__footer__count"
                        >
                            {drawerData?.details?.history?.length || 0}
                        </span>
                        {Boolean(filteredHistory?.length) &&
                            <CommentList data={filteredHistory} />}
                    </div>
                </div>
            )}
        </Drawer>
    )
}

export default ModerationDrawer
