import { LandingAiGenerationSteps, LandingSectionCategory } from './models';

const baseUrl = import.meta.env.VITE_API_URL as string;

export const imageAction = `${baseUrl}/partner/landings/image`

export const OptionName = {
    backgroundColor: 'Background Color',
    backgroundImage: 'Background Image',
    image: 'Image',
    images: 'Images',
    video: 'Video',
    pdf: 'Pdf',
}
export const sectionTitle: Record<LandingSectionCategory, string> = {
    hero: 'Hero',
    about: 'About',
    service: 'Services',
    services: 'Services',
    team: 'Team',
    cta: 'Call to Action',
    form: 'Form',
    text: 'Text',
    gallery: 'Images & Gallery',
    video: 'Video',
};

export const aiGenerationStepsText: Record<LandingAiGenerationSteps, string> = {
    title: 'Boost Ai Assistant is trying to get the company name...',
    description: 'The company description generation is in progress...',
    images: 'In a couple of seconds, Ai Assistant will generate the necessary visual content...',
    finish: "Great! Let's check out the result 🚀",
    video: ''
};