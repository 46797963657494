import React from "react";
import styles from "./styles.module.css";

type OnboardingLayoutPropsType = {
    headerSlot: React.ReactNode | null;
    leftSlot: React.ReactNode | null;
    rightSlot:  React.ReactNode | null;
}

const OnboardingLayout:React.FC<OnboardingLayoutPropsType> = (props) => {
    const {headerSlot,leftSlot,rightSlot} = props;

    return (
        <div className={styles.OnboardingLayout}>
            <header className={styles.OnboardingLayout__header}>
                {headerSlot}
            </header>
            <section className={styles.OnboardingLayout__content}>
                <article className={styles.OnboardingLayout__contentLeftSlot}>
                    {leftSlot}
                </article>
                <article className={styles.OnboardingLayout__contentRightSlot}>
                    {rightSlot}
                </article>
            </section>
        </div>
    )
}

export default OnboardingLayout;
